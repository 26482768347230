export default class QueryStringBuilder {
  constructor() {
    this.pairs = []
  }

  add(key, value) {
    this.pairs.push({ key, value })
    return this
  }

  build(encode = true) {
    let result = this.pairs.map(pair => {
      if (pair.value === undefined) {
        return pair.key
      }

      return `${pair.key}=${pair.value}`
    })

    result = result.join('&')

    if (encode) {
      result = encodeURI(result)
    }

    return result
  }
}