import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import showNotification from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"
import Select from "../layout/Select"
import { useNavigate } from 'react-router-dom'

const CustomReversal = (props) => {
  const orderId = props.orderid ? props.orderid : props.match.params.orderid
  const storeId = props.storeid ? props.storeid : props.match.params.storeid
  const [data, setData] = useState({ orderId: orderId, storeId: storeId, orderNo: "", taxCode: "", amount: "", ean: "", gtin: "", comment: "", eans: [], remainingReversalAmount: 0 })
  const navigate = useNavigate()
  const taxCodes = [{ id: 1, value: 1, name: '25 %' }, { id: 2, value: 2, name: '12 %' }, { id: 3, value: 3, name: '6 %' }]

  useEffect(() => {
    const fetchData = async () => {
      if (orderId) {
        const response = await getData(
          `/api/stores/${storeId}/order/${orderId}/transactions/custom`
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  async function HandleReversal() {
    var conf = window.confirm(`Vill du återbetala ${data.amount} kr`)
    if (conf) {
      if (data.amount > data.remainingReversalAmount) {
        showNotification("Reversal", `Du kan återbetala max ${data.remainingReversalAmount} kr`, NotificationTypes.ERROR)
        return
      }
      const reversal = {
        orderId: orderId,
        storeId: storeId,
        taxCode: data.taxCode,
        amount: data.amount,
        ean: data.ean,
        gtin: data.gtin,
        comment: data.comment
      }
      const response = await postData(
        `api/order/reversal/custom/v2`, reversal, true
      )
      if (!response) {
        showNotification("Återbetalning", response.detail, NotificationTypes.ERROR)
      }
      else if (response.status && response.status !== 200) {
        showNotification("Återbetalning", response.detail, NotificationTypes.ERROR)
      } else {
        showNotification("Återbetalning", `Du har betalat tillbaka ${response.totalReversalAmount} kr`, NotificationTypes.SUCCESS)
        navigate("../..", { relative: "path" })
      }
    }
  }
  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (<div>
    <BackButton editPage={true} disabled={false} {...props}>{` Återbetalning för order ${data.orderNo}`}</BackButton>
    <ProntoContainer>
      <div className="form">
        <div className="form-row">
          <div className="form-section c-6 pr-10">
            <input className="input" type="number" onChange={handleInputChange} name="amount" defaultValue={data.amount} placeholder={Placeholder.Amount} />
            {taxCodes &&
              <div className="row">
                <Select data={taxCodes} name="taxCode" handleEvent={handleInputChange} defaultOption="Välj momssats"
                  defaultValue={data.taxCode} />
              </div>
            }
            {data.eans &&
              <div className="row">
                <select className="input input-select" onChange={handleInputChange} name="ean" required={true}>
                  <option key="-1" value="">Välj artikel</option>
                  {data.eans.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            }
            {/* <input className= "input" type="text" onChange={handleInputChange} name="ean" defaultValue={data.ean} placeholder={Placeholder.Ean}/> */}
            <input className="input" type="text" onChange={handleInputChange} name="gtin" defaultValue={data.gtin} placeholder={Placeholder.Gtin} />
            <input className="input" type="text" onChange={handleInputChange} name="comment" defaultValue={data.comment} placeholder={Placeholder.Comment} />

            <div className="row">
              <ClickButton className="btn--black mr-10" title={'Återbetala'} onClick={HandleReversal} />
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={() => navigate(-1)} />
            </div>
          </div>
        </div>
      </div>
    </ProntoContainer>
  </div>

  )
}

export default CustomReversal