import React from 'react'
import ArrowListItem from '../layout/ArrowListItem'

function SwishListItem(props) {
  const item = props.data
  const storeid = props.match.params.storeid
  var url = storeid ? `/stores/${storeid}/orders/${item.id}` : `/orders/${item.id}`
  if (item.isV2)
    url += '/v2'
  return (
    <ArrowListItem key={item.id} url={url} icon={item.icon} name={item.orderNr ? item.orderNr : "Saknas"}>
      <div className="list-item__element link-edit">{item.dateString}</div>
      <div className="list-item__element link-edit">{item.transactionCount}</div>
      <div className="list-item__element link-edit">{item.totalPrice}</div>
    </ArrowListItem>
  )
  
}

export default SwishListItem

