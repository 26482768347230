import React from 'react'
import {GetCssInput} from '../../layout/Input'


const CampaignMinimumPurchaseAmountRule = (props)=> {
  const {mode,handleInputChange, data} = props

  return (
    <div className="form">
      {/* <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.Name} placeholder={Placeholder.Name}/> */}
      <div className="row">
        <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="minimumPurchaseAmountRule__minimumAmount" defaultValue={data.minimumAmount}  />
        <span className="label form-label ml-10">Minsta summan att handla för</span>  
      </div>   
    </div>
  )
  
}


export default CampaignMinimumPurchaseAmountRule

