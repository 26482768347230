/* eslint-disable indent */
import React from 'react'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import { ProntoContainer } from '../layout/Containers'
import { defaultNotifications } from '../../utilis/Notifications'
import { useForm } from 'react-hook-form'
import { CloseIcon, TrashIconLined } from '../layout/Icons'
import Placeholder from '../layout/Placeholder'
import BackButton from '../backbutton/BackButton'
import Textbox from '../Form/Textbox'
import DropdownSelector from '../layout/DropdownSelector'
import DatePicker from '../Form/DatePicker'
import styles from './LimitedDeliveryPeriod.module.css'
import moment from 'moment'
import Button from '../UI/Button'

const LimitedDeliveryPeriod = (props) => {
  const mode = props.mode
  const history = props.history
  const storeId = props.storeId ? props.storeId : props.match.params.storeid
  const limitedDeliveryPeriodId = props.limitedDeliveryPeriodId
    ? props.limitedDeliveryPeriodId
    : props.match.params.limitedDeliveryPeriodId
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [categoryData, setCategoryData] = React.useState([])
  const [allProductsFromCategories, setAllProductsFromCategories] =
    React.useState([])
  const [selectedCategoryName, setSelectedCategoryName] = React.useState([])
  const [productData, setProductData] = React.useState([])
  const [selectedProducts, setSelectedProducts] = React.useState([])
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getTime()),
  )
  const [endDate, setEndDate] = React.useState(new Date(new Date().getTime()))
  let minDate = new Date()
  minDate.setDate(minDate.getDate() - 1)

  React.useEffect(() => {
    async function getLimitedDeliveryPeriod() {
      const { data } = await getServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeId}/limitedDeliveryPeriods/${limitedDeliveryPeriodId}`,
      )
      setData(data)
      setSelectedProducts(data?.productIds)
      handleStartDateChange(data?.fromDate)
      handleEndDateChange(data?.toDate)

      reset({
        ...data,
      })
    }
    if (props.mode === 'Edit') {
      getLimitedDeliveryPeriod()
    } else {
      handleStartDateChange(new Date())
      handleEndDateChange(new Date())
    }
  }, [])

  React.useEffect(() => {
    async function getCategories() {
      const { data } = await getServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeId}/maincategories/full`,
      )
      const flatData = data.flatMap((group) =>
        group.categories.map((category) => category),
      )

      setCategoryData(flatData)

      const uniqueIds = new Set()

      const allProducts = Array.from(
        new Set(
          flatData.reduce((acc, category) => {
            return acc.concat(category.products)
          }, []),
        ),
      ).filter((obj) => {
        if (!uniqueIds.has(obj.id)) {
          uniqueIds.add(obj.id)
          return true
        }
        return false
      })

      setAllProductsFromCategories(allProducts)
    }
    getCategories()
  }, [])

  const getProductsForCategory = (categoryId) => {
    const products = categoryData?.filter(
      (category) => category.id === categoryId,
    )[0]?.products
    setProductData(products)
  }

  function redirectToDelivery() {
    history.push(`/stores/${storeId}/limited-delivery-period`)
  }

  const handleEndDateChange = (date) => {
    setEndDate(moment(date).format('YYYY-MM-DD').toString())
  }

  const handleStartDateChange = (date) => {
    setStartDate(moment(date).format('YYYY-MM-DD').toString())
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setLoading(true)
    try {
      if (props.mode === 'Edit') {
        editLimitedDeliveryPeriod()
      }
      if (props.mode === 'Create') {
        createLimitedDeliveryPeriod()
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  async function createLimitedDeliveryPeriod() {
    const response = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeId}/limitedDeliveryPeriods`,
      {
        ...data,
        productIds: selectedProducts,
        fromDate: startDate,
        toDate: endDate,
      },
      'POST',
    )
    responseHelper(response, 'created')
  }

  async function editLimitedDeliveryPeriod() {
    const response = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeId}/limitedDeliveryPeriods/${limitedDeliveryPeriodId}`,
      {
        ...data,
        productIds: selectedProducts,
        fromDate: startDate,
        toDate: endDate,
      },
      'PUT',
    )
    responseHelper(response, 'updated')
  }

  async function deleteDeliveryProvider() {
    if (
      !window.confirm(`Är du säker på att du vill ta bort "${data?.name}"?`)
    ) {
      return
    }
    try {
      const response = await sendServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeId}/limitedDeliveryPeriods/${limitedDeliveryPeriodId}`,
        {},
        'DELETE',
      )
      responseHelper(response, 'deleted')
    } catch (error) {
      console.log(error)
    }
  }

  function responseHelper(response, type) {
    if (response.ok) {
      defaultNotifications[type](Placeholder.AvailabilityHours, data?.name)
      redirectToDelivery()
    } else {
      defaultNotifications.error(Placeholder.AvailabilityHours)
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    const value = event.target.value
    setData((prev) => ({ ...prev, [event.target.name]: value }))
  }

  return (
    <div>
      <BackButton backLabel={Placeholder.Delivery} editPage={true} {...props}>
        {mode === 'Create'
          ? 'Lägg till leveransperiod för tillfälligt sortiment'
          : `Ändra ${data?.name}`}
      </BackButton>
      <ProntoContainer>
        <form
          className="form"
          id="deliveryForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-section pr-10">
            <Textbox
              label={Placeholder.Catering.LimitedDeliveryPeriod.TitleLabel}
              placeholder={
                Placeholder.Catering.LimitedDeliveryPeriod.TitlePlaceholder
              }
              id="name"
              name="name"
              value={data?.name || ''}
              onChange={handleInputChange}
              validationRegister={register}
              validation={{
                required: true,
              }}
              errors={errors}
            />
            <DatePicker
              label={Placeholder.Catering.LimitedDeliveryPeriod.DatePickerStart}
              name="FromDate"
              selectedDate={
                new Date(startDate ? startDate : new Date(new Date().getTime()))
              }
              onChange={(date) => handleStartDateChange(date)}
            />
            <DatePicker
              label={Placeholder.Catering.LimitedDeliveryPeriod.DatePickerEnd}
              name="ToDate"
              selectedDate={new Date(endDate ? endDate : new Date())}
              onChange={(date) => handleEndDateChange(date)}
            />
            <DropdownSelector
              label={`${Placeholder.Catering.LimitedDeliveryPeriod.AddProductsToPeriod} (${selectedProducts.length})`}
              headerText={
                productData?.length > 0 ? selectedCategoryName : 'Kategorier'
              }
              dropdownTakeover={productData?.length > 0}
              selectedItems={selectedProducts}
              placeholder={'Catering - Alla produkter'}
              listItems={productData?.length > 0 ? productData : categoryData}
              setSelected={(e, selected) => {
                e.preventDefault()
                if (selected === 'back') {
                  setProductData([])
                } else if (selected === 'all') {
                  if (
                    productData.every((productData) =>
                      selectedProducts.includes(productData.id),
                    )
                  ) {
                    setSelectedProducts((prev) =>
                      prev.filter((productId) => {
                        return !productData.some(
                          (data) => data.id === productId,
                        )
                      }),
                    )
                  } else {
                    const addProducts = productData.filter(
                      (product) => !selectedProducts.includes(product.id),
                    )
                    setSelectedProducts((prev) => {
                      const productIds = addProducts.map(
                        (product) => product.id,
                      )
                      return [...productIds, ...prev]
                    })
                  }
                } else if (productData?.length === 0) {
                  setSelectedCategoryName(selected.name)
                  getProductsForCategory(selected.id)
                } else {
                  setSelectedProducts((prev) => {
                    const indexOfSelected = prev.indexOf(selected.id)
                    return indexOfSelected < 0
                      ? [...prev, selected.id]
                      : prev.filter((id) => id !== selected.id)
                  })
                }
              }}
            />
            <div
              style={{ marginBottom: '1rem' }}
              className="currentDeliveryPeriods"
            >
              {selectedProducts.map((productId) => {
                return (
                  <Button
                    Icon={() => (
                      <div
                        className="iconAsButton"
                        onClick={() => {
                          setSelectedProducts((prev) => {
                            return prev.filter(
                              (selectedProductId) =>
                                productId !== selectedProductId,
                            )
                          })
                        }}
                      >
                        <CloseIcon color="#fff" size="16" />
                      </div>
                    )}
                    IconPlacement="afterText"
                    key={productId}
                    size="small"
                    type="info"
                  >
                    <p className="limitedDeliveryPeriodButton">{`${
                      allProductsFromCategories.find(
                        (product) => product.id === productId,
                      )?.name
                    }`}</p>
                  </Button>
                )
              })}
            </div>
          </div>
        </form>
        <div className={styles.buttonGroup}>
          {limitedDeliveryPeriodId ? (
            <button
              className={styles.deleteButton}
              onClick={deleteDeliveryProvider}
            >
              <TrashIconLined color="#E3000B" />
              <span className={styles.deleteText}>
                Ta bort leveransalternativ
              </span>
            </button>
          ) : (
            <div></div>
          )}
          <div>
            <button
              className={`${styles.button} ${styles.pink} ${styles.cancel}`}
              onClick={redirectToDelivery}
              disabled={loading}
            >
              Avbryt
            </button>
            <button
              className={`${styles.button} ${styles.red}`}
              disabled={loading}
              type="submit"
              form="deliveryForm"
            >
              Spara Leveransperiod
            </button>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default LimitedDeliveryPeriod
