export function isEnvironment(env) {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV.trim() === env
  }
  else {
    return process.env.NODE_ENV.trim() === env
  }
}

export function GetBaseRoute() {
  return process.env.REACT_APP_BASE_ROUTE.trim()
}

export function GetGoogleAPiKey() {
  return process.env.REACT_APP_GOOGLEMAPSAPIKEY.trim()
}