import React, {useState, useEffect} from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import Checkbox,{parseBool} from '../layout/Checkbox'
import {ProntoContainerTop} from '../layout/Containers'
import {LinkButton,ClickButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Barcode from 'react-barcode'




const SwishPayment = (props) => {
  const KronorConverter = 100
  const mode = props.mode
  const history = props.history
  const orderId = props.orderid ? props.orderid : props.match.params.orderid
  const [data, setData] = useState({orderNo: ""})
  const [plockatItems, setPlockatItems] = useState([])
  const [markAsDelivered, setMarkAsDelivered] = useState(false)
    
    
  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/order/' + orderId
      )
      const result = await response
      if(result){
        setData(result)
      }
           
    }

    fetchData()
  }, [])


  function EanBarcode(ean){
    return  <Barcode value={`${ean}`} width={1} height={20} fontSize={10}/>
  }
  function GetTax(taxCode){
    if (taxCode === 1)
    {
      return 25
    }

    if (taxCode === 2)
    {
      return 12
    }

    if (taxCode === 3)
    {
      return 6
    }

    return 0
  }
  function CampaignItem(order){
    const campaign = order.campaign
    return   <div className="list-item">
      <div className="list-item__element">Rabatt</div>
      <div className="list-item__element">{campaign.discountCode}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">-{ campaign.campaignValue}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
    </div>
  }
  function OrderStatus(order){
    var isChecked = order.hasBeenDelivered === true
    console.log(order,isChecked)

    return   <div className="list-item">
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element"></div>
      <div className="list-item__element">Levererad: {<Checkbox checked={isChecked}  name="hasBeenDelivered" disabled={isChecked} handleEvent={handleOrderStatusChange}/>}</div>
    </div>
  }
  function DeliveryItem(order){
    const delivery = order.delivery
    return   <div className="list-item">
      <div className="list-item__element">{delivery.type}</div>
      <div className="list-item__element"> {delivery.ean && EanBarcode(delivery.ean)}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{ delivery.deliveryFee}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{GetTax(delivery.taxCode)}%</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{delivery.hasBeenReversed ? delivery.deliveryFee : 0}</div>
      <div className="list-item__element"></div>
    </div>
  }
  function OrderItem(item) {
    return <div className="list-item">
      <div className="list-item__element">{item.name}</div>
      <div className="list-item__element">
        {item.ean &&  EanBarcode(item.ean)}
      </div>
      <div className="list-item__element">{item.amount}</div>
      <div className="list-item__element">{item.price}</div>
      <div className="list-item__element">{item.VatSubTotal}</div>
      <div className="list-item__element">{GetTax(item.taxCode)}%</div>
      <div className="list-item__element">{item.subTotal}</div>
      <div className="list-item__element">{item.reversalAmount ? item.reversalAmount / KronorConverter : 0}</div>
      <div className="list-item__element">{<Checkbox checked={item.status === 10} name="active" disabled={mode !== Mode.Edit} handleEvent={handlePlockatChange(item.id)}/>}</div>
         
    </div>
  }

  const handleOrderStatusChange =  (event) => {
    const value = parseBool(event.target.value)
    setMarkAsDelivered(value)
  }

  const handlePlockatChange = (id) => (event) => {
    const value = parseBool(event.target.value)
    const index = plockatItems.findIndex( x => x.Id === id)
    if (index > -1) {
      plockatItems[index].Plockad = value
    }else{
      plockatItems.push(
        {
          Id:id,
          Plockad:value
        })
    }
    setPlockatItems(plockatItems)
  }

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/orders/edit'
    }
    
    return undefined
  }
  const handlePostData = async (event) => {
    event.persist()
    const url = getPostUrl()
    var orderData = {
      orderId: orderId,
      Items:plockatItems,
      MarkAsDelivered: markAsDelivered
    }
    if(url){
      const response = await postData(
        url,orderData
      )
      await response
      history.goBack()
    }else{
      console.error("url not defined")
    }
  }

  return (
    <ProntoContainerTop>
      <BackButton editPage={false} disabled={false} {...props}>{`Order ${data.orderNo}`}</BackButton>
      <div className="flex-right btn-control--absolut btn-control--absolut__top">
        {mode === Mode.Edit && <ClickButton onClick={handlePostData} className=" btn-control btn--black mr-10"title={`Spara`}/>}
        {mode === Mode.Edit && <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack} />    }

        {mode !== Mode.Edit && <LinkButton to={`${props.history.location.pathname}/edit`} className=" btn-control  btn--white"
          title={`Ändra`}/>}
        {mode !== Mode.Edit && <LinkButton to={`${props.history.location.pathname}/reversal`} className=" btn-control btn--white"
          title={`Hantera återbetalningar`}/>}
      </div>
       
      <ul className="no-style bg-grey">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Namn</div>
            <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
            <div
              className="list-item__element label hidden-down">Antal {data && data.totalItems && `(${data.totalItems} st)`}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Price}</div>
            <div className="list-item__element label hidden-down">Moms(kr)</div>
            <div className="list-item__element label hidden-down">{Placeholder.Moms}</div>
                      
            <div
              className="list-item__element label hidden-down">Total {data && data.totalPrice && `(${data.totalPrice} kr)`}</div>
            <div className="list-item__element label hidden-down">Återbetalat</div>
            <div className="list-item__element label hidden-down">Plockad</div>
                     
          </div>
        </li>
        {data && data.orderItemsFood && data.orderItemsFood.map(item => (
          <li key={item.itemId}>
            {OrderItem(item)}
          </li>
        ))}
        {data && data.orderItemsGrocery && data.orderItemsGrocery.map(item => (
          <li key={item.itemId}>
            {OrderItem(item)}
          </li>
        ))}
        {data.delivery  && 
                    <li>{DeliveryItem(data)}</li>
        }
        {data && data.campaign && data.campaign.discountCode && 
                    <li>{CampaignItem(data)}</li>
        }
        {data.id && mode === Mode.Edit && <li>{OrderStatus(data)}</li>}
      </ul>
    </ProntoContainerTop>
  )

}


export default SwishPayment