import React from "react"
import InputField from "./InputField"
import InputLabel from "./InputLabel"
import CreatableSelect from 'react-select/creatable'
import "./CreatableSelectBox.css"

function CreatableSelectBox ({label, ...props}) {
  return (
    <InputField className="creatable-select-box">
      <InputLabel label={label} />
      <CreatableSelect {...props}/>
    </InputField>
  )
}

export default CreatableSelectBox