import React, { useState, useEffect } from 'react'
import authService from '../../services/api-authorization/AuthorizeService'
import { ProntoContainerTop } from '../layout/Containers'
import './Login.css'
import { showErrorNotification } from "../../utilis/Notifications"
import Placeholder from "../layout/Placeholder"
import { useForm } from "react-hook-form"
import Textbox from '../Form/Textbox'
import Button from '../UI/Button'

function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const textboxStyle = { backgroundColor: "#fff" }

  const [loggingIn, setLoggingIn] = React.useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search)
      const authorizationCode = params.get('code')
      if (authorizationCode) {
        setLoggingIn(true)
        var result = await authService.getOauthAccesTokenAndSignIn(authorizationCode)
        if (result === true) {
          window.location = "/"
        } else {
          showErrorNotification("Logga in", "Fel användarnamn, lösenord eller behörighet saknas")
          setLoggingIn(false)
        }
      }
    }
    fetchData()
  }, [])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setLoggingIn(true)

    var result = await authService.signIn({ email, password })
    if (result) {
      window.location = "/"
    } else {
      showErrorNotification("Logga in", "Fel användarnamn eller lösenord")
      setLoggingIn(false)
    }
  }

  const onOauthLogin = async function onLoginOauth(e) {
    e.preventDefault()
    setLoggingIn(true)

    const response = await authService.getOauthUri()
    if (response && response.data && response.data.oAuthUri) {
      window.location = response.data.oAuthUri
    } else {
      showErrorNotification("Logga in", "gick inte att hämta login path")
      setLoggingIn(false)
    }
  }

  return (
    <div className="pronto-login-wrapper">
      <ProntoContainerTop className="pronto-login">
        <h3 className="pronto-login-wrapper__main-headline">ICA Now Inloggningsuppgifter</h3>
        <form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
          <Textbox
            type="text"
            label={Placeholder.Email}
            autoComplete="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            validationRegister={register}
            validation={
              {
                required: true
              }
            }
            errors={errors}
            style={textboxStyle}
          />
          <Textbox
            type="password"
            label={Placeholder.Password}
            autoComplete="current-password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            validationRegister={register}
            validation={
              {
                required: true
              }
            }
            errors={errors}
            style={textboxStyle}
          />

          <Button
            type="save"
            expanded
            loading={loggingIn}
            nativeType="submit"
            form="loginForm"
          >
            Logga in
          </Button>
          <br></br>
        </form>
        <div className='flex pronto-login__otherlogin'>
          <a onClick={onOauthLogin} className='btn-control btn-control__link btn--white btn--inline-flex mt-10 mb-10-mobile'>
            Logga in med ICA AD konto
          </a>
        </div>
      </ProntoContainerTop>
    </div>
  )
}

export default Login