import React, { useState, useEffect } from 'react'
import { getData, sendData, downloadFile } from '../../../services/HttpClient'
import Placeholder from '../../../components/layout/Placeholder'
import ContentContainer from '../../../components/layout/ContentContainer'
import GridRow from '../../../components/Grid/GridRow'
import GridItem from '../../../components/Grid/GridItem'
import SelectList from '../../../components/Form/SelectList'
import Button from '../../../components/UI/Button'
import DatePicker from '../../../components/Form/DatePicker'
import { emptyGuid } from '../../../utilis/Constants'
import { defaultNotifications } from '../../../utilis/Notifications'
import ItemList, {ItemListColumn} from '../../../components/ItemList/ItemList'
import ListSummary from '../../../components/UI/ListSummary'
import Badge from '../../../components/UI/Badge'

const OrderStatusReport = ({
  storeSpecific = false,
  ...props
}) => {
  const storeId = props.match.params.storeid
  const defaultDate = new Date(new Date().setHours(0, 0, 0, 0))
  const noDateValue = "---"
  const [channels, setChannels] = useState([])
  const [stores, setStores] = useState([])
  const [data, setData] = useState({
    orders: [],
    continuationToken: ""
  })
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [fetchingData, setFetchingData] = useState(false)
  const [generatingReport, setGeneratingReport] = useState(false)
  const [isSearchPerformed, setIsSearchPerformed] = useState(false)
  const [selectedStoreId, setSelectedStoreId] = useState(emptyGuid)
  const [selectedChannelTypeId, setSelectedChannelTypeId] = useState(emptyGuid)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  useEffect(() => {
    setStartDate(getDefaultDate())
    handleEndDateChange(getDefaultDate())

    const fetchData = async () => {
      setIsInitialFetch(true)

      const channelResult = await getData('api/system/channels')
      if (channelResult.ok) {
        setChannels(channelResult.data)
      } else {
        defaultNotifications.error(Placeholder.Channels)
      }

      if (!storeSpecific) {
        const storeResult = await getData('api/stores/v2')
        if (storeResult.ok) {
          setStores(storeResult.data)
        } else {
          defaultNotifications.error(Placeholder.Stores)
        }
      }

      setData({})
      setIsInitialFetch(false)
    }

    fetchData()
  }, [storeId, storeSpecific])

  const handleSelectedChannelTypeIdChange = (e) => {
    setSelectedChannelTypeId(e.target.value)
  }

  const handleSelectedStoreIdChange = (e) => {
    setSelectedStoreId(e.target.value)
  }

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999)
    setEndDate(date)
  }

  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0)
    setStartDate(date)
  }

  async function fetchInitialOrders() {
    await fetchOrders(true)
  }

  async function fetchMoreOrders() {
    await fetchOrders(false)
  };

  async function fetchOrders(isInitialSearch) {
    setFetchingData(true)

    var searchCriteria = {
      storeId: storeSpecific ? storeId : selectedStoreId,
      selectedChannelTypeId: selectedChannelTypeId,
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
    }

    var customHeader = !isInitialSearch && data.continuationToken ? { 'x-ms-continuation': data.continuationToken } : undefined

    const res = await sendData('api/reports/orderStatus', searchCriteria, "POST", customHeader)
    if (res.ok) {
      let newData = {}
      if (!isInitialSearch) {
        newData = {
          orders: data.orders.concat(res.data.orders),
          continuationToken: res.data.continuationToken
        }
      }
      else {
        newData = res.data
      }

      setData(newData)
    } else {
      defaultNotifications.error()
    }

    setFetchingData(false)
    setIsSearchPerformed(true)
  }

  async function onCreateReportClick() {
    setGeneratingReport(true)

    const res = await downloadFile(`/api/reports/export/orderstatus/${storeSpecific ? storeId : selectedStoreId}/${selectedChannelTypeId}/${startDate.toISOString()}/${endDate.toISOString() }`)
    if (!res.ok) {
      defaultNotifications.error()
    }

    setGeneratingReport(false)
  }

  const getDefaultDate = () => {
    return new Date(defaultDate.getTime())
  }

  return (
    <ContentContainer
      title="Statusrapport"
      fetchingData={isInitialFetch}
      expandContent={true}
      backDisabled={!storeSpecific}
      backText={Placeholder.Reports.Reports}
      backTo={`/stores/${storeId}/reports`}
      actions={() =>
        <>
          <Button
            type="action"
            loading={generatingReport}
            onClick={() => onCreateReportClick()}
          >
            {Placeholder.Reports.CreateReport}
          </Button>
        </>
      }
    >
      <div className="top-container gutter bg--white">
        <GridRow>
          <GridItem cols="2">
            <SelectList
              label="Köpkanal"
              options={channels}
              optionValueField="value"
              name="selectedChannelTypeId"
              onChange={handleSelectedChannelTypeIdChange}
              defaultOption="Alla köpkanaler"
              value={selectedChannelTypeId}
            />
          </GridItem>
          {!storeSpecific &&
            <GridItem cols="2">
              <SelectList
                label="Butik"
                options={stores}
                optionValueField="id"
                name="selectedStoreId"
                onChange={handleSelectedStoreIdChange}
                defaultOption="Alla butiker"
                value={selectedStoreId}
              />
            </GridItem>
          }
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, från"
              name="StartDate"
              selectedDate={new Date(startDate)}
              onChange={(date) => handleStartDateChange(date)}
            />
          </GridItem>
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, till"
              name="EndDate"
              selectedDate={new Date(endDate)}
              onChange={(date) => handleEndDateChange(date)}
            />
          </GridItem>
        </GridRow>

        <Button onClick={fetchInitialOrders} loading={fetchingData}>Visa</Button>
      </div>

      {data && data.orders && isSearchPerformed &&
        <>
          <ItemList
            data={data.orders}
            showEmpty={true}
            shadow={false}
            emptyHeading="Din sökning resulterade inte i några träffar."
            stickyHeader={true}
            renderRow={(item) =>
              <React.Fragment>
                <ItemListColumn id="orderno" label="Ordernummer" narrow>
                  {item.orderNo}
                </ItemListColumn>
                <ItemListColumn id="channel" label="Köpkanal" narrow>
                  {item.channelTypeName}
                </ItemListColumn>
                <ItemListColumn id="store" label="Butik">
                  {item.storeName}
                </ItemListColumn>
                <ItemListColumn id="deliveryTypeText" label="Leveranssätt" narrow>
                  {item.deliveryBongDisplayName 
                    ? `${item.deliveryTypeText} (${item.deliveryBongDisplayName})` 
                    : item.deliveryTypeText}
                </ItemListColumn>
                <ItemListColumn id="groceryItems" label="Varor" narrow>
                  {item.totalGrocery} st
                </ItemListColumn>
                <ItemListColumn id="menuItems" label="Mat" narrow>
                  {item.totalMenuItems} st
                </ItemListColumn>
                <ItemListColumn id="totalPrice" label="Ordersumma" narrow>
                  {item.totalPrice ? item.totalPrice.toFixed(2) : 0.00} kr
                </ItemListColumn>
                <ItemListColumn id="deliveryDate" label="Leveransdatum" columns={1}>
                  <Badge expanded>
                    {item.deliveryDateFormatted || noDateValue}
                  </Badge>
                </ItemListColumn>
                <ItemListColumn id="underProcessing" label="Tillagas" columns={1}>
                  <Badge expanded>
                    {item.underProcessing || noDateValue}
                  </Badge>
                </ItemListColumn>
                <ItemListColumn id="readyForDelivery" label="Redo att hämtas" columns={1}>
                  <Badge expanded>
                    {item.readyForDelivery || noDateValue}
                  </Badge>
                </ItemListColumn>
                <ItemListColumn id="outForDelivery" label="Ute på leverans" columns={1}>
                  <Badge expanded>
                    {item.outForDelivery || noDateValue}
                  </Badge>
                </ItemListColumn>
                <ItemListColumn id="delivered" label="Levererad" columns={1}>
                  <Badge expanded type="success">
                    {item.delivered || noDateValue}
                  </Badge>
                </ItemListColumn>
              </React.Fragment>
            }>
          </ItemList>

          <ListSummary
            hasMore={data.continuationToken}
            itemCount={data && data.orders && data.orders.length > 0 ? data.orders.length : 0}
            loading={fetchingData}
            onLoadMore={fetchMoreOrders}
          />
        </>
      }
    </ContentContainer>
  )
}

export default OrderStatusReport
