import React, { useState, useEffect } from "react"
import Placeholder from "../layout/Placeholder"
import { getData, postData } from "../../services/Ajax"
import Mode from "../../utilis/Mode"
import { GetCssInput } from "../layout/Input"
import { ProntoContainer } from "../layout/Containers"
import { ClickButton, SaveButton } from "../layout/Button"
import BackButton from "../backbutton/BackButton"
import MediaLibraryUploadInfo from "../UI/MediaLibraryUploadInfo"
import Textbox from "../Form/Textbox"
import ChannelType from "../channels/ChannelType"

const Restaurant = (props) => {
  const mode = props.mode
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid
  const history = props.history
  const [data, setData] = useState({ storeId: storeId })

  useEffect(() => {
    const fetchData = async () => {

      if (restaurantId) {
        const response = await getData(`/api/restaurant/${restaurantId}`)
        const result = await response
        setData(result)
      }
    }
    fetchData()
  }, [])

  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return "/api/restaurants/edit"
    }
    if (mode === Mode.Create) {
      return "/api/restaurants/create"
    }
    if (mode === Mode.Delete) {
      return "/api/restaurants/delete"
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if (url) {
      const response = await postData(url, data)
      return await response
    } else {
      console.error("url not defined")
    }
  }

  const handleChannelInputChange = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const key = values[0]
    const name = values[1]

    const objIndex = data.availableChannelTypes.findIndex((x) => x.id == key)

    data.availableChannelTypes[objIndex][name] = event.target.value == "true"
    setData(data)
  }

  const handleImageChange = (value) => {
    // data.imageUrl = value;
    console.log(value, data.imageUrl)
    setData(() => ({ ...data, imageUrl: value }))
  }
  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <div>
      <BackButton
        backLabel={Placeholder.Restaurants}
        editPage={true}
        {...props}
      >
        {data && data.name}
      </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <Textbox
              className={GetCssInput(mode)}
              label='Bild'
              placeholder='Klistra in URL'
              id='image'
              name='image'
              value={data.imageUrl || ''}
              disabled={mode === Mode.Delete}
              onChange={(e) => handleImageChange(e.target.value)}
              beforeTextbox={() => <MediaLibraryUploadInfo storeId={storeId} channel='icakiosk' />}
            />
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="name"
              defaultValue={data.name}
              placeholder={Placeholder.Name}
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="orderingNumber"
              defaultValue={data.orderingNumber}
              placeholder={Placeholder.Sort}
            />
            <textarea
              name="description"
              className={GetCssInput(mode) + " input-textarea"}
              onChange={handleInputChange}
              defaultValue={data.description}
              placeholder={Placeholder.Description}
            ></textarea>
          </div>
          <div className="form-section">
            <span className="label">Kanaler</span>
            <hr />
            {data.availableChannelTypes &&
              data.availableChannelTypes.map((item) => (
                <li key={item.id} style={{ listStyleType: "none" }}>
                  <ChannelType
                    data={item}
                    {...props}
                    handleEvent={handleChannelInputChange}
                  />
                </li>
              ))}
          </div>

          <div className="row">
            <SaveButton
              className="btn--black mr-10"
              title={
                Mode.Delete === mode
                  ? Placeholder.Delete
                  : Placeholder.SaveButton
              }
              onClick={handlePostData}
            />
            <ClickButton
              className="btn--white"
              title={Placeholder.Cancel}
              onClick={history.goBack}
            />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default Restaurant
