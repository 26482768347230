import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData, postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import {parseBool} from '../layout/Checkbox'
import Select from "../layout/Select"


const ProntoProducts = (props) => {

  const categoryId = props.match.params.categoryid
  const defaultLimit = 200
  const [data, setData] = useState([])
  const [limit] = useState(defaultLimit)
  const [categories, setCategories] = useState([])
  const [showModal, setModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [currentCatetory, setCurrentCatetory] = useState()
  const [dataLoading, setDataLoading] = useState(false)
  const [continuationToken, setContinuationToken] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const categoryResponse = await getData(
        `/api/categories/`
      )
      const categoryResult = await categoryResponse
      if(categoryResult) {
        const items = categoryResult.map((c) => ({ id: c.id, value: c.id, name: c.name}))
        setCategories(items)
                
        if(categories) {
          setCurrentCatetory(items.filter(item => item.id === categoryId)[0].name)
        }
      }
      const result = await loadProducts()
      setData(result)
      setDataLoading(false)
    }

    fetchData()
  }, [])

  const loadProducts = async () => {
    setDataLoading(true)

    if (categoryId) {
      var customHeader = continuationToken? {'x-ms-continuation': continuationToken}: undefined
      const response = await getData(
        `/api/category/${categoryId}/products/${limit}`, customHeader
      )
      const result = await response

      let products = undefined 
      if(result.products) {
        products = result.products.map((p) => ({id: p.id, ean: p.ean, categoryId: p.categoryId, imageUrl: p.imageUrl, active: p.active, groceryDisplayName : p.groceryDisplayName, selected: false}))
      }
      setDataLoading(false)
      setContinuationToken(result.continuationToken)
      return products
    }

  }

  const handleLoadMore = async () => {
    if(dataLoading || !continuationToken) {
      return
    }
    const result = await loadProducts()
    if (result) {
      const temp = data.concat(result)
      setData(temp)
    }
  }
    
  const selectItem = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const id = values[0]
    const objIndex = data.findIndex(x => x.id === id)
    data[objIndex].selected = parseBool(event.target.value)
    setData(data)
  }
  const selectCategory = (event) =>{
    event.persist()

    if(event.target.value === '00000000-0000-0000-0000-000000000000') {
      alert('välj kategori')
      return
    }

    if(event.target.value && selectedCategory !== categoryId) {
      setSelectedCategory(event.target.value)
    }
    else{
      alert('välj annan kategori')
    }
  }

  const changeCategory = async(event) =>{
    event.persist()
    const itemSelected = data.filter(item=>item.selected)
    if(!itemSelected || itemSelected.length === 0) {
      alert('välj produkter')
      return
    }
    if(!selectedCategory) {
      alert('välj kategori')
      return
    }
    if(selectedCategory === categoryId) {
      alert('välj annan kategori')
      return
    }
    const url =   `/api/category/${categoryId}/products/updatecategory`
    const dataToSend = {categoryId : selectedCategory, products : itemSelected}

    if (url) {
      const response = await postData(
        url, dataToSend
      )

      await response

      var result = await loadProducts()
      setData(result)
      toggleModal()
    } 
  }
  const toggleModal = ()=> {
    setModal(!showModal)
  }

  return (
    <div>
      <BackButton {...props} editPage={true}>{currentCatetory && `${currentCatetory} - produkter`}</BackButton>
      <ProntoContainerTop>
        {<ClickButton className="flex-right btn-control btn--red flex-right btn-control btn--red btn-control--absolut btn-control--absolut__top nav-link" onClick={toggleModal} title={`Byta kategori`} />}
        {showModal && <div className="modal">
          <div className="modal__content">
            <form>
              <div className="form-section c-6 pl-10">
                <span className="label">Byta kategori</span>
                <hr/>
              </div> 
              <div className="form-section c-6 pl-10">
                {categories && <div className="row">
                  <Select data={categories} name="categoryId" handleEvent={selectCategory} defaultOption="Välj kategori"
                    defaultValue={selectedCategory} />
                </div>
                }
                <br></br>
              </div>  
              <div className="row">
                <ClickButton className="btn--black mr-10" title="Byta ketegori" onClick={changeCategory} />
                <ClickButton className="btn--white mr-10" title={Placeholder.Cancel} onClick={toggleModal} />    
              </div>
            </form>
          </div>
        </div>}
        <ul className="no-style drop-shadow">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
              <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
              <div className="list-item__element list-item__element--s label hidden-down">{"Välj"}</div>

              <div className="list-item__element label hidden-down">{Placeholder.Change}</div>
            </div>
          </li>
          {data.map(item => (
            <li key={item.id} className="list-item">
              <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt="" /></div>
              <div className="list-item__element">
                {item.groceryDisplayName}
              </div>
              <div className="list-item__element">
                {item.ean}
              </div>
              <div className="list-item__element list-item__element--s">
                <Checkbox checked={item.active} name="active" disabled="disabled" />
                <span className="label form-label hidden-up">{Placeholder.Active}</span>
              </div>
              <div className="list-item__element list-item__element--s">
                <Checkbox checked={item.selected} name={item.id+"__selected"} handleEvent={selectItem} />
              </div>
              <div className="list-item__element">
                <Link className="link-edit" to={`/categories/${categoryId}/products/${item.id}/edit/`}>{Placeholder.Change}</Link>
                <Link className="link-edit" to={`/categories/${categoryId}/products/${item.id}/delete/`}>{Placeholder.Delete}</Link>
              </div>


            </li>
          ))}
        </ul>
        {!dataLoading && data.length > 0 && <ClickButton onClick={handleLoadMore} title={`Totalt: ${data.length} Ladda mer`} />} 
      </ProntoContainerTop>
    </div>)
}
export default ProntoProducts