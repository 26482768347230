export const shared = {
  getColumns(renderRow) {
    const columnsArray = []
    const rendered = renderRow({}, 0)
    if (Array.isArray(rendered.props.children)) {
      rendered.props.children.map((child) => {
        const { ...rest } = child.props
        columnsArray.push(rest)
      })
    } else {
      const { ...rest } = rendered.props
      columnsArray.push(rest)
    }

    return columnsArray
  },

  buildClassName(
    css,
    shadow,
    stickyHeader,
    arrowList,
    className
  ) {
    let newClassName = css["item-list"]
    if (shadow) {
      newClassName += ` drop-shadow`
    }
    if (stickyHeader) {
      newClassName += ` ${css["sticky-header"]}`
    }
    if (arrowList) {
      newClassName += ` ${css["arrow-list"]}`
    }
    if (className) {
      newClassName += ` ${className}`
    }
    return newClassName
  }
}