import React from 'react'
import InputField from './InputField'
import InputLabel from './InputLabel'
import { handleValidation } from '../../utilis/ValidationHelpers'
import css from './Textbox.module.css'
import ValidationErrorMessage from './ValidationErrorMessage'
import { QuestionMarkIcon } from '../layout/Icons'

function Textbox({
  label,
  type = 'text',
  onChange,
  value,
  id,
  className,
  beforeTextbox,
  afterTextbox,
  validationRegister,
  validation,
  errors,
  onFocus,
  onBlur,
  tooltip = false,
  tooltipText,
  ...props
}) {
  let parsedId = id
  let parsedName = props.name

  if (validation) {
    parsedId = id.replaceAll(',', '').replaceAll('.', '')
    parsedName = props.name.replaceAll(',', '').replaceAll('.', '')
  }

  const validationData = handleValidation(
    parsedId,
    parsedName,
    label,
    onChange,
    validationRegister,
    validation,
    errors,
  )

  var classNames = css.textbox
  if (validationData.hasError) {
    classNames += ' ' + css.error
  }

  if (className) {
    classNames += ' ' + className
  }

  return (
    <InputField onFocus={onFocus} onBlur={onBlur}>
      {tooltip && tooltipText ? (
        <div className={css.tooltipWrapper}>
          <InputLabel label={label} htmlFor={id} />
          <span className={css.tooltip}>
            <QuestionMarkIcon
              className={css.tooltipIcon}
              color="var(--redDark)"
            />
            <span className={css.tooltipText}>{tooltipText}</span>
          </span>
        </div>
      ) : (
        <InputLabel label={label} htmlFor={id} />
      )}
      {beforeTextbox && beforeTextbox()}
      <input
        id={parsedId}
        className={classNames}
        type={type}
        onChange={onChange}
        value={value === null || value === undefined ? '' : value}
        onWheel={type === 'number' ? (e) => e.target.blur() : undefined}
        disabled={props.disabled}
        {...props}
        {...validationData.register}
      />
      {afterTextbox && afterTextbox()}
      {validationData.hasError && validationData.errorMessage && (
        <ValidationErrorMessage message={validationData.errorMessage} />
      )}
    </InputField>
  )
}

export default Textbox
