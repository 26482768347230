import React, { useState, useEffect } from 'react'

import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import GroupListItem from './GroupListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'


const Groups = (props)=> {
  const [data, setData] = useState( {title :Placeholder.Groups} )
  const referenceEntityId = props.referenceEntityId ? props.referenceEntityId : props.match.params.referencentityid
  const referenceType = props.referenceType ? props.referenceType: "restaurants" 
  useEffect(() => {
    const fetchData = async () => {
      if(referenceEntityId){
        const response = await getData(
          `/api/groups/${referenceEntityId}/${referenceType}`
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`${props.location.pathname}create/`} title={`${Placeholder.Create} kategori`} />
      {props.match.path.includes('restaurants') ?
        <BackButton backLabel={Placeholder.Restaurants} {...props}>{data.title}</BackButton> :
        <BackButton backLabel={Placeholder.CookByYourself} {...props}>{data.title}</BackButton>
      }
      <ul className="no-style drop-shadow groups-table">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data && data.groups && data.groups.map(item => (
          <li key={item.id}>
            <GroupListItem data={item} referenceType={referenceType} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Groups