import React from 'react'
import Dropdown from '../layout/Dropdown'
import RemovableTextBox from '../layout/RemovableTextBox'
import { PlusIconLarge } from '../layout/Icons'
import { variants } from '../../utilis/DefaultVariants'
import InputField from '../Form/InputField'
import InputLabel from '../Form/InputLabel'
import styles from './VariantForm.module.css'

function VariantForm({
  variantCategory,
  mode,
  onSave
}) {
  const [selectedVariantCategory, setSelectedVariantCategory] = React.useState(variantCategory.name ? variantCategory.name : '')
  const [values, setValues] = React.useState(!variantCategory.values ? [] : variantCategory.values)
  const [newVariantCategoryName, setNewVariantCategoryName] = React.useState(variantCategory.name ? variantCategory.name : '')
  const [validation, setValidation] = React.useState({ error: false, errorText: '' })

  function handleValueChange({ type, value = "", index }) {
    const updatedValues = [...values]
    switch (type) {
    case 'updateValue':
      updatedValues[index].name = value
      break
    case 'addValue':
      updatedValues.push({ name: value })
      break
    case 'removeValue':
      updatedValues.splice(index, 1)
      break
    default:
      break
    }
    setValues(updatedValues)
    setValidation({ ...validation, error: false })
  }

  function onSelectVariantCategory(selected) {
    setSelectedVariantCategory(selected.name)
    setValues(selected.values.map(v => ({ name: v })))
    setValidation({ ...validation, error: false })
  }

  function validate() {
    if (!selectedVariantCategory) {
      setValidation({ error: true, errorText: 'Varianttyp saknas' })
      return false
    } else if ((selectedVariantCategory === '+ Skapa nytt variantval' || mode === 'edit') && newVariantCategoryName.trim().length < 1) {
      setValidation({ error: true, errorText: 'Namn för variantvalet saknas' })
      return false
    } else if (values.filter(Boolean).length === 0) {
      setValidation({ error: true, errorText: 'Minst ett värde måste anges för ett variantval' })
      return false
    }
    return true
  }

  function onSaveClick() {
    if (validate()) {
      onSave({
        ...variantCategory,
        name: newVariantCategoryName ? newVariantCategoryName.trim() : selectedVariantCategory.trim(),
        values: values.filter(v => v.name).map((v, i) => ({ ...v, sortOrder: i + 1 })),
      })
    }
  }

  return (
    <div className={styles.container}>
      {validation.error ? <p className={styles.errorText}>{validation.errorText}</p> : null}

      {mode === 'create' && (
        <Dropdown
          label='Välj variantval'
          placeholder={selectedVariantCategory ? selectedVariantCategory : 'Välj typ av variantval'}
          listItems={variants}
          setSelected={(e, selected) => {
            e.preventDefault()
            onSelectVariantCategory(selected)
          }}
          large={true}
        />
      )}

      {(selectedVariantCategory === '+ Skapa nytt variantval' || mode === 'edit') && (
        <InputField className={styles.newVariant}>
          <InputLabel label='Namn' className={styles.valuesLabel} />
          <input
            type='text'
            className={styles.newVariantInput}
            value={newVariantCategoryName}
            onChange={(e) => {
              setNewVariantCategoryName(e.target.value)
              setValidation({ error: false, errorText: '' })
            }}
            placeholder={'Skriv önskad namnsättning'}
          />
        </InputField>
      )}

      {selectedVariantCategory && (
        <InputField className={styles.valuesContainer}>
          <InputLabel label='Värden' className={styles.valuesLabel} />
          <div className={styles.valuesGroup}>
            {values.length > 0 && (
              <div className={styles.valueBoxes}>
                {values.map((value, index) => {
                  return (
                    <RemovableTextBox
                      key={value.name + index}
                      text={value.name}
                      onChange={(val) => handleValueChange({ type: 'updateValue', value: val, index: index })}
                      onRemove={() => handleValueChange({ type: 'removeValue', index: index })}
                    />
                  )
                })}
              </div>
            )}
            <button className={styles.plusButton} onClick={() => handleValueChange({ type: 'addValue' })}>
              <PlusIconLarge className={styles.plusIcon} color='#CF2E05' width='20' height='20' />
            </button>
          </div>
        </InputField>
      )}
      <button className={styles.saveButton} onClick={onSaveClick} type='button'>
        Ok
      </button>
    </div>
  )
}

export default VariantForm
