import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData, postMultiPartData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'

function checkExtension(fileName) {
  return ( /\.(xls?x|txt|csv)$/i.test(fileName))
}

function countStatus(isLoading,activeCount,totalCount){

  var total = activeCount + totalCount
  return isLoading && total  == 0 ? "laddar..": `${activeCount}/${totalCount}`
}
const Categories = (props)=> {
  const [data, setData] = useState( [] )
  const [isLoading, setIsloading] = useState( true)
  const [file, setFile] = useState( {} )
  const [percentage, setPercentage] = useState()
  const [showModal, setModal] = useState(false)
  const [message, setMessage] = useState()

  const storeId = props.match.params.storeid

  const onChangePercentage =  (e) => {
    setPercentage(e.target.value)
  }

  const toggleModal = ()=> {
    setMessage('')
    setFile(undefined)
    setModal(!showModal)
  }
  const updateFile = (e)=>{
    if(!checkExtension(e.target.files[0].name)){
      setMessage("xls, csv or txt krävs")
      return
    }
    setMessage('')
    setFile(e.target.files[0])
  }
  const uploadFile = async () => {
    const url = `/api/stores/${storeId}/price`
    if(!file || !file.name){
      setMessage("xls, csv or txt krävs")
      return
    }
    setMessage('')
    // const files = e.target.files
    const formData = new FormData()
    formData.append('file', file)
    formData.append('percentage', percentage)
    
    var response = await postMultiPartData(url, formData)
    await response

    if(response.rows){
      setMessage(`${response.rows} produkter uppdaterat`)
    }
    if(response.status == 500){
      setMessage('error')
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if(storeId){
        const response = await getData(
          '/api/categories/store/'+storeId
        )
        const result = await response
        setData(result)

        setData([...result])

        for (const index in result) {
          const item = result[index]
          const countResponse = await getData(
            `/api/categories/${item.id}/store/${storeId}/count`
          )
  
          const countResult = await countResponse
          if(countResult){
            result[index].activeProductsCount = countResult.activeProductsCount
            result[index].totalProductsCount = countResult.totalProductsCount
            setData([...result])
          }
        }
        setIsloading(false)
      }

    }

    fetchData()
  }, [])

  return (
    <div className="form-section">
      <BackButton {...props} editPage={true}>{Placeholder.Categories}</BackButton>
      <ul className="no-style drop-shadow categories-table">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            {/* <label className="flex-right btn-control btn--red btn-control--absolut btn-control--absolut__top nav-link btn-file-upload" onClick={e => toggleModal(e)}>Läs in fil med mina priser</label> */}
          </div>
        </li>
        <li>
          {showModal &&<div className="modal">
            <div className="modal__content">
              <form>
                <div className="form-section c-6 pl-10">
                  <span className="label">Import fil</span>
                  <hr/>
                  <input id="percentage" name="percentage" className="input"  type="text" onChange={e => onChangePercentage(e)} value={percentage} placeholder="%"/>
                </div> 
                <div className="form-section c-6 pl-10">
                  <label htmlFor="priceFileUpload" className="btn-control btn--red nav-link btn-file-upload">{(file && file.name?file.name:'välj fil')}</label>
                  <input id="priceFileUpload" name="priceFileUpload" className="file-upload"  type="file" onChange={e => updateFile(e)}/>
                  <span className="label">{message}</span>
                  <br></br>
                </div>  
                <div className="row">
                  <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={uploadFile} />
                  <ClickButton className="btn--white mr-10" title={Placeholder.Cancel} onClick={toggleModal} />    
                </div>
              </form>
            </div>
          </div>}
        </li>
        {data.map(item => (
          <li key={item.id} className="list-item list-item--start">   
            <Link className="link-edit" to={`/stores/${storeId}/categories/${item.id}/products/`}>{item.name}</Link>({countStatus(isLoading,item.activeProductsCount,item.totalProductsCount)})
          </li>
        ))}
      </ul>
    </div>
  )
  
}

export default Categories