import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'


import Checkbox from '../layout/Checkbox'
import { GetCssInput } from '../layout/Input'
import Mode from '../../utilis/Mode'
import { ClickButton, SaveButton } from '../layout/Button'

const CustomizationMenuItem = (props) => {
  const mode = props.mode
  const history = props.history

  const itemId = props.match.params.itemid

  const subMenuId = props.match.params.submenuid
  const customizationId = props.match.params.customizationid
  const [data, setData] = useState({ customizationId: customizationId })


  useEffect(() => {
    const fetchData = async () => {
      if (itemId && customizationId) {
        const response = await getData(
          `/api/customizations/${customizationId}/submenu/${subMenuId}/items/${itemId}/`
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }

  const handlePostData = async () => {

    const url = getPostUrl()
    if (url) {
      const response = await postData(
        url, data
      )
      console.log("handlePostData", response)
      return await response
          
    } else {
      console.error("url not defined")
    }
  }

  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return `/api/customizations/submenu/${subMenuId}/items/${itemId}/edit`
    }
    if (mode === Mode.Create) {
      return `/api/customizations/submenu/${subMenuId}/items/create`
    }
    if (mode === Mode.Delete) {
      return `/api/customizations/submenu/${subMenuId}/items/${itemId}/delete`
    }

    return undefined
  }

  return (
    <div>
      <BackButton backLabel={"Anpassning"} {...props} editPage={true} ></BackButton>
      <ProntoContainer>

        <div className="form">
          <div className="form-section c-6 pr-10">
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.name} placeholder={Placeholder.Name} />
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="price" defaultValue={data.price} placeholder={Placeholder.Price} />


            <div className="row">
              {data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              {!data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>
            {data && data.customizationId == 3 && <div className="row">
              {data.id && <Checkbox checked={data.preSelected} name="preSelected" handleEvent={handleInputChange} />}
              {!data.id && <Checkbox checked={data.preSelected} name="preSelected" handleEvent={handleInputChange} />}
              <span className="label form-label">{Placeholder.PreSelected}</span>
            </div>}
          </div>
          <div className="row">
            <SaveButton className="btn--black mr-10" history={history} title={Mode.Delete === mode ? Placeholder.Delete : Placeholder.SaveButton} onClick={handlePostData} />
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack} />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )

}

export default CustomizationMenuItem