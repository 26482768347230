import React, { useState, useEffect, useContext } from 'react'
import { getData } from '../services/Ajax'
import { Link } from 'react-router-dom'
import { NavMenuContext } from './Layout'
import { getEnvirionment } from '../utilis/GetEnvironment'
import styles from './NavMenu.module.css'

const NavMenu = () => {

  const [data, setData] = useState([])
  const { menuActive, setMenuActive } = useContext(NavMenuContext)
  const [environment, setEnvironment] = React.useState('')

  useEffect(() => {
    const fetchData = async () => {

      const response = await getData(
        '/api/navigation/'
      )
      const result = await response
      if (result) {
        setData(result)
      }
    }

    setEnvironment(getEnvirionment(window.location.host))
    fetchData()
  }, [])

  const toggleMenu = (e) => {
    e.preventDefault()
    setMenuActive(!menuActive)
  }

  return (
    <nav className={`${styles.prontoNavbar} ${menuActive ? styles.visible : ''}`} >
      {data && data.length > 0 &&
        <ul className={styles.nav}>
          {data && data.map((item, i) => (
            <li className={`${styles.navItem} ${styles[environment]}`} key={i} onClick={toggleMenu}>
              <Link className={`${styles.navLink} ${styles[environment]}`} to={item.url}>{item.name}</Link>
            </li>
          ))}
        </ul>
      }
    </nav>
  )
}

export default NavMenu
