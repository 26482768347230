import React from "react"
import Placeholder from "../layout/Placeholder"
import Button from "../UI/Button"
import css from "./FormActions.module.css"

function FormActions({
  showSave = true, 
  showCancel = true,
  showDelete = false,
  form,
  onDelete,
  onCancel,
  saving = false,
  deleting = false,
  saveText = Placeholder.SaveButton,
  cancelText = Placeholder.Cancel,
  deleteText = Placeholder.Delete,
  ...props 
}) {
  return (
    <div className={css.formActions} {...props}>
      {showDelete && 
        <Button 
          type="text"
          disabled={saving}
          loading={deleting}
          onClick={onDelete}
          narrow
        >
          {deleteText}
        </Button>
      }
      {showCancel && 
        <Button 
          type="cancel"
          disabled={saving || deleting}
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      }
      {showSave && 
        <Button 
          type="save"
          nativeType="submit"
          form={form}
          loading={saving}
          disabled={deleting}
        >
          {saveText}
        </Button>
      }
    </div>
  )
}

export default FormActions