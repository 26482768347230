import React from 'react'
import Checkbox from '../layout/Checkbox'
import Placeholder from '../layout/Placeholder'


function ProductOffer(props) {
  const item = props.data
  const handleEvent = props.handleEvent
  console.log(item)
  return (
    <div className="list-item">
      <div className="list-item__element">{item.purchaseChannelTypeName}</div>
      <div className="list-item__element">{item.groceryDisplayName}</div>
      {/* <div className="list-item__element">
                 <input className="input" type="number" onChange={handleEvent} name={`${item.purchaseChannelTypeId}__RecommendedPrice`} defaultValue={item.recommendedPrice} placeholder={Placeholder.RecomendedPrice} />
             </div> */}
      <div className="list-item__element">
        <input className="input" type="number" onChange={handleEvent} name={ `${item.purchaseChannelTypeId}__Price`} defaultValue={item.price} placeholder={Placeholder.Price}/>
      </div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.checked} name={ `${item.purchaseChannelTypeId}__checked`} defaultValue={item.checked} handleEvent={handleEvent}/>     
        <span className="label form-label hidden-up">{Placeholder.Active}</span>   
      </div>         
    </div>
  )
  
}

export default ProductOffer

