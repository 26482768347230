import Placeholder from "../components/layout/Placeholder"

export default function getMessage(validationType, fieldName, validationValue) {
  if (!fieldName) fieldName = Placeholder.Value

  if (validationType) {
    switch (validationType) {
    case "required":
      return `${fieldName} saknas`
    case "min":
      return `Minsta tillåtna värde är ${validationValue}`
    default:
      return ""
    }
  }
  return ""
}

export function handleValidation(
  id,
  name,
  fieldName,
  onChange,
  validationRegister,
  validation,
  errors) {
  const validationName = id || name
  const hasValidation = validationRegister && validation

  if (hasValidation && !validationName) {
    throw new Error("Validation name is missing. Ensure id or name is set on element.")
  }

  const hasError = validationName && errors && errors[validationName]

  let errorMessage = ''
  if (hasError) {
    errorMessage = errors[validationName].message
    if (!errorMessage) {
      const type = errors[validationName].type
      errorMessage = getMessage(type, fieldName, validation[type])
    }
  }

  var register
  if (hasValidation) {
    register = validationRegister(validationName, {
      ...validation,
      onChange
    })
  }

  return {
    hasError,
    errorMessage,
    register
  }
}