import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import Heading from '../layout/Headings'
import {getData,postData} from '../../services/Ajax'
import Mode from '../../utilis/Mode'
import {ProntoContainer} from '../layout/Containers'
import {GetCssInput} from '../layout/Input'
import {ClickButton,SaveButton} from '../layout/Button'

const AppConfig = (props)=> {
  const mode = props.mode
  const appName = props.match.params.appname
  const history = props.history
  const [data, setData] = useState( {})

  useEffect(() => {
    const fetchData = async () => {
      if(appName){
        const response = await getData(
          '/api/appconfig/'+appName
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/appconfig/edit'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response
    }else{
      return {status: 400}
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <ProntoContainer>
      <Heading mode={mode} title={`${data.appName}`}/>
      <hr/>
      <div className="form">
        <div className="form-section c-6 pr-10">
          <input className="input" type="text" name="name" defaultValue={data.appName} disabled/>
          <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="version" defaultValue={data.version} placeholder="1.0.0"/>
        </div>  

        <div className="row">
          <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
          <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
        </div>
      </div>
    </ProntoContainer>
  )
  
}


export default AppConfig

