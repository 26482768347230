import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {parseBool} from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import ProductOffer from './ProductOffer'



const ProductOffers = (props)=> {
  const [data, setData] = useState({data : []} )
  const storeId = props.match.params.storeId
  const productId = props.match.params.productId
  const history = props.history

  useEffect(() => {
    const fetchData = async () => {
      if(storeId && productId){
        const response = await getData(
          `/api/stores/${storeId}/products/${productId}/offers`
        )
        const result = await response
        setData(result)
        console.log("result: ", result)
      }
    }

    fetchData()
  }, [])

  function ShouldParseInt(name){
    if(name === 'checked'){
      return true
    }
    return false
  }

  const handleInputChange = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const key = values[0]
    const name = values[1]
    console.log("key: ", key)
    console.log("name: ", name)
    const objIndex = data.productOffers.findIndex(x => x.purchaseChannelTypeId == key)
    console.log("objIndex: ", objIndex)
    data.productOffers[objIndex][name] = ShouldParseInt(name) ? parseBool(event.target.value) : event.target.value
    setData(data)
    console.log("Updated data: ", data)
  }

  const handlePostData = async (event) => {
    event.persist()
    const url = `/api/stores/${storeId}/products/${productId}/offers`
    if(url){
      const response = await postData(
        url,data
      )
      await response
     
      history.goBack()
    }else{
      console.error("url not defined")
    }
  }
  return (
    <ProntoContainerTop>
      <BackButton backLabel={Placeholder.Products} {...props}>Product offers</BackButton>
      <div className="form-section">
        <ul className="no-style">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">Säljkanal</div>
              <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
              {/* <div className="list-item__element label hidden-down">{Placeholder.RecomendedPrice}</div>    */}
              <div className="list-item__element label hidden-down">{Placeholder.Price}</div>      
              <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            </div>
          </li>
          {data.productOffers && data.productOffers.map(item => (

            <li key={item.purchaseChannelTypeId}>
              <ProductOffer data={item}  {...props} handleEvent={handleInputChange}/>
            </li>

          ))}
        </ul>
        <div className="row mt-10">
          <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={handlePostData}/>
          <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
        </div>
      </div>
    </ProntoContainerTop>
  )
  
}

export default ProductOffers