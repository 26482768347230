import React, { useState, useEffect } from 'react'
import { getData, sendData } from '../../services/HttpClient'
import './Register.css'
import { ProntoContainer } from '../layout/Containers'
import { ClickButton, TopAddButton } from '../layout/Button'
import Placeholder from '../layout/Placeholder'
import BackButton from '../backbutton/BackButton'
import { defaultNotifications } from '../../utilis/Notifications'
import ItemList, { ItemListColumn } from '../ItemList/ItemList'
import Link from '../UI/Link'
import { TrashIcon } from '../layout/Icons'
import { emptyGuid } from '../../utilis/Constants'
import SelectList from '../Form/SelectList'

const Users = (props) => {
  const [data, setData] = useState([])
  const [storeId, setStoreId] = useState(
    props.storeid ? props.storeid : props.match.params.storeid,
  )
  const [users, setUsers] = useState([])
  const [sortFilter, setSortFilter] = React.useState('')

  useEffect(() => {
    const fetchStores = async () => {
      const response = await getData('/api/user/stores')
      setData(response.data)
    }

    fetchStores()
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      await fetchStoreUsers(getUsersUrl())
    }

    fetchUsers()
  }, [storeId])

  async function fetchStoreUsers(url) {
    const response = await getData(url)
    setUsers(response.data.users)
  }

  function getUsersUrl() {
    if (storeId) {
      return `/api/store/${storeId}/users/`
    }
    return '/api/users/'
  }

  async function deleteUser(user) {
    if (window.confirm(`Är du säker på att du vill ta bort ${user.email}?`)) {
      const response = await sendData(
        `/api/user/${user.id}/delete`,
        {},
        'DELETE',
      )
      if (response.ok) {
        defaultNotifications.deleted(Placeholder.Users, user.email)
        setUsers([...users].filter((item) => item.id !== user.id))
      } else {
        defaultNotifications.error(Placeholder.Users, response.statusText)
      }
    }
  }

  function changeStore(e) {
    let newStoreId = ''
    if (e.target.value && e.target.value !== emptyGuid) {
      newStoreId = e.target.value
    }
    setStoreId(newStoreId)
  }

  const handleStoreChange = async () => {
    await fetchStoreUsers(getUsersUrl())
  }

  function getListDisplayValue(arr, entityName) {
    if (arr && arr.length > 1) {
      return `${arr.length} ${entityName.toLowerCase()}`
    } else if (arr && arr.length > 0) {
      return arr[0].name
    }
    return ''
  }

  const handleInputChange = (event) => {
    setSortFilter(event.target.value)
  }

  return (
    <div>
      <BackButton editPage={true} disabled={true}>
        {Placeholder.SelectStore}
      </BackButton>
      <ProntoContainer>
        <TopAddButton
          className="flex-right btn-control btn--red"
          to={`/user/create/`}
          title={`${Placeholder.Create} användare`}
        />
        <div className="form">
          <div className="form-section">
            <div className="row">
              {data && data.stores && (
                <div className="store-picker">
                  <SelectList
                    style={{ minWidth: '500px' }}
                    value={storeId}
                    options={data.stores.filter((option) => {
                      if (sortFilter.trim() === '') {
                        return true
                      }
                      return option.name
                        .toLowerCase()
                        .includes(sortFilter.toLowerCase())
                    })}
                    name="stores"
                    onChange={changeStore}
                    defaultOption="Välj butik"
                    defaultValue={storeId}
                    optionValueField={'value'}
                  />
                  <input
                    style={{ height: '1.9rem', width: '300px' }}
                    type="text"
                    placeholder="Filtrera butiker..."
                    value={sortFilter}
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <ClickButton
              className="btn--black mr-10"
              title={Placeholder.Search}
              onClick={handleStoreChange}
            />
          </div>

          <div>
            <BackButton disabled={true}>{Placeholder.Users}</BackButton>
            <ItemList
              data={users}
              stickyHeader={true}
              shadow={false}
              arrowList
              navigationUrl={(item) => `/user/${item.id}/view/`}
              renderRow={(item) => (
                <React.Fragment>
                  <ItemListColumn id="email" label="Epost">
                    <Link
                      text={item.email}
                      to={`/user/${item.id}/view/`}
                      title={item.email}
                    >
                      {item.email}
                    </Link>
                  </ItemListColumn>
                  <ItemListColumn id="role" label="Roll">
                    {getListDisplayValue(item.roles, Placeholder.Roles)}
                  </ItemListColumn>
                  <ItemListColumn id="store" label="Butik">
                    {getListDisplayValue(item.stores, Placeholder.Stores)}
                  </ItemListColumn>
                  <ItemListColumn id="delete" label={Placeholder.Delete}>
                    <Link
                      text={Placeholder.Delete}
                      to="#"
                      title={Placeholder.Delete}
                      onClick={() => deleteUser(item)}
                    >
                      <TrashIcon />
                      {Placeholder.Delete}
                    </Link>
                  </ItemListColumn>
                </React.Fragment>
              )}
            ></ItemList>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}
export default Users
