import React, { useState, useEffect } from 'react'
import { getData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import { TopAddButton } from '../layout/Button'
import LoadingContainer from '../layout/LoadingContainer'
import ItemList, { ItemListColumn } from "../ItemList/ItemList"
import Checkbox from '../layout/CheckboxV2'
import { Link } from 'react-router-dom'

const Merchants = (props) => {
  const [paymentTypes, setPaymentTypes] = useState([])
  const [data, setData] = useState([])
  const [allowAdd, setAllowAdd] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [fetching, setFetching] = React.useState(false)

  const storeId = props.match.params.storeid

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      await fetchPaymentTypes()
      await fetchMerchants()
      setFetching(false)
    }

    const fetchPaymentTypes = async () => {
      const paymentTypes = await getData(
        '/api/paymentmethods/types'
      )
      var response = await paymentTypes

      var types = []
      for (const prop in await response.types) {
        types.push({
          id: prop,
          value: response.types[prop]
        })
      }

      setPaymentTypes(types)
    }

    const fetchMerchants = async () => {
      if (storeId) {
        const res = await getData(`/api/stores/${storeId}/merchants`)
        setData(res)
        setDataFetched(true)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (dataFetched && (data.length === 0 || data.find(m => !m.merchant))) {
      setAllowAdd(true)
      return
    }
    setAllowAdd(false)
  }, [data, dataFetched])

  function isPaymentTypeActive(paymentType, item) {
    if (!item.merchant) return false
    return item.merchant.paymentMethods.some(pm => {
      return pm.toLowerCase() === paymentType.value.toLowerCase()
    })
  }

  return (
    <ProntoContainerTop >
      {allowAdd &&
        <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/paymentMethods/payexMerchants/create/`} title={`${Placeholder.Create} kanal`} />
      }
      <BackButton
        to={`/stores/${storeId}/paymentMethods`}
        backLabel={Placeholder.PaymentMethod}
        {...props}
      >
        {Placeholder.PayexSettings}
      </BackButton>
      <LoadingContainer loading={fetching}>
        <ItemList
          data={data.filter(m => m.merchant)}
          idProp="channel"
          renderRow={(item) =>
            <React.Fragment>
              <ItemListColumn id="channel" label="Kanal" columns={2}>
                {item.channelName}
              </ItemListColumn>
              <ItemListColumn id="type" label="Typ">
                {paymentTypes.map(paymentType => (
                  <span key={paymentType.value} style={{ marginRight: 20 }}>
                    <Checkbox
                      key={paymentType.id}
                      label={paymentType.value}
                      checked={isPaymentTypeActive(paymentType, item)}
                      name={"paymentType_" + paymentType.id}
                      text={paymentType.value}
                      disabled="disabled"
                    />
                  </span>
                ))}
              </ItemListColumn>
              <ItemListColumn id="edit" label="Ändra" narrow>
                {item.merchant &&
                  <Link className="link-edit" to={`/stores/${storeId}/paymentMethods/payexMerchants/${item.merchant.channel}/${item.merchant.storeId}/`}>{Placeholder.Edit}</Link>
                }
              </ItemListColumn>
            </React.Fragment>
          }>
        </ItemList>
      </LoadingContainer>
    </ProntoContainerTop>
  )

}

export default Merchants