import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { getData, getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../../components/layout/Placeholder'
import { defaultNotifications } from "../../utilis/Notifications"
import ContentContainer from '../../components/layout/ContentContainer'
import Textbox from '../../components/Form/Textbox'
import SelectList from '../../components/Form/SelectList'
import Checkbox from '../../components/Form/Checkbox'
import FormActions from '../../components/Form/FormActions'
import FormSection from '../../components/Form/FormSection'
import MessageBox from '../../components/layout/MessageBox'

const PaymentMethod = (props) => {
  const storeId = props.match.params.storeid
  const id = props.match.params.id

  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    fee: 0,
    minimumPrice: 0,
    active: false,
    channelTypes: []
  })
  const [channels, setChannels] = useState([])
  const [providerTypes, setProviderTypes] = useState([])
  const [fetchingData, setFetchingData] = useState(false)
  const [fetchingChannels, setFetchingChannels] = useState(false)
  const [fetchingProviderTypes, setFetchingProviderTypes] = useState(false)
  const [saving, setSaving] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)

  const isFetching = fetchingData || fetchingChannels || fetchingProviderTypes
  const pageTitle = id ? paymentMethod.name : Placeholder.CreatePaymentMethod

  useEffect(() => {
    const fetchData = async () => {   
      if (storeId && id) {
        setFetchingData(true)
        const response = await getServiceData('PaymentApi', 'v1', `/stores/${storeId}/paymentMethods/${id}`)
        if (response.ok) {
          setPaymentMethod(response.data)
          reset(response.data)
        } else {
          defaultNotifications.error(Placeholder.PaymentMethod)
        }
        setFetchingData(false)
      }
    }

    fetchData()
  }, [storeId, id])

  useEffect(() => {
    const fetchChannels = async () => {   
      if (storeId) {
        setFetchingChannels(true)
        const response = await getData(`/api/stores/${storeId}`)
        if (response.ok) {
          setChannels(response.data.channelTypes.filter(c => c.checked))
        } else {
          defaultNotifications.error(Placeholder.Store)
        }
        setFetchingChannels(false)
      }
    }

    fetchChannels()
  }, [storeId])

  useEffect(() => {
    const fetchProviderTypes = async () => {
      setFetchingProviderTypes(true)
      const response = await getServiceData('PaymentApi', 'v1', "/paymentProviders")
      if (response.ok) {
        setProviderTypes(response.data)
      } else {
        defaultNotifications.error("Typer")
      }
      setFetchingProviderTypes(false)
    }

    fetchProviderTypes()
  }, [])

  const handleInputChange = (e) => {
    var newPaymentMethod = {...paymentMethod}
    newPaymentMethod[e.target.name] = e.target.value
    setPaymentMethod(newPaymentMethod)
  }

  function handleActiveChange(e) {
    setPaymentMethod({
      ...paymentMethod,
      active: e.target.checked
    })
  }

  function handleProviderTypeChange(e) {
    var channelTypes = [...paymentMethod.channelTypes]
    var paymentProviderType = e.target.value

    paymentMethod.channelTypes.forEach((channelId, index) => {
      var channel = channels.find(c => c.id === channelId)
      var isRestricted = getChannelRestrictedByProviderType(paymentProviderType, channel.id)
      if (isRestricted) {
        channelTypes.splice(index, 1)
      }
    })
    
    setPaymentMethod({
      ...paymentMethod,
      paymentProviderType,
      channelTypes
    })
  }

  function handleChannelChange(e) {
    const id = e.target.name.split("_")[1]
    const channelTypes = [...paymentMethod.channelTypes]

    const index = channelTypes.indexOf(id)
    if (e.target.checked) {
      if (index === -1) {
        channelTypes.push(id)
      }
    } else {    
      if (index > -1) {
        channelTypes.splice(index, 1)
      }
    }

    setPaymentMethod({
      ...paymentMethod,
      channelTypes: channelTypes
    })
  }

  function channelIsChecked(id) {
    return paymentMethod.channelTypes.includes(id)
  }

  function getChannelLabel(channel, isRestricted) {
    if (isRestricted) {
      var providerType = findProviderType(paymentMethod.paymentProviderType)

      return `${channel.displayName} (har inte stöd för ${providerType.displayName})`
    }

    return channel.displayName
  }

  function getChannelRestrictedByProviderType(paymentProviderType, channelId) {
    var providerType = findProviderType(paymentProviderType)
    if (providerType && providerType.restrictedToChannelIds.length > 0) {
      var isRestricted = !providerType.restrictedToChannelIds.some(r => r === channelId)
      return isRestricted
    }
    return false
  }

  function findProviderType(paymentProviderType) {
    return providerTypes.find(p => p.type === paymentProviderType)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    let url = `/stores/${storeId}/paymentMethods`
    let method = "POST"
    if (id) {
      url = `/stores/${storeId}/paymentMethods/${id}`
      method = "PUT"
    }

    const res = await sendServiceData('PaymentApi', 'v1', url, paymentMethod, method)

    if (res.ok) {
      defaultNotifications.created(Placeholder.PaymentMethod, paymentMethod.name)
      redirectToList()
    } else {
      defaultNotifications.error(Placeholder.PaymentMethod)
    }
    
    setSaving(false)
  }

  async function onRemove(e) {
    e.preventDefault()

    if (!window.confirm(`Är du säker på att du vill ta bort "${paymentMethod.name}"?`)) {
      return
    }

    setDeleting(true)

    const res = await sendServiceData('PaymentApi', 'v1',  `/stores/${storeId}/paymentMethods/${id}`, null, "DELETE")

    if (res.ok) {
      defaultNotifications.deleted(Placeholder.PaymentMethod, paymentMethod.name)
      redirectToList()
    } else {
      defaultNotifications.error(Placeholder.PaymentMethod)
    }

    setDeleting(false)
  }

  function redirectToList() {
    props.history.push(`/stores/${storeId}/paymentMethods`)
  }

  return (
    <ContentContainer
      title={pageTitle}
      backDisabled={!storeId}
      fetchingData={isFetching}
      backTo={`/stores/${storeId}/paymentMethods/`}
      backText={Placeholder.PaymentMethod}
    >
      <form className='form' id='paymentMethodForm' onSubmit={handleSubmit(onSubmit)}>
        <Textbox
          label={Placeholder.Name}
          id='name'
          name='name'
          value={paymentMethod.name}
          onChange={handleInputChange}
          validationRegister={register}
          validation={{
            required: true,
          }}
          errors={errors}
        />
        <SelectList
          label="Typ"
          defaultOption="(Välj typ)"
          defaultOptionValue=""
          options={providerTypes}
          optionValueField="type"
          optionDisplayField="displayName"
          name="type"
          disabled={id}
          onChange={handleProviderTypeChange}
          value={paymentMethod.paymentProviderType}
          validationRegister={register}
          validation={{
            required: true,
            disabled: id
          }}
          errors={errors}
        />
        <Textbox
          label="Minsta belopp"
          id='minimumPrice'
          name='minimumPrice'
          value={paymentMethod.minimumPrice}
          onChange={handleInputChange}
          validationRegister={register}
          validation={{
            required: true,
          }}
          errors={errors}
        />
        <Checkbox
          label={Placeholder.Active}
          checked={paymentMethod.active}
          name='active'
          onClick={handleActiveChange}
        />

        <FormSection title="Kanaler">
          {!paymentMethod.paymentProviderType 
            ? <MessageBox
              type="info"
              message="Välj typ av betalsätt för att ställa in kanaler."
            />
            : channels.map(channel => {
              var isRestricted = getChannelRestrictedByProviderType(paymentMethod.paymentProviderType, channel.id)

              return <Checkbox
                key={channel.id}
                disabled={isRestricted}
                label={getChannelLabel(channel, isRestricted)}
                checked={channelIsChecked(channel.id)}
                name={`channel_${channel.id}`}
                onClick={handleChannelChange}
              />
            })
          }
        </FormSection>

        <FormActions 
          form="paymentMethodForm"
          onCancel={redirectToList}
          showDelete={id}
          onDelete={onRemove}
          saving={saving}
          deleting={deleting}
        />
      </form>
    </ContentContainer>
  )
}

export default PaymentMethod