import React, { useState, useEffect } from 'react'
import {TopAddButton} from '../layout/Button'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import ExtraIngredientListItem from './ExtraIngredientListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

const ExtraIngredients = (props)=> {
  const [data, setData] = useState([])
  const menuId = props.match.params.menuId
  const menuItemId = props.match.params.menuItemId
  useEffect(() => {
    const fetchData = async () => {
      if (menuItemId){
        const response = await getData(
          '/api/menuitem/' + menuItemId + '/extras'
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`/menus/${menuId}/items/${menuItemId}/extras/create/`} title={`${Placeholder.Create} extra ingrediens`} />
      <BackButton backLabel={data.itemName} {...props}>Extra ingredienser</BackButton>
      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Bild</div>
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>        
            <div className="list-item__element label hidden-down">{Placeholder.Price}</div>        
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.extraIngredients && data.extraIngredients.map(item => (
          <li key={item.id}>               
            <ExtraIngredientListItem  data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default ExtraIngredients