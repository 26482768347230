import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import { getData, postData } from '../../services/Ajax'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import { ClickButton } from '../layout/Button'
import ArrowListItem from '../layout/ArrowListItem'
import DatePicker from "react-datepicker"
import Moment from "moment"

function DoorEventListItem(props) {
  const item = props.data
  return (
    <ArrowListItem url={`/stores/${item.storeId}/doorevents/${item.id}`}>
      <div className="list-item__element link-edit arrow-list--left-align">{Moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      <div className="list-item__element link-edit">{getDoorEventType(item.type)}</div>
      <div className="list-item__element link-edit arrow-list--left-align">{item.personInformation.curityUserHouseholdId}</div>
      <div className="list-item__element link-edit arrow-list--left-align">{item.personInformation.curityId}</div>
      <div className="list-item__element link-edit arrow-list--left-align">{item.personInformation.curityAcr}</div>
    </ArrowListItem>
  )

}

function getDoorEventType(doorEventType) {
  var typeText = 'Öppnad dörr'
  switch (doorEventType) {
  case 'EnterDoor':
    typeText += ' - Inpassering'
    break
  case 'ExitDoor':
    typeText += ' - Utpassering'
    break
  }
  return typeText
}

const DoorEvents = (props) => {
  const storeId = props.match.params.storeid
  const [continuationToken, setContinuationToken] = useState()
  const [startDate, setStartDate] = useState(new Date(new Date().getTime()))
  const [endDate, setEndDate] = useState(new Date())
  const [dataLoading, setDataLoading] = useState(false)
  const [doorEvents, setDoorEvents] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const response = await getData(
          '/api/doorsevents/' + storeId
        )
        const result = await response
        setUpdateView(result)
      }
    }

    fetchData()
  }, [])

  const setUpdateView = (data) => {
    if (data.events) {
      var tmpEvents = doorEvents.concat(data.events)
      setDoorEvents(tmpEvents)
    }
    setContinuationToken(data.continuationToken)
    setStartDate(data.startDate)
    setEndDate(data.endDate)
  }

  const handleDateChange = (date, name) => {
    date = Moment(date).format('YYYY-MM-DD')
    if (name === 'startDate') {
      setStartDate(date)
    }
    if (name === 'endDate') {
      setEndDate(date)
    }
    setDoorEvents([])
    setContinuationToken(undefined)
  }


  const handlSearch = async () => {
    if (dataLoading) {
      return
    }
    setDataLoading(true)
    const result = await LoadDoorEvents()
    if (result) {
      setUpdateView(result)
    }
    setDataLoading(false)
  }

  const handleLoadMore = async () => {
    if (dataLoading || !continuationToken) {
      return
    }
    setDataLoading(true)
    const result = await LoadDoorEvents()
    if (result) {
      setUpdateView(result)
    }
    setDataLoading(false)
  }

  const LoadDoorEvents = async () => {

    const search = {
      endDate,
      startDate,
      storeId,
      continuationToken,
    }
    const response = await postData(
      `/api/doorevents/search`, search, true
    )
    const result = await response

    return result
  }

  return (
    <ProntoContainerTop>
      <BackButton disabled={false}  {...props}>{Placeholder.DoorEvents}</BackButton>
      <div className="top-container gutter bg--white">
        <div className="form">
          <div className="form-section">
            <div className="row">
              <DatePicker locale="sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(startDate ? startDate : new Date())} name="StartDate"
                placeholderText="startdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChange(date, "startDate")} />
              <span className="label form-label ml-10">Startdatum</span>
            </div>
            <div className="row">
              <DatePicker locale="sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(endDate ? endDate : new Date())} name="EndDate"
                placeholderText="slutdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChange(date, "endDate")} />
              <span className="label form-label ml-10">Slutdatum</span>
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={"Sök"} onClick={handlSearch} />
            </div>
          </div>
        </div>

        <ul className="no-style arrow-list">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">Datum</div>
              <div className="list-item__element label hidden-down">Händelse</div>
              <div className="list-item__element label hidden-down">HouseholdId</div>
              <div className="list-item__element label hidden-down">CurityId</div>
              <div className="list-item__element label hidden-down">Acr</div>
              <div className="width14"></div>
            </div>
          </li>
          {doorEvents && doorEvents.map((item, index) => (
            <li key={index}>
              <DoorEventListItem data={item} />
            </li>
          ))}
        </ul>
        {!dataLoading && doorEvents && doorEvents.length > 0 && <ClickButton onClick={handleLoadMore} title={`Totalt: ${doorEvents.length} Ladda mer`} />}
      </div>
    </ProntoContainerTop>
  )

}

export default DoorEvents