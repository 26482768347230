import React from 'react'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import ArrowListItem from '../layout/ArrowListItem'
import {
  PlusIcon
} from '../layout/Icons'

const UsersMenu = (props)=> {
  var items = [
    { url: `admins/`, name: Placeholder.UsersMenu.Items.Admins, icon: <PlusIcon /> },
    { url: `employees/`, name: Placeholder.UsersMenu.Items.Employees, icon: <PlusIcon /> },
    { url: `vending/`, name: Placeholder.UsersMenu.Items.Vending, icon: <PlusIcon /> },
  ]

  return (
    <ProntoContainerTop>
      <BackButton disabled={false} {...props} to={`/`}>{Placeholder.UsersMenu.Title}</BackButton>
      <ul className="no-style arrow-list">
        {items.map((item, index) => (
          <ArrowListItem key={index} url={item.url} icon={item.icon} name={item.name} />
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default UsersMenu