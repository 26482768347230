import React from 'react'
import {GetCssInput} from '../../layout/Input'


const CampaignCodeRule = (props)=> {
  const {mode,handleInputChange, data} = props

  return (
    <div className="form">
      <div className="form-section c-6 pr-10">
        <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="codeRule__code" defaultValue={data.code} placeholder="CODE123!"/>
      </div>  
    </div>
  )
  
}


export default CampaignCodeRule



