import React from 'react'
import '../layout/RadioButton.css'
const RadioButton = ({ name, id, onChange, checked, label }) => {
  function onChangeInternal(e) {
    if (onChange) {
      onChange(e)
    }
  }
  return (
    <div className="radioWrapper">
      <input
        className="radioButton"
        id={id}
        type="radio"
        name={name}
        onChange={(e) => onChangeInternal(e)}
        checked={checked}
      />
      <label htmlFor={name} className="radioButtonLabel">
        {label}
      </label>
    </div>
  )
}
export default RadioButton