import React from "react"
import PropTypes from "prop-types"
import css from "./Badge.module.css"

Badge.propTypes = {
  type: PropTypes.oneOf(["default", "success"])
}

function Badge({
  children,
  type = "default",
  expanded = false,
}) {
  var className = css.badge
  if (type !== "default") {
    className += ` ${css[type]}`
  }
  if (expanded) {
    className += ` ${css.expanded}`
  }

  return (
    <span className={className}>
      {children}
    </span>
  )
}

export default Badge