import React, { useState, useEffect } from 'react'
import {TopAddButton} from '../layout/Button'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import MessageListItem from './MessageListItem'


const Messages = (props) => {
  const storeId = props.storeId ? props.storeId : props.match.params.storeId
  const [data, setData] = useState([])
  const [messageTypes, setMessageTypes] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        `/api/stores/${storeId}/messages`
      )
      const result = await response
      setData(result)

      const messageTypesResponse = await getData(
        'api/stores/messageTypes')
      const messageTypesResult = await messageTypesResponse
      setMessageTypes(messageTypesResult)
    }


    fetchData()
  }, [])

  return (
    <ProntoContainerTop >
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/messages/create/`} title={`${Placeholder.Create} meddelande`} />
      <BackButton {...props}>Meddelanden</BackButton>
      <ul className="no-style drop-shadow">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Typ</div>
            <div className="list-item__element label hidden-down">Titel</div>
            <div className="list-item__element label hidden-down">Visa i app</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.map(item => (
          <li key={item.id}>
            <MessageListItem data={item} storeId={storeId} messageType={messageTypes.find(m => m.id == item.messageType)} {...props} />
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Messages