const Placeholder = {
  CloneProducts: 'Klona produkter',
  CopyFrom: 'Kopiera från',
  CopyTo: 'Kopiera till',
  Ready: 'Klart',
  Retrun: 'Återbetala',
  Create: 'Lägg till',
  ChangePassword: 'Ändra lösenord',
  Password: 'Lösenord',
  PasswordRepeat: 'Upprepa lösenord',
  Delete: 'Ta bort',
  Edit: 'Ändra',
  Cancel: 'Avbryt',
  Save: 'Spara',
  SaveButton: 'Spara',
  Send: 'Skicka',
  Change: 'Ändra',
  Active: 'Aktiv',
  Inactive: 'Inaktiv',
  ActiveExplanatory: '(Inaktiva butiker får ingen AoB-rapportering)',
  CustomerNumberError:
    'Kundnummer kan inte ändras. Vänligen kontakta IT-support för mer information.',
  Marked: 'Markerat',
  Required: 'Obligatorisk',
  Paused: 'Pausad',
  Groups: 'Grupper',
  GroupedProducts: 'Grupperade produkter',
  Image: 'Bild',
  ImageUrl: 'http://din-web-anpassade-bild-url.png',
  Name: 'Namn',
  OrganizationNumber: 'Organizationnummer',
  Logo: 'Logo',
  Price: 'Pris',
  SetPrice: 'Ange pris',
  Moms: 'Moms',
  Campaign: 'Kampanj',
  Campaigns: 'Kampanjer',
  RecomendedPrice: 'Rekomenderat pris',
  Delivery: 'Leverans',
  DeliveryProviderName: 'Leverantör',
  DeliveryProviderType: 'Typ',
  Gtin: 'Gtin',
  Ean: 'EAN',
  SetEan: 'Ange EAN',
  Assigned: 'Vald',
  GroceryDisplayName: 'Namn',
  Brand: 'Varumärke',
  Sort: 'Sortering',
  Product: 'Produkt',
  Products: 'Produkter',
  AdditionalProducts: 'Tillvalsprodukter',
  Category: 'Kategori',
  ChangeCategory: 'Ändra kategori',
  Description: 'Beskrivning',
  Latitude: 'Latitude',
  Longitude: 'Longitude',
  City: 'Ort',
  OpenHours: 'Öppettider',
  DeliveryHours: 'Upphämtnings-/leveranstider',
  AvailabilityHours: 'Leveranstider',
  PostalCode: 'Postnummer',
  StreetAdress: 'Adress',
  ZipCode: 'Postnummer',
  ContactName: 'Butiks kontaktnamn',
  ContactEmail: 'Butiks email',
  ContactPhoneNumber: 'Butiks telefonummer',
  CopyToEmail: 'Skicka kopia av orderbekräftelse till e-postadress',
  SupportPhone: 'Telefonummer till support',
  FoodDescription: "T.ex. 'Dagens lunch från Bistron, Pizza & Juicebar.' ",
  PickupDelay: 'Uthämtningstid i minuter',
  DeliveryTimeInMinutes: 'Leveranstid i minuter',
  AllowOnce: 'En gång per användare',
  Themes: 'Teman',
  CookByYourself: 'Laga själv',
  Categories: 'Kategorier',
  Restaurants: 'Restauranger',
  Menus: 'Menyer',
  Menu: 'Meny',
  MenuItems: 'Menyalternativ',
  Stores: 'Butiker',
  LogOut: 'Logga ut',
  ProntoProducts: 'Pronto produkter',
  Order: 'Order',
  Orders: 'Ordrar',
  Reports: {
    Reports: 'Rapporter',
    CreateReport: 'Skapa rapport',
    AccountingReport: {
      Name: 'Bokföringsunderlag',
    },
    CsvReport: {
      Name: 'CSV',
    },
    DeliveryExcelReport: {
      Name: 'Leveransinformation',
    },
    OrderStatusReport: {
      Name: 'Statusrapport',
    },
    OrderSalesReport: {
      Name: 'Försäljningsrapport',
    },
  },
  AppVersions: 'App-versioner',
  Register: 'Registrera',
  ChooseStore: 'Välj butik(er)',
  Today: 'Idag',
  Users: 'Användare',
  Role: 'Roll',
  Roles: 'Roller',
  Store: 'Butik',
  ApplicationStatus: 'Pronto Backend',
  ApplicationName: 'Applikation namn',
  Status: 'Status',
  ActiveCampaign: 'Aktiva Kampanjer',
  NoOrder: 'Inga beställningar under de senaste 3 dagarna!',
  AirmeeNotConfigured: 'Airmee konfigureration saknas!',
  StorePickupNotConfigured: 'Inga leveranssätt!',
  SelectStore: 'Välj butik',
  Search: 'Sök',
  Partners: 'Partner',
  PartnerType: 'Typ',
  Setting: 'Inställningar',
  Amount: 'Belopp',
  TaxCode: 'Moms code',
  Comment: 'Kommentar',
  Unmanned: 'Obemannat',
  Doors: 'Dörrar',
  DoorEvents: 'Dörrhändelser',
  Door: 'Dörr',
  CreateDoor: 'Skapa ny dörr',
  DoorExternalId: 'Dörr-ID hos leverantör',
  IsEntrance: 'Inpassering',
  IsExit: 'Utpassering',
  Gates: 'Streckkod',
  HasEntranceGate: 'Visa streckkod in',
  HasExitGate: 'Visa streckkod ut',
  Message: 'Meddelande',
  Title: 'Rubrik',
  PreSelected: 'Förvald',
  PaymentMethod: 'Betalsätt',
  CreatePaymentMethod: 'Lägg till betalsätt',
  Value: 'Värde',
  OK: 'OK',
  Monday: 'Måndag',
  Tuesday: 'Tisdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lördag',
  Sunday: 'Söndag',
  Date: 'Datum',
  From: 'Från',
  To: 'Till',
  TargetGroup: 'Målgrupp',
  StammisOnly: 'Endast stammisar',
  Email: 'Epost',
  CustomerTitle: 'Kundtitel',
  BongTitle: 'Bongtitel',
  PayexSettings: 'Payex-inställningar',
  Channels: 'Kanaler',
  Forbidden: 'Behörighet saknas',
  BlockDoors: {
    BlockedBy: 'Spärrad av',
    SsnLabel: 'Spärra svenskt personnummer',
    Title: 'Spärra användare ICA ToGo',
  },
  Catering: {
    Name: 'Catering',
    Product: {
      CreateHeadline: 'Lägg till produkt',
      AllowComment: 'Visa kommentarsfält',
    },
    CopyStore: {
      Title: 'Kopiera butik',
      Info: 'Kopierar produkter, kategorier, startsida, öppettider och cateringspecifika leveranssätt från vald butik.',
      FromStore: 'Från butik',
    },
    Settings: {
      Title: 'Inställningar',
      AllowOrderComment: 'Visa kommentarsfält i kassan',
    },
    LimitedDeliveryPeriod: {
      AddLimitedDeliveryPeriod: 'Lägg till leveransperiod',
      AddPeriodsToProduct:
        'Koppla produkter till leveransperiod för tillfälligt sortiment',
      AddProductsToPeriod: 'Lägg till produkter till leveransperiod',
      ConnectedProducts: 'Antalet kopplade produkter',
      DatePickerEnd: 'Slutdatum (ÅÅÅÅ-MM-DD)',
      DatePickerStart: 'Startdatum (ÅÅÅÅ-MM-DD)',
      DeliveryPeriod: 'Leveransperiod',
      Heading: 'Leveransperioder för tillfälligt sortiment ',
      TitleLabel: 'Titel leveransperiod',
      TitlePlaceholder: 'Ex jul & nyår',
    },
  },
  UnmannedSettings: {
    Title: 'Inställningar',
    MainStoreName: 'Huvudbutik',
    StoreType: {
      Title: 'Butikstyp',
      Store: 'Butik',
      BranchStore: 'Filialbutik',
      VendingMachine: 'Varuautomat',
    },
  },
  UsersMenu: {
    Title: 'Hantera användare',
    Items: {
      Admins: 'Administratörer',
      Employees: 'Butiksanställda',
      Vending: 'Påfyllning av varuautomater',
    },
  },
  Admins: {
    Title: 'Administratörer obemannat',
    Add: 'Lägg till',
    Key: 'Id',
  },
  Admin: {
    Admin: 'Administratör',
    CreateTitle: 'Skapa administratör obemannat',
    EditTitle: 'Redigera administratör obemannat',
    Key: 'Id',
  },
  Employees: {
    Title: 'Butiksanställda obemannat',
    Add: 'Lägg till',
    Key: 'Id',
    Stores: 'Antal butiker',
  },
  Employee: {
    Employee: 'Butiksanställd',
    CreateTitle: 'Skapa butiksanställd obemannat',
    EditTitle: 'Redigera butiksanställd obemannat',
    Key: 'Id',
  },
  VendingEmployees: {
    Title: 'Påfyllning av varuautomater',
    Add: 'Lägg till',
    Key: 'Id',
    Stores: 'Antal butiker'
  },
  VendingEmployee: {
    VendingEmployee: 'Påfyllning av varuautomater',
    CreateTitle: 'Skapa anställd för påfyllning av varuautomater',
    EditTitle: 'Redigera anställd för påfyllning av varuautomater',
    Key: 'Id'

  }
}
export default Placeholder
