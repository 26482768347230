export function getEnvirionment(pathname) {
  if(pathname.includes('testing')) {
    return 'test'
  } else if(pathname.includes('staging')) {
    return 'stage'
  } else if(pathname.includes('localhost')) {
    return 'local'
  } else {
    return 'production'
  }
}