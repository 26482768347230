import React from 'react'
import Checkbox from '../../layout/Checkbox'
import {GetCssInput} from '../../layout/Input'

const CampaignStoreRule = (props)=> {
  const {mode,handleInputChange} = props
  const stores = props.stores ? props.stores : []
  const storeId = props.storeId
  const objIndex = stores.findIndex(x => x.value === storeId)
  const defaultStore = objIndex >= 0 ? stores[objIndex] : undefined
  return (
    <div className="form">
      {defaultStore &&  <input className={GetCssInput(mode)} type="text" disabled name="storeRulestoreId" defaultValue={defaultStore.name} />}
      {/* {!defaultStore && <SelectMultiple  data={campaignTypes} defaultValue={data.campaignType} name="campaignType" handleEvent={handleInputChange}/>} */}

      <ul className="no-style">
        {!defaultStore && stores.map((store,i) => (
          <li key={i}>
            <div className="row">
              {<Checkbox checked={store.selected} name={"selectedStores__"+store.value} handleEvent={handleInputChange}/>  }
              <span className="label form-label">{store.name}</span>  
            </div>
  
          </li>
        ))} 
      </ul>
           
    </div>
  )
  
}


export default CampaignStoreRule
