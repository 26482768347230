import React from 'react'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import ArrowListItem from '../layout/ArrowListItem'
import { CheckIcon } from '../layout/Icons'

function DoorListItem(props) {
  const item = props.data
  return (
    <ArrowListItem url={`/stores/${item.storeId}/doors/${item.id}/edit`}>
      <div className="list-item__element link-edit arrow-list--left-align">{item.name}</div>
      <div className="list-item__element">
        <Checkbox checked={item.active} name="active" disabled="disabled" />
        <span className="label form-label link-edit hidden-up">{Placeholder.Active}</span>
      </div>
      <div className="list-item__element link-edit">
        {item.isEntrance &&
          <CheckIcon color="#000" />
        }
      </div>
      <div className="list-item__element link-edit">
        {item.isExit &&
          <CheckIcon color="#000" />
        }</div>
    </ArrowListItem>
  )

}

export default DoorListItem

