import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '../layout/Checkbox'
import Placeholder from '../layout/Placeholder'



function GroupListItem(props) {
  const item = props.data
  const storeId = props.match.params.storeid
  const referencEntityId = props.match.params.referencentityid
  const referenceType = props.referenceType ? props.referenceType : "restaurants"

  function GetProductsRoute(item) {
    if (item.type === 5) {
      return `/stores/${storeId}/${referenceType}/${referencEntityId}/menus/${item.selectedMenu}/items/`
    }
    else {
      return `/stores/${storeId}/${referenceType}/${referencEntityId}/groups/${item.id}/products/`
    }
  }

  function GetDisplayText(item) {
    if (item.type === 5) {
      return Placeholder.Menu
    }
    else {
      return Placeholder.Products
    }
  }

  return (
    <div className="list-item">
      <div className="list-item__element">{item.name}</div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.active} name="active" disabled="disabled"/>     
        <span className="label form-label hidden-up">{Placeholder.Active}</span>   
      </div>
      <div className="list-item__element">
        <Link className="link-edit" to={`/stores/${storeId}/${referenceType}/${referencEntityId}/groups/${item.id}/edit/`}>{Placeholder.Edit}</Link>
        {item.type != 5 && <Link className="link-edit" to={GetProductsRoute(item)}>{GetDisplayText(item)}</Link>}
        <Link className="link-edit" to={`/stores/${storeId}/${referenceType}/${referencEntityId}/groups/${item.id}/delete/`}>{Placeholder.Delete}</Link>
      </div>          
    </div>
  )
  
}

export default GroupListItem

