import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'



const ProntoDeletedCategories = ()=> {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/categories/'
      )
        
      const result = await response
      setData([...result])

      for (const index in result) {
        const item = result[index]
        const countResponse = await getData(
          `/api/category/${item.id}/products/deleted/count`
        )

        const countResult = await countResponse
        if(countResult){
          result[index].totalProductsCount = countResult.totalProductsCount
                      
          setData([...result])
        }
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <BackButton disabled={true}>{Placeholder.ProntoProducts}</BackButton>

      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
          </div>
        </li>
        {data && data.map(item => (
          <li key={item.id} className="list-item list-item--flex-row-mobile">   
            <div className="list-item__element">
              <Link className="link-edit" to={`/categories/${item.id}/products/deleted/list`}>{item.name}</Link>({item.totalProductsCount? item.totalProductsCount: 0})
            </div>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default ProntoDeletedCategories