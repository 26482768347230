import React, { useEffect, useState } from 'react'
import Mode from '../../utilis/Mode'
import { getData } from '../../services/HttpClient'
import authService from '../../services/api-authorization/AuthorizeService'
import { ApplicationPaths } from '../../services/api-authorization/ApiAuthorizationConstants'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import './UserItem.css'
import '../layout/BulletinBoard.css'
import BackButton from "../backbutton/BackButton"
import { Link } from "react-router-dom"
import { defaultNotifications } from "../../utilis/Notifications"
import Textbox from '../Form/Textbox'
import SelectBox from "../Form/SelectBox"
import LoadingContainer from "../layout/LoadingContainer"

const UserItem = (props) => {
  const mode = props.mode
  const userId = props.id ? props.id : props.match.params.userid
  const [fetching, setFetching] = React.useState(false)
  const [user, setUser] = useState({})

  const selectedStores = user.stores ? user.stores.filter((store) => store.checked) : []
  const selectedRoles = user.roles ? user.roles.filter((role) => role.checked) : []

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      let url = ApplicationPaths.Register
      if (mode === Mode.Edit) {
        url = `/api/user/${userId}/view`
      }
      const res = await getData(url)
      setUser(res.data)
      setFetching(false)
    }

    fetchData()

  }, [userId, mode])

  const handleStoresInputChange = (options) => {
    const newUser = { ...user }
    newUser.stores.map(x => x.checked = false)
    options.forEach(option => {
      const storeIndex = newUser.stores.findIndex(x => x.id === option.id)
      newUser.stores[storeIndex].checked = true
    })
    setUser(newUser)
  }

  const handleRolesInputChange = (options) => {
    const newUser = { ...user }
    newUser.roles.map(x => x.checked = false)
    options.forEach(option => {
      const roleIndex = newUser.roles.findIndex(x => x.id === option.id)
      newUser.roles[roleIndex].checked = true
    })
    setUser(newUser)
  }

  const handlePostData = async () => {
    let response
    if (mode === Mode.Edit) {
      response = await authService.update(user)
      if (response.ok) {
        defaultNotifications.updated(Placeholder.Users, user.email)
        redirectToUserList()
      } else {
        defaultNotifications.error(Placeholder.Users)
      }
    }
    else if (mode === Mode.Create) {
      response = await authService.register(user)
      if (response && response.success) {
        defaultNotifications.created(Placeholder.Users, user.email)
        redirectToUserList()
      } else if (response.status === 400) {
        defaultNotifications.error(Placeholder.Users, response.detail)
      } else if (response && response.error) {
        defaultNotifications.error(Placeholder.Users, response.error)
      } else {
        defaultNotifications.error(Placeholder.Users)
      }
    }
  }

  const handleInputChange = (event) => {
    const newUser = { ...user }
    newUser[event.target.name] = event.target.value
    setUser(newUser)
  }

  function redirectToUserList() {
    props.history.push("/users")
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Users}
        editPage={true}
        match={props.match}
        history={props.history}
        {...props}
      >
        {mode === Mode.Edit ? user.email : `${Placeholder.Create} ${Placeholder.Users.toLowerCase()}`}
      </BackButton>
      <ProntoContainer>
        <LoadingContainer loading={fetching}>
          <div className="form c-12">
            <div className="form-row">
              <div className="form-section c-6 pr-10">
                <Textbox
                  label={Placeholder.Email}
                  name="email"
                  value={user.email}
                  readOnly={mode === Mode.Edit ? true : false}
                  onChange={handleInputChange}
                  placeholder={Placeholder.Email}
                />
                <Textbox
                  label={Placeholder.StreetAdress}
                  name="address"
                  value={user.address}
                  onChange={handleInputChange}
                  placeholder={Placeholder.StreetAdress}
                />
                <Textbox
                  label={Placeholder.ZipCode}
                  name="zipCode"
                  value={user.zipCode}
                  onChange={handleInputChange}
                  placeholder={Placeholder.ZipCode}
                />
                <Textbox
                  label={Placeholder.City}
                  name="city"
                  value={user.city}
                  onChange={handleInputChange}
                  placeholder={Placeholder.City}
                />

                {mode === Mode.Create &&
                  <React.Fragment>
                    <Textbox
                      label={Placeholder.Password}
                      name="password"
                      value={user.password}
                      type="password"
                      onChange={handleInputChange}
                      placeholder={Placeholder.Password}
                    />
                    <Textbox
                      label={Placeholder.PasswordRepeat}
                      name="rePassword"
                      value={user.rePassword}
                      type="password"
                      onChange={handleInputChange}
                      placeholder={Placeholder.PasswordRepeat}
                    />
                  </React.Fragment>
                }

                <SelectBox
                  label={Placeholder.Stores}
                  name="stores"
                  options={user.stores}
                  getOptionLabel={(data) => data.name}
                  getOptionValue={(data) => data.id}
                  value={selectedStores}
                  isMulti
                  onChange={handleStoresInputChange}
                />
                <SelectBox
                  label={Placeholder.Roles}
                  name="roles"
                  options={user.roles}
                  getOptionLabel={(data) => data.name}
                  getOptionValue={(data) => data.id}
                  value={selectedRoles}
                  isMulti
                  onChange={handleRolesInputChange}
                />
              </div>
            </div>
            <div className="row">
              <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={handlePostData} />
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={redirectToUserList}/>
            </div>

            <div className="row change-password">
              <Link className="link-edit" to={`/user/${user.id}/${user.storeId}/changePassword/`} >{Placeholder.ChangePassword}</Link>
            </div>
          </div>
        </LoadingContainer>
      </ProntoContainer>
    </div>

  )
}

export default UserItem
