import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import CatagoryProduct from './CatagoryProduct'
import {parseBool} from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { ClickButton ,SaveButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import {GetCssInput} from '../layout/Input'



const CategoryProducts = (props)=> {
  const mode = props.mode
  const defaultLimit = 200
  const [allItemsLoaded, setAllItemsLoaded] = useState(false)
  const [limit] = useState(defaultLimit)
  const [activeLoadingCompleted, setActiveLoadingCompleted] = useState(false)
  const [data, setData] = useState({products : []} )
  const [productChanged, setProductChanged] = useState([])
  const storeId = props.match.params.storeid
  const categoryId = props.match.params.categoryid
  const [search, setSearch] = useState({storeId : storeId,categoryId: categoryId})
  const [inactiveContinuationToken, setInactiveContinuationToken] = useState()
  const [activeContinuationToken, setActiveContinuationToken] = useState()

  const history = props.history

  useEffect(() => {
    const fetchData = async () => {
      if(storeId && categoryId){
        const response = loadProducts()
        const result = await response
        setData(result)
        
      }
    }

    fetchData()
  }, [])

  function ShouldParseInt(name){
    if(name === 'active'){
      return true
    }
    return false
  }

  const handleInputChange = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const id = values[0]
    const channelId = values[1]
    const name = values[2]
    const newValue = ShouldParseInt(name) ? parseBool(event.target.value) : event.target.value
    const objIndex = data.products.findIndex(x => x.ean === id)
    const storeGrocery = data.products[objIndex]

    var existingOffer = false
    storeGrocery.offers.forEach(offer => {
      if(offer.purchaseChannelTypeId == channelId){
        offer[name] = newValue
        existingOffer = true
      }
    }   
    )
    if(!existingOffer){
      var newOffer = {
        storeId : storeId,
        purchaseChannelTypeId: channelId,
        recommendedPrice : storeGrocery.price
      }
      newOffer[name] = newValue
      storeGrocery.offers.push(newOffer)
    }

    setData(data)
  
    const index = productChanged.findIndex((e) => e.ean === storeGrocery.ean)
    if (index === -1) {
      productChanged.push(storeGrocery)
    } else {
      productChanged[index] = storeGrocery
    }
  
    setProductChanged(productChanged)
  }


  const handlePostData = async () => {
    const url = "/api/categories/products/"
    var d = {
      storeId : storeId,
      products : productChanged,
      categoryId: categoryId,
    }
    if(url){
      const response = await postData(
        url,d
      )
      return await response
    }else{
      console.error("url not defined")
    }
  }

  const loadProducts = async () => {        

    if (categoryId && activeLoadingCompleted) {
      const customHeader = inactiveContinuationToken ? { 'x-ms-continuation': inactiveContinuationToken } : undefined
      const response = await getData(
        `/api/categories/${categoryId}/store/${storeId}/products/inactive/${limit}`, customHeader
      )
      const result = await response
      var continuationToken = result.continuationToken
      setInactiveContinuationToken(continuationToken)
      if (!continuationToken && activeLoadingCompleted) {
        setAllItemsLoaded(true)
      }
      return result
    } else if (categoryId && storeId && !activeLoadingCompleted) {
      const customHeader = activeContinuationToken ? { 'x-ms-continuation': activeContinuationToken } : undefined
      const response = await getData(
        `/api/categories/${categoryId}/store/${storeId}/products/active/${limit}`, customHeader
      )

      const result = await response
      var cont = result.continuationToken
      setActiveContinuationToken(cont)

      if(!result.products || result.products.length <= 0 || !result.continuationToken){
        setActiveLoadingCompleted(true)
      }
      return result
    }

  }

  const handleSearchData = async (event) => {
    event.persist()
    const url = '/api/categories/products/search'
    if (url) {
      const response = await postData(
        url, search,true
      )
      const result = await response

      var newData = {...data, products: [...result]}
      setData(newData)
    } else {
      console.error("url not defined")
    }
  }

  const handleSearchChange = (event) => {
    event.persist()
    search[event.target.name] = event.target.value
    setSearch(search)
  }

  const handleLoadMore = async () => {
    if (allItemsLoaded) {
      return
    }
    const result = await loadProducts()
    if (result && result.products) {
      const newProducts = data.products.concat(result.products)
      var newData = {...data, products: [...newProducts]}
      setData(newData)
    }
  }

  return (
    <ProntoContainerTop>
      <BackButton backLabel={Placeholder.Categories} {...props}>{Placeholder.Products}</BackButton>
      <input className={GetCssInput(mode)} type="text" onChange={handleSearchChange} name="query" defaultValue={search.query} placeholder={"Sök på namn eller EAN-nummer"}/>
      <ClickButton className="btn--black mr-10" title={"Sök"} onClick={handleSearchData}/>
      <div className="form-section">
        <ul className="no-style">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
              <div className="list-item__element label hidden-down">Säljkanal</div>
              {/* <div className="list-item__element label hidden-down">{Placeholder.RecomendedPrice}</div>    */}
              <div className="list-item__element label hidden-down">{Placeholder.Price}</div>      
              <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
              {/* <Checkbox checked={false} name={`all__checked`} defaultValue={false} handleEvent={handleCheckAll} />      */}
            </div>
          </li>
          {data.products && data.products.map(item => (

            <li key={item.ean}>
              <CatagoryProduct data={item} availableChannels={data.availableChannels} storeId={storeId}  {...props} handleEvent={handleInputChange}/>
            </li>

          ))}
        </ul>
        <div className="row mt-10">
          <SaveButton className="btn--black mr-10" title={Placeholder.SaveButton} onClick={handlePostData}/>
          <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
        </div>
      </div>
      <ClickButton onClick={handleLoadMore} title={`Totalt: ${data.products.length} Ladda mer`} />
    </ProntoContainerTop>
  )
  
}

export default CategoryProducts