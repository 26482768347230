import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import { getData, sendData } from '../../services/HttpClient'
import ContentContainer from '../layout/ContentContainer'
import { defaultNotifications } from '../../utilis/Notifications'
import ItemList, { ItemListColumn } from "../ItemList/ItemList"
import CheckboxV2 from '../layout/CheckboxV2'
import GridRow from '../Grid/GridRow'
import GridItem from '../Grid/GridItem'
import SelectList from '../Form/SelectList'
import { emptyGuid } from '../../utilis/Constants'
import InputLabel from '../Form/InputLabel'
import Textbox from '../Form/Textbox'
import Button from '../UI/Button'
import { PlusIcon } from '../../components/layout/Icons'
import LinkButton from '../../components/UI/LinkButton'
import StoreItem from './StoreItem'

function Stores() {
  const [stores, setStores] = useState([])
  const [store, setStore] = useState({})
  const [search, setSearch] = useState({
    showActive: true,
    showInActive: true,
    showPaused: true,
    showUnPaused: true,
    channelTypeId: emptyGuid,
    query: ""
  })
  const [channels, setChannels] = useState([])
  const [fetchingData, setFetchingData] = useState(false)
  const [isInitialFetch, setIsInitialFetch] = React.useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsInitialFetch(true)

      const res = await getData('/api/stores/')
      if (res.ok) {
        if (res.data && res.data.stores && res.data.stores.length > 0) {
          setStores(res.data.stores)
        } else if (res.data && res.data.store) {
          setStore(res.data.store)
        }
      } else {
        defaultNotifications.error(Placeholder.Store)
      }

      const channelResponse = await getData('/api/system/channels')
      if (res.ok) {
        setChannels(channelResponse.data)
      } else {
        defaultNotifications.error()
      }

      setIsInitialFetch(false)
    }

    fetchData()
  }, [])

  async function onSearch(e) {
    e.preventDefault()
    setFetchingData(true)

    var res = await sendData('/api/stores/search', search)
    if (res.ok) {
      setStores(res.data.stores)
    } else {
      defaultNotifications.error()
    }

    setFetchingData(false)
  }

  const handleSearchChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    })
  }

  const handleSearchCheckboxChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.checked
    })
  }

  return (
    <>
      {stores && !store.id && 
        <ContentContainer
          title={Placeholder.Stores}
          backDisabled={true}
          fetchingData={isInitialFetch}
          expandContent={true}
          actions={() =>
            <>
              <LinkButton 
                type="save"
                Icon={PlusIcon}
                to={`/stores/create/`}
              >
                {`${Placeholder.Create} butik`}
              </LinkButton>
            </>
          }
        >
          <div className="top-container gutter bg--white">
            <form onSubmit={onSearch}>
              <GridRow>
                <GridItem cols="2">
                  <SelectList
                    label="Köpkanal"
                    options={channels}
                    optionValueField="value"
                    name="channelTypeId"
                    onChange={handleSearchChange}
                    defaultOption="Alla köpkanaler"
                    value={search.channelTypeId}
                  />
                </GridItem>
                <GridItem cols="3">
                  <Textbox
                    label="Fritextsök"
                    name="query"
                    placeholder="Sök på namn eller BMS-nummer"
                    value={search.query}
                    onChange={handleSearchChange}
                    autoFocus
                  />
                </GridItem>
                <GridItem cols="7">
                  <InputLabel label="Status" />
                  <div style={{marginTop: 4}}>
                    <span className="mr-10">
                      <CheckboxV2 
                        label="Visa aktiva"
                        name="showActive"
                        checked={search.showActive}
                        onChange={handleSearchCheckboxChange}
                      />
                    </span>
                    <span className="mr-10">
                      <CheckboxV2
                        label="Visa inakiva"
                        name="showInActive"
                        checked={search.showInActive}
                        onChange={handleSearchCheckboxChange}
                      />
                    </span>
                    <span className="mr-10">
                      <CheckboxV2
                        label="Visa pausade"
                        name="showPaused"
                        checked={search.showPaused}
                        onChange={handleSearchCheckboxChange}
                      />
                    </span>
                    <span className="mr-10">
                      <CheckboxV2
                        label="Visa ej pausade"
                        name="showUnPaused"
                        checked={search.showUnPaused}
                        onChange={handleSearchCheckboxChange}
                      />
                    </span>
                  </div>
                </GridItem>
              </GridRow>
    
              <Button
                loading={fetchingData}
                nativeType="submit"
              >
                {Placeholder.Search}
              </Button>
            </form>
          </div>
    
          {stores &&
            <ItemList
              data={stores}
              stickyHeader={true}
              arrowList
              navigationUrl={(item) => `/stores/${item.id}/view/`}
              renderRow={(item, index) =>
                <React.Fragment>
                  <ItemListColumn id="index" label="#" columns={1}>
                    {index + 1}
                  </ItemListColumn>
                  <ItemListColumn id="name" label={Placeholder.Name}>
                    {item.name}
                  </ItemListColumn>
                  <ItemListColumn id="active" label={Placeholder.Active} columns={1}>
                    <CheckboxV2 readonly checked={item.active} />
                  </ItemListColumn>
                  <ItemListColumn id="paused" label={Placeholder.Paused} columns={1}>
                    <CheckboxV2 readonly checked={item.paused} />
                  </ItemListColumn>
                </React.Fragment>
              }>
            </ItemList>
          }
        </ContentContainer>
      }
      {store && store.id && 
        <StoreItem storeid={store.id} />
      }
    </>
  ) 
}

export default Stores