import React from "react"
import { sendServiceData, getServiceData } from "../../../services/HttpClient"
import Placeholder from "../../layout/Placeholder"
import { defaultNotifications } from "../../../utilis/Notifications"
import Textbox from '../../Form/Textbox'
import { useForm } from "react-hook-form"
import { emailRegex } from "../../../utilis/Constants"
import FormSection from "../../Form/FormSection"
import CreatableSelectBox from '../../Form/CreatableSelectBox'
import FormActions from "../../Form/FormActions"
import ContentContainer from "../../layout/ContentContainer"
import LinkButton from "../../UI/LinkButton"

const defaultOrderConfirmationText = "Tack för din beställning. Det här är en bekräftelse på att din order är skapad. Nedan hittar du en summering av det du beställt. Läs gärna igenom för att se att allt stämmer."
const defaultChangeOrCancelOrderText = "Om du har ytterligare frågor eller funderingar är du välkommen att höra av dig till oss i butiken. För att avboka beställningen behöver du kontakta oss i butik minst 48h innan leveransdagen."
const defaultPickupText = "Vid upphämtning i butik är du välkommen att komma till delikatessdisken."
const defaultGreetingText = "Vi i köket!"

function MailContent({ history, match }) {
  const {
    params: { storeid },
  } = match || {}
  const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm()
  const navigateToCateringUrl = `/stores/${storeid}/catering`

  const [orderConfirmationText, setOrderConfirmationText] = React.useState("")
  const [cateringPickupText, setCateringPickupText] = React.useState("")
  const [greetingText, setGreetingText] = React.useState("")
  const [changeOrCancelOrderText, setChangeOrCancelOrderText] = React.useState("")
  const [copyToEmails, setCopyToEmails] = React.useState([])
  const [isInitialFetch, setIsInitialFetch] = React.useState(false)
  const [saving, setSaving] = React.useState(false)

  React.useEffect(() => {
    let componentMounted = true

    async function getMailContent() {
      setIsInitialFetch(true)
      try {
        const res = await getServiceData("FoodApi", "v1", `/catering/${storeid}/mailcontent`)
        if (res.ok && componentMounted) {
          setOrderConfirmationText(res.data.orderConfirmationText)
          setCateringPickupText(res.data.cateringPickupText)
          setChangeOrCancelOrderText(res.data.changeOrCancelOrderText)
          setGreetingText(res.data.greetingText)
          setCopyToEmails(res.data.copyToEmails)
          reset(res.data)
        }
      } catch (error) {
        console.log(error)
      }
      setIsInitialFetch(false)
    }
    if (storeid) {
      getMailContent()
    }
    return () => {
      componentMounted = false
    }
  }, [storeid])

  function getEmailOptions() {
    return copyToEmails.map(email => ({
      label: email,
      value: email
    }))
  }

  const handleCreateEmailOption = (inputValue) => {
    var regex = emailRegex
    if(regex.test(inputValue)){
      setCopyToEmails(current => [...current, inputValue])
    } else {
      alert("Felaktigt formaterad epostadress!")
    }
  }

  const handleCopyToEmailChange = (newValue) => {
    let nonEmptyValues = newValue.filter(value => Object.keys(value).length !== 0).map(v => v.value)
    setCopyToEmails(nonEmptyValues)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()

    setSaving(true)

    try {
      let data = {
        orderConfirmationText,
        cateringPickupText,
        greetingText,
        changeOrCancelOrderText,
        copyToEmails,
      }
      var response = await sendServiceData("FoodApi", "v1", `/catering/${storeid}/mailcontent`, data, "PUT")
      if (response.ok) {
        defaultNotifications.updated("E-postinställningar", "E-postinställningar")
        history.push(navigateToCateringUrl)
      } else {
        defaultNotifications.error("E-postinställningar")
      }
    } catch (error) {
      console.log(error)
    }

    setSaving(false)
  }

  function onClick() {
    setOrderConfirmationText(defaultOrderConfirmationText)
    setValue("orderConfirmationText", defaultOrderConfirmationText)
    setCateringPickupText(defaultPickupText)
    setValue("cateringPickupText", defaultPickupText)
    setChangeOrCancelOrderText(defaultChangeOrCancelOrderText)
    setValue("changeOrCancelOrderText", defaultChangeOrCancelOrderText)
    setGreetingText(defaultGreetingText)
    setValue("greetingText", defaultGreetingText)
  }

  return (
    <ContentContainer
      title={"Hantera e-postinställningar"}
      fetchingData={isInitialFetch}
      backTo={navigateToCateringUrl}
      backText={Placeholder.Catering.Name}
      actions={() =>
        <>
          <LinkButton 
            type="default"
            onClick={onClick}
          >
            Använd standardinställningar
          </LinkButton>
        </>
      }
    >
      <form className="form" id="mailform" onSubmit={handleSubmit(onSubmit)}>
        <FormSection title="Innehåll">
          <Textbox
            label="Orderbekräftelse inledande text"
            id="orderConfirmationText"
            name="orderConfirmationText"
            value={orderConfirmationText}
            onChange={(e) => setOrderConfirmationText(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
          <Textbox
            label="Upphämtning/leveransinformation"
            id="cateringPickupText"
            name="cateringPickupText"
            value={cateringPickupText}
            onChange={(e) => setCateringPickupText(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
          <Textbox
            label="Ändra eller avboka"
            id="changeOrCancelOrderText"
            name="changeOrCancelOrderText"
            value={changeOrCancelOrderText}
            onChange={(e) => setChangeOrCancelOrderText(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
          <Textbox
            label="Hälsningar"
            id="greetingText"
            name="greetingText"
            value={greetingText}
            onChange={(e) => setGreetingText(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
        </FormSection>
        <CreatableSelectBox
          label={Placeholder.CopyToEmail}
          isClearable
          isMulti
          value={getEmailOptions()}
          onChange={handleCopyToEmailChange}
          onCreateOption={handleCreateEmailOption}>
        </CreatableSelectBox>

        <FormActions 
          form="mailform"
          onCancel={() =>
            history.push(navigateToCateringUrl)
          }
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default MailContent
