import React, { useState, useEffect } from 'react'
import { ProntoContainerTop } from '../layout/Containers'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import GroupProduct from './GroupProduct'
import {parseBool} from '../layout/Checkbox'
import BackButton from '../backbutton/BackButton'
import { ClickButton,SaveButton } from '../layout/Button'
import {GetCssInput} from '../layout/Input'
import showNotification from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"
import Select from '../layout/Select'


const GroupProducts = (props)=> {
  const mode = props.mode
  const storeId = props.match.params.storeid
  const groupId = props.groupId ? props.groupId : props.match.params.groupid
  const history = props.history
  const [data, setData] = useState( {} )
  const [search, setSearch] = useState({storeId : storeId,groupId: groupId})

  const [groupName, setgroupName] = useState()

  useEffect(() => {
    const fetchData = async () => {
      if(storeId && groupId){
        const groupResponse = await getData(`/api/group/${groupId}/`)
        const resultgroup = await groupResponse
        setgroupName(resultgroup.name)
        const response = await getData(
          `/api/group/${groupId}/store/${storeId}/products`
        )
        const result = await response
        setData(result)
      
      }
    }

    fetchData()
  }, [])
  const handlePostData = async () => {
    const url = "/api/group/products/"
    const d = data
    d.products = data.products.filter(function(p) {return p.visibleInApp === true })
    if(url){
      const response = await postData(
        url,d
      )
      return await response
     
    }else{
      console.error("url not defined")
    }
  }
  const handleCheckBoxChange = (event) => {
    event.persist()
    if(event.target.name.includes("__")){
      const values = event.target.name.split("__")
      const value = values[1]
      const prop = values[0]
      const productIndex = data.products.findIndex(x => x.groceryViewModel.id === value)
      data.products[productIndex][prop] =  parseBool(event.target.value)
      setData({...data})
    }
  }

  const handleSearchData = async (event) => {
    event.persist()
    const url = '/api/group/product/search/'
    if (url) {
      const response = await postData(
        url, search,true
      )
      const result = await response
      if (response.status === 400) {
        showNotification("Fel", result.detail, NotificationTypes.ERROR)
      }
      else {
        var newProducts = [...data.products.filter(product => product.visibleInApp || product.preSelectedInApp)]
        result.forEach(element => {
          const productIndex = newProducts.findIndex(x => x.groceryViewModel.id === element.groceryViewModel.id)
          if (productIndex === -1) {
            newProducts.push(element)
          }
        })
        var newData = { ...data, products: newProducts }
        setData(newData)
      }
    } else {
      console.error("url not defined")
    }
  }
  const handleSearchChange = (event) => {
    event.persist()
    search[event.target.name] = event.target.value
    setSearch(search)
  }

  return (
    <ProntoContainerTop>
      {props.match.path.includes('themes') ?
        <BackButton backLabel={Placeholder.Themes} editPage={false} {...props}>{(groupName ? groupName: Placeholder.GroupedProducts)}</BackButton>:
        <BackButton backLabel={Placeholder.Groups} editPage={false} {...props}>{(groupName ? groupName: Placeholder.GroupedProducts)}</BackButton>
      }
      <div className="row mt-10">
        {data.channels && data.channels.length > 0 && <Select data={data.channels} name="SelectedChannelTypeId" handleEvent={handleSearchChange} defaultOption="Alla köpkanaler" />}
        <span className="label form-label ml-10 mt-10">Köpkanal</span>
      </div>
      <div className="row">
        {data.categories && data.categories.length > 0 && <Select data={data.categories} name="SelectedCategoryId" handleEvent={handleSearchChange} defaultOption="Välj en kategori" />}
        <span className="label form-label ml-10 mt-10">Kategorier</span>
      </div>
      <input className={GetCssInput(mode)} type="text" onChange={handleSearchChange} name="query" defaultValue={search.query} placeholder={"Sök på namn eller EAN-nummer"}/>
      <ClickButton className="btn--black mr-10" title={"Sök"} onClick={handleSearchData}/>

      <ul className="no-style drop-shadow grouped-products-table">
        <li></li>
        <li>
          <div className="list-item list-item__heading list-item__heading--spaced">
            <div className="list-item__element label hidden-down"></div>       
            <div className="list-item__element label hidden-down">{Placeholder.GroceryDisplayName}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Brand}</div>
            <div className="list-item__element list-item__element--s  label">Synlig</div>
            <div className="list-item__element list-item__element--s  label">Förvald</div>
          </div>
        </li>
        {data.products && data.products.map((item,index) => (
          <li key={index}>
            {item && item.groceryViewModel && <GroupProduct data={item}  {...props} handleEvent={handleCheckBoxChange}/>}
          </li>
        ))}
      </ul>
      <div className="row mt-10">
        <SaveButton type="button" value={Placeholder.SaveButton} title="Spara" className="btn mr-10" onClick={handlePostData}/>
        <input type="button" value={Placeholder.Cancel} className="btn btn--white" onClick={history.goBack} />
      </div>
    </ProntoContainerTop>
  )
  
}

export default GroupProducts