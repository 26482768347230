import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'



function CampaignRuleListItem(props) {
  const item = props.data
  return (
    <div className="list-item">
      <div className="list-item__element">{item.Name}</div>

      <div className="list-item__element">
        <Link  className="link-edit" to={`${props.location.pathname}${item.RuleId}/edit/`}>{Placeholder.Edit}</Link>
        <Link  className="link-edit" to={`${props.location.pathname}${item.RuleId}/delete/`}>{Placeholder.Delete}</Link>
      </div>          
    </div>
  )
  
}

export default CampaignRuleListItem

