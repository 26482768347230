import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'



function MessageListItem(props) {
  const item = props.data
  const storeId = props.storeId
  const messageType = props.messageType ? props.messageType.name : "Unknown"
   
  return (
    <div className="list-item">
      <div className="list-item__element">{messageType}</div>
      <div className="list-item__element">{item.title}</div>
      <div className="list-item__element">
        <Checkbox checked={item.active} name="active" disabled="disabled" />
        <span className="label form-label link-edit hidden-up">{Placeholder.Active}</span>
      </div>        <div className="list-item__element">
        <Link className="link-edit" to={`/stores/${storeId}/messages/${item.id}/edit/`}>{Placeholder.Edit}</Link>
        <Link className="link-edit" to={`/stores/${storeId}/messages/${item.id}/delete/`}>{Placeholder.Delete}</Link>    
      </div>          
    </div>


  )
  
}

export default MessageListItem

