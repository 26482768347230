import React from 'react'
import { Link } from 'react-router-dom'

const ArrowListItem = props => {

  return (
    <Link className="list-item" to={props.url}>
      {
        props.name &&
                <div className="list-item__element link-edit">
                  {props.icon && <span className="icon--red mr-10">{props.icon}</span> }
                  {props.name}
                </div>
      }
      {props.children}
      <img src="/arrow-small.png" alt="arrow" />
    </Link>
  )
}

export default ArrowListItem