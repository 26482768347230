import React from 'react'
import Checkbox from '../layout/Checkbox'
import Placeholder from '../layout/Placeholder'



function GroupProduct(props) {

  const item = props.data
  var grocery = item.groceryViewModel
  const handleEvent = props.handleEvent

  return (
    <div className="list-item">
      <div className="list-item__element"><img className="list-item__img" src={grocery.imageUrl} alt=""/></div>
      <div className="list-item__element">{grocery.groceryDisplayName}</div>
      <div className="list-item__element">{grocery.ean}</div>
      <div className="list-item__element">{grocery.brandName}</div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.visibleInApp} name={'visibleInApp__'+grocery.id} defaultValue={item.visibleInApp} handleEvent={handleEvent}/>     
        <span className="label form-label hidden-up">{Placeholder.Active}</span>
      </div>   
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.preSelectedInApp} name={'preSelectedInApp__'+grocery.id} defaultValue={item.preSelectedInApp} handleEvent={handleEvent}/>     
        <span className="label form-label hidden-up">{Placeholder.Marked}</span>
      </div>  
    </div>
  )
  
}

export default GroupProduct

