import React, { useState, useEffect } from 'react'
import { TopAddButton } from '../layout/Button'
import { getData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import CustomizationMenuListItem from './CustomizationMenuListItem'

const CustomizationMenus = (props) => {
  const [data, setData] = useState([])
  // const menuId = props.match.params.menuid;
  const menuItemId = props.match.params.menuitemid
  const customizationId = props.match.params.customizationid
  const menuId = props.match.params.menuid

  useEffect(() => {
    const fetchData = async () => {
      if (menuItemId && customizationId) {
        const response = await getData(
          `/api/customizations/${customizationId}/source/${menuItemId}/`,
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  const cuztomizationType = () => {
    if (customizationId === '1') {
      return 'för "Lägg till"'
    } else if (customizationId === '2') {
      return 'för "Ta bort"'
    } else if (customizationId === '3') {
      return 'för "Anpassa"'
    }
  }

  return (
    <ProntoContainerTop>
      <TopAddButton
        className="flex-right btn-control btn--red"
        to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationId}/submenu/create/`}
        title={`${Placeholder.Create} anpassningsgrupp`}
      />
      <BackButton backLabel={'Anpassning'} {...props}>
        Anpassningsgrupper {cuztomizationType()}
      </BackButton>
      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">
              {Placeholder.Name}
            </div>
            <div className="list-item__element list-item__element--s label hidden-down">
              {Placeholder.Active}
            </div>
            <div className="list-item__element label hidden-down">
              {Placeholder.Change}
            </div>
          </div>
        </li>
        {data.map((item) => (
          <li key={item.id}>
            <CustomizationMenuListItem customizationMenu={item} {...props} />
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
}

export default CustomizationMenus
