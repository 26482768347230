import React, { useState, useEffect } from 'react'
import {getData} from '../../services/Ajax'
import {TopAddButton} from '../layout/Button'
import Placeholder from '../layout/Placeholder'
import MenusListItem from './MenusListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

const Menus = (props)=> {
  const [data, setData] = useState( [] )
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid
  useEffect(() => {
    const fetchData = async () => {
      if(restaurantId){
        const response = await getData(
          '/api/menus/'+restaurantId
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop >
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/restaurants/${restaurantId}/menus/create/`} title={`${Placeholder.Create} meny`} />
      <BackButton backLabel={Placeholder.Restaurants} {...props}>{Placeholder.Menus}</BackButton>

      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.map(item => (
          <li key={item.id}>               
            <MenusListItem  data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Menus