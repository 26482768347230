import React, { useState } from 'react'
import '../layout/Checkbox.css'



export function parseBool(val) { return val === true || val === "true" }

/**
 * @deprecated CheckboxV2 should be used instead. See Components.md for more information.
 * */
const Checkbox = (props)=> {
  const disabled = props.disabled
  const name = props.name
  const handleEvent = props.handleEvent
  const checked = props.checked
  const [isChecked, setIsChecked] = useState(checked)
  let inputClass = "checkbox"
  if(props.inputClass){
    inputClass = props.inputClass
  }

  let checkmarkStyle= "checkmark"

  if(props.checkMarkStyle){
    checkmarkStyle = props.checkMarkStyle
  }
    
  const handleChange = (event) => {
    const value = !isChecked
    setIsChecked(value)
    if(handleEvent){
      event.target.value = value
      handleEvent(event)
    }
  }
  
  return (
    <div className={inputClass}>
      <input 
        type="checkbox"
        className="checkbox-round"
        name={name}
        disabled={disabled} 
        defaultChecked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={name} className={checkmarkStyle}></label> {props.text && props.text}
    </div>
  )
    
}
export default Checkbox