import React from 'react'
import Modal from '../layout/Modal'
import styles from './AdditionalCategories.module.css'
import GridRow from '../Grid/GridRow'
import GridItem from '../Grid/GridItem'
import CheckboxV2 from '../layout/CheckboxV2'
import SelectList from '../Form/SelectList'
import Placeholder from '../layout/Placeholder'
import Button from '../UI/Button'

function AdditionalCategories({ 
  categories,
  selectedCategories,
  mainCategoryId,
  onUpdateSelectedCategories,
  onUpdateMainCategory
}) {
  const [categoryModalIsActive, setCategoryModalIsActive] = React.useState(false)

  const mainCategory = categories ? categories.find((category) => category.id === mainCategoryId) : {}

  function toggleCategory(category) {
    onUpdateSelectedCategories((prev) => {
      const exists = prev.find((item) => item.id === category.id)
      if (exists && prev.length > 1) {
        return prev.filter((item) => item.id !== category.id)
      } 

      if (!exists) {
        return [...prev, category]
      }

      return prev
    })
  }

  function sortCategoryCollection(categoryArray) {
    return categoryArray ? [...categoryArray].sort((a, b) => (a.name).localeCompare(b.name, 'sv')) : []
  }

  return (
    <div>
      {mainCategory && (
        <div className={styles.categoryWrapper}>
          <h3 className={styles.headingText}>
            Huvudkategori - {mainCategory.name} ({mainCategory.active ? Placeholder.Active.toLowerCase() : Placeholder.Inactive.toLowerCase()})
          </h3>
        </div>
      )}
      {categories && categories.length > 0 && (
        <div className={styles.categoryWrapper}>
          <div className='selectedAdditionalCategories'>
            <h3 className={styles.headingText}>Visas i följande kategorier</h3>
            {sortCategoryCollection(selectedCategories).map((cat, index) => (
              <span className='additionalCategoriesItem' key={`${index}-${cat.id}`}>
                {cat.name} ({cat.active ? Placeholder.Active.toLowerCase() : Placeholder.Inactive.toLowerCase()})
              </span>
            ))}
            <Button 
              type='action'
              narrow
              inline
              onClick={() => setCategoryModalIsActive(true)}
            >
              {Placeholder.Change}
            </Button>
          </div>

          <Modal
            maxWidth='938px'
            minHeight='300px'
            title='Visas under valda kategorier'
            active={categoryModalIsActive}
            onConfirm={() => null}
            onCancel={() => setCategoryModalIsActive(false)}
            withClose={true}
          >
            <div className={styles.container}>
              <GridRow>
                {sortCategoryCollection(categories).map((cat, index) => {
                  const selected = selectedCategories.find((item) => item.id === cat.id)
                  const isMainCategory = mainCategoryId == cat.id
                  return (
                    <GridItem className={styles.categoryItem} cols={3} key={`${index}-${cat.id}`}>
                      <CheckboxV2
                        label={cat.name}
                        disabled={isMainCategory}
                        title={isMainCategory ? 'Det går inte att välja bort huvudkategorin.' : ''}
                        checked={selected}
                        onChange={() => toggleCategory(cat)}
                      />
                    </GridItem>
                  )
                })}
              </GridRow>

              <GridRow>
                <GridItem cols={6}>
                  <SelectList
                    label="Huvudkategori"
                    defaultOption="(Välj huvudkategori)"
                    defaultOptionValue=""
                    options={selectedCategories}
                    optionValueField="id"
                    optionDisplayField="name"
                    name="mainCategory"
                    value={mainCategoryId}
                    onChange={(e) => onUpdateMainCategory(e.target.value)}
                  />
                </GridItem>
              </GridRow>
            </div>
            
            <div className={styles.container}>
              <Button 
                type='save'
                onClick={() => setCategoryModalIsActive(false)}
              >
                {Placeholder.OK}
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default AdditionalCategories
