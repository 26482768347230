import React, {useState} from 'react'
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./CampaignDateRule.css"
import sv from "date-fns/locale/sv"
import Mode from "../../../utilis/Mode"

registerLocale("sv", sv)

const CampaignDateRule = (props) => {
  const {handleDateChanges, data, mode} = props
  const [startDate, setStartDate] = useState(props.data.startDate)
  const [endDate, setEndDate] = useState(props.data.endDate)

  if(Mode.Create !== mode && data.startDate && data.endDate){
    data.startDate = new Date(data.startDate ? data.startDate : null)
    data.endDate = new  Date(data.endDate ? data.endDate : null)
  }

  function handleDateEvent(date, dateRule, name) {
    if (name === 'endDate') {
      setEndDate(date)
    } else {
      setStartDate(date)

    }
    handleDateChanges(date, dateRule, name)
  }

  return (
    <div className="form">

      <div className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <DatePicker locale="sv"
            dateFormat="yyyy-MM-dd"
            selected={startDate || props.data.startDate}
            name="dateRule__startDate"
            placeholderText="startdatum (yyyy-mm-dd)"
            onChange={(date) => handleDateEvent(date, "dateRule", "startDate")}/>
        </div>
      </div>
      <div className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <DatePicker
            locale="sv"
            dateFormat="yyyy-MM-dd"
            selected={endDate || props.data.endDate} 
            name="dateRule__endDate"
            placeholderText="slutdatum (yyyy-mm-dd)"
            onChange={(date) => handleDateEvent(date, "dateRule", "endDate")}/>
        </div>
      </div>
    </div>
  )

}


export default CampaignDateRule





