import React from 'react'
import { Link } from 'react-router-dom'
import CheckboxV2 from '../layout/CheckboxV2'
import styles from './DeliveryProviderListItem.module.css'

function DeliveryProviderListItem(props) {
  const item = props.data
  const providerTypes = props.providerTypes
  const storeId = props.match.params.storeid

  return (
    <Link className={styles.container} to={`/stores/${storeId}/deliveryproviders/${item.id}/edit/`}>
      {providerTypes && item && (
        <div className={styles.listItemWrapper}>
          <div className={styles.listItem}>{item.bongName}</div>
          <div className={styles.listItem}>{item.name}</div>
          <div className={styles.listItem}>
            {providerTypes.names[item.serviceProvider] && providerTypes.names[item.serviceProvider]}
          </div>
          <div className={styles.listItem}>{providerTypes.types[item.providerType]}</div>

          <div className={`${styles.listItem} ${styles.small}`}>{item.price}</div>
          <div className={`${styles.listItem} ${styles.small}`}>
            <CheckboxV2 checked={item.active} name='active' />
          </div>
        </div>
      )}
    </Link>
  )
}

export default DeliveryProviderListItem
