import React from 'react'
import { getData } from '../../services/Ajax'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import './QrCodes.css'
import QRGenerator from './QRGenerator'

function QrCodeIndex({ match, ...props }) {
  const {
    params: { storeid }
  } = match || {}

  const [setRoles] = React.useState({ isAdmin: false, isReader: true })

  React.useEffect(() => {
    const fetchRoles = async () => {
      const rolesResponse = await getData('api/navigation/roles')
      const roles = await rolesResponse
      setRoles(roles)
    }

    fetchRoles()
  }, [storeid])

  return (
    <div>
      <ProntoContainerTop className='scroll-x-mobile'>
        <BackButton
          editPage={false}
          disabled={!storeid}
          {...props}
          match={match}
        >
          QR-Koder
        </BackButton>
        <div>
          <QRGenerator storeId={storeid} />
        </div>
      </ProntoContainerTop>
    </div>
  )
}

export default QrCodeIndex
