import React from 'react'
import BackButton from '../backbutton/BackButton'
import LoadingContainer from './LoadingContainer'
import css from './ContentContainer.module.css'

export default function ContentContainer({
  title,
  backText,
  backTo,
  backDisabled,
  actions,
  children,
  expandContent = false,
  fetchingData = false,
}) {
  var contentClassNames = css.content
  if (expandContent) {
    contentClassNames += ` ${css.expand}`
  }

  return (
    <div className={css.contentContainer}>
      <div className={`${css.header} drop-shadow`}>
        <div className={css.backButton}>
          <BackButton
            simple
            backLabel={backText}
            to={backTo}
            disabled={backDisabled}
          />
        </div>
        <div className={css.title}>
          <h2>{title}</h2>
        </div>
        {actions && 
          <div className={css.actions}>
            {actions()}
          </div>
        }
      </div>
      <div className={contentClassNames}>
        <LoadingContainer loading={fetchingData}>
          {children}
        </LoadingContainer>
      </div>
    </div>
  )
}
