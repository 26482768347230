import React from 'react'
import Checkbox from '../layout/Checkbox'


function ChannelType(props) {
  const channel = props.data
  const handleEvent = props.handleEvent
  const disabled = props.disabled
  return (
    <div className="row">
      <Checkbox checked={channel.checked} name={ `${channel.id}__checked`} defaultValue={channel.checked} handleEvent={handleEvent} disabled={disabled}/>      
      <span className="label form-label">{channel.displayName}</span>  
    </div>
  )
  
}

export default ChannelType

