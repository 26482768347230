import React from 'react'
import Placeholder from '../layout/Placeholder'
import styles from './TimeInput.module.css'
import RadioButton from './RadioButton'

function TimeInput({ deliveryHour, title, onChange, onEditClick, withInput, valid, withRadio, withChange }) {
  let inputId = deliveryHour.date ? deliveryHour.date : deliveryHour.dayOfWeek

  function onTimeInputBlur(e) {
    if (!e.target.value) return

    var value = e.target.value
    var newValue = e.target.value
    if (value.indexOf(':') === -1) {
      if (value.length === 1) {
        newValue = `0${value}:00`
      } else if (value.length === 2) {
        if (value >= 24) {
          value = '00'
        }
        newValue = `${value}:00`
      }
    }
    onChange(newValue, e.target.name, deliveryHour.id)
  }

  return (
    <div>
      {withChange ? (
        <React.Fragment>
          <h3 className={styles.exceptionalDay}>{title}</h3>
          <button className={styles.editExceptionalButton} onClick={(e) => onEditClick(e)}>
            Ändra
          </button>
        </React.Fragment>
      ) : (
        <h3 className={styles.openingTitle}>{title}</h3>
      )}
      <div className={styles.openingHours}>
        {withInput && (
          <div className={styles.inputWrapper}>
            <div>
              <label className={styles.openingLabel} htmlFor={inputId + 'From'}>
                {Placeholder.From}
              </label>
              <input
                id={inputId + 'From'}
                placeholder='00:00'
                className={`${styles.openingInput} ${valid ? '' : styles.inputError}`}
                name='startTime'
                type='text'
                value={deliveryHour.isAvailable ? deliveryHour.startTime : '--'}
                disabled={deliveryHour.isAvailable ? false : true}
                onChange={(e) => onChange(e.target.value, e.target.name, deliveryHour.id)}
                onBlur={(e) => onTimeInputBlur(e)}
                maxLength='5'
              />
            </div>
            <div>
              <label className={styles.openingLabel} htmlFor={inputId + 'To'}>
                {Placeholder.To}
              </label>
              <input
                id={inputId + 'To'}
                placeholder='00:00'
                className={`${styles.openingInput} ${valid ? '' : styles.inputError}`}
                name='endTime'
                type='text'
                value={deliveryHour.isAvailable ? deliveryHour.endTime : '--'}
                disabled={deliveryHour.isAvailable ? false : true}
                onChange={(e) => onChange(e.target.value, e.target.name, deliveryHour.id)}
                onBlur={(e) => onTimeInputBlur(e)}
                maxLength='5'
              />
            </div>
          </div>
        )}
        {withRadio && (
          <div className={withInput ? styles.radioGroupWrapperInput : styles.radioGroupWrapper}>
            <RadioButton
              label={'Tillgängligt'}
              name={`${inputId}Available`}
              id={`${inputId}Available`}
              onChange={() => onChange(true, 'isAvailable')}
              checked={deliveryHour.isAvailable}
            />
            <RadioButton
              label={'Ej tillgängligt'}
              name={`${inputId}NotAvailable`}
              id={`${inputId}NotAvailable`}
              onChange={() => onChange(false, 'isAvailable')}
              checked={!deliveryHour.isAvailable}
            />
          </div>
        )}
      </div>
      {!valid && (
        <div>
          <p className={styles.errorText}>Kontollera tidsformatet (HH:MM)</p>
        </div>
      )}
    </div>
  )
}

export default TimeInput
