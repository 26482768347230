import authService from "./api-authorization/AuthorizeService"
import { createBrowserHistory } from "history"
import { GetBaseRoute } from "../utilis/Enviroment"
import { getServiceUrl } from "./ServiceUrlResolver"
const history = createBrowserHistory({ basename: GetBaseRoute() })

function redirectToMaintenancePage() {
  var maintainPath = "/maintenance"
  if (history.location.pathname !== maintainPath) {
    window.location = maintainPath
  }
}
async function getAccessToken() {
  const token = await authService.getAccessToken()
  if (token) {
    return token
  }
  console.log("no token")
  return ""
}

async function buidHeader(contentType) {
  const accessToken = await getAccessToken()
  if (!contentType || contentType === "") {
    return {
      Authorization: "Bearer " + accessToken,
    }
  }
  return {
    "Content-Type": contentType,
    Authorization: "Bearer " + accessToken,
  }
}

async function LogProblem(response) {
  if (response) {
    const contentType = response.headers.get("content-type")

    if (contentType && contentType.includes("application/problem+json")) {
      const clone = response.clone()
      console.error(await clone.json())
    } else {
      console.error(response)
    }
  }
  return
}

export async function getData(url = "", customHeader) {
  // Default options are marked with *
  let header = await buidHeader("application/json")

  if (customHeader != undefined) {
    header = { ...header, ...customHeader }
  }
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: header,
  })
  const result = await response
  if (result.status === 401) {
    authService.redirectToLoginPage()

    return
  }
  if (result.status === 503) {
    redirectToMaintenancePage()
    return
  }
  if (result.status !== 200) {
    await LogProblem(result)
    return
  }

  return result.json() // parses JSON response into native JavaScript objects
}

export async function getServiceData(service, version, url, customHeader) {
  const baseUrl = getServiceUrl(service, version)
  return await getData(`${baseUrl}${url}`, customHeader)
}

export async function postData(
  url = "",
  data = {},
  isJson = false,
  customHeader,
  method = "POST"
) {
  // Default options are marked with *
  let header = await buidHeader("application/json")
  if (customHeader != undefined) {
    header = { ...header, ...customHeader }
  }
  const response = await fetch(url, {
    method,
    // mode: 'cors', // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: header,
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  const result = await response
  if (result.status >= 200 && result.status <= 299) {
    if (isJson) {
      const clone = response.clone()
      const text = await clone.text()
      if (text) {
        return response.json() // parses JSON response into native JavaScript objects
      }
    }
    return result // parses JSON response into native JavaScript objects
  }

  await LogProblem(result)
  if (!isJson) {
    return
  } else {
    return result.json() // parses JSON response into native JavaScript objects
  }
}

export async function postServiceData(
  service,
  version,
  url,
  data = {},
  isJson = false,
  customHeader,
  method = "POST"
) {
  const baseUrl = getServiceUrl(service, version)
  return await postData(`${baseUrl}${url}`, data, isJson, customHeader, method)
}

export async function postMultiPartData(url = "", data) {
  // Default options are marked with *
  const header = await buidHeader()
  const response = await fetch(url, {
    method: "POST",

    // mode: 'cors', // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: header,
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // no-referrer, *client
    body: data, // body data type must match "Content-Type" header
  })
  const result = await response
  if (result.status !== 200) {
    console.error(result)
    return result
  }
  return result.json() // parses JSON response into native JavaScript objects
}
