import React from 'react'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'




function ProntoProductSearchItem(props) {
  const item = props.data
  const handleEvent = props.handleEvent
  console.log(item)
  return (
    <div className="list-item">
      <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt=""/></div>
      <div className="list-item__element">{item.groceryDisplayName}</div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.checked}  name={item.id} defaultValue={item.checked} handleEvent={handleEvent}/>     
        <span className="label form-label hidden-up">{Placeholder.Assigned}</span>   
      </div>
          
    </div>
  )
  
}

export default ProntoProductSearchItem

