import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'
import ChannelType from '../channels/ChannelType'

function RestaurantListItem(props) {
  const restaurant = props.data
  const storeId = props.match.params.storeid
  const availableChannels = restaurant.availableChannelTypes
  return (
    <div className="list-item">
      <div className="list-item__element">
        <img className="list-item__img" src={restaurant.imageUrl} alt="" />
      </div>
      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/menus/`}
        >
          {restaurant.name}
        </Link>
      </div>
      <div className="list-item__element list-item__element--s">
        <ul className="no-style">
          {availableChannels &&
            availableChannels.map((item) => (
              <li key={item.id} style={{ listStyleType: 'none' }}>
                <ChannelType {...props} data={item} disabled="disabled" />
              </li>
            ))}
        </ul>
      </div>
      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/edit/`}
        >
          {Placeholder.Edit}
        </Link>
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/openhours/`}
        >
          {Placeholder.OpenHours}
        </Link>
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/groups/`}
        >
          {Placeholder.AdditionalProducts}
        </Link>
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/delete/`}
        >
          {Placeholder.Delete}
        </Link>
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurant.id}/message/`}
        >
          {Placeholder.Message}
        </Link>
      </div>
    </div>
  )
}

export default RestaurantListItem
