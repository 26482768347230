import React, { useState, useEffect } from 'react'
import {TopAddButton} from '../layout/Button'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import MenuItemsListItem from './MenuItemsListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

const MenuItems = (props)=> {
  const [data, setData] = useState( [] )
  const [menu, setMenu] = useState({})
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid
  const menuId = props.match.params.menuid
  useEffect(() => {
    const fetchData = async () => {
      if(menuId){
        const response = await getData(
          '/api/menuitems/'+menuId
        )
        const result = await response
        setData(result)

        const menuResponse = await getData(
          '/api/menu/' + menuId
        )
        const menuResult = await menuResponse
        setMenu(menuResult)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/restaurants/${restaurantId}/menus/${menuId}/items/create`} title={`${Placeholder.Create} maträtt`} />
      <BackButton backLabel={Placeholder.Menus} {...props}>{menu.name}</BackButton>
      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Price}</div>        
            <div className="list-item__element label hidden-down">{Placeholder.Description}</div>        
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.map(item => (
          <li key={item.id}>               
            <MenuItemsListItem  data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default MenuItems