import React from "react"
import "./InputField.css"

function InputField({ children, className, ...props }) {
  const cssClass = className ? `input-field ${className}` : "input-field"

  return (
    <div className={cssClass} {...props}>
      {children}
    </div>
  )
}

export default InputField