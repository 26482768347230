import React, {  useEffect } from 'react'
import authService from '../../services/api-authorization/AuthorizeService'
import {ApplicationPaths} from '../../services/api-authorization/ApiAuthorizationConstants'

const Logout = ()=> {
  
  useEffect(() => {
    const fetchData = async () => {  
      var result = await authService.signOut()
      if(result){
        window.location = ApplicationPaths.LoginPath
      }
    }

    fetchData()
  },[])

  
  return (
    <div>    
        
    </div>
     
  )
  
}


export default Logout

