import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/sv'
import styles from './LimitedDeliveryPeriodListItem.module.css'
import GridItem from '../Grid/GridItem'

moment.locale('sv')

function LimitedDeliveryPeriodListItem(props) {
  const item = props.data
  const storeId = props.match.params.storeid

  const periodDatesAsString = (fromDate, toDate) => {
    const fromDateNumber = moment(fromDate).format('D')
    const fromMonthAbriviated = moment(fromDate).format('MMM')
    const toDateNumber = moment(toDate).format('D')
    const toMonthAbriviated = moment(toDate).format('MMM')
    const fromYear = moment(fromDate).format('YYYY')
    const toYear = moment(toDate).format('YYYY')

    return `${fromDateNumber} ${fromMonthAbriviated} ${fromYear} - ${toDateNumber} ${toMonthAbriviated} ${toYear}`
  }

  return (
    <div className={styles.container}>
      {item && (
        <>
          <Link
            to={`/stores/${storeId}/limited-delivery-period/${item.id}/edit/`}
            style={{ width: '100%', textDecoration: 'none' }}
          >
            <div className={styles.listItemWrapper}>
              <GridItem cols={'2'}>
                <div className={styles.listItem}>{item.name}</div>
              </GridItem>
              <GridItem cols={'2'}>
                <div
                  className={styles.listItem}
                  style={{ marginLeft: '-0.5rem' }}
                >
                  {periodDatesAsString(item.fromDate, item.toDate)}
                </div>
              </GridItem>
              <GridItem cols={'3'}>
                <div
                  className={`${styles.listItem} ${styles.small}`}
                  style={{ marginLeft: '-1rem' }}
                >
                  {`${item.productCount} produkter`}
                </div>
              </GridItem>
            </div>
          </Link>
        </>
      )}
    </div>
  )
}

export default LimitedDeliveryPeriodListItem
