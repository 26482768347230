import React from "react"
import Modal from "../layout/Modal"
import VariantForm from "./VariantForm"
import VariantItemForm from './VariantItemForm'
import VariantCategoryDeleteForm from "./VariantCategoryDeleteForm"
import CheckboxV2 from "../layout/CheckboxV2"
import ItemList, { ItemListColumn } from "../ItemList/ItemList"
import Placeholder from '../layout/Placeholder'
import "./ProductVariants.css"
import { formatAmount } from "../../utilis/PricingUtils"
import { OnChangeEntityType, OnChangeAction, generateVariants } from "../../utilis/catering/productVariantUtils"
import Button from "../UI/Button"

function ProductVariants({
  variantCategories,
  variants,
  onChange,
  productInfo,
}) {
  const [variantToEdit, setVariantToEdit] = React.useState({})
  const [variantModalIsActive, setVariantModalIsActive] = React.useState(false)
  const [variantCategoryToEdit, setVariantCategoryToEdit] = React.useState({})
  const [variantCategoryModalIsActive, setVariantCategoryModalIsActive] = React.useState(false)
  const [variantCategoryToDelete, setVariantCategoryToDelete] = React.useState({})
  const [variantCategoryDeleteModalIsActive, setVariantCategoryDeleteModalIsActive] = React.useState(false)

  function onEditVariantCategory(index) {
    openVariantCategoryModal(variantCategories[index] || {})
  }

  function openVariantCategoryModal(variantCategory = {}) {
    setVariantCategoryToEdit(variantCategory)
    setVariantCategoryModalIsActive(true)
  }

  function closeVariantCategoryModal() {
    setVariantCategoryToEdit({})
    setVariantCategoryModalIsActive(false)
  }

  function onEditVariant(index) {
    openVariantModal(variants[index] || {})
  }

  function openVariantModal(variant = {}) {
    setVariantToEdit(variant)
    setVariantModalIsActive(true)
  }

  function closeVariantModal() {
    setVariantToEdit({})
    setVariantModalIsActive(false)
  }

  function openVariantCategoryDeleteModal(variantCategory) {
    setVariantCategoryToDelete(variantCategory)
    setVariantCategoryDeleteModalIsActive(true)
  }

  function closeVariantCategoryDeleteModal() {
    setVariantCategoryToDelete({})
    setVariantCategoryDeleteModalIsActive(false)
  }

  function onVariantActiveChange(isActive, variant) {
    onVariantSave(variant, {
      ...variant,
      active: isActive
    })
  }

  function onDeleteVariantCategory(variantCategory) {
    if (variantCategories.length > 1) {
      openVariantCategoryDeleteModal(variantCategory)
    } else {
      onVariantCategoryDeleteSave([], [])
    }
  }

  function onVariantCategoryDeleteSave(variantCategories, generatedVariants) {
    onChange({
      entityType: OnChangeEntityType.VariantCategory,
      action: OnChangeAction.Delete,
      variantCategories: variantCategories,
      variants: generatedVariants
    })
    closeVariantCategoryDeleteModal()
  }

  function onVariantCategoryFormSave(variantCategory) {
    let newVariantCategories = [...variantCategories]
    let index = variantCategories.indexOf(variantCategoryToEdit)
    if (~index) {
      newVariantCategories[index] = variantCategory
    } else {
      newVariantCategories.push(variantCategory)
    }
    newVariantCategories = newVariantCategories.map((vc, i) => ({...vc, sortOrder: i + 1 }))

    const action = Object.keys(variantCategoryToEdit).length === 0 ? OnChangeAction.Create : OnChangeAction.Update

    let generatedVariants = generateVariants(newVariantCategories, variants, action)

    onChange({
      entityType: OnChangeEntityType.VariantCategory,
      action: action,
      variantCategories: newVariantCategories,
      variants: generatedVariants,
      entity: variantCategory
    })

    closeVariantCategoryModal()
  }

  function onVariantSave(existingVariant, updatedVariant) {
    let newVariants = [...variants]
    let index = newVariants.indexOf(existingVariant)
    if (~index) {
      newVariants[index] = updatedVariant
    }
    onChange({
      entityType: OnChangeEntityType.Variant,
      action: OnChangeAction.Update,
      variants: newVariants,
      entity: updatedVariant
    })
    closeVariantModal()
  }

  return (
    <div className="variantsContainer" id="varianter">
      <div className="variants">
        <h3 className="variantsHeading">Variantval</h3>
        {variantCategories && variantCategories.length > 0 ? (
          <div>
            <div>
              {variantCategories.map((variantCategory, i) => (
                <div className="variantsRow" key={variantCategory.id + variantCategory.name}>
                  <div className="variantsRowText">
                    <span className="variantsRowLabel">{variantCategory.name}</span>
                    {variantCategory.values.map((value) => (
                      <span className="variantsRowTag" key={value.name}>
                        {value.name}
                      </span>
                    ))}
                  </div>
                  <div className="variantsRowActions" style={{ display: "flex"}}>
                    <Button
                      type="text"
                      narrow
                      onClick={() => onDeleteVariantCategory(variantCategory)}
                    >
                      {Placeholder.Delete}
                    </Button>
                    <Button
                      type="action"
                      narrow
                      onClick={() => onEditVariantCategory(i)}
                      style={{marginLeft: "0.5rem"}}
                    >
                      {Placeholder.Edit}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="noVariants">
            Den här produkten har ännu inga variantval som t.ex. storlek och
            måltidstyp. Tryck nedan för att lägga till
          </p>
        )}
        {productInfo.productType === 2 && variantCategories.length >= 1 ? null : (
          <div className="variantsActions">
            <button
              className="variantsAddButton"
              onClick={() => openVariantCategoryModal({})}
              type="button"
            >
              <span>+ </span>Lägg till variantval
            </button>
          </div>
        )}
      </div>
      {variants && variants.length > 0 && (
        <div className="variants">
          <h3 className="variantsHeading">Varianter</h3>
          <ItemList
            data={variants}
            showEmpty={false}
            shadow={false}
            stickyHeader={true}
            idProp={variants.every(v => v.id) ? "id" : "name"}
            renderRow={(item, i) =>
              <React.Fragment>
                <ItemListColumn id="name" label={Placeholder.Name}>
                  {item.name}
                </ItemListColumn>
                <ItemListColumn id="price" label={Placeholder.Price} columns={3}>
                  {item.price 
                    ? formatAmount(item.price * 0.01)
                    : formatAmount(productInfo.price)
                  }
                </ItemListColumn>
                <ItemListColumn id="ean" label={Placeholder.Ean} columns={3}>
                  {item.ean 
                    ? item.ean
                    : productInfo.ean
                  }
                </ItemListColumn>
                <ItemListColumn id="active" label={Placeholder.Active} columns={1}>
                  <CheckboxV2
                    name="active"
                    id={`isActive-${item.name}`}
                    checked={item.active}
                    onChange={(e) => onVariantActiveChange(e.target.checked, item)}
                  />
                </ItemListColumn>
                <ItemListColumn id="actions" label="" narrow className="text-right">
                  <Button
                    type="action"
                    narrow
                    onClick={() => onEditVariant(i)}
                  >
                    {Placeholder.Edit}
                  </Button>
                </ItemListColumn>
              </React.Fragment>
            }>
          </ItemList>
        </div>
      )}

      <Modal
        maxWidth="938px"
        minHeight="519px"
        title={Object.keys(variantCategoryToEdit).length > 0 ? "Ändra variantval" : "Skapa variantval"}
        active={variantCategoryModalIsActive}
        onConfirm={() => null}
        onCancel={closeVariantCategoryModal}
        withClose={true}
      >
        <VariantForm
          variantCategory={variantCategoryToEdit}
          mode={Object.keys(variantCategoryToEdit).length > 0 ? "edit" : "create"}
          onSave={onVariantCategoryFormSave}
        />
      </Modal>

      <Modal
        maxWidth="938px"
        minHeight="400px"
        title={"Ändra variant"}
        active={variantModalIsActive}
        onConfirm={() => null}
        onCancel={closeVariantModal}
        withClose={true}
      >
        <VariantItemForm
          variantItem={variantToEdit}
          onSave={onVariantSave}
        />
      </Modal>

      <Modal
        height='100%'
        title={`Ta bort variantval - ${variantCategoryToDelete.name}`}
        active={variantCategoryDeleteModalIsActive}
        onConfirm={() => null}
        onCancel={closeVariantCategoryDeleteModal}
        withClose={true}
      >
        <VariantCategoryDeleteForm
          variantCategories={variantCategories}
          variantCategoryToDelete={variantCategoryToDelete}
          currentVariants={variants}
          productInfo={productInfo}
          onSave={onVariantCategoryDeleteSave}
        />
      </Modal>
    </div>
  )
}

export default ProductVariants
