import React from 'react'
import {GetCssInput} from '../../layout/Input'


const CampaignUseRule = (props)=> {
  const {mode,handleInputChange, data} = props

  return (
    <div className="form">
      {/* <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.Name} placeholder={Placeholder.Name}/> */}
      <div className="row">
        <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="useRule__maxCount" defaultValue={data.maxCount}  />
        <span className="label form-label ml-10">Max antal gånger</span>  
      </div>   
    </div>
  )
  
}


export default CampaignUseRule

