import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import {getData,postData} from '../../services/Ajax'
import Checkbox from '../layout/Checkbox'
import { ProntoContainer } from '../layout/Containers'
import {ClickButton,SaveButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'

const RestaurantMessage = (props)=> {
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid
  const history = props.history
  const [data, setData] = useState({ storeId: storeId })
  const emptyGuid = "00000000-0000-0000-0000-000000000000"

  useEffect(() => {
    const fetchData = async () => {

      if(restaurantId){
        const response = await getData( `/api/restaurants/${restaurantId}/message`)
        const result = await response
        setData(result)
        console.log("result", result)
      }
    }

    fetchData()
  }, [])

  const getPostUrl = () => {
    if (data.id != emptyGuid) {
      return `/api/restaurants/${restaurantId}/message/edit`
    }
    else {
      return `/api/restaurants/${restaurantId}/message/create`
    }
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response
     
    }else{
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <div>
      <BackButton backLabel={Placeholder.Restaurants} editPage={true} {...props}>{data && data.name}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input className="input-edit" type="text" onChange={handleInputChange} name="title" defaultValue={data.title} placeholder={Placeholder.Title} />
            <textarea name="text" className={"input-edit" + " input-textarea"} onChange={handleInputChange} defaultValue={data.text} placeholder={Placeholder.Message}></textarea>
            <div className="row">
              {data.active && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              {!data.active && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              <span className="label form-label">Visa i app</span>
            </div>
          </div>  
            

          <div className="row">
            <SaveButton className="btn--black mr-10" title={Placeholder.SaveButton} onClick={handlePostData}/>
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
  
}


export default RestaurantMessage

