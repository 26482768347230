import React from 'react'
import { MinusIcon } from './Icons'
import styles from "./RemovableTextBox.module.css"

const RemovableTextBox = ({ text, onChange, onRemove }) => {

  const [value, setValue] = React.useState(text)

  return (
    <div className={styles.container}>
      <input
        type="text"
        value={value}
        className={styles.textInput}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
      />
      <button className={styles.minusButton} onClick={() => onRemove()}>
        <MinusIcon className={styles.minusIcon} color="#CF2E05"/>
      </button>
    </div>
  )
}
export default RemovableTextBox