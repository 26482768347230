import React from 'react'

export const ProntoContainerTop = (props)  => {
  return (
    <div className={`flex flex-column pronto-container pronto-container--top ${props.className}`} >
      {props.children}
    </div>
  )
}

export const ProntoContainer = (props)  => {
  return (
    <div className={`flex flex-column pronto-container pronto-container--spacing drop-shadow ${props.className}`}  >
      {props.children}
    </div>
  )
}