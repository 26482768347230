import React from "react"
import InputField from "./InputField"
import InputLabel from "./InputLabel"
import ReactSelect from 'react-select'
import "./SelectBox.css"

function SelectBox({ label, ...props }) {
  return (
    <InputField className="select-box">
      <InputLabel label={label} />
      <ReactSelect {...props} />
    </InputField>
  )
}

export default SelectBox