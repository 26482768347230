export const ApplicationName = 'AspAuthenticationReact'

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
}

export const LogoutActions = {
  Logout: 'logout',
}

export const LoginActions = {
  Login: 'login',
  Register: 'register'
}

const prefix = '/api/user'

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  Register: `${prefix}/${LoginActions.Register}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoginPath: '/user/login',
  LogoutPath: '/user/logout',
  IdentityRegisterPath: '/user/create/',
  IdentityManagePath: '/user/manage',
  ListUserPath: '/users',
  ListUserWithStore: '/user/:id/:storeid/view/',
  UsersMenu: '/usersIcanow/',
  AdminsPath: '/usersIcanow/admins/',
  CreateAdminPath: '/usersIcanow/admins/create/',
  EditAdminPath: '/usersIcanow/admins/:adminKey/',
  EmployeesPath: '/usersIcanow/employees/',
  CreateEmployeePath: '/usersIcanow/employees/create/',
  EditEmployeePath: '/usersIcanow/employees/:employeeKey/',
  VendingEmployeesPath: '/usersIcanow/vending/',
  CreateVendingEmployeePath: '/usersIcanow/vending/create/',
  EditVendingEmployeePath: '/usersIcanow/vending/:vendingEmployeeKey',
  ViewUser: '/user/:userid/view/',
  ChangePassword: '/user/:userid/:storeid/changePassword/',
  DeleteUser: '/user/:userid/:storeid/delete/',
  UpdateUser: '/api/user/update/'
}
