import React from "react"
import { sendServiceData } from "../../services/HttpClient"
import Placeholder from "../layout/Placeholder"
import { ProntoContainer } from "../layout/Containers"
import BackButton from "../backbutton/BackButton"
import { defaultNotifications } from "../../utilis/Notifications"
import Textbox from '../Form/Textbox'
import { useForm } from "react-hook-form"
import FormActions from "../Form/FormActions"
import "./FormStyles.css"

function CreateGroup({ history, match, ...props }) {
  const {
    params: { storeid },
  } = match || {}

  const { register, handleSubmit, formState: { errors } } = useForm()

  const [name, setName] = React.useState("")
  const [saving, setSaving] = React.useState(false)

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    const res = await sendServiceData(
      'FoodApi',
      'v1', 
      `/catering/${storeid}/maincategories`,
      {
        name
      },
      "POST"
    )

    if (res.ok) {
      defaultNotifications.created("Grupp", name)
      history.push(`/stores/${storeid}/catering/categories`)
    } else {
      defaultNotifications.error("Grupp")
    }
    
    setSaving(false)
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Catering.Name}
        editPage={true}
        match={match}
        history={history}
        {...props}
      >
        {Placeholder.Catering.Name} - {Placeholder.Create} rubrik
      </BackButton>
      <ProntoContainer>
        <form className="form" id="groupForm" onSubmit={handleSubmit(onSubmit)}>
          <Textbox
            label="Rubrik för kategorier"
            placeholder="Ex. Vår cateringmeny"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
          <FormActions 
            form="groupForm"
            onCancel={() =>
              history.push(`/stores/${storeid}/catering/categories`)
            }
            saving={saving}
          />
        </form>
      </ProntoContainer>
    </div>
  )
}

export default CreateGroup
