import React, { useState, useEffect } from 'react'
import { getData, getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import Mode from '../../utilis/Mode'
import { parseBool } from '../layout/Checkbox'
import Checkbox from '../Form/Checkbox'
import { ClickButton } from '../layout/Button'
import Textbox from '../Form/Textbox'
import BackButton from '../backbutton/BackButton'
import { defaultNotifications } from '../../utilis/Notifications'

const Door = (props) => {
  const mode = props.mode
  const doorId = props.match.params.doorid
  const storeId = props.match.params.storeid
  const history = props.history
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (doorId) { // If editing existing door
        const response = await getServiceData('StoreApi', 'v1', `/door/${doorId}`)
        if (response.data) {
          setData(response.data)
        }
        else {
          console.error(`Door with id ${doorId} does not exist.`)
          navigateBack() // Return to doors list instead of showing blank page. Particularly useful if user navigates back in browsing history to a removed entity.
        }
      }
      else { // If creating new door
        const response = await getData('/api/openhours/' + storeId)
        if (response.ok) {
          const openHours = response.data
          let doorOpenHours = []
          openHours.forEach(oh => {
            const doorOpenHour = {
              day: oh.day,
              openTime: `${oh.openTime}:00`,
              closeTime: `${oh.closeTime}:00`,
              active: oh.active
            }
            doorOpenHours.push(doorOpenHour)
          })

          const newDoor = {
            storeId: storeId,
            isEntrance: true,
            isExit: true,
            openHours: doorOpenHours,
          }
          setData(newDoor)
        }
      }
    }

    fetchData()
  }, [doorId, storeId])

  const handleSave = async (event) => {
    event.persist()
    let response

    switch (mode) {
    case Mode.Create:
      response = await sendServiceData('StoreApi', 'v1', '/door', data)
      break
    case Mode.Edit:
      response = await sendServiceData('StoreApi', 'v1', `/door/${data.id}`, data, 'PUT')
      break
    default:
      console.error("Unsuppored mode")
      return
    }

    if (response.ok) {
      switch (mode) {
      case Mode.Create:
        defaultNotifications.created(Placeholder.Door, data.name)
        break
      case Mode.Edit:
        defaultNotifications.updated(Placeholder.Door, data.name)
        break
      }
      navigateBack()
    }
    else {
      defaultNotifications.error(Placeholder.Door)
    }
  }

  const handleDelete = async (event) => {
    event.persist()

    if (!window.confirm(`Är du säker på att du vill ta bort "${data.name}"?`)) {
      return
    }

    const response = await sendServiceData('StoreApi', 'v1', `/door/${data.id}`, null, 'DELETE')

    if (response.ok) {
      defaultNotifications.deleted(Placeholder.Door, data.name)
      navigateBack()
    }
    else {
      defaultNotifications.error(Placeholder.Door)
    }
  }

  const handleInputChange = (event) => {
    let value
    if (event.target.type == 'checkbox') {
      value = event.target.checked
    }
    else {
      value = event.target.value
    }

    event.persist()
    const newData = { ...data }
    newData[event.target.name] = value
    setData(newData)
  }

  function ShouldValidateTime(name, value) {
    if (name === "openTime" || name === "closeTime") {
      return ValidateTime(value)
    }
    return false
  }

  function ValidateTime(value) {
    if (value && value.match(/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/) && value.match("[0-9][0-9]:[0-9][0-9]")) {
      return true
    }
    return false
  }

  const GetDayName = (dayNumber) => {
    const days = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"]

    return days[dayNumber]
  }

  const GetTime = (time) => {
    var splitTime = time.split(":")
    return `${splitTime[0]}:${splitTime[1]}`
  }

  function ShouldParseInt(name) {
    if (name === 'active') {
      return true
    }
    return false
  }

  const handleTimeInputChange = (event) => {
    event.persist()
    const values = event.target.name.split("__")
    const day = parseInt(values[0], 10)
    const name = values[1]
    const objIndex = data.openHours.findIndex(x => x.day === day)
    const newData = { ...data }

    if (ShouldParseInt(name)) {
      newData.openHours[objIndex][name] = parseBool(event.target.checked)
    } else if (ShouldValidateTime(name, event.target.value)) {
      var newTimeSpan = event.target.value + ":00"  //hh:mm:ss
      newData.openHours[objIndex][name] = newTimeSpan
    }
    setData(newData)
  }

  function navigateBack() {
    history.push('..')
  }

  return (
    <div>
      <BackButton backLabel={Placeholder.Door} editPage={true} to=".." {...props}>{mode === Mode.Create ? Placeholder.CreateDoor : data && data.name}</BackButton>
      <ProntoContainer>
        <div className="form c-12">
          <div className="form-row">
            <div className="form-section c-6 pr-10">
              <span className="label">Dörrdata</span>
              <hr />
              <Textbox className="input-edit" onChange={handleInputChange} id="name" name="name" value={data.name} label={Placeholder.Name} placeholder={Placeholder.Name} />
              <Textbox className="input-edit" onChange={handleInputChange} id="externalId" name="externalId" value={data.externalId} label={Placeholder.DoorExternalId} placeholder={Placeholder.DoorExternalId} />
              <div className="row">
                <Checkbox checked={data.active} name="active" label={Placeholder.Active} onChange={handleInputChange} />
              </div>
              <div className="row">
                <Checkbox checked={data.isEntrance} name="isEntrance" label={Placeholder.IsEntrance} onChange={handleInputChange} />
              </div>
              <div className="row">
                <Checkbox checked={data.isExit} name="isExit" label={Placeholder.IsExit} onChange={handleInputChange} />
              </div>
            </div>
            {data && data.openHours &&
              <div className="form-section c-6 pr-10">
                <span className="label">Fyll i butikens bemannade öppettider. Utanför dessa tider krävs dörröppning med ICA to go-appen</span>
                <hr />
                <ul className="no-style drop-shadow">
                  <li>
                    <div className="list-item list-item__heading">
                      <div className="list-item__element label hidden-down">Dag</div>
                      <div className="list-item__element label hidden-down">Öppnar</div>
                      <div className="list-item__element label hidden-down">Stänger</div>
                      <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
                    </div>
                  </li>
                  {data.openHours && data.openHours.sort((a, b) => a.day - b.day).map(item => (
                    <li key={item.day}>
                      <div className="list-item">
                        <div className="list-item__element">{GetDayName(item.day)}</div>
                        <div className="list-item__element">
                          <input className="input-edit input--small mt-10 mb-10" type="text" onChange={handleTimeInputChange} name={`${item.day}__openTime`} defaultValue={GetTime(item.openTime)} placeholder="08:00" />
                        </div>
                        <div className="list-item__element">
                          <input className="input-edit input--small mt-10 mb-10" type="text" onChange={handleTimeInputChange} name={`${item.day}__closeTime`} defaultValue={GetTime(item.closeTime)} placeholder="22:00" />
                        </div>
                        <div className="list-item__element list-item__element--s">
                          <Checkbox checked={item.active} name={`${item.day}__active`} onChange={handleTimeInputChange} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            }
          </div>
          <div className="row">
            <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={handleSave} />
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={navigateBack} />
            {mode === Mode.Edit &&
              <ClickButton className="btn--red ml-10" title={Placeholder.Delete} onClick={handleDelete} />
            }
          </div>
        </div>
      </ProntoContainer>
    </div>
  )

}


export default Door

