import React, { useState } from 'react'
import {  postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Heading from '../layout/Headings'
import { GetCssInput } from '../layout/Input'
import {parseBool} from '../layout/Checkbox'
import ProntoProductSearchIem from './ProntoProductSearchItem'
import {ProntoContainer} from '../layout/Containers'
import {ClickButton} from '../layout/Button'

const ProntoProductSearch = (props) => {
  const mode = props.mode
  const history = props.history
  const categoryId = props.match.params.categoryid
  const [data, setData] = useState([])
  const [search, setSearch] = useState({})

  const handlePostData = async (event) => {
    event.persist()
    const url = `/api/category/${categoryId}/products/`
    if (url) {
      const response = await postData(
        url, data
      )
      await response
      history.goBack()
    } else {
      console.error("url not defined")
    }
  }

  const handleSearchData = async (event) => {
    event.persist()
    const url = '/api/products/search'
    if (url) {
      const response = await postData(
        url, search,true
      )
      const result = await response
      setData(result)
    } else {
      console.error("url not defined")
    }
  }


  const handleInputChange = (event) => {
    event.persist()
    const objIndex = data.findIndex(x => x.id === event.target.name)
    data[objIndex].checked = parseBool(event.target.value)
    setData(data)
  }
  const handleSearchChange = (event) => {
    event.persist()
    search[event.target.name] = event.target.value
    setSearch(search)
  }
  return (
    <ProntoContainer>
      <Heading mode={mode} title="Produkter" />
      <hr />
      <div className="form">
        <div className="form-section c-6 pr-10">
          <textarea name="Query" className={GetCssInput(mode) + " input-textarea"} onChange={handleSearchChange} defaultValue={search.Query} placeholder="Sök med EAN-nummer eller fritext (ett per rad) "></textarea>
        </div>
        <div className="row">
          <ClickButton className="btn--black mr-10" title={"Sök"} onClick={handleSearchData}/>
          <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
        </div>
      </div>
      {data && data.length > 0 &&
        <div className="form">
          <ul className="no-style">
            <li>
              <div className="list-item list-item__heading">
                <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
                <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
                <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Assigned}</div>   
              </div>
            </li>
            {data && data.map(item => (
              <li key={item.id}>   
                <ProntoProductSearchIem data={item} handleEvent={handleInputChange}/> 
              </li>
            ))}
          </ul>
          <div className="row">
            <ClickButton className="btn--black mr-10" title={Placeholder.Create} onClick={handlePostData}/>
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
          </div>
        </div>
        
      }
     
    </ProntoContainer>
  )

}


export default ProntoProductSearch

