import React from "react"

function FormSection({ children, title, ...props }) {
  return (
    <div {...props}>
      {title &&
        <h2>{ title }</h2>
      }
      {children}
    </div>
  )
}

export default FormSection