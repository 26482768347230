import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '../layout/Checkbox'
import Placeholder from '../layout/Placeholder'



function CampaignListItem(props) {
   
  const item = props.data

  return (
    <div className="list-item list-item--flex-row-mobile">
      <div className="list-item__element">
        {item.name}
        {/* <Link  className="link-edit" to={`${props.location.pathname}${item.id}/rules/`}> {item.name}</Link> */}
      </div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.active} name="active" disabled="disabled"/>     
        <span className="label form-label hidden-up">{Placeholder.Active}</span>   
      </div>
      <div className="list-item__element">
        <Link  className="link-edit" to={`${props.location.pathname}${item.id}/edit/`}>{Placeholder.Edit}</Link>
        <Link  className="link-edit" to={`${props.location.pathname}${item.id}/delete/`}>{Placeholder.Delete}</Link>
      </div>          
    </div>
  )
  
}

export default CampaignListItem

