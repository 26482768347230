import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'


const ProntoDeletedProducts = (props) => {
  const defaultLimit = 50
  const [data, setData] = useState([])
  const [limit] = useState(defaultLimit)
  const [continuationToken, setContinuationToken] = useState()
  const categoryId = props.match.params.categoryid

  useEffect(() => {
    const fetchData = async () => {
      const result = await loadProducts()
      setData(result)
    }

    fetchData()
  }, [])

  const loadProducts = async () => {
    var customHeader = continuationToken? {'x-ms-continuation': continuationToken}: undefined
    const response = await getData(
      `/api/category/${categoryId}/products/deleted/list/${limit}`, customHeader
    )
    const result = await response
    setContinuationToken(result.continuationToken)
    return result.products
  }

  const handleLoadMore = async () => {
    if(!continuationToken) {
      return
    }
    const result = await loadProducts()
    if (result) {
      const temp = data.concat(result)

      setData(temp)
    }
  }

  return (
    <div>
      <BackButton {...props} editPage={true}>{'Raderade produkter'}</BackButton>
      <ProntoContainerTop>
        {/* <TopAddButton className="flex-right btn-control btn--red" to={`/categories/${categoryId}/products/add`} title={`${Placeholder.Create} produkter`} /> */}
        <ul className="no-style drop-shadow">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
              <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
              <div className="list-item__element label hidden-down">{Placeholder.Change}</div>
            </div>
          </li>
          {data && data.map(item => (
            <li key={item.id} className="list-item">
              <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt="" /></div>
              <div className="list-item__element">
                {item.groceryDisplayName}
              </div>
              <div className="list-item__element">
                {item.ean}
              </div>
              <div className="list-item__element list-item__element--s">
                <Checkbox checked={item.active} name="active" disabled="disabled" />
                <span className="label form-label hidden-up">{Placeholder.Active}</span>
              </div>
              <div className="list-item__element">
                <Link className="link-edit" to={`/categories/${item.categoryId}/products/${item.id}/edit/`}>{Placeholder.Change}</Link>
              </div>


            </li>
          ))}
        </ul>
        {data && data.length > 0 && <ClickButton onClick={handleLoadMore} title={`Totalt: ${data.length} Ladda mer`} />}
      </ProntoContainerTop>
    </div>)
}
export default ProntoDeletedProducts