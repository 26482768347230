import React from 'react'
import { CheckIcon } from './Icons'
import styles from './ToggleSwitch.module.css'

const ToggleSwitch = ({ label, id, checked, onChange }) => {
  return (
    <label htmlFor={id} className={styles.container}>
      <span className={styles.label}>{label}</span>
      <div className={styles.toggleBox}>
        <input type='checkbox' id={id} checked={checked} onChange={onChange} />
        <span className={styles.slider}>
          <CheckIcon className={styles.checkIcon} color={checked ? '#fff' : '#BDBBB9'} />
        </span>
      </div>
    </label>
  )
}
export default ToggleSwitch
