import React from 'react'
import NoItemsBox from '../layout/NoItemsBox'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { DraggableIcon } from '../layout/Icons'
import { shared } from './ItemListShared'
import css from './SortableList.module.css'
import itemListStyle from './ItemList.module.css'
import { ItemListHeaderRow, ItemListRow, ItemListCell } from './ItemList'

function CrossSortableList({
  data,
  renderRow,
  showEmpty = true,
  emptyHeading,
  emptyLinkUrl,
  emptyLinkText,
  showHeader = true,
  shadow = true,
  stickyHeader = false,
  droppableId,
  idProp = 'id',
  ...props
}) {
  const className = shared.buildClassName(
    itemListStyle,
    shadow,
    stickyHeader,
    false,
    props.className,
  )

  const [columns, setColumns] = React.useState([])

  React.useEffect(() => {
    if (data && data.length > 0) {
      setColumns(shared.getColumns(renderRow))
    }
  }, [data, renderRow])

  return data && data.length > 0 ? (
    <table className={className}>
      {showHeader && (
        <thead>
          <ItemListHeaderRow columns={columns} />
        </thead>
      )}
      <Droppable droppableId={droppableId} isCombineEnabled={true}>
        {(provided, snapshot) => (
          <tbody
            className={snapshot.isDraggingOver ? css.dragOver : css.noDragOver}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {data.map((item, i) => (
              <Draggable draggableId={item.id} index={i} key={item.id}>
                {(provided, snapshot) => (
                  <ItemListRow
                    key={item[idProp]}
                    {...provided.draggableProps}
                    innerRef={provided.innerRef}
                    className={
                      snapshot.isDragging ? css.dragging : css.notDragging
                    }
                    renderRow={renderRow}
                    columns={columns}
                    dataItem={item}
                    dataItemIndex={i}
                    extraColumns={() => (
                      <ItemListCell
                        className={`${css.dragHandle}`}
                        {...provided.dragHandleProps}
                      >
                        <DraggableIcon color="#BDBBB9" />
                      </ItemListCell>
                    )}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </table>
  ) : (
    showEmpty && (
      <NoItemsBox
        heading={emptyHeading}
        linkUrl={emptyLinkUrl}
        linkText={emptyLinkText}
      />
    )
  )
}

export default CrossSortableList
