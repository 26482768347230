import React from "react"
import { sendServiceData, getServiceData } from "../../services/HttpClient"
import Placeholder from "../layout/Placeholder"
import { defaultNotifications } from "../../utilis/Notifications"
import Checkbox from "../Form/Checkbox"
import FormActions from "../Form/FormActions"
import ContentContainer from "../layout/ContentContainer"
import FormSection from "../Form/FormSection"

function Settings({ history, match }) {
  const {
    params: { storeId },
  } = match || {}
  const navigateToCateringUrl = `/stores/${storeId}/catering`

  const [allowOrderComment, setAllowOrderComment] = React.useState(true)
  const [isInitialFetch, setIsInitialFetch] = React.useState(false)
  const [saving, setSaving] = React.useState(false)


  React.useEffect(() => {
    async function getSettings() {
      setIsInitialFetch(true)
      try {
        const res = await getServiceData("FoodApi", "v1", `/catering/${storeId}/settings`)
        if (res.ok) {
          setAllowOrderComment(res.data.allowOrderComment)
        }
      } catch (error) {
        console.log(error)
      }
      setIsInitialFetch(false)
    }

    if (storeId) {
      getSettings()
    }
  }, [storeId])

  async function onSubmit(e) {
    e.preventDefault()

    setSaving(true)
    try {
      let data = {
        allowOrderComment,
      }
      var response = await sendServiceData("FoodApi", "v1", `/catering/${storeId}/settings`, data, "PUT")
      if (response.ok) {
        defaultNotifications.updated(Placeholder.Catering.Settings.Title, Placeholder.Catering.Settings.Title)
        redirectToCatering()
      } else {
        defaultNotifications.error(Placeholder.Catering.Settings.Title)
      }
    } catch (error) {
      console.log(error)
    }
    setSaving(false)
  }

  function redirectToCatering() {
    history.push(navigateToCateringUrl)
  }

  return (
    <ContentContainer
      title={Placeholder.Catering.Settings.Title}
      fetchingData={isInitialFetch}
      backTo={navigateToCateringUrl}
      backText={Placeholder.Catering.Name}
    >
      <form className="form" id="settingsForm" onSubmit={onSubmit}>
        <FormSection title={Placeholder.Order}>
          <Checkbox 
            label={Placeholder.Catering.Settings.AllowOrderComment}
            id="allowOrderComment"
            name="allowOrderComment"
            checked={allowOrderComment}
            onChange={(e) => setAllowOrderComment(e.target.checked)}
          />
        </FormSection>


        <FormActions 
          form="settingsForm"
          onCancel={() =>
            history.push(navigateToCateringUrl)
          }
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default Settings
