
import React, { useState, useEffect } from 'react'
import {getData,postData,postMultiPartData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer,ProntoContainerTop} from '../layout/Containers'
import Select from '../layout/Select'
import { ClickButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Checkbox, {parseBool} from '../layout/Checkbox'

const FileGroceryImport = (props)=> {
  const storeId = props.storeid ? props.storeid: props.match.params.storeid
  const [showModal, setModal] = useState(true)
  const [file, setFile] = useState( {} )
  const [message, setMessage] = useState()

  const [updatePrice, setUpdatePrice] = useState(true)
  const [updateOffer, setUpdateOffer] = useState(true)
  const [justifyPrice, setJustifyPrice] = useState(false)
  const [store, setStore] = useState({})
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [data, setData] = useState({
    importStarted: false, 
    isReady : false,
    message:"Starta import"} )

  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        //Store Data
        const response = await getData(
          '/api/import/store/' + storeId
        )
        const result = await response
        setStore(result)
      }

      fetchData()
    }
  }, [])


  const handleInputChange = (event) => {
    event.persist()

    console.log(data)
    data.isReady = true
    setData({...data})
    setSelectedChannel(event.target.value)
    console.log(data)

  }
  const handlePostData = async (event) => {
    event.persist()
    if(storeId && selectedChannel){
      const url = `/api/import/store/${storeId}/file/`
      if(url){
        data.message = "Startar import..."
        data.importStarted = true
        setData({...data})
        const d = {
          StoreId : storeId,
          ChannelTypeId : selectedChannel,
          UpdatePrice : updatePrice,
          JustifyPrice : justifyPrice,
          UpdateOffer : updateOffer
        }
        const response = await postData(
          url,d,true
        )
        var result = await response
        setData({...result})
      }else{
        console.error("url not defined")
      }
    }else{
      alert("välj channel")
    }
    
  }

  function checkExtension(fileName) {
    return ( /\.(csv)$/i.test(fileName))
  }


  const toggleModal = ()=> {
    setFile(undefined)
    setModal(!showModal)
  }
  const updateFile = (e)=>{
    if(!checkExtension(e.target.files[0].name)){
      setMessage("Välj en CSV fil")
      return
    }
    setMessage('')
    setFile(e.target.files[0])
  }
  const uploadFile = async () => {
    const url = `/api/import/store/${storeId}/upload`
    if(!file || !file.name){
      setMessage("Välj en CSV fil")
      return
    }
    setMessage('')
    // const files = e.target.files
    const formData = new FormData()
    formData.append('file', file)
    
    var response = await postMultiPartData(url, formData)
    var fileResponse = await response
    console.log(fileResponse)
    if(fileResponse && fileResponse.message){
      setModal(false)
     
    }
    else {
      setMessage('error')
    }
   
  }
  const handleCheckboxInputChange = (event) => {
    var newValue = parseBool(event.target.value)
    if(event.target.name === 'updatPrice'){
      setUpdatePrice(newValue)
    }
    else if(event.target.name === 'justifyPrice'){
      setJustifyPrice(newValue)
    }
    else if(event.target.name === 'updateOffer'){
      setUpdateOffer(newValue)
    }
  }

  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Importera produkter från fil </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section">
            <div className="row">
              { store && store.channels && store.channels.length > 0 &&  <Select data={ store.channels} name="channelId" handleEvent={handleInputChange} defaultOption="Välj" defaultValue={selectedChannel}/>   }
              <span className="label form-label ml-10">Kanal</span>
            </div>
            <div className="row">
              <Checkbox checked={updatePrice} name="updatPrice" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Uppdatera pris</span> 
            </div>
            <div className="row">
              <Checkbox checked={justifyPrice} name="justifyPrice" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Avrunda pris</span> 
            </div>
            <div className="row">
              <Checkbox checked={updateOffer} name="updateOffer" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Aktivera artikel om det är en ny</span> 
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={data.message} disabled={data && !data.isReady} onClick={handlePostData}/>
            </div>
            {data && data.failMessage && <div className="row">
              {data.failMessage}
            </div>}
          </div>
        </div>
      </ProntoContainer>
      <ul className="no-style drop-shadow categories-table">
        <li>
          <div className="list-item list-item__heading">
            <label className="flex-right btn-control btn--red btn-control--absolut btn-control--absolut__top nav-link btn-file-upload" onClick={e => toggleModal(e)}>Läs in csv fil med produkter</label>
          </div>
        </li>
        <li>
          {showModal &&<div className="modal">
            <div className="modal__content">
              <form>
                <div className="form-section c-6 pl-10">
                  <span className="label">Import fil</span>
                  <hr/>
                </div> 
                <div className="form-section c-6 pl-10">
                  <label htmlFor="priceFileUpload" className="btn-control btn--red nav-link btn-file-upload">{(file && file.name?file.name:'välj fil')}</label>
                  <input id="priceFileUpload" name="priceFileUpload" className="file-upload"  type="file" onChange={e => updateFile(e)}/>
                  <span className="label">{message}</span>
                  <br></br>
                </div>  
                <div className="row">
                  <ClickButton className="btn--black mr-10" title="Ladda in fil" onClick={uploadFile} />
                  <ClickButton className="btn--white mr-10" title={Placeholder.Cancel} onClick={toggleModal} />    
                </div>
              </form>
            </div>
          </div>}
        </li>
      </ul>
              
        
              
    </ProntoContainerTop>
  )

}


export default FileGroceryImport
