import React, { useState } from 'react'
import authService from '../../services/api-authorization/AuthorizeService'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer} from '../layout/Containers'
import {ClickButton} from '../layout/Button'
import './UserItem.css'
import '../layout/BulletinBoard.css'
import BackButton from "../backbutton/BackButton"
import {Link} from "react-router-dom"
import showNotification from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"

const RenderUsers = (storeId, props) => {
  return (
    props.history.push(`/user/${undefined}/${storeId}/view/`)
  )
}

const ChangePassword = (props) => {
  const userId = props.match.params.userid
  const storeId = props.match.params.storeid
  const [user, setUser] = useState({id:userId})
  const [userUpdated, setUserUpdated] = useState(false)

  const handleInputChange = (event) => {
    event.persist()
    user[event.target.name] = event.target.value
    setUser(user)
  }


  const handlePostData = async (event) => {
    event.persist()
    var response = await authService.changePassword(user)
    if (response!= null && response.status === 200){
      showNotification("Andra lösenord", "Ditt lösenord har ändrats", NotificationTypes.SUCCESS)
      setUserUpdated(true)
    }else{
      showNotification("Andra lösenord", response.detail, NotificationTypes.ERROR)
    }
  }
  return (
    <div>
      {userUpdated && RenderUsers(storeId, props)}
      <BackButton editPage={true} disabled={true}>{Placeholder.ChangePassword}</BackButton>
      <ProntoContainer>
        <div className="form c-12">
          <div className="form-row">

            <div className="form-section c-6 pr-10">
              <input  type="password" name="newPassword" onChange={handleInputChange} placeholder={"Lösenord"}/>
              <input  type="password" name="confirmPassword" onChange={handleInputChange} placeholder={"Upprepa"}/>
            </div>

          </div>
          <div className="row">
            <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={handlePostData}/>
            <Link className="link-edit icon--red"  to={`/user/${user.id}/${user.storeId}/view/`} ><ClickButton className="btn--white" title={Placeholder.Cancel}/></Link>
          </div>
        </div>
      </ProntoContainer>
    </div>

  )
}

export default ChangePassword
