import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import {getData,postData} from '../../services/Ajax'
import Mode from '../../utilis/Mode'
import {ProntoContainer} from '../layout/Containers'
import {ClickButton,SaveButton} from '../layout/Button'
import Select from '../layout/Select'
import CampaignUseRule from './rules/CampaignUseRule'
import CampaignCodeRule from './rules/CampaignCodeRule'
import CampaignDateRule from './rules/CampaignDateRule'
import CampaignStoreRule from './rules/CampaignStoreRule'


const CampaignRule = (props)=> {
  const mode = props.mode
  const campaignRuleId = props.match.params.ruleid
  const campaignId = props.match.params.campaignid
  const history = props.history
  const [data, setData] = useState({metaData:{}})
  const [rulesTypes, setRulesTypes] = useState( [ // eslint-disable-line no-unused-vars
    {id:-1, name: "Välj",value: "not-set"},
    {id:1, name: "Antal gånger",value: "CampaignUseRule"},
    {id:2,name: "Kod",value: "CampaignCodeRule"},
    {id:3,name: "Datum",value: "CampaignDateRule"},
    {id:4,name: "Butik",value: "CampaignStoreRule"}] )

  useEffect(() => {
    const fetchData = async () => {
      if(campaignRuleId){
        const response = await getData(
          '/api/campaignrule/'+campaignRuleId
        )
        const result = await response
        result.metaData = JSON.parse(result.metaData)
        setData(result)
      }
    }

    fetchData()
  }, [])

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/campaignrule/edit'
    }
    if(mode === Mode.Create){
      return '/api/campaignrule/create'
    }
    if(mode === Mode.Delete){
      return '/api/campaignrule/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()

    if(url){
      const response = await postData(
        url,{
          Id : data.id,
          CampaignId : data.campaignId ? data.campaignId:campaignId,
          Name : data.metaData.name,
          RuleType : data.ruleType,
          MetaData : JSON.stringify(data.metaData),
        }
      )
      return await response
    
    }else{
      console.error("url not defined")
    }
  }

  const handleRuleTypeChange = (event) => {
    event.persist()
    
    setData({ruleType: event.target.value, metaData: {}})
  }

  const handleInputChange = (event) => {
    event.persist()
    data.metaData[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <ProntoContainer>
      {mode === Mode.Create && rulesTypes && rulesTypes.length > 0 && 
             <Select data={rulesTypes} name="StoreId" handleEvent={handleRuleTypeChange}/>  
      }
      {data && data.ruleType && 
            <div className="form">
              <div className="form-section c-6 pr-10">
                {data.ruleType === 'CampaignUseRule' && <CampaignUseRule data={data.metaData} handleInputChange={handleInputChange} mode={mode}/>}
                {data.ruleType === 'CampaignStoreRule' && <CampaignStoreRule data={data.metaData} handleInputChange={handleInputChange}  mode={mode}/>}
                {data.ruleType === 'CampaignDateRule' && <CampaignDateRule data={data.metaData} handleInputChange={handleInputChange}  mode={mode}/>}
                {data.ruleType === 'CampaignCodeRule' && <CampaignCodeRule data={data.metaData} handleInputChange={handleInputChange}  mode={mode}/>}
              </div>  
              <div className="row">
                <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
                <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
              </div>
            </div>
      }
    </ProntoContainer>
  )
  
}


export default CampaignRule

