/* eslint-disable indent */
import { isEnvironment } from '../utilis/Enviroment'

export function getServiceUrl(service, version) {
  switch (service) {
    case 'FoodApi':
      return getFoodUrl(version)
    case 'StoreApi':
      return getStoreUrl(version)
    case 'PaymentApi':
      return getPaymentUrl(version)
    case 'AuthApi':
      return getAuthUrl(version)
    case 'ADAuthApi':
      return getADAuthUrl(version)
    case 'ChannelApi':
      return getChannelUrl(version)
    default:
      return ''
  }
}

function getFoodUrl(version) {
  if (isEnvironment('development')) {
    return `http://localhost:8556/${version}`
  } else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/app-food/${version}`
  } else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/app-food/${version}`
  } else if (isEnvironment('production')) {
    return `https://api.icapronto.se/app-food/${version}`
  }
}

function getStoreUrl(version) {
  if (isEnvironment('development')) {
    return `http://localhost:8553/${version}`
  } else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/app-store/${version}`
  } else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/app-store/${version}`
  } else if (isEnvironment('production')) {
    return `https://api.icapronto.se/app-store/${version}`
  }
}

function getPaymentUrl(version) {
  if (isEnvironment('development')) {
    return `https://api-testing.icapronto.se/svc-payment/${version}`
  } else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/svc-payment/${version}`
  } else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/svc-payment/${version}`
  } else if (isEnvironment('production')) {
    return `https://api.icapronto.se/svc-payment/${version}`
  }
}

function getAuthUrl(version) {
  if (isEnvironment('development')) {
    return `http://localhost:8554/${version}`
  } else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/auth/${version}`
  } else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/auth/${version}`
  } else if (isEnvironment('production')) {
    return `https://api.icapronto.se/auth/${version}`
  }
}

function getADAuthUrl(version) {
  // Use dev auth service for ad login when running locally
  if (isEnvironment('development')) {
    return `https://api-testing.icapronto.se/auth/${version}`
  } else {
    return getAuthUrl(version)
  }
}

function getChannelUrl(version) {
  if (isEnvironment('development')) {
    return `http://localhost:8559/${version}`
  } else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/svc-channel/${version}`
  } else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/svc-channel/${version}`
  } else if (isEnvironment('production')) {
    return `https://api.icapronto.se/svc-channel/${version}`
  }
}
