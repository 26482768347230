/* eslint-disable indent */
import React from 'react'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import TextEditor from '../layout/TextEditor'
import AdditionalCategories from './AdditionalCategories'
import ProductVariants from './ProductVariants'
import {
  OnChangeAction,
  OnChangeEntityType,
} from '../../utilis/catering/productVariantUtils'
import { defaultNotifications } from '../../utilis/Notifications'
import Textbox from '../Form/Textbox'
import { useForm } from 'react-hook-form'
import './FormStyles.css'
import FormActions from '../Form/FormActions'
import Mode from '../../utilis/Mode'
import MessageBox from '../layout/MessageBox'
import ContentContainer from '../layout/ContentContainer'
import Checkbox from '../Form/Checkbox'
import { toSlug } from '../../utilis/UrlUtils'
import ImageSelector from './ImageSelector'
import DropdownSelector from '../layout/DropdownSelector'
import Button from '../UI/Button'
import { CloseIcon } from '../layout/Icons'
import moment from 'moment'
import 'moment/locale/sv'

moment.locale('sv')

function Product({ match, history, mode }) {
  const {
    params: { storeId, productId },
  } = match || {}
  const currentCategoryId = match.params.categoryId
  const navigateToCategoryUrl = `/stores/${storeId}/catering/categories/${currentCategoryId}`
  const descriptionRef = React.useRef(null)
  const nutrientFactsRef = React.useRef(null)
  const deliveryInformationRef = React.useRef(null)
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm()

  const [isInitialFetch, setIsInitialFetch] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [slug, setSlug] = React.useState('')
  const [slugError, setSlugError] = React.useState(false)
  const [availableSlug, setAvailableSlug] = React.useState('')
  const [productType, setProductType] = React.useState(1)
  const [minimumDaysToDelivery, setMinimumDaysToDelivery] = React.useState(0)
  const [minimumNumberOfItems, setMinimumNumberOfItems] = React.useState(0)
  const [ean, setEan] = React.useState('')
  const [name, setName] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')
  const [imageUrl2, setImageUrl2] = React.useState('')
  const [imageUrl3, setImageUrl3] = React.useState('')
  const [imageAlt, setImageAlt] = React.useState('')
  const [imageAlt2, setImageAlt2] = React.useState('')
  const [imageAlt3, setImageAlt3] = React.useState('')
  const [selectedLimitedDeliveryPeriods, setSelectedLimitedDeliveryPeriods] =
    React.useState([])
  const [price, setPrice] = React.useState(0)
  const [taxCode, setTaxCode] = React.useState(2)
  const [active, setActive] = React.useState(false)
  const [allowComment, setAllowComment] = React.useState(true)
  const [description, setDescription] = React.useState('')
  const [nutrientFacts, setNutrientFacts] = React.useState('')
  const [deliveryInformation, setDeliveryInformation] = React.useState('')
  const [allCategories, setAllCategories] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [categoryId, setCategoryId] = React.useState(currentCategoryId)
  const [variantCategories, setVariantCategories] = React.useState([])
  const [variants, setVariants] = React.useState([])
  const [variantsModified, setVariantsModified] = React.useState(false)
  const [variantEanExists, setVariantEanExists] = React.useState(false)
  const [eanInputFocused, setEanInputFocused] = React.useState(false)
  const [buffetVariantError, setBuffetVariantError] = React.useState(false)
  const [limitedDeliveryPeriods, setLimitedDeliveryPeriods] = React.useState([])

  const imageAltValidationCriteria = {
    minLength: {
      value: 30,
      message:
        'Beskriv kortfattat och sakligt vad bilden innehåller, minst 30 tecken',
    },
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        try {
          const response = await getServiceData(
            'FoodApi',
            'v1',
            `/catering/${storeId}/limitedDeliveryPeriods`,
          )
          if (response.ok) {
            const result = await response.data
            setLimitedDeliveryPeriods(result)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

    fetchData()
  }, [storeId])

  React.useEffect(() => {
    let componentMounted = true

    async function getProduct() {
      setIsInitialFetch(true)

      try {
        const res = await getServiceData(
          'FoodApi',
          'v1',
          `/catering/${storeId}/products/${productId}`,
        )
        if (componentMounted && res.ok) {
          const product = res.data
          setCategories(product.categories || [])
          setCategoryId(product.categoryId || currentCategoryId)
          setProductType(product.productType || 1)
          setSlug(product.slug)
          setMinimumDaysToDelivery(product.minimumDaysToDelivery || 0)
          setMinimumNumberOfItems(product.minimumNumberOfItems || 0)
          setEan(product.ean || '')
          setName(product.name || '')
          setImageUrl(product.imageUrl || '')
          setImageUrl2(product.imageUrl2 || '')
          setImageUrl3(product.imageUrl3 || '')
          setImageAlt(product.imageAltText || '')
          setImageAlt2(product.imageAltText2 || '')
          setImageAlt3(product.imageAltText3 || '')
          setSelectedLimitedDeliveryPeriods(
            product.limitedDeliveryPeriods || [],
          )
          setPrice((product.price * 0.01).toFixed(2) || 0)
          setTaxCode(product.taxCode || 1)
          setActive(product.active || false)
          setAllowComment(product.allowComment)
          setDescription(product.description)
          setNutrientFacts(product.nutrientFacts)
          setDeliveryInformation(product.deliveryInformation)
          setVariantCategories(product.variantCategories || [])
          setVariants(product.variants || [])
          setVariantsModified(false)
          if (product.variants) {
            setVariantEanExists(
              product.variants.some((variant) => variant.ean !== ''),
            )
          }

          reset({
            ...product,
            price: (product.price * 0.01).toFixed(2),
          })
        }
      } catch (error) {
        console.log(error)
      }
      setIsInitialFetch(false)
    }

    if (productId) {
      getProduct()
    }

    return () => {
      componentMounted = false
    }
  }, [productId])

  React.useEffect(() => {
    let componentMounted = true

    async function getCategories() {
      const res = await getServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeId}/categories`,
      )
      if (res.ok && componentMounted) {
        setAllCategories(res.data)

        if (mode === Mode.Create) {
          let category = res.data.find((c) => c.id === currentCategoryId)
          setCategories([category])
        }
      }
    }
    if (storeId) {
      getCategories()
    }
    return () => {
      componentMounted = false
    }
  }, [storeId])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)
    try {
      const description = descriptionRef.current.getContent() || ''
      const nutrientFacts = nutrientFactsRef.current.getContent() || ''
      const deliveryInformation =
        deliveryInformationRef.current.getContent() || ''

      const data = {
        categoryId,
        storeId: storeId,
        productType,
        minimumDaysToDelivery,
        minimumNumberOfItems,
        ean,
        name,
        imageUrl,
        imageUrl2,
        imageUrl3,
        imageAltText: imageAlt,
        imageAltText2: imageAlt2,
        imageAltText3: imageAlt3,
        limitedDeliveryPeriods: selectedLimitedDeliveryPeriods,
        price: parseFloat((price * 100).toFixed(2)),
        description,
        nutrientFacts,
        deliveryInformation,
        taxCode,
        active,
        categories,
        variantsModified,
        allowComment,
        slug,
      }

      if (variantsModified) {
        data.variantCategories = variantCategories
        data.variants = variants
      }

      let url = `/catering/${storeId}/products`
      let method = 'POST'
      if (mode === Mode.Edit) {
        url = `/catering/${storeId}/products/${productId}`
        method = 'PUT'
      }

      const res = await sendServiceData('FoodApi', 'v1', url, data, method)

      if (res.ok) {
        if (mode === Mode.Create) {
          defaultNotifications.created(Placeholder.Product, name)
        } else {
          defaultNotifications.updated(Placeholder.Product, name)
        }
        redirectToCategory()
      } else {
        defaultNotifications.error(Placeholder.Product)
      }
    } catch (error) {
      console.log(error)
    }
    setSaving(false)
  }

  async function onDeleteProduct() {
    if (!window.confirm(`Är du säker på att du vill ta bort "${name}"?`)) {
      return
    }

    setDeleting(true)

    try {
      const res = await sendServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeId}/products/${productId}`,
        undefined,
        'DELETE',
      )

      if (res.ok) {
        defaultNotifications.deleted(Placeholder.Product, name)
        redirectToCategory()
        setSaving(false)
      } else {
        defaultNotifications.error(Placeholder.Product)
      }
    } catch (error) {
      console.log(error)
    }
    setDeleting(false)
  }

  function onProductVariantChange(result) {
    switch (result.action) {
      case OnChangeAction.Create:
      case OnChangeAction.Update:
        if (result.entityType === OnChangeEntityType.VariantCategory) {
          setVariantCategories(result.variantCategories)
          setVariants(result.variants)
        } else if (result.entityType === OnChangeEntityType.Variant) {
          setVariants(result.variants)
        }
        break
      case OnChangeAction.Delete:
        if (result.entityType === OnChangeEntityType.VariantCategory) {
          setVariantCategories(result.variantCategories)
          setVariants(result.variants)
        }
        break
      default:
        throw new Error('Unknown action')
    }
    setVariantsModified(true)
  }

  async function createSlug(productName) {
    const slugSuggestion = toSlug(productName)

    const slugRes = await getServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeId}/products/slug/${slugSuggestion}`,
    )
    if (!slugRes.ok) {
      defaultNotifications.error()
      return
    }
    const { isAvailable, suggestion } = slugRes.data
    setSlug(isAvailable ? slugSuggestion : suggestion)
  }

  async function verifySlug(enteredSlug) {
    const slugSuggestion = toSlug(enteredSlug)

    const slugRes = await getServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeId}/products/slug/${slugSuggestion}`,
    )
    if (!slugRes.ok) {
      defaultNotifications.error()
      return
    }
    const { isAvailable, suggestion } = slugRes.data
    if (isAvailable) {
      setSlug(slugSuggestion)
    } else {
      setSlugError(true)
      setSlug(slugSuggestion)
      setAvailableSlug(suggestion)
    }
  }

  function redirectToCategory() {
    history.push(navigateToCategoryUrl)
  }

  function setBuffetType() {
    setProductType(2)
    if (variantCategories.length > 1) {
      setBuffetVariantError(true)
    }
  }

  function getBackText() {
    const currentCategory = allCategories
      ? allCategories.find((category) => category.id === currentCategoryId)
      : null
    if (currentCategory && currentCategory.name) {
      return `${Placeholder.Category} (${
        currentCategory ? currentCategory.name : ''
      })`
    }
    return Placeholder.Category
  }

  return (
    <ContentContainer
      title={
        mode === Mode.Create
          ? Placeholder.Catering.Product.CreateHeadline
          : name
      }
      fetchingData={isInitialFetch}
      backTo={navigateToCategoryUrl}
      backText={getBackText}
    >
      <form
        className="cateringForm"
        id="productForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <AdditionalCategories
          mainCategoryId={categoryId}
          categories={allCategories}
          selectedCategories={categories}
          onUpdateSelectedCategories={setCategories}
          onUpdateMainCategory={setCategoryId}
        />
        <DropdownSelector
          label={Placeholder.Catering.LimitedDeliveryPeriod.AddPeriodsToProduct}
          headerText={'Välj leveransperiod'}
          selectedItems={selectedLimitedDeliveryPeriods}
          placeholder={'Leveransperioder för tillfälligt sortiment'}
          listItems={limitedDeliveryPeriods}
          itemType="select"
          setSelected={(e, selected) => {
            e.preventDefault()
            setSelectedLimitedDeliveryPeriods((prev) => {
              const indexOfSelected = prev.findIndex(
                (item) => item.id === selected.id,
              )
              return indexOfSelected < 0
                ? [...prev, selected]
                : prev.filter((item) => item.id !== selected.id)
            })
          }}
        />
        {selectedLimitedDeliveryPeriods.length > 0 && (
          <div className="connectedDeliveryPeriods">
            <p>Kopplade leveransperioder</p>
            <p>
              Leveransperioder hanteras och aktiveras under{' '}
              <a href={`/stores/${storeId}/limited-delivery-period`}>
                hantera leveransperioder för tillfälligt sortiment
              </a>
            </p>
            <div className="currentDeliveryPeriods">
              {selectedLimitedDeliveryPeriods.map((period) => {
                return (
                  <Button
                    Icon={() => (
                      <div
                        className="iconAsButton"
                        onClick={() => {
                          setSelectedLimitedDeliveryPeriods((prev) =>
                            prev.filter(
                              (selected) => selected.id !== period.id,
                            ),
                          )
                        }}
                      >
                        <CloseIcon color="#fff" size="16" />
                      </div>
                    )}
                    IconPlacement="afterText"
                    key={period.id}
                    size="small"
                    type="info"
                  >
                    <p className="limitedDeliveryPeriodButton">{`${
                      period.name
                    } (${moment(period.fromDate).format('DMMM')} ${moment(
                      period.fromDate,
                    ).format('YYYY')} - ${moment(period.toDate).format(
                      'DMMM',
                    )} ${moment(period.toDate).format('YYYY')}) `}</p>
                  </Button>
                )
              })}
            </div>
          </div>
        )}
        <div className="radioGroup">
          <input
            className="radioInput"
            id="productType1"
            type="radio"
            name="productType"
            onChange={() => {
              setProductType(1)
              setBuffetVariantError(false)
            }}
            checked={productType === 1}
          />
          <label htmlFor="productType1" className="radioLabel">
            Produktvy
          </label>
          <input
            className="radioInput"
            id="productType2"
            type="radio"
            name="productType"
            onChange={setBuffetType}
            checked={productType === 2}
          />
          <label htmlFor="productType2" className="radioLabel">
            Buffévy
          </label>
        </div>
        {buffetVariantError ? (
          <MessageBox
            type="info"
            withTitle={true}
            title="Produkten kan ej göras om till buffévy"
            message="En produkt i buffévy kan maximalt ha ett variantval. Denna produkt har fler än ett variantval tillagt."
          />
        ) : (
          <>
            <Textbox
              type="number"
              label="Dagar till tidigaste leverans"
              placeholder="Dagar till tidigaste leverans"
              id="minimumDaysToDelivery"
              name="minimumDaysToDelivery"
              value={minimumDaysToDelivery}
              onChange={(e) => setMinimumDaysToDelivery(e.target.value)}
            />
            <Textbox
              type="number"
              label="Minsta antal för beställning (Frivillig)"
              placeholder="Minsta antal i beställning"
              id="minimumNumberOfItems"
              name="minimumNumberOfItems"
              value={minimumNumberOfItems}
              onChange={(e) => setMinimumNumberOfItems(e.target.value)}
            />
            <Textbox
              type="number"
              label={Placeholder.Ean}
              placeholder={Placeholder.SetEan}
              id="ean"
              name="ean"
              value={ean}
              onChange={(e) => setEan(e.target.value)}
              validationRegister={register}
              validation={{
                minLength: {
                  value: 8,
                  message: 'EAN måste vara minst 8 tecken',
                },
                maxLength: {
                  value: 13,
                  message: 'EAN får inte vara längre än 13 tecken',
                },
                pattern: {
                  value: /^\b(?!21)(?!22)\d{8,13}\b/i,
                  message:
                    'Det är inte möjligt att bokföra EAN-koder som börjar på 21 eller 22',
                },
              }}
              errors={errors}
              onFocus={() => setEanInputFocused(true)}
              onBlur={() => setEanInputFocused(false)}
              afterTextbox={() => (
                <>
                  <MessageBox
                    type="info"
                    withTitle={true}
                    title="OBS!"
                    message="EAN Måste fyllas i för att redovisning av försäljning ska fungera vid förskottsbetalning."
                  />
                  {variantEanExists && ean && eanInputFocused && (
                    <MessageBox
                      type="info"
                      withTitle={true}
                      title="OBS!"
                      message="Ändringar av EAN på huvudnivå påverkar inte varianter med eget EAN på variantnivå"
                    />
                  )}
                </>
              )}
            />
            <Textbox
              label="Produktnamn"
              placeholder="Ange produktnamn"
              id="name"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              onBlur={!slug ? () => createSlug(name) : null}
              validationRegister={register}
              validation={{ required: true }}
              errors={errors}
            />
            <Textbox
              label="Slug"
              tooltip={true}
              tooltipText={
                <>
                  <p style={{ margin: 0 }}>
                    En slug är den sista delen av en URL-adress och fungerar som
                    en unik identifierare för en webbsida. Slug:en ska
                    kortfattat beskriva sidans innehåll.
                  </p>
                  <p style={{ margin: '8px 0 0 0' }}>
                    Exempel: smorgastarta-lax-rakor
                  </p>
                </>
              }
              placeholder="Ange slug"
              id="slug"
              name="slug"
              value={slug}
              onChange={(e) => {
                setSlug(e.target.value)
                setSlugError(false)
              }}
              onBlur={() => verifySlug(slug)}
              validationRegister={register}
              validation={{
                validate: {
                  required: () => {
                    if (slugError) return 'Den angivna slug:en är upptagen.'
                  },
                },
              }}
              errors={errors}
              afterTextbox={
                slugError
                  ? () => (
                      <MessageBox
                        type="error"
                        message={
                          <>
                            {`Den angivna slugen är upptagen. Vänligen ändra slugen eller använd ledigt förslag:`}
                            <button
                              onClick={() => {
                                setSlug(availableSlug)
                                setSlugError(false)
                                clearErrors('slug')
                              }}
                              style={{
                                backgroundColor: 'transparent',
                                border: 0,
                                boxShadow: 'none',
                                color: '#CF2E05',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '20px',
                              }}
                            >
                              {availableSlug}
                            </button>
                          </>
                        }
                      />
                    )
                  : null
              }
              disabled={mode === Mode.Create ? false : true}
            />
            <ImageSelector
              imageUrl={imageUrl}
              onChangeImageUrl={(value) => setImageUrl(value)}
              storeId={storeId}
              imageAlt={imageAlt}
              onChangeImageAlt={(value) => setImageAlt(value)}
              validationRegister={register}
              validationText={imageAltValidationCriteria}
              errors={errors}
              preferredAspectRatio={'1:1'}
            />
            <ImageSelector
              label="Kompletterande bild (Frivillig)"
              id="image2"
              name="image2"
              imageUrl={imageUrl2}
              onChangeImageUrl={(value) => setImageUrl2(value)}
              storeId={storeId}
              imageAlt={imageAlt2}
              onChangeImageAlt={(value) => setImageAlt2(value)}
              validationRegister={register}
              validationText={imageAltValidationCriteria}
              errors={errors}
              preferredAspectRatio={'1:1'}
            />
            <ImageSelector
              label="Kompletterande bild (Frivillig)"
              id="image3"
              name="image3"
              imageUrl={imageUrl3}
              onChangeImageUrl={(value) => setImageUrl3(value)}
              storeId={storeId}
              imageAlt={imageAlt3}
              onChangeImageAlt={(value) => setImageAlt3(value)}
              validationRegister={register}
              validationText={imageAltValidationCriteria}
              errors={errors}
              preferredAspectRatio={'1:1'}
            />
            <Textbox
              type="number"
              step="0.01"
              label="Pris (kr)"
              placeholder={Placeholder.SetPrice}
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              validationRegister={register}
              validation={{
                required: true,
                min: {
                  value: 1,
                  message: 'Pris måste vara större än 0',
                },
              }}
              errors={errors}
            />
            <div className="texteditor">
              <span className="groupLabel">Produktbeskrivning</span>
              <TextEditor
                initialValue={description || ''}
                editorRef={descriptionRef}
                height={200}
              />
            </div>
            <div className="texteditor">
              <span className="groupLabel">Näringsinnehåll/Allergener</span>
              <TextEditor
                initialValue={nutrientFacts || ''}
                editorRef={nutrientFactsRef}
                height={200}
              />
            </div>
            <div className="texteditor">
              <span className="groupLabel">Hur levereras din catering?</span>
              <TextEditor
                initialValue={deliveryInformation || ''}
                editorRef={deliveryInformationRef}
                height={200}
              />
            </div>
            <span className="groupLabel">Moms</span>
            <div className="radioGroup">
              <input
                className="radioInput"
                id="taxCode1"
                type="radio"
                name="taxCode"
                onChange={() => setTaxCode(1)}
                checked={taxCode === 1}
              />
              <label htmlFor="taxCode1" className="radioLabel">
                25%
              </label>
              <input
                className="radioInput"
                id="taxCode2"
                type="radio"
                name="taxCode"
                onChange={() => setTaxCode(2)}
                checked={taxCode === 2}
              />
              <label htmlFor="taxCode2" className="radioLabel">
                12%
              </label>
              <input
                className="radioInput"
                id="taxCode3"
                type="radio"
                name="taxCode"
                onChange={() => setTaxCode(3)}
                checked={taxCode === 3}
              />
              <label htmlFor="taxCode3" className="radioLabel">
                6%
              </label>
            </div>
            <Checkbox
              label={Placeholder.Active}
              id="isActive"
              name="active"
              checked={active}
              onChange={() => setActive((prev) => !prev)}
            />
            <Checkbox
              label={Placeholder.Catering.Product.AllowComment}
              id="allowComment"
              name="allowComment"
              checked={allowComment}
              onChange={() => setAllowComment((prev) => !prev)}
            />
          </>
        )}
      </form>
      {!buffetVariantError && (
        <>
          <ProductVariants
            productInfo={{ price, ean, productType }}
            onChange={onProductVariantChange}
            variantCategories={variantCategories}
            variants={variants}
          />
          <FormActions
            form="productForm"
            saving={saving}
            deleting={deleting}
            showDelete={mode === Mode.Edit}
            onCancel={redirectToCategory}
            onDelete={onDeleteProduct}
          />
        </>
      )}
    </ContentContainer>
  )
}

export default Product
