
import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import SwishListItem from './SwishListItem'
import {ProntoContainerTop} from '../layout/Containers'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import DatePicker from "react-datepicker"
import Moment from "moment"
import authService from '../../services/api-authorization/AuthorizeService'

const SwishPayments = (props)=> {
  const storeid = props.storeid ? props.storeid: props.match.params.storeid
  const [data, setData] = useState( {stores:[]} )
  const [search, setSearch] = useState({})
  const [continuationToken, setContinuationToken] = useState()
  const [dataLoading, setDataLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date(new Date().getTime()))
  const [endDate, setEndDate] = useState(new Date())
  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/orders/swishpayments/' + storeid
      )
      const result = await response
      handleResponse(result)
    }

    fetchData()
  }, [])
  const handleResponse = async (response) => {
    const token = await authService.getAccessToken()
    setSearch({
      token: token,
      StoreId: response.criteria.storeId,
      stores: response.stores,
      endDate: response.criteria.endDate,
      startDate: response.criteria.startDate
    })
    setContinuationToken(response.continuationToken)
    setStartDate(response.criteria.startDate)
    setEndDate(response.criteria.endDate)
    setData({ orders: response })
  }

  const handleLoadMore = async () => {
    if (dataLoading || !continuationToken) {
      return
    }
    setDataLoading(true)
    const result = await LoadOrders()
    if (result) {
      var tmpOrders = data.orders.orders.concat(result.orders)
      data.orders.orders = tmpOrders
      setContinuationToken(result.continuationToken)
      setData(data)
    }
    setDataLoading(false)
  }

  const LoadOrders = async () => {
    search.continuationToken = continuationToken
    const response = await postData(
      `api/orders/swishpayments/search`, search, true
    )
    const result = await response

    return result
  }

  const handleDateChange = (date, name) => {
    search[name] = date
    setSearch(search)
  }

  function handleDateChanges(date, name) {
    date = Moment(date).format('YYYY-MM-DD')

    if (name === 'startDate') {
      setStartDate(date)
    } else {
      setEndDate(date)
    }
    handleDateChange(date, name)
  }

  const handlePostData = async (event) => {
    event.persist()
    const url = '/api/orders/swishpayments'
    if(url){
      const response = await postData(
        url,search,true
      )
      await response
      console.log(response)
      setData({orders:response})

    }else{
      console.error("url not defined")
    }
  }

  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeid} {...props}>Swish</BackButton>
      <div className="top-container gutter bg--white">
        <div className="form">
          <div className="form-section">
            <div className="row">
              <DatePicker  locale = "sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(startDate ? startDate : new Date())} name="StartDate"
                placeholderText="startdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChanges(date,"startDate")}/>
              <span className="label form-label ml-10">Startdatum</span>
            </div>
            <div className="row">
              <DatePicker  locale = "sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(endDate ? endDate : new Date())} name="EndDate"
                placeholderText="slutdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChanges(date,"endDate")}/>
              <span className="label form-label ml-10">Slutdatum</span>
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={"Sök"} onClick={handlePostData}/>
            </div>
          </div>
        </div>
      </div>
              
      {data && data.orders && data.orders.orders &&
                <div className="mid-container arrow-list drop-shadow">
                  <div className="arrow-list__header arrow-list__header--space-between">
                    <div className="arrow-list__header__element label">Ordrar ({data.orders.totalOrders ? data.orders.totalOrders : 0})</div>
                    <div className="arrow-list__header__element label">Datum</div>
                    <div className="arrow-list__header__element label">Antal transaktioner ({data.orders.totalTransactionCount ? data.orders.totalTransactionCount : 0})</div>
                    <div className="arrow-list__header__element label">Pris ({data.orders.totalOrderSum ? data.orders.totalOrderSum : 0})</div>
                    <div className="width14"></div>
                  </div>
                  <ul className="no-style">
                    {data.orders.orders.map(item => (
                      <SwishListItem data={item} {...props} key={item.id} />
                    ))}
                  </ul>
                </div>
      }
      {!dataLoading && data && data.orders && data.orders.orders && data.orders.orders.length > 0 && <ClickButton onClick={handleLoadMore} title={`Totalt: ${data.orders.orders.length} Ladda mer`} />}
    </ProntoContainerTop>
  )

}


export default SwishPayments
