import React, { useState, useEffect } from 'react'
import { GetCssInput } from '../layout/Input'
import {getData,postData} from '../../services/Ajax'
import Select from '../layout/Select'
import {ProntoContainer,ProntoContainerTop} from '../layout/Containers'
import {ClickButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'

const OnlineGroceryImport = (props)=> {
  const mode = props.mode
  const storeId = props.match.params.storeid
  const [store, setStore] = useState({})
  const [updatePrice] = useState(false)
  const [updateOffer] = useState(false)
  const [justifyPrice] = useState(false)
  const [sourceBms, setSourceBms] = useState("12304")
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [data, setData] = useState({
    importStarted: false, 
    isReady : false,
    message:"Starta import"} )

  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        //Store Data
        const response = await getData(
          '/api/import/store/' + storeId
        )
        const result = await response
        setStore(result)
      }

      fetchData()
    }
  }, [])

  const handleInputChange = (event) => {
    event.persist()

    console.log(data)
    data.isReady = true
    setData({...data})
    setSelectedChannel(event.target.value)

  }

  const handleBmsChange = (event) => {
    event.persist()

    var bms = event.target.value
    setSourceBms(bms)
    console.log(bms)
  

  }

  const handlePostData = async (event) => {
    event.persist()
    if(storeId && selectedChannel){
      const url = `/api/import/store/${storeId}/online/`
      if(url){
        data.message = "Startar import..."
        data.importStarted = true
        setData({...data})
       
        const d = {
          StoreId : storeId,
          ChannelTypeId : selectedChannel,
          UpdatePrice : updatePrice,
          JustifyPrice : justifyPrice,
          UpdateOffer : updateOffer,
          SourceBms : sourceBms
        }
        const response = await postData(
          url,d,true
        )
        var result = await response
        setData({...result})
      }else{
        console.error("url not defined")
      }
    }else{
      alert("välj channel")
    }
    
  }

  return (
    <ProntoContainerTop>
      <BackButton editPage={false} disabled={false}>{"Importera från online"}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section">
            <div className="row">
              { store && store.channels && store.channels.length > 0 &&  <Select data={ store.channels} name="channelId" handleEvent={handleInputChange} defaultOption="Välj" defaultValue={selectedChannel}/>   }
              <span className="label form-label ml-10">Kanal</span>
            </div>
            <div className="row">
              <input className={GetCssInput(mode)} type="text" onChange={handleBmsChange} name="SourceBms" defaultValue={sourceBms} placeholder="bmsnummer" />
              <span className="label form-label ml-10">bmsnummer</span>
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={data.message} disabled={data && !data.isReady} onClick={handlePostData}/>
            </div>
            {data && data.failMessage && <div className="row">
              {data.failMessage}
            </div>}
          </div>
        </div>
           
      </ProntoContainer>
    </ProntoContainerTop>
  )

}


export default OnlineGroceryImport
