import React from 'react'
import { Link } from 'react-router-dom'
import { getData } from '../../services/Ajax'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import ItemList, { ItemListColumn } from '../ItemList/ItemList'
import './Catering.css'
import { AngleIconRight } from '../layout/Icons'
import Placeholder from '../layout/Placeholder'

function CateringIndex({ match, ...props }) {
  const {
    params: { storeid },
  } = match || {}

  const [roles, setRoles] = React.useState({ isAdmin: false, isReader: true })
  const [accountNumber, setAccountNumber] = React.useState({})
  const [accountActive, setAccountActive] = React.useState(true)
  const [storeName, setStoreName] = React.useState('')

  React.useEffect(() => {
    const fetchData = async () => {
      if (storeid) {
        const response = await getData('/api/stores/' + storeid)
        const { accountNumber, active } = await response
        setAccountNumber(accountNumber)
        setAccountActive(active)
        setStoreName(
          encodeURIComponent(response.name.toLowerCase().replaceAll(' ', '-')),
        )
      }
    }

    fetchData()
  }, [])

  React.useEffect(() => {
    const fetchRoles = async () => {
      const rolesResponse = await getData('api/navigation/roles')
      const roles = await rolesResponse
      setRoles(roles)
    }

    fetchRoles()
  }, [storeid])

  return (
    <div>
      <ProntoContainerTop className="scroll-x-mobile">
        <BackButton
          editPage={false}
          disabled={!storeid}
          {...props}
          match={match}
          to={`/stores/${storeid}/view`}
        >
          Catering
        </BackButton>
        <div>
          <CateringNavigation storeid={storeid} roles={roles} />
        </div>
      </ProntoContainerTop>
      {!accountActive && (
        <a
          href={`https://ica.se/catering/${storeName}-${accountNumber}/?new_catering=31daf8d2-09e3-42f6-a34b-e3bfc7fd84c6`}
          target="_blank"
          rel="noreferrer"
          role="button"
          className="link"
        >
          <span className="link-button">
            Se kundvy
            <AngleIconRight color="#fff" className="arrow" />
          </span>
        </a>
      )}
    </div>
  )
}

const CateringNavigation = ({ storeid, roles }) => {
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    const arr = [
      {
        url: `/stores/${storeid}/catering/content/start`,
        name: 'Hantera startsidan',
      },
      {
        url: `/stores/${storeid}/catering/categories`,
        name: 'Hantera sortiment',
      },
      {
        url: `/stores/${storeid}/deliveryproviders`,
        name: 'Hantera upphämtnings-/leveranstider',
      },
      {
        url: `/stores/${storeid}/limited-delivery-period`,
        name: 'Hantera leveransperioder för tillfälligt sortiment',
      },
      {
        url: `/stores/${storeid}/catering/content/mail`,
        name: 'Hantera e-postinställningar',
      },
      {
        url: `/stores/${storeid}/catering/settings`,
        name: Placeholder.Catering.Settings.Title,
      },
    ]

    if (roles.isAdmin) {
      arr.push({
        url: `/stores/${storeid}/catering/copy`,
        name: Placeholder.Catering.CopyStore.Title,
      })
    }

    setItems(arr)
  }, [storeid, roles])

  return (
    <ItemList
      data={items}
      showHeader={false}
      showEmpty={false}
      idProp="name"
      renderRow={(item) => (
        <React.Fragment>
          <ItemListColumn id="name" label="Namn">
            {item.url ? <Link to={item.url}>{item.name}</Link> : item.name}
          </ItemListColumn>
        </React.Fragment>
      )}
    ></ItemList>
  )
}

export default CateringIndex
