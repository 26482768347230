import React from 'react'
import { sendServiceData, getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import { defaultNotifications } from '../../utilis/Notifications'
import Textbox from '../Form/Textbox'
import Checkbox from '../Form/Checkbox'
// import MediaLibraryUploadInfo from '../UI/MediaLibraryUploadInfo'
import { useForm } from 'react-hook-form'
import FormActions from '../Form/FormActions'
import LoadingContainer from '../layout/LoadingContainer'
import MessageBox from '../layout/MessageBox'
import './FormStyles.css'
import ImageSelector from './ImageSelector'

function EditCategory({ history, match, ...props }) {
  const {
    params: { storeid, categoryId },
  } = match || {}

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [category, setCategory] = React.useState({})
  const [name, setName] = React.useState('')
  const [slug, setSlug] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')
  const [imageAlt, setImageAlt] = React.useState('')
  const [active, setActive] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(false)

  const imageAltValidationCriteria = {
    minLength: {
      value: 30,
      message:
        'Beskriv kortfattat och sakligt vad bilden innehåller, minst 30 tecken',
    },
  }

  React.useEffect(() => {
    let componentMounted = true

    async function getCategory() {
      setFetching(true)
      try {
        const res = await getServiceData(
          'FoodApi',
          'v1',
          `/catering/${storeid}/categories/${categoryId}`,
        )

        if (componentMounted && res.ok) {
          setCategory(res.data)
          reset(res.data)
          setName(res.data.name || '')
          setImageUrl(res.data.imageUrl || '')
          setImageAlt(res.data.imageAltText || '')
          setSlug(res.data.slug || '')
          setActive(res.data.active)
        }
      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }
    if (storeid && categoryId) {
      getCategory()
    }
    return () => {
      componentMounted = false
    }
  }, [categoryId, storeid])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setError(false)
    setSaving(true)

    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/${categoryId}`,
      {
        id: categoryId,
        active,
        name,
        imageUrl,
        mainCategoryId: category.mainCategoryId,
        imageAltText: imageAlt,
      },
      'PUT',
    )

    if (res.ok) {
      defaultNotifications.updated('Kategori', name)
      redirectToCategory()
    } else {
      defaultNotifications.error('Kategori')
    }

    setSaving(false)
  }

  async function onRemove(e) {
    e.preventDefault()
    setError(false)

    if (category.products?.length > 0) {
      setDeleting(false)
      setError(true)
      return
    }

    if (!window.confirm(`Är du säker på att du vill ta bort "${name}"?`)) {
      return
    }

    setDeleting(true)

    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/${categoryId}`,
      {},
      'DELETE',
    )

    if (res.ok) {
      defaultNotifications.deleted('Kategori', name)
      redirectToCategories()
    } else {
      defaultNotifications.error('Kategori')
    }

    setDeleting(false)
  }

  function redirectToCategories() {
    history.push(`/stores/${storeid}/catering/categories`)
  }

  function redirectToCategory() {
    history.push(`/stores/${storeid}/catering/categories/${categoryId}`)
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Catering.Name}
        editPage={true}
        match={match}
        history={history}
        {...props}
      >
        {Placeholder.Edit} kategori
      </BackButton>
      <ProntoContainer>
        <LoadingContainer loading={fetching}>
          <form
            className="form"
            id="categoryForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Textbox
              label="Kategorinamn"
              placeholder="Ex. Tårtor"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationRegister={register}
              validation={{ required: true }}
              errors={errors}
            />
            <ImageSelector
              label="Kategoribild"
              imageUrl={imageUrl}
              onChangeImageUrl={(value) => setImageUrl(value)}
              storeId={storeid}
              imageAlt={imageAlt}
              onChangeImageAlt={(value) => setImageAlt(value)}
              validationRegister={register}
              validationText={imageAltValidationCriteria}
              errors={errors}
            />
            {slug && (
              <div>
                <span className="slugLabel">Slug:</span>
                <div className="slugField">/{slug}</div>
              </div>
            )}
            <Checkbox
              label={Placeholder.Active}
              id="active"
              name="active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            <FormActions
              form="categoryForm"
              onCancel={redirectToCategory}
              showDelete
              onDelete={onRemove}
              saving={saving}
              deleting={deleting}
            />
          </form>
          {error && (
            <MessageBox
              type="info"
              withTitle={true}
              title="OBS! Kategorin kan inte tas bort"
              message="Säkerställ att kategorin inte innehåller några produkter."
            />
          )}
        </LoadingContainer>
      </ProntoContainer>
    </div>
  )
}

export default EditCategory
