import React, { useState, useEffect } from 'react'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import CampaignRuleListItem from './CampaignRuleListItem'
import {ProntoContainerTop} from '../layout/Containers'

const CampaignRules = (props)=> {
  const [data, setData] = useState( [] )
  const campaignId = props.match.params.campaignid

  useEffect(() => {
    const fetchData = async () => {
      if(campaignId){
        const response = await getData(
          `/api/campaignrules/${campaignId}`
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`${props.location.pathname}create/`} title={`${Placeholder.Create} kampanjregel`}/>
      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.map(item => (
          <li key={item.id}>
            <CampaignRuleListItem data={JSON.parse(item.metaData)} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}


export default CampaignRules