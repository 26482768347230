import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox, { parseBool } from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { LinkButton, ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Barcode from 'react-barcode'
import showNotification from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"
import OrderItemPrice from './OrderItemPrice'
import { useNavigate } from 'react-router-dom'

const Reversal = (props) => {
  const navigate = useNavigate()
  const version = props.version
  const orderId = props.orderid ? props.orderid : props.match.params.orderid
  const storeId = props.storeid ? props.storeid : props.match.params.storeid
  const [data, setData] = useState({ orderNo: "" })
  const [itemIdList, setItemIdList] = useState([])
  const [reverseDeliveryFee, setReverseDeliveryFee] = useState(false)
  const [filteredList, setFilteredList] = useState([])

  if (version !== 2) throw new Error("No support for Order V1")

  useEffect(() => {
    const fetchData = async () => {
      if (orderId) {
        const url = `/api/order/${orderId}/${storeId}/payment/`
        const response = await getData(url)
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (data.orderItems) {
      const list = []

      data.orderItems.map((item) => {  
        if (item.quantity > 0) {
          for (let j = 0; j < item.quantity; j++) {
            const currentItemAmount = item.totalPrice / item.quantity
            const newItem = { ...item, isReversible: item.remainingReversalAmount > 0 }
            item.remainingReversalAmount = item.remainingReversalAmount - currentItemAmount
            list.push(newItem)
          }
        }
      })
      setFilteredList(list)
    }
  }, [data.orderItems])

  async function HandleReversal(order) {
    var conf = window.confirm("Vill du återbetala markerade varor?")
    if (conf) {
      const reversal = {
        OrderId: order.id,
        StoreId: order.storeId,
        products: itemIdList,
        ReverseDeliveryFee: reverseDeliveryFee
      }
      const url = 'api/order/reversal/v2'
      const response = await postData(
        url, reversal, true
      )
      if (!response) {
        showNotification("Återbetalning", response.detail, NotificationTypes.ERROR, 2000)
      }
      else if (response.status && response.status !== 200) {
        showNotification("Återbetalning", response.detail, NotificationTypes.ERROR, 2000)
      } else {
        showNotification("Återbetalning", `Återbetalning genomförd`, NotificationTypes.SUCCESS, 2000)
        navigate(-1)
      }
    }
  }

  function EanBarcode(ean) {
    return <Barcode value={`${ean}`} width={1} height={20} fontSize={10} />
  }

  function OrderItemV2(item) {
    return <div className="list-item">
      <div className="list-item__element">{item.name} {item.promotionRestriction &&
            <div className="list-item__element--small">({item.promotionRestriction})</div>}
      </div>
      <div className="list-item__element">
        {item.ean && EanBarcode(item.ean)}
      </div>
      <div className="list-item__element"><OrderItemPrice subTotal={item.totalPrice / item.quantity} discountSubTotal={item.totalDiscount / item.quantity}></OrderItemPrice></div>
      <div className="list-item__element">{<Checkbox checked={item.status > 0} name="active" inputClass="checkbox" disabled />}
      </div>
      <div className="list-item__element">{<Checkbox checked={!item.isReversible} disabled={!item.isReversible} name="returnOrderItem" handleEvent={handleChange(item.id)} />}</div>
    </div>

  }

  function OrderItem(item, campaignType) {
    const orderItem = item.orderItem
    const reversalPossible = item.remainingReversalAmount > 0
    return <div className="list-item">
      <div className="list-item__element">{orderItem.name}</div>
      <div className="list-item__element">
        {orderItem.ean && EanBarcode(orderItem.ean)}
      </div>
      <div className="list-item__element"><OrderItemPrice subTotal={orderItem.subTotal} discountSubTotal={orderItem.discountSubTotal} campaignType={campaignType}></OrderItemPrice></div>
      <div className="list-item__element">{<Checkbox checked={orderItem.status > 0} name="active" inputClass="checkbox" disabled />}
      </div>
      <div className="list-item__element">{<Checkbox checked={!reversalPossible} disabled={!reversalPossible} name="returnOrderItem" handleEvent={handleChange(orderItem.id)} />}</div>
    </div>
  }

  function DeliveryItem(order) {
    const delivery = order.delivery
    return <div className="list-item">
      <div className="list-item__element">{delivery.type}</div>
      <div className="list-item__element"> {delivery.ean && EanBarcode(delivery.ean)}</div>
      <div className="list-item__element">{delivery.deliveryFee}</div>
      <div className="list-item__element"></div>
      <div className="list-item__element">{<Checkbox checked={delivery.hasBeenReversed} disabled={delivery.hasBeenReversed} name="ReverseDeliveryFee" handleEvent={handleReverseDeliveryFeeChange} />}</div>

    </div>
  }

  const handleChange = (id) => (event) => {
    const value = parseBool(event.target.value)
    if (value) {
      itemIdList.push(id)
    } else {
      const index = itemIdList.indexOf(id)
      if (index > -1) {
        itemIdList.splice(index, 1)
      }
    }
    setItemIdList(itemIdList)
  }

  const handleReverseDeliveryFeeChange = (event) => {
    const value = parseBool(event.target.value)
    setReverseDeliveryFee(value)
  }

  return (
    <ProntoContainerTop>
      <BackButton backLabel={Placeholder.Order} disabled={false} {...props}>{` Återbetalning för order ${data.orderNo}`}</BackButton>

      <div className="flex-right btn-control--absolut btn-control--absolut__top">
        <LinkButton to={`${props.history.location.pathname}/create`} className=" btn-control  btn--white"
          title={`Anpassad återbetalning`} />

        <ClickButton className="flex-right btn-control btn--white"
          onClick={() => HandleReversal(data)} title={`Återbetala`} />
      </div>

      <ul className="no-style bg-grey">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Namn</div>
            <div className="list-item__element label hidden-down">{Placeholder.Ean}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Price}</div>
            <div className="list-item__element label hidden-down">Plockad</div>
            <div className="list-item__element label hidden-down">{Placeholder.Retrun}</div>
          </div>
        </li>
        {filteredList.map((item, i) => (
          <li key={i}>
            {OrderItemV2(item)}
          </li>
        ))}
        {data && data.orderItemsFood && data.orderItemsFood.map((item, i) => (
          <li key={i}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data && data.orderItemsGrocery && data.orderItemsGrocery.map((item, i) => (
          <li key={i}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data && data.partnerOrderItems && data.partnerOrderItems.map((item, i) => (
          <li key={i}>
            {OrderItem(item, data.campaignType)}
          </li>
        ))}
        {data.delivery &&
          <li>{DeliveryItem(data)}</li>
        }
      </ul>
    </ProntoContainerTop>
  )

}

export default Reversal
