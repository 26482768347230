import React, { useState, useEffect } from 'react'
import { getServiceData, sendServiceData, getData } from '../../services/HttpClient'
import Placeholder from '../../components/layout/Placeholder'
import { defaultNotifications } from "../../utilis/Notifications"
import ContentContainer from '../../components/layout/ContentContainer'
import { ItemListColumn } from '../../components/ItemList/ItemList'
import SortableList from '../../components/ItemList/SortableList'
import Link from '../../components/UI/Link'
import CheckboxV2 from '../../components/layout/CheckboxV2'
import { PlusIcon, CogIcon } from '../../components/layout/Icons'
import LinkButton from '../../components/UI/LinkButton'

const PaymentMethods = (props) => {
  const storeId = props.match.params.storeid

  const [paymentMethods, setPaymentMethods] = useState([])
  const [providerTypes, setProviderTypes] = useState([])
  const [fetchingData, setFetchingData] = useState(false)
  const [fetchingProviderTypes, setFetchingProviderTypes] = useState(false)
  const [roles, setRoles] = useState({ isAdmin: false, isReader: true })

  const isFetching = fetchingData || fetchingProviderTypes

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        setFetchingData(true)
        const response = await getServiceData('PaymentApi', 'v1', `/stores/${storeId}/paymentMethods`)
        if (response.ok) {
          setPaymentMethods(response.data)
        } else {
          defaultNotifications.error(Placeholder.PaymentMethod)
        }

        const res = await getData('api/navigation/roles')
        if (response.ok) {
          setRoles(res.data)
        } else {
          defaultNotifications.error(Placeholder.Roles)
        }

        setFetchingData(false)
      }
    }

    fetchData()
  }, [storeId])

  useEffect(() => {
    const fetchProviderTypes = async () => {
      setFetchingProviderTypes(true)
      const response = await getServiceData('PaymentApi', 'v1', "/paymentProviders")
      if (response.ok) {
        setProviderTypes(response.data)
      } else {
        defaultNotifications.error("Typer")
      }
      setFetchingProviderTypes(false)
    }

    fetchProviderTypes()
  }, [])

  function getPaymentProviderTypeName(paymentProviderType) {
    var providerType = providerTypes.find(p => p.type === paymentProviderType)
    if (providerType) {
      return providerType.displayName
    }
    return "Okänd"
  }

  const onDragEnd = (result) => {
    const { isSorted, sortedItems } = result

    if (!isSorted) {
      return
    }

    updateSortOrder(sortedItems)
    setPaymentMethods(sortedItems)
  }

  async function updateSortOrder(sortedItems) {
    const sortData = sortedItems.map(item => ({ id: item.id, sortOrder: item.sortOrder }))

    const res = await sendServiceData(
      'PaymentApi',
      'v1',
      `/stores/${storeId}/paymentMethods/sort`,
      sortData,
      'PATCH'
    )

    if (!res.ok) {
      defaultNotifications.error(Placeholder.PaymentMethod, 'Ett fel inträffade vid sorteringen.')
    }
  }

  return (
    <ContentContainer
      title={Placeholder.PaymentMethod}
      backDisabled={!storeId}
      fetchingData={isFetching}
      expandContent
      backTo={`/stores/${storeId}/view`}
      actions={() =>
        <>
          {roles && roles.isAdmin && 
            <LinkButton
              disabled={!paymentMethods || !paymentMethods.find(p => p.paymentProviderType === 'Payex')}
              type="action"
              Icon={CogIcon}
              to={`/stores/${storeId}/paymentMethods/payexMerchants/`}
            >
              {Placeholder.PayexSettings}
            </LinkButton>
          }
          <LinkButton 
            type="save"
            Icon={PlusIcon}
            to={`/stores/${storeId}/paymentMethods/create/`}
          >
            {Placeholder.Create} {Placeholder.PaymentMethod.toLowerCase()}
          </LinkButton>
        </>
      }
    >
      <SortableList
        data={paymentMethods}
        onDragEnd={onDragEnd}
        droppableId="paymentMethods"
        isDropDisabled={false}
        stickyHeader={true}
        shadow={false}
        renderRow={(item) =>
          <>
            <ItemListColumn id="name" label="Namn" columns="7">
              <Link
                text={item.name}
                to={`/stores/${storeId}/paymentMethods/${item.id}/`}
                title={item.name}
              >
                {item.name}
              </Link>
            </ItemListColumn>
            <ItemListColumn id="type" label="Typ" columns="2">
              {getPaymentProviderTypeName(item.paymentProviderType)}
            </ItemListColumn>
            <ItemListColumn id="channels" label="Kanaler" columns="1">
              {item.channelTypes ? item.channelTypes.length : 0} st
            </ItemListColumn>
            <ItemListColumn id="minPrice" label="Minsta summa" columns="2">
              {item.minimumPrice} kr
            </ItemListColumn>
            <ItemListColumn id='active' label={Placeholder.Active} narrow>
              <CheckboxV2
                id={`active-${item.id}`}
                checked={item.active}
                readonly
              />
            </ItemListColumn>
          </>
        }
      />
    </ContentContainer>
  )
}

export default PaymentMethods
