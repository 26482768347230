import React from 'react'
import ItemList, {ItemListColumn} from '../ItemList/ItemList'
import ListSummary from '../UI/ListSummary'

const OrderList = ({
  orders,
  hasMore,
  onLoadMore,
  fetchingData,
  clickable = false,
}) => {
  return (
    <div className="mid-container arrow-list bg--white drop-shadow">
      <ItemList
        data={orders}
        showEmpty={true}
        emptyHeading="Din sökning resulterade inte i några träffar."
        shadow={false}
        stickyHeader={true}
        idProp="id"
        arrowList={clickable}
        navigationUrl={(item) => clickable ? `/stores/${item.storeId}/orders/${item.id}/v2` : ''}
        renderRow={(item) =>
          <React.Fragment>
            <ItemListColumn id="orderno" label="Ordernummer" narrow>
              {item.orderNo}
            </ItemListColumn>
            <ItemListColumn id="channel" label="Köpkanal" narrow>
              {item.channelTypeName}
            </ItemListColumn>
            <ItemListColumn id="store" label="Butik" narrow>
              {item.storeName}
            </ItemListColumn>
            <ItemListColumn id="deliveryType" label="Leveranssätt" narrow>
              {item.deliveryBongDisplayName 
                ? `${item.deliveryType} (${item.deliveryBongDisplayName})` 
                : item.deliveryType}
            </ItemListColumn>
            <ItemListColumn id="deliveryFee" label="Leveranspris" narrow>
              {item.deliveryFee} kr
            </ItemListColumn>
            <ItemListColumn id="deliveryDate" label="Leveransdatum" narrow>
              {item.deliveryDate}
            </ItemListColumn>
            <ItemListColumn id="createdAt" label="Beställd" narrow>
              {item.createdAt}
            </ItemListColumn>
            <ItemListColumn id="payment" label="Betalsätt" narrow>
              {item.paymentInternalDisplayName}
            </ItemListColumn>
            <ItemListColumn id="totalPrice" label="Ordersumma" narrow>
              {item.totalPrice} kr
            </ItemListColumn>
          </React.Fragment>
        }>
      </ItemList>

      <ListSummary 
        hasMore={hasMore}
        itemCount={orders && orders.length > 0 ? orders.length : 0}
        loading={fetchingData}
        onLoadMore={onLoadMore}
      />
    </div>
  )
}

export default OrderList
