import React from 'react'
import GroupProducts from '../groups/GroupProducts'


const ThemeProducts = (props)=> {
  const themeId = props.match.params.themeid
  
  return (
    <GroupProducts   referenceType="themes" groupId={themeId} title="Tema produkter" {...props}/>
  )
  
}

export default ThemeProducts