import React from "react"
import { Link } from "react-router-dom"
import Placeholder from "../layout/Placeholder"
import { TrashIcon } from "../layout/Icons"

function DoorBlockListItem(props) {
  const item = props.data

  return (
    <div className="list-item list-item--flex-row-mobile">
      <div className="list-item__element">{item.createdAt}</div>
      <div className="list-item__element">{item.ssn}</div>
      <div className="list-item__element">{item.acr}</div>
      <div className="list-item__element">{item.customerId}</div>
      <div className="list-item__element">{item.createdByEmail}</div>
      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`${props.location.pathname}${item.id}/delete/`}
          title={Placeholder.Delete}
        >
          <span className="icon--red mr-10">
            <TrashIcon />
          </span>
          {Placeholder.Delete}
        </Link>
      </div>
    </div>
  )
}

export default DoorBlockListItem
