export default async function checkMixedImageDimensions(imageUrls) {
  /**
 * Checks if a set of images have mixed dimensions or if they follow specific aspect ratios
 * @param {Array.<string>} imageUrls List of image url strings
 * @returns A boolean
 */

  let hasMixedDimensions = false
  let oneToOne = false
  let sixteenToNine = false
  
  const checkImage = (url) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        const { width, height } = img
        const aspectRatio = width / height
  
        if (aspectRatio === 1) {
          oneToOne = true
        } else if (Math.abs(aspectRatio - (16 / 9)) < 0.03) {
          sixteenToNine = true
        } else {
          hasMixedDimensions = true
        }
  
        resolve()
      }
  
      img.onerror = () => {
        console.error(`Error loading image: ${url}`)
        resolve()
      }
  
      img.src = url
    })
  }
  
  // Loop through imageUrls array
  const imagePromises = imageUrls.map((url) => checkImage(url))
  
  try {
    await Promise.all(imagePromises)
  
    if (hasMixedDimensions || (oneToOne && sixteenToNine)) {
      return true // Images have mixed dimensions
    } else {
      return false // Images have consistent dimensions
    }
  } catch (error) {
    console.error(error)
  }
}
  