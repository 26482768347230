import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'

const CustomizationMenuListItem = (props) => {
  const menuId = props.match.params.menuid
  const menuItemId = props.match.params.menuitemid
  const customizationMenu = props.customizationMenu
  return (
    <div className="list-item">
      <div className="list-item__element">
        <Link className="link-edit" to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationMenu.customizationId}/submenu/${customizationMenu.id}/items/`}>{customizationMenu.name}</Link>
      </div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={customizationMenu.active} name="active" disabled="disabled" />
        <span className="label form-label hidden-up">{Placeholder.Active}</span>
      </div>
          
      <div className="list-item__element">
        <Link className="link-edit" to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationMenu.customizationId}/submenu/${customizationMenu.id}/edit/`}>{Placeholder.Edit}</Link>
        <Link className="link-edit" to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationMenu.customizationId}/submenu/${customizationMenu.id}/delete/`}>{Placeholder.Delete}</Link>
      </div>
    </div>
  )
}

export default CustomizationMenuListItem