import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import Checkbox from '../layout/Checkbox'
import { GetCssInput } from '../layout/Input'
import { ProntoContainer } from '../layout/Containers'
import { ClickButton, SaveButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Select from "../layout/Select"
import MediaLibraryUploadInput from "../UI/MediaLibraryUploadInput"


const Group = (props) => {
  const mode = props.mode
  const type = props.type
  const history = props.history
  const groupId = props.groupId ? props.groupId : props.match.params.groupid
  const storeId = props.match.params.storeid
  const referenceEntityId = props.referenceEntityId ? props.referenceEntityId : props.match.params.referencentityid
  const [data, setData] = useState({ type: type, referenceEntityId: referenceEntityId, selectedMenu: "0" })
  const [menus, setMenus] = useState([])
  const [showComponent, setShowComponent] = useState(false)
  const [showMenus, setShowMenus] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      var groupType = 0
      if (groupId) {
        const response = await getData(
          '/api/group/' + groupId
        )
        const result = await response
        setData(result)
        groupType = result.type
      }
      if (type === 1 && mode == Mode.Create || groupType === 5) {
        const menuResponse = await getData(`api/stores/${storeId}/selectableRestaurants`)
        const menuResult = await menuResponse
        setMenus(menuResult)
        setShowMenus(true)
      }


      setShowComponent(true)
    }

    fetchData()
  }, [])

  function getLabel(type) {
    if (type === 1) {
      return Placeholder.Restaurants
    } else if (type === 2) {
      return Placeholder.Themes
    } else if (type === 3) {
      return Placeholder.CookByYourself
    }
    return ""
  }

  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/group/edit'
    }
    if (mode === Mode.Create) {
      return '/api/group/create'
    }
    if (mode === Mode.Delete) {
      return '/api/group/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if (url) {
      const response = await postData(
        url, data
      )
      return await response

    } else {
      console.error("url not defined")
    }
  }
  const handleImageChange = (value) => {
    setData(() => ({ ...data, imageUrl: value }))
  }
  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <div>
      <BackButton backLabel={getLabel(type)} editPage={true} {...props}>{data.name}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            {showComponent &&
              <MediaLibraryUploadInput storeId={storeId} imageUrl={data.imageUrl || ''} onChange={(e) => handleImageChange(e.target.value)} mode={mode} />
            }
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.name} placeholder={Placeholder.Name} />
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="orderingNumber" defaultValue={data.orderingNumber} placeholder={Placeholder.Sort} />
            <div className="row">
              {data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              {!data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>

          </div>
          <div className="row">
            {menus && showMenus &&
              <Select data={menus} name="selectedRestaurant" handleEvent={handleInputChange}
                defaultOption="Välj produkter själv" defaultValue={data && data.selectedRestaurant ? data.selectedRestaurant : "0"}
                disabled={mode != Mode.Create} />}
          </div>
          <div className="row">
            <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete : Placeholder.SaveButton} onClick={handlePostData} />
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack} />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )


}


export default Group

