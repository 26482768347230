import React from 'react'
import { Link } from 'react-router-dom'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import { TopAddButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import { ItemListColumn } from '../ItemList/ItemList'
import LoadingContainer from '../layout/LoadingContainer'
import CheckboxV2 from '../layout/CheckboxV2'
import { InfoIcon } from '../layout/Icons'
import { defaultNotifications } from '../../utilis/Notifications'
import SortableList from '../ItemList/SortableList'
import checkMixedImageDimensions from '../../utilis/CheckMixedImageDimensions'
import MessageBox from '../layout/MessageBox'
import './Catering.css'

function CategoryDetail({ match, ...props }) {
  const {
    params: { storeid, categoryId },
  } = match || {}
  const [category, setCategory] = React.useState({})
  const [fetching, setFetching] = React.useState(false)
  const prevCategory = React.useRef({})

  React.useEffect(() => {
    let componentMounted = true

    async function getCategory() {
      setFetching(true)
      try {
        const res = await getServiceData('FoodApi', 'v1', `/catering/${storeid}/categories/${categoryId}`)

        if (res.ok && componentMounted) {
          setCategory(prev => ({
            ...prev,
            ...res.data,
            products: [
              ...(prev.products || []),
              ...res.data.products.sort((a, b) => a.sortOrder - b.sortOrder)
            ]
          }))
        }
      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }

    if (categoryId) {
      getCategory()
    }
    return () => {
      componentMounted = false
    }
  }, [categoryId, storeid])

  async function onChangeActive(checked, productId) {
    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/products/${productId}/active/${checked}`,
      null,
      'PATCH'
    )
    if(res.ok) {
      let newProducts = [...category.products]
      const ind = newProducts?.findIndex(product => product.id === productId)
      newProducts[ind].active = checked
      setCategory((prev) => ({...prev, products: newProducts}))
    } else {
      defaultNotifications.error(Placeholder.Category, res.statusText)
    }
  }

  async function onSortCategory(sortedCategories) {
    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/products/categories/${categoryId}/sort`,
      sortedCategories,
      'PATCH'
    )
    if (!res.ok) {
      defaultNotifications.error(Placeholder.Category, 'Något gick fel vid sortering av kategorier')
    }
  }

  const handleDragEnd = (result) => {
    const { isSorted, sortedItems } = result
    
    if (!isSorted) {
      return
    }

    let sortedCategories = sortedItems.map((item) => ({ id: item.id, sortOrder: item.sortOrder }))
    onSortCategory(sortedCategories)

    const newGroup = {
      ...category,
      products: sortedItems,
    }

    setCategory(
      newGroup
    )
  }

  React.useEffect(() => {
    let componentMounted = true
    
    if (!category.products) return

    prevCategory.current = category
  
    const handleMixedImages = async () => {
      const hasMixedDimensions = await checkMixedImageDimensions(category.products.map(product => product.imageUrl))

      const updatedCategory = {
        ...category,
        hasMixedDimensions,
      }

      if (componentMounted && hasMixedDimensions !== category.hasMixedDimensions) {
        setCategory(updatedCategory)
        prevCategory.current = updatedCategory
      }
    }
    handleMixedImages()
    return () => {
      componentMounted = false
    }
  }, [category.products])

  return (
    <div>
      <ProntoContainerTop className='scroll-x-mobile'>
        {category && category.products && category.products.length > 0 ? (
          <TopAddButton
            className='flex-right btn-control btn--red'
            to={`/stores/${storeid}/catering/categories/${categoryId}/products/create`}
            title={Placeholder.Catering.Product.CreateHeadline}
          />
        ) : null}
        <BackButton editPage={false} match={match} to={`/stores/${storeid}/catering/categories`} {...props}>
          <span>{fetching ? 'Hämtar kategorinamn' : category.name || ''}</span>
        </BackButton>
        <div>
          <LoadingContainer loading={fetching}>
            {!category.active && category.products && category.products.length > 0 && (
              <div className='category-info-wrapper'>
                <div className='inactive-category-info'>
                  <InfoIcon className='info-icon' color='purple' />
                  <div className='info-text-wrapper'>
                    <p className='info-heading'>OBS! Denna kategori är inaktiverad</p>
                    <p className='info-text'>
                      Produkterna är därför inte synliga på webbplatsen (om produkten inte även är placerad i en annan
                      kategori som är aktiv)
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div>
              <SortableList
                data={category.products}
                stickyHeader={true}
                emptyHeading='Än finns det inga produkter i denna kategori.'
                emptyLinkUrl={`/stores/${storeid}/catering/categories/${categoryId}/products/create`}
                emptyLinkText={`${Placeholder.Create} produkt`}
                onDragEnd={handleDragEnd}
                droppableId={category.id}
                shadow={false}
                renderRow={(item) => (
                  <React.Fragment>
                    <ItemListColumn id='name' label='Namn' columns="8">
                      <Link to={`/stores/${storeid}/catering/categories/${categoryId}/products/${item.id}`}>
                        {item.name}
                      </Link>
                    </ItemListColumn>
                    <ItemListColumn id="categoryCount" columns="2">
                      {item.categoryCount > 1 &&
                              <span>Visas i flera kategorier</span>
                      }
                    </ItemListColumn>
                    <ItemListColumn id='active' label={Placeholder.Active} columns='1'>
                      <CheckboxV2
                        id={`active-${item.id}`}
                        checked={item.active}
                        onChange={(e) => onChangeActive(e.target.checked, item.id)}
                      />
                    </ItemListColumn>
                    <ItemListColumn id='add-variant' narrow>
                      <Link to={`/stores/${storeid}/catering/categories/${categoryId}/products/${item.id}#varianter`}>
                          + Lägg till variant
                      </Link>
                    </ItemListColumn>
                  </React.Fragment>
                )}
              />
              <br />
              <Link className='editCategoryLink' to={`/stores/${storeid}/catering/categories/${categoryId}/edit`}>
                {Placeholder.Edit}
              </Link>
              {category.hasMixedDimensions && (
                <MessageBox
                  type="info"
                  withTitle={true}
                  title={`OBS! Produktbilder i ${category.name} har olika format`}
                  message="För bästa visuella användarupplevelse rekommenderar vi att använda endast ett bildformat, vi rekommenderar 16:9."
                />
              )}
            </div>
          </LoadingContainer>
        </div>
      </ProntoContainerTop>
    </div>
  )
}

export default CategoryDetail
