import React from "react"
import { Link as RouterLink } from "react-router-dom"
import css from "./Link.module.css"

function Link({ to, className, ...props }) {
  const cssClass = className ? `${css.link} ${className}` : css.link
  const relative = to.includes('..') ? 'path' : ''
  return (
    <RouterLink className={cssClass} to={to} {...props} relative={relative}>
      {props.children}
    </RouterLink>
  )
}

export default Link