import React from "react"
import InputField from "./InputField"
import CheckboxV2 from "../layout/CheckboxV2"
import "./Checkbox.css"
import AdditionalInfo from "../layout/AdditionalInfo"

function Checkbox({ label, info, ...props }) {
  return (
    <InputField className="checkbox">
      <CheckboxV2 label={label} {...props} />
      {info && <AdditionalInfo info={info} />}
    </InputField>
  )
}

export default Checkbox