import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'


const MenuItemsListItem = (props)=> {
  const item = props.data
  const menuId = props.match.params.menuId
  const menuItemId = props.match.params.menuItemId

  return (
    <div className="list-item">
      <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt="" /></div>
      <div className="list-item__element">{item.displayName}</div>
      <div className="list-item__element">{item.price}kr</div>

      <div className="list-item__element">
        <Link className="link-edit" to={`/menus/${menuId}/items/${menuItemId}/extras/${item.id}/edit/`}>{Placeholder.Edit}</Link>
        <Link className="link-edit" to={`/menus/${menuId}/items/${menuItemId}/extras/${item.id}/delete/`}>{Placeholder.Delete}</Link>
      </div>          
    </div>
  )
  
}

export default MenuItemsListItem