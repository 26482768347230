import React from 'react'
import { Link } from "react-router-dom"
import NoItemsBox from '../layout/NoItemsBox'
import css from './ItemList.module.css'
import { shared } from './ItemListShared'
import withRouter from '../../utilis/withRouter'

const ItemList = withRouter(({
  data,
  renderRow,
  showEmpty = true,
  emptyHeading,
  emptyLinkUrl,
  emptyLinkText,
  showHeader = true,
  shadow = true,
  stickyHeader = false,
  arrowList = false,
  navigationUrl,
  idProp = 'id',
}, props) => {
  const className = shared.buildClassName(css, shadow, stickyHeader, arrowList, props.className)
  const [columns, setColumns] = React.useState([])

  React.useEffect(() => {
    if (data && data.length > 0) {
      setColumns(shared.getColumns(renderRow))
    }
  }, [data, renderRow])

  function getNavigationUrl(item) {
    if (!navigationUrl) return ''
    if (typeof navigationUrl === 'string' || navigationUrl instanceof String) {
      return navigationUrl
    } else if (typeof navigationUrl === 'function') {
      return navigationUrl(item)
    }
    return ''
  }

  return (
    <>
      {data && data.length > 0 ? (
        <table className={className} >
          {showHeader &&
            <thead>
              <ItemListHeaderRow
                columns={columns}
                arrowList={arrowList}
              />
            </thead>
          }
          <tbody>
            {data.map((item, i) => (
              <ItemListRow
                key={item[idProp]}
                navigationUrl={navigationUrl ? getNavigationUrl(item) : ''}
                renderRow={renderRow}
                columns={columns}
                dataItem={item}
                dataItemIndex={i}
                arrowList={arrowList}
              />
            ))}
          </tbody>
        </table>
      ) : (
        showEmpty && <NoItemsBox heading={emptyHeading} linkUrl={emptyLinkUrl} linkText={emptyLinkText} />
      )}
    </>
  )
})


export const ItemListColumn = (props) => {
  return props.children
}

export const ItemListHeaderRow = ({
  columns,
  arrowList
}) => {
  return (
    <tr className={css["item-list-row"]}>
      {columns.map((column) => (
        <ItemListCell key={column.id} isHeader={true} {...column}>
          {column.label}
        </ItemListCell>
      ))}
      {arrowList &&
        <ItemListCell isHeader={true} narrow />
      }
    </tr>
  )
}

export const ItemListRow = ({
  navigationUrl,
  renderRow,
  columns,
  dataItem,
  dataItemIndex,
  arrowList,
  extraColumns,
  innerRef,
  ...props
}) => {
  const className = props.className ? `${css["item-list-row"]} ${props.className}` : css["item-list-row"]
  const trProps = {...props}

  let RootTag = 'tr'
  if (arrowList) {
    RootTag = Link
    trProps.to = navigationUrl
  }

  return (
    <RootTag className={className} ref={innerRef} {...trProps}>
      {columns.length > 1 ? (
        renderRow(dataItem, dataItemIndex).props.children.map((child) => (
          <ItemListCell key={child.props.id} {...child.props}>
            {child}
          </ItemListCell>
        ))
      ) : (
        <ItemListCell key="column" {...renderRow(dataItem, dataItemIndex).props.children.props}>
          {renderRow(dataItem, dataItemIndex).props.children}
        </ItemListCell>
      )}
      {arrowList &&
        <ItemListCell narrow>
          <img src="/arrow-small.png" alt="arrow" />
        </ItemListCell>
      }
      {extraColumns &&
        extraColumns()
      }
    </RootTag>
  )
}


export const ItemListCell = ({isHeader, narrow, columns, className, children, ...props}) => {
  const RootTag = isHeader ? 'th' : 'td'

  function buildClassName() {
    let newClassName = ""
    if (narrow) {
      newClassName += ` ${css["narrow"]}`
    }
    if (columns) {
      newClassName += ` ${css["col-" + columns]}`
    }
    if (className) {
      newClassName += ' ' + className
    }
    return newClassName.trim()
  }

  return (
    <RootTag className={buildClassName()} {...props}>
      {children}
    </RootTag>
  )

}

export default ItemList