import React from 'react'
import Placeholder from '../../components/layout/Placeholder'
import ContentContainer from '../../components/layout/ContentContainer'
import ItemList, {ItemListColumn} from '../../components/ItemList/ItemList'

const ReportsList = (props) => {
  const storeId = props.match.params.storeid

  const orderReports = [
    {
      name: Placeholder.Reports.AccountingReport.Name,
      to: `/stores/${storeId}/reports/onDemand/`
    },
    {
      name: Placeholder.Reports.CsvReport.Name,
      to: `/stores/${storeId}/reports/csv/`
    },
    {
      name: Placeholder.Reports.DeliveryExcelReport.Name,
      to: `/stores/${storeId}/reports/deliveryexcel/`
    },
    {
      name: Placeholder.Reports.OrderStatusReport.Name,
      to: `/stores/${storeId}/reports/orderstatus/`
    },
    {
      name: Placeholder.Reports.OrderSalesReport.Name,
      to: `/stores/${storeId}/reports/orderSales/`
    },
  ]

  return (
    <ContentContainer
      title={Placeholder.Reports.Reports}
      backDisabled={!storeId}
      fetchingData={false}
      backTo={`/stores/${storeId}/view`}
    >
      <h3>{Placeholder.Orders}</h3>
      <ItemList
        data={orderReports}
        idProp="name"
        showHeader={false}
        arrowList={true}
        shadow={false}
        navigationUrl={(item) => item.to}
        renderRow={(item) =>
          <React.Fragment>
            <ItemListColumn id="name" label="Namn">
              {item.name}
            </ItemListColumn>
          </React.Fragment>
        }>
      </ItemList>
    </ContentContainer>
  )
}

export default ReportsList
