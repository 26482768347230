import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

import Checkbox from '../layout/Checkbox'
import { GetCssInput } from '../layout/Input'
import Mode from '../../utilis/Mode'
import { ClickButton, SaveButton } from '../layout/Button'

const CustomizationMenu = (props) => {
  const mode = props.mode
  const history = props.history

  const menuItemId = props.match.params.menuitemid
  const subMenuId = props.match.params.submenuid
  const customizationId = props.match.params.customizationid
  const [data, setData] = useState({
    menuItemId: menuItemId,
    customizationId: customizationId,
  })

  useEffect(() => {
    const fetchData = async () => {
      if (subMenuId && customizationId) {
        const response = await getData(
          `/api/customizations/${customizationId}/submenu/${subMenuId}/`,
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
    console.log(data)
  }

  const handlePostData = async () => {
    const url = getPostUrl()
    if (url) {
      const response = await postData(url, data)
      return await response
    } else {
      console.error('url not defined')
    }
  }

  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/customizations/submenu/edit'
    }
    if (mode === Mode.Create) {
      return '/api/customizations/submenu/create'
    }
    if (mode === Mode.Delete) {
      return '/api/customizations/submenu/delete'
    }

    return undefined
  }

  return (
    <div>
      <BackButton
        backLabel={'Anpassning'}
        {...props}
        editPage={true}
      >{`${Placeholder.Create} anpassningsgrupp`}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="name"
              defaultValue={data.name}
              placeholder={Placeholder.Name}
            />

            <div className="row">
              {data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              {!data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>
          </div>
          <div className="row">
            <SaveButton
              className="btn--black mr-10"
              title={
                Mode.Delete === mode
                  ? Placeholder.Delete
                  : Placeholder.SaveButton
              }
              onClick={handlePostData}
            />
            <ClickButton
              className="btn--white"
              title={Placeholder.Cancel}
              onClick={history.goBack}
            />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default CustomizationMenu
