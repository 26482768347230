import React, { useState, useEffect } from 'react'
import { getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import DoorListItem from './DoorListItem'

const Doors = (props)=> {
  const storeId = props.match.params.storeid
  const [doors, setDoors] = useState( [] )

  useEffect(() => {
    const fetchData = async () => {  
      if(storeId){
        const response = await getServiceData('StoreApi', 'v1', `/door/store/${storeId}`)
        setDoors(response.data)
      }
    }
    fetchData()
  },[])
  return (
    <ProntoContainerTop>
      <BackButton disabled={false} to="../unmanned"  {...props}>{Placeholder.Doors}</BackButton>
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/doors/create/`} title={`${Placeholder.Create} dörr`}/>

      <ul className="no-style arrow-list">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.IsEntrance}</div>
            <div className="list-item__element label hidden-down">{Placeholder.IsExit}</div>
            <div className="width14"></div>
          </div>
        </li>
        {doors && doors.map((item) => (
          <li key={item.id}>
            <DoorListItem data={item}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Doors