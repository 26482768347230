import React from "react"
import css from "./ValidationErrorMessage.module.css"

function ValidationErrorMessage({ 
  message,
  style
}) {
  return (
    <p style={style} className={css.errorMessage}>
      {message}
    </p>
  )
}

export default ValidationErrorMessage