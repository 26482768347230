import React from 'react'
import { TopAddButton } from '../layout/Button'
import { getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import LimitedDeliveryPeriodListItem from './LimitedDeliveryPeriodListItem'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import styles from './LimitedDeliveryPeriodListItem.module.css'
import GridItem from '../Grid/GridItem'

const LimitedDeliveryPeriods = (props) => {
  const [data, setData] = React.useState([])
  const storeId = props.match.params.storeid

  React.useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        try {
          const response = await getServiceData(
            'FoodApi',
            'v1',
            `/catering/${storeId}/limitedDeliveryPeriods`,
          )
          if (response.ok) {
            const result = await response.data
            setData(result)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

    fetchData()
  }, [storeId])

  return (
    <ProntoContainerTop>
      <TopAddButton
        className="flex-right btn-control btn--red"
        to={`/stores/${storeId}/limited-delivery-period/create/`}
        title={
          Placeholder.Catering.LimitedDeliveryPeriod.AddLimitedDeliveryPeriod
        }
      />
      <BackButton to={`/stores/${storeId}/catering`} {...props}>
        {Placeholder.Catering.LimitedDeliveryPeriod.Heading}
      </BackButton>
      <ul className="no-style drop-shadow">
        <li key={'list-header'}>
          <div className="list-item list-item__heading">
            <GridItem cols={'4'}>
              <div className={`${styles.label} list-item__element`}>
                {Placeholder.Catering.LimitedDeliveryPeriod.DeliveryPeriod}
              </div>
            </GridItem>
            <GridItem cols={'4'}>
              <div className={`${styles.label} list-item__element`}>
                {Placeholder.Date}
              </div>
            </GridItem>
            <GridItem cols={'3'}>
              <div
                className={`${styles.label} ${styles.small} list-item__element`}
              >
                {Placeholder.Catering.LimitedDeliveryPeriod.ConnectedProducts}
              </div>
            </GridItem>
          </div>
        </li>
        {data &&
          data?.map((item) => (
            <li key={item.id}>
              <LimitedDeliveryPeriodListItem data={item} {...props} />
            </li>
          ))}
      </ul>
    </ProntoContainerTop>
  )
}

export default LimitedDeliveryPeriods
