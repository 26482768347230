import React, { useState, useEffect } from 'react'
import {  getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import ContentContainer from "../layout/ContentContainer"
import LinkButton from '../../components/UI/LinkButton'
import { PlusIcon } from '../../components/layout/Icons'
import ItemList, { ItemListColumn } from "../ItemList/ItemList"

const Employees = () => {
  const [employees, setEmployees] = useState([])
  const [fetchingData, setFetchingData] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      const response = await getServiceData('AuthApi', 'v1', '/employee')
      if (response.ok) {
        setEmployees(response.data)
      }
      setFetchingData(false)
    }

    fetchData()
  }, [])

  function getChannelsText(channels) {
    return channels?.map(c => c.channelName).join(', ')
  }

  return (
    <ContentContainer
      title={Placeholder.Employees.Title}
      fetchingData={fetchingData}
      backTo="../"
      actions={() =>
        <LinkButton
          type="save"
          to="create/"
          Icon={PlusIcon}
        >
          {Placeholder.Employees.Add}
        </LinkButton>
      }
    >
      <ItemList
        data={employees}
        idProp="key"
        arrowList
        navigationUrl={employee => `${employee.key}/`}
        stickyHeader={true}
        renderRow={employee =>
          <>
            <ItemListColumn id="key" label={Placeholder.Employees.Key} columns={2}>{employee.key}</ItemListColumn>
            <ItemListColumn id="description" label={Placeholder.Description}>{employee.description}</ItemListColumn>
            <ItemListColumn id="channels" label={Placeholder.Channels} columns={4}>{getChannelsText(employee.channels)}</ItemListColumn>
            <ItemListColumn id="stores" label={Placeholder.Employees.Stores} columns={2}>{employee.stores?.length}</ItemListColumn>
          </>
        }
      >
      </ItemList>
    </ContentContainer>
  )
}
export default Employees
