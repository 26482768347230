import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import { GetCssInput } from '../layout/Input'
import Checkbox from '../layout/Checkbox'
import {ProntoContainer} from '../layout/Containers'
import {ClickButton,SaveButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Select from "../layout/Select"


const ProntoProduct = (props) => {
  const mode = props.mode
  const history = props.history
  const productId = props.match.params.productid
  const categoryId = props.match.params.categoryid
  const [data, setData] = useState({})
  const [categories, setCategories] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      const categoryResponse = await getData(
        `/api/categories/`
      )
      const categoryResult = await categoryResponse
      const items = categoryResult.map((c) => ({ id: c.id, value: c.id, name: c.name}))
      setCategories(items)

      if (productId && categoryId) {
        const response = await getData(
          `/api/product/${productId}/categoryId/${categoryId}`
        )
        const result = await response
        setData(result)
        setDataLoaded(true)
      } 
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/product/edit'
    }
    if (mode === Mode.Delete) {
      return `/api/product/delete`
    }

    return undefined
  }
  const handlePostData = async () => {
  
    const url = getPostUrl()
    if (url) {
      const response = await postData(
        url, data
      )
      return await response
     
    } else {
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }

  return (

    <div>
      <BackButton modePrefix={mode} editPage={true} backLabel={Placeholder.ProntoProducts} {...props}>{Placeholder.Product}</BackButton>
      <ProntoContainer>
        {data && dataLoaded && <div className="form">
          <div className="form-section c-6 pr-10">
            <div className="row">
              <input className="input mr-10" type="text" name="ean" defaultValue={data.ean} disabled/>
              <span className="label form-label">{Placeholder.Ean}</span>  
            </div>
            <div className="row">
              <input className="input mr-10" type="text" name="gtin" defaultValue={data.gtin} disabled/>
              <span className="label form-label">{Placeholder.Gtin}</span>  
            </div>
            {categories && data.categoryId && <div className="row">
              <Select data={categories} name="categoryId" handleEvent={handleInputChange} defaultOption="Välj kategori"
                defaultValue={data.categoryId} />
            </div>
            }
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="imageUrl" defaultValue={data.imageUrl} placeholder={Placeholder.ImageUrl} />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="groceryDisplayName" defaultValue={data.groceryDisplayName} placeholder={Placeholder.GroceryDisplayName} />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="brandName" defaultValue={data.brandName} placeholder={Placeholder.Brand} />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="nutrientFacts" defaultValue={data.nutrientFacts} placeholder="Näringsinnehåll" />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="ingredient" defaultValue={data.ingredient} placeholder="Ingredienser" />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="countryOfOrigin" defaultValue={data.countryOfOrigin} placeholder="Land" />
            {/* <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="price" defaultValue={data.price} placeholder={Placeholder.RecomendedPrice} /> */}
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="grossWeight" defaultValue={data.grossWeight} placeholder="Grossvikt" />
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="netWeightVolume" defaultValue={data.netWeightVolume} placeholder="Nettovikt" />
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="meanWeight" defaultValue={data.meanWeight} placeholder="Rapporteringsvikt i gram" />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="uomCode" defaultValue={data.uomCode} placeholder="UomCode" />

            <div className="row">
              { data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange}/>     }
              { !data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange}/>     }
              <span className="label form-label">{Placeholder.Active}</span>  
            </div>
            {(mode === Mode.Edit) && <div className="row">
              { data.id && <Checkbox checked={data.isDeleted} name="isDeleted" handleEvent={handleInputChange}/>     }
              { !data.id && <Checkbox checked={data.isDeleted} name="isDeleted" handleEvent={handleInputChange}/>     }
              <span className="label form-label">{'raderad'}</span>  
            </div>}
          </div>
          <div className="row">
            <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete: Placeholder.SaveButton} onClick={handlePostData}/>
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
          </div>
        </div>}
      </ProntoContainer>
    </div>
  )

}


export default ProntoProduct

