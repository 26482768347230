import React from 'react'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import ArrowListItem from '../layout/ArrowListItem'
import {
  CalenderIcon,
  PlusIcon
} from '../layout/Icons'




const Unmanned = (props)=> {
  const storeId = props.match.params.storeid
  var items = [
    { url: `/stores/${storeId}/doors/`, name: Placeholder.Doors, icon: <PlusIcon /> },
    { url: `/stores/${storeId}/gates/`, name: Placeholder.Gates, icon: <PlusIcon /> },
    { url: `/stores/${storeId}/targetgroup/`, name: Placeholder.TargetGroup, icon: <PlusIcon /> },
    { url: `/stores/${storeId}/unmannedsettings/`, name: Placeholder.UnmannedSettings.Title, icon: <PlusIcon /> },
    { url: `/stores/${storeId}/doorevents/`, name: Placeholder.DoorEvents, icon: <CalenderIcon /> },
  ]

  return (
    <ProntoContainerTop>
      <BackButton disabled={false} {...props} to={`/stores/${storeId}/view`}>{Placeholder.Unmanned}</BackButton>
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/doors/create/`} title={`${Placeholder.Create} dörr`}/>

      <ul className="no-style arrow-list">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Active}</div>
            <div className="width14"></div>
          </div>
        </li>
        {items.map((item, index) => (
          <ArrowListItem key={index} url={item.url} icon={item.icon} name={item.name} />
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Unmanned