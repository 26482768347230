import React from "react"
import { NewWindowIcon } from "../layout/Icons"
import css from './MediaLibraryUploadInfo.module.css'

function MediaLibraryUploadInfo({ channel, storeId, aspect }) {
  const aspectForUrl = aspect ? `?aspect=${aspect.replace(":", "_")}` : ''
  const storeIdSlug = storeId ? `/${storeId}` : ''
  const channelSlug = channel && storeId ? `/${channel}/ladda-upp${aspectForUrl}` : ''


  return (
    <p className={css.mediaLibraryUploadInfoLink} >
      <a href={`https://media.icapronto.se${storeIdSlug}${channelSlug}`} target="_blank" rel="noreferrer">
        {`Ladda upp eller använd befintlig bild${aspect ? ' (format ' + aspect + ')' : ''}`}
        <NewWindowIcon color='var(--redDark)' />
      </a>
      - Kopiera URL och klistra in nedan
    </p>
  )
}

export default MediaLibraryUploadInfo