import React from 'react'
import { formatAmount } from './../../utilis/PricingUtils'

const DiscountElement = ({ subTotal, discount, bottleDepositPrice }) => {
  const totalWithDiscount = discount > subTotal ? discount : (parseFloat(subTotal + discount) - bottleDepositPrice)
  return (<React.Fragment><span style={{ 'color': 'red', 'text-decoration': 'line-through' }}>
    {<span style={{ 'color': 'black' }}>{formatAmount(totalWithDiscount)}</span>}
  </span>
  <span style={{ 'color': 'red' }}>{formatAmount(parseFloat(subTotal))}</span>
  </React.Fragment>)
}

const OrderItemPriceV2 = ({ subTotal, discountSubTotal, bottleDepositPrice }) => {

  const discount = discountSubTotal ? discountSubTotal : 0

  return (<div>
    {discount > 0 && <DiscountElement subTotal={subTotal} discount={discount} bottleDepositPrice={bottleDepositPrice}></DiscountElement>}
    {discount <= 0 && <span style={{ 'color': 'black' }}>{formatAmount(subTotal)}</span>}
  </div>)
}

export default OrderItemPriceV2