import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'
import CheckboxV2 from '../layout/CheckboxV2'
import { postData } from '../../services/Ajax'

const MenusListItem = (props) => {
  const [item, setItem] = React.useState(props.data)
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid

  function handleItemChange() {
    postData(
      `/api/menus/${item.id}/active/${!item.active}`,
      {},
      false,
      {},
      'PATCH',
    )
    setItem({ ...item, active: !item.active })
  }

  return (
    <div className="list-item">
      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`/stores/${storeId}/restaurants/${restaurantId}/menus/${item.id}/items`}
        >
          {item.name}
        </Link>
      </div>

      <div
        className="list-item__element list-item__element--s"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <CheckboxV2
          onChange={handleItemChange}
          checked={item.active}
          name="active"
        />
        <span
          className="label form-label hidden-up"
          style={{ marginBottom: 0 }}
        >
          {Placeholder.Active}
        </span>
      </div>

      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`/Stores/${storeId}/Restaurants/${restaurantId}/menus/${item.id}/edit/`}
        >
          {Placeholder.Edit}
        </Link>

        <Link
          className="link-edit"
          to={`/Stores/${storeId}/Restaurants/${restaurantId}/menus/${item.id}/delete/`}
        >
          {Placeholder.Delete}
        </Link>
      </div>
    </div>
  )
}

export default MenusListItem
