import React from 'react'
import Textbox from '../Form/Textbox'
import MediaLibraryUploadInfo from '../UI/MediaLibraryUploadInfo'
import './ImageSelector.css'

function ImageSelector({
  id = 'image',
  name = 'image',
  label = 'Bild',
  storeId,
  imageUrl,
  onChangeImageUrl,
  imageAlt,
  onChangeImageAlt,
  validationRegister,
  validationUrl,
  validationText,
  errors,
  preferredAspectRatio = '16:9',
}) {
  return (
    <div className="imageSelectorContainer">
      <div className="imageAltContainer">
        <div className={!imageUrl ? 'imagePlaceholder' : 'imagePreview'}>
          {imageUrl ? (
            <img
              style={{ width: 'inherit' }}
              src={imageUrl}
              alt={'Vald bild visas här'}
            />
          ) : (
            'Vald bild visas här'
          )}
        </div>
        <div className="textBoxContainer">
          <Textbox
            label={label}
            placeholder="Klistra in URL"
            id={id}
            name={name}
            value={imageUrl}
            errors={errors}
            validation={validationUrl}
            validationRegister={validationRegister}
            onChange={(e) => onChangeImageUrl(e.target.value)}
            beforeTextbox={() => (
              <MediaLibraryUploadInfo
                storeId={storeId}
                channel="icacatering"
                aspect={preferredAspectRatio}
              />
            )}
          />
          <Textbox
            id={`${id}-description`}
            name={`${name}-description`}
            errors={errors}
            validation={validationText}
            validationRegister={validationRegister}
            tooltip={true}
            tooltipText={
              <>
                <p style={{ margin: 0 }}>
                  Bildbeskrivningen används av sökmotorer samt personer som
                  använder skärmläsare, till exempel personer med nedsatt syn,
                  för att förstå innehållet på din webbplats.
                  <br />
                  OBS! Det är enligt tillgänglighetslagen ett krav att bilder
                  ska ha en beskrivande text.
                </p>
              </>
            }
            style={{ width: '800px', height: '70px' }}
            label={'Bildbeskrivning'}
            placeholder="Ex. Grön prinsesstårta med en ros på toppen"
            value={imageAlt}
            onChange={(e) => onChangeImageAlt(e.target.value)}
            afterTextbox={() => (
              <p className="imageHelperText">
                Beskriv kortfattat och sakligt vad bilden innehåller, använd 1 -
                3 meningar (detta hjälper sökmotorer och synskadade förstå vad
                bilden innehåller).
              </p>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageSelector
