import React from 'react'
import Mode from '../../utilis/Mode'
import Placeholder from '../layout/Placeholder'

const  Heading = (props) => {
  const mode = props.mode
  const title = props.title
  if(mode === Mode.Edit){
    return <h1>{Placeholder.Edit} {title}</h1>
  }
  if(mode === Mode.Delete){
    return <h1>{Placeholder.Delete} {title}</h1>
  }
  return <h1>{Placeholder.Create} {title}</h1>
}

export default Heading