import React from 'react'
import { Link } from "react-router-dom"
import Placeholder from "./Placeholder"
import './NoItemsBox.css'

const NoItemsBox = ({ heading = 'Här var det tomt!', linkUrl, linkText = `${Placeholder.Create} ny` }) => {
  return (
    <div className="no-items-box">
      <h2>{heading}</h2>
      {linkUrl &&
        <Link to={linkUrl}>
          {linkText}
        </Link>
      }
    </div>
  )
}

export default NoItemsBox