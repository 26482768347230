import React from "react"
import css from "./Grid.module.css"

function GridItem({children, cols, offset, className, ...props }) {
  let classes = []

  let cols_xs = props["cols-xs"] || 12
  let cols_sm = cols || props["cols-sm"] || 12
  let cols_md = props["cols-md"]
  let cols_lg = props["cols-lg"]
  if (cols_xs) {
    classes.push(getColClassName(cols_xs, "xs"))
  }
  if (cols_sm) {
    classes.push(getColClassName(cols_sm, "sm"))
  }
  if (cols_md) {
    classes.push(getColClassName(cols_md, "md"))
  }
  if (cols_lg) {
    classes.push(getColClassName(cols_lg, "lg"))
  }

  let offset_xs = props["offset-xs"]
  let offset_sm = offset || props["offset-sm"]
  let offset_md = props["offset-md"]
  let offset_lg = props["offset-lg"]
  if (offset_xs) {
    classes.push(getOffsetClassName(offset_xs, "xs"))
  }
  if (offset_sm) {
    classes.push(getOffsetClassName(offset_sm, "sm"))
  }
  if (offset_md) {
    classes.push(getOffsetClassName(offset_md, "md"))
  }
  if (offset_lg) {
    classes.push(getOffsetClassName(offset_lg, "lg"))
  }

  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(" ")}>
      {children}
    </div>
  )
}

function getColClassName(cols, size) {
  let className = ""
  if (cols) {
    if (cols === "auto") {
      className = css[`col-${size}`]
    } else {
      className = css[`col-${size}-${cols}`]
    }
  }
  return className
}

function getOffsetClassName(cols, size) {
  let className = ""
  if (cols) {
    className = css[`col-${size}-offset-${cols}`]
  }
  return className
}

export default GridItem