import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { GetBaseRoute } from '../utilis/Enviroment'
// import thunk from 'redux-thunk'

export default function configureReduxStore(initialState) {
  const history = createBrowserHistory({ basename: GetBaseRoute() })

  const rootReducer = {}

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  })

  store.history = history

  return store
}
