
import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import {ProntoContainer,ProntoContainerTop} from '../layout/Containers'
import Select from '../layout/Select'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'

const FeedImport = (props)=> {
  const storeId = props.storeid ? props.storeid: props.match.params.storeid

  const [store, setStore] = useState({})
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [data, setData] = useState({
    importStarted: false, 
    isReady : false,
    message:"Uppdatera"} )

  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        //Store Data
        const response = await getData(
          '/api/import/store/' + storeId
        )
        const result = await response
        setStore(result)
      }
      
      fetchData()
    }
  }, [])


  const handleInputChange = (event) => {
    event.persist()

    console.log(data)
    data.isReady = true
    setData({...data})
    setSelectedChannel(event.target.value)
    console.log(data)

  }
  const handlePostData = async (event) => {
    event.persist()
    if(storeId && selectedChannel){
      const url = `/api/import/store/${storeId}/feed/`
      if(url){
        data.message = "Startar import..."
        data.importStarted = true
        setData({...data})
        const d = {
          StoreId : storeId,
          ChannelTypeId : selectedChannel,
          SourceBms : store.customerNumber
        }
        const response = await postData(
          url,d,true
        )
        var result = await response
        setData({...result})
      }else{
        console.error("url not defined")
      }
    }else{
      alert("välj channel")
    }
    
  }


  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Uppdatera productfeed </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section">
    
              
            <div className="row">
              { store && store.channels && store.channels.length > 0 &&  <Select data={ store.channels} name="channelId" handleEvent={handleInputChange} defaultOption="Välj" defaultValue={selectedChannel}/>   }
              <span className="label form-label ml-10">Kanal</span>
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={data.message} disabled={data && !data.isReady} onClick={handlePostData}/>
            </div>
            {data && data.failMessage && <div className="row">
              {data.failMessage}
            </div>}
          </div>
        </div>
      </ProntoContainer>
              
        
              
    </ProntoContainerTop>
  )

}


export default FeedImport
