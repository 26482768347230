import React from 'react'
import { CheckIcon } from './Icons'
import css from './CheckboxV2.module.css'

/**
 * New and better look for checkboxes. Should be used instead of the old Checkbox component.
 * */
const CheckboxV2 = ({
  disabled,
  name,
  id,
  onChange,
  checked,
  label,
  title,
  readonly,
  ...props
}) => {
  function onChangeInternal(e) {
    if (onChange) {
      onChange(e)
    }
  }

  var labelClassNames = css.checkboxV2
  if (disabled) {
    labelClassNames += ` ${css.disabled}`
  }
  if (readonly) {
    labelClassNames += ` ${css.readonly}`
  }

  return (
    <label className={labelClassNames} title={title}>
      <input
        id={id}
        name={name}
        disabled={disabled}
        type='checkbox'
        checked={!!checked}
        onChange={(e) => onChangeInternal(e)}
        {...props}
      />
      <span className={css.check}>
        <CheckIcon className={css["check-icon"]} color='#fff' />
      </span>
      {label && <span className={css["checkbox-label"]}>{label}</span>}
    </label>
  )
}

export default CheckboxV2
