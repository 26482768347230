import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'




function ThemeListItem(props) {
  const item = props.data
  const storeId = props.match.params.storeid

  return (
    <div className="list-item">
      <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt=""/></div>
      <div className="list-item__element">{item.name}</div>
      <div className="list-item__element list-item__element--s">
        <Checkbox checked={item.active} name="active" disabled="disabled"/>     
        <span className="label form-label hidden-up">{Placeholder.Active}</span>   
      </div>
      <div className="list-item__element">
        <Link className="link-edit" to={`/stores/${storeId}/themes/${item.id}/edit/`}>{Placeholder.Change}</Link>
        <Link className="link-edit" to={`/stores/${storeId}/themes/${item.id}/products/`}>{Placeholder.Products}</Link>
        <Link className="link-edit" to={`/stores/${storeId}/themes/${item.id}/delete/`}>{Placeholder.Delete}</Link>
      </div>          
    </div>
  )
  
}

export default ThemeListItem

