import React, { useState } from 'react'
import '../../layout/Checkbox.css'

const AllowOnceRule = (props)=> {
  const disabled = props.disabled
  const name = props.name
  const handleEvent = props.handleEvent
  const [isChecked, setIsChecked] = useState(props.checked)

  const handleChange = (event) => {
    const value = !isChecked
    setIsChecked(value)
    if(handleEvent){
      event.target.value = value
      handleEvent(event)
    }
  }

  return (
    <div className="checkbox">
      <input type="checkbox" className="checkbox-round"
        name={name}  disabled={disabled}
        defaultChecked={isChecked} onChange={handleChange}/>
      <label htmlFor={name} className="checkmark"></label>
    </div>
  )

}
export default AllowOnceRule
