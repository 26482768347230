import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import { GetCssInput } from '../layout/Input'
import Checkbox from '../layout/Checkbox'
import { ProntoContainer } from '../layout/Containers'
import { ClickButton, SaveButton } from '../layout/Button'
import MediaLibraryUploadInput from '../UI/MediaLibraryUploadInput'
import BackButton from '../backbutton/BackButton'

const ProntoCategory = (props) => {
  const mode = props.mode
  const history = props.history
  const categoryId = props.match.params.categoryid
  const [data, setData] = useState({})
  const [showComponent, setShowComponent] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (categoryId) {
        const response = await getData('/api/category/' + categoryId)
        const result = await response
        setData(result)
        setShowComponent(true)
      }
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/category/edit'
    }
    if (mode === Mode.Create) {
      return '/api/category/create'
    }
    if (mode === Mode.Delete) {
      return '/api/category/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if (url) {
      const response = await postData(url, data)
      return await response
    } else {
      console.error('url not defined')
    }
  }
  const handleImageChange = (value) => {
    setData(() => ({ ...data, imageUrl: value }))
  }

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <div>
      <BackButton
        modePrefix={mode}
        editPage={true}
        backLabel={Placeholder.Categories}
        {...props}
      >
        {Placeholder.Category}
      </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="name"
              defaultValue={data.name}
              placeholder={Placeholder.Name}
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="orderingNumber"
              defaultValue={data.orderingNumber}
              placeholder={Placeholder.Sort}
            />
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="vgrName"
              defaultValue={data.vgrName}
              placeholder="Vgr namn"
            />
            <input
              className={GetCssInput(mode)}
              type="text"
              onChange={handleInputChange}
              name="businessArea"
              defaultValue={data.businessArea}
              placeholder="Äffarsområde"
            />
            <input
              className={GetCssInput(mode)}
              type="number"
              onChange={handleInputChange}
              name="vgr"
              defaultValue={data.vgr}
              placeholder="Vgrnummer"
            />
            {showComponent && (
              <MediaLibraryUploadInput
                imageUrl={data.imageUrl || ''}
                onChange={(e) => handleImageChange(e.target.value)}
                mode={mode}
                label="Välj bild"
              />
            )}
            <div className="row">
              {data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              {!data.id && (
                <Checkbox
                  checked={data.active}
                  name="active"
                  handleEvent={handleInputChange}
                />
              )}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>
          </div>
          <div className="row">
            <SaveButton
              className="btn--black mr-10"
              title={
                Mode.Delete === mode
                  ? Placeholder.Delete
                  : Placeholder.SaveButton
              }
              onClick={handlePostData}
            />
            <ClickButton
              className="btn--white"
              title={Placeholder.Cancel}
              onClick={history.goBack}
            />
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default ProntoCategory
