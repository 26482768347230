import {Store} from "react-notifications-component"
import NotificationTypes from "./NotificationTypes"

const defaultErrorMessage = "Ett fel inträffade."

export function showNotification(title, message, type, duration = 10000) {
  let notificationType = ""
  if(type === NotificationTypes.SUCCESS) {
    notificationType = "success"
  } else if(type === NotificationTypes.WARNING) {
    notificationType = "warning"
  }else if(type === NotificationTypes.ERROR){
    notificationType = "danger"
  }
  Store.addNotification({
    title: title,
    message: message,
    type: notificationType,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  })
}

export function showCreatedNotification(entityType, entityName) {
  showNotification(entityType, `"${entityName}" skapades.`, NotificationTypes.SUCCESS, 5000)
}

export function showUpdatedNotification(entityType, entityName) {
  showNotification(entityName, "Ändringarna har sparats!", NotificationTypes.SUCCESS, 5000)
}

export function showDeletedNotification(entityType, entityName) {
  showNotification(entityType, `"${entityName}" har tagits bort!`, NotificationTypes.SUCCESS, 5000)
}

export function showErrorNotification(entityType, message) {
  showNotification(entityType, message ? message : defaultErrorMessage, NotificationTypes.ERROR, 5000)
}

export const defaultNotifications = {
  created: showCreatedNotification,
  updated: showUpdatedNotification,
  deleted: showDeletedNotification,
  error: showErrorNotification
}

export default showNotification
