import React, { useState, useEffect } from 'react'
import { TopAddButton } from '../layout/Button'
import { getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import DeliveryProviderListItem from './DeliveryProviderListItem'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import styles from './DeliveryProviderListItem.module.css'

const DeliveryProviders = (props) => {
  const [providerTypes, setProviderTypes] = useState({})
  const [data, setData] = useState([])
  const storeId = props.match.params.storeid

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const providerTypes = await getServiceData('FoodApi', 'v1', `/deliveryProviders/types`)
        setProviderTypes(await providerTypes.data)
        const response = await getServiceData('FoodApi', 'v1', `/stores/${storeId}/deliveryProviders`)
        const result = await response.data
        setData(result)
      }
    }

    fetchData()
  }, [storeId])

  return (
    <ProntoContainerTop>
      <TopAddButton
        className='flex-right btn-control btn--red'
        to={`/stores/${storeId}/deliveryproviders/Create/`}
        title={`${Placeholder.Create} leverantör`}
      />
      <BackButton {...props} to={`/stores/${storeId}/view`}>{Placeholder.Delivery}</BackButton>
      <ul className='no-style drop-shadow'>
        <li>
          <div className='list-item list-item__heading'>
            <div className={`${styles.label} list-item__element`}>{Placeholder.BongTitle}</div>
            <div className={`${styles.label} list-item__element`}>{Placeholder.CustomerTitle}</div>
            <div className={`${styles.label} list-item__element`}>{Placeholder.DeliveryProviderName}</div>
            <div className={`${styles.label} list-item__element`}>{Placeholder.DeliveryProviderType}</div>
            <div className={`${styles.label} ${styles.small} list-item__element`}>{Placeholder.Price}</div>
            <div className={`${styles.label} ${styles.small} list-item__element`}>{Placeholder.Active}</div>
          </div>
        </li>
        {data.map((item) => (
          <li key={item.id}>
            <DeliveryProviderListItem data={item} providerTypes={providerTypes} {...props} />
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
}

export default DeliveryProviders
