import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer} from '../layout/Containers'
import Mode from '../../utilis/Mode'
import {GetCssInput} from '../layout/Input'
import {ClickButton,SaveButton} from '../layout/Button'
import Checkbox from '../layout/Checkbox'
import BackButton from '../backbutton/BackButton'

const Menu = (props)=> {
  const mode = props.mode
  const history = props.history
  const storeId = props.match.params.storeid
  const restaurantId = props.match.params.restaurantid
  const menuId = props.match.params.menuid
  const [data, setData] = useState( {storeId:storeId,restaurantId:restaurantId})
  useEffect(() => {
    const fetchData = async () => {
      if(menuId){
        const response = await getData(
          '/api/menu/'+menuId
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/menus/edit'
    }
    if(mode === Mode.Create){
      return '/api/menus/create'
    }
    if(mode === Mode.Delete){
      return '/api/menus/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
  
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response

    }else{
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }
  return (
    <div>
      <BackButton backLabel={Placeholder.Menus} editPage={true} {...props} truncateTitle={true}>{data.name}</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.name} placeholder={Placeholder.Name}/>
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="imageUrl" defaultValue={data.imageUrl} placeholder={Placeholder.ImageUrl}/>
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="orderingNumber" defaultValue={data.orderingNumber} placeholder={Placeholder.Sort}/>
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="category" defaultValue={data.category} placeholder={Placeholder.Category}/>
            <textarea name="description" className={GetCssInput(mode) + " input-textarea"}onChange={handleInputChange} defaultValue={data.description} placeholder={Placeholder.Description}></textarea>

            <div className="row">
              {data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              {!data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
              <span className="label form-label">{Placeholder.Active}</span>
            </div>

            <div className="row">
              <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
            </div>
          </div>  
        </div>
      </ProntoContainer>
    </div>
  )
  
}


export default Menu

