import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import { ProntoContainer } from '../layout/Containers'
import Placeholder from '../layout/Placeholder'
import Select from '../layout/Select'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'


const CloneStoreProducts = () => {

  const [stores, setStores] = useState([])
  const [sourceStoreId, setSourceStoreId] = useState(null)
  const [destinationStoreId, setDestinationStoreId] = useState(null)
  const [cloneRecepies] = useState(false)

  const [ready, setReady] = useState(false)

  const [cloningOnProgress, setcloningOnProgress] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/stores/'
      )

      const result = await response

      if (result.stores !== "undefined") {
        const storesData = result.stores.map((store) => {
          return {
            id: store.id,
            name: store.name,
            value: store.id,
          }
        })
        setStores(storesData)
        if (storesData.length > 0) {
          setSourceStoreId(storesData[0].id)
          setDestinationStoreId(storesData[0].id)
        }
      }
    }

    fetchData()
  }, [])

  const handleFormSubmit = async () => {

    if (sourceStoreId === destinationStoreId) {
      window.alert("Ställ in olika butiker i dropdownmenyerna")
      return
    }

    if(cloningOnProgress){
      window.alert("Kloning är på gång!")
      return
    }

    var cloneOk = window.confirm("Vill du klona samtliga produkter mellan de valda butikerna?")
    if (cloneOk === true) {

      setcloningOnProgress(true)

      var data = {
        SourceStoreId : sourceStoreId,
        DestinationStoreId : destinationStoreId,
        CloneRecepies : cloneRecepies,
      }
      const response = await postData(
        `api/stores/cloneproducts/`,data
      )

      setcloningOnProgress(false)

      if (response.ok) {
        setReady(true)
      }
    }
  }

  const handleSourceStoreIdInputChange = (event) => {
    setReady(false)
    setSourceStoreId(event.target.value)
  }

  const handleDestinationStoreIdInputChange = (event) => {
    setReady(false)
    setDestinationStoreId(event.target.value)
  }

  return (
    <div>
      <BackButton editPage={true} disabled={true}>{Placeholder.CloneProducts}</BackButton>
      <ProntoContainer>
        <p>Klona alla produkter från en butik till en annan.</p>
        <div className="form">
          <div className="form-section c-3 pr-10">
            <label htmlFor="sourceStoreId" className="label form-label mb-10">{Placeholder.CopyFrom}:</label>
            {stores && <Select data={stores} defaultOption="Välj butik" defaultValue={sourceStoreId} name="sourceStoreId" handleEvent={handleSourceStoreIdInputChange} />}
            <label htmlFor="destinationStoreId" className="label form-label mb-10">{Placeholder.CopyTo}:</label>
            {stores && <Select data={stores} defaultOption="Välj butik" defaultValue={destinationStoreId} name="destinationStoreId" handleEvent={handleDestinationStoreIdInputChange} />}
            <div className="row">
              <ClickButton className="btn--black mr-10" title={Placeholder.CloneProducts} onClick={handleFormSubmit} />
            </div>
            <p>
              {ready ? Placeholder.Ready + '!' : ''}
            </p>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )
}

export default CloneStoreProducts