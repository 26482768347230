import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import { ProntoContainerTop } from '../layout/Containers'
import { TopAddButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'



const ProntoCategories = ()=> {
  const [data, setData] = useState( [] )
  const [isLoading, setIsloading] = useState( true)

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/categories/'
      )
      const result = await response
          
      setData([...result])

      for (const index in result) {
        const item = result[index]
        const countResponse = await getData(
          `/api/categories/${item.id}/count`
        )

        const countResult = await countResponse
        if(countResult){
          result[index].totalProductsCount = countResult.totalProductsCount
                      
          setData([...result])
        }
      }
      setIsloading(false)
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>

      <TopAddButton className="flex-right btn-control btn--red" to={`/categories/create/`} title={`${Placeholder.Create} kategori`} />

      <BackButton disabled={true}>{Placeholder.ProntoProducts}</BackButton>

      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data && data.map(item => (
          <li key={item.id} className="list-item list-item--flex-row-mobile">   
            <div className="list-item__element">
              <Link className="link-edit" to={`/categories/${item.id}/products/`}>{item.name}</Link>({isLoading && item.totalProductsCount  == 0 ? "laddar..": item.totalProductsCount})
            </div>
            <div className="list-item__element list-item__element--s">
              <Checkbox checked={item.active} name="active" disabled="disabled"/>     
              <span className="label form-label hidden-up">{Placeholder.Active}</span>   
            </div>
            <div className="list-item__element">
              <Link className="link-edit" to={`/categories/${item.id}/edit/`}>{Placeholder.Change}</Link>
            </div>                     
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default ProntoCategories