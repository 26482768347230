import React, { useEffect, useContext, useState } from 'react'
import authService from '../../services/api-authorization/AuthorizeService'
import { ApplicationPaths } from '../../services/api-authorization/ApiAuthorizationConstants'
import Placeholder from '../layout/Placeholder'
import { NavMenuContext } from '../Layout'
import HamburgerButton from './HamburgerButton'
import { getEnvirionment } from '../../utilis/GetEnvironment'
import { IcaLogo } from './Icons'
import css from './Header.module.css'

export default function Header() {
  const { menuActive, setMenuActive } = useContext(NavMenuContext)
  const [user, setUser] = useState({})
  const [environment, setEnvironment] = React.useState('')

  useEffect(() => {
    const getUser = async () => {  
      const currentUser = await authService.getUser()
      if(currentUser){
        setUser(currentUser)
      }
    }

    setEnvironment(getEnvirionment(window.location.host))
    getUser()
  }, [])

  function toggleMenu(e) {
    e.preventDefault()
    setMenuActive(!menuActive)
  }

  return (
    <div className={css.container}>
      <header className={`${css.header} ${css[environment]}`}>
        <a href="/" className={css.logo} alt="ICA Now">
          <IcaLogo height="2rem"/> <span className={css.logoText}>Now</span>
        </a>
        {user && user.displayName && 
        <div className={css.account}>
          <span className={css.username}>
            {user.displayName}
          </span>
          <a href={ApplicationPaths.LogoutPath} className="link-edit">{Placeholder.LogOut}</a>
        </div>
        }
        <HamburgerButton onClick={toggleMenu} />
      </header>
      {environment !== 'production' && 
        <div className={`${css.environmentBanner} ${css[environment]}`}>
          {[...Array(30)].map((e, i) => <span key={i}>{environment.toUpperCase()}</span>)}
        </div>
      }
    </div>
  )
};