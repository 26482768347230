import React, { useState, useEffect } from 'react'
import {TopAddButton} from '../layout/Button'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import SystemMessageListItem from './SystemMessageListItem'


const SystemMessages = (props)=> {
  const [data, setData] = useState([])
  const [applications, setApplications] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData(
        '/api/system/messages'
      )
      const result = await response
      setData(result)

      const applicationResponse = await getData(
        'api/system/applications')
      const applicationResult = await applicationResponse
      setApplications(applicationResult)
    }


    fetchData()
  }, [])

  return (
    <ProntoContainerTop >
      <TopAddButton className="flex-right btn-control btn--red" to={'/system/messages/create/'} title={`${Placeholder.Create} systemmeddelande`} />
      <BackButton {...props} to={`/stores`}>Systemmeddelanden</BackButton>
      <ul className="no-style drop-shadow">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">Applikation</div>
            <div className="list-item__element label hidden-down">Datum</div>
            <div className="list-item__element label hidden-down">Från</div>
            <div className="list-item__element label hidden-down">Till</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data.map(item => (
          <li key={item.id}>
            <SystemMessageListItem data={item} application={applications.find(app => app.id == item.application)} {...props} />
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default SystemMessages