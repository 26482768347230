import React, {useEffect, useState} from 'react'
import Placeholder from '../layout/Placeholder'
import {getData, postData} from '../../services/Ajax'
import Mode from '../../utilis/Mode'
import Select from '../layout/Select'
import Checkbox, {parseBool} from '../layout/Checkbox'
import {GetCssInput} from '../layout/Input'
import {ProntoContainer} from '../layout/Containers'
import {ClickButton,SaveButton} from '../layout/Button'
import CampaignUseRule from './rules/CampaignUseRule'
import CampaignCodeRule from './rules/CampaignCodeRule'
import CampaignDateRule from './rules/CampaignDateRule'
import CampaignStoreRule from './rules/CampaignStoreRule'
import CampaignMinimumPurchaseAmountRule from './rules/CampaignMinimumPurchaseAmountRule'
import AllowOnceRule from './rules/AllowOnceRule'
import BackButton from '../backbutton/BackButton'


const Campaign = (props)=> {
  const mode = props.mode
  const history = props.history
  const storeId = props.match.params.storeid
  const campaignId = props.match.params.campaignid

  const [data, setData] = useState({storeId : storeId,codeRule:{},storeRule:{},dateRule:{},useRule:{}, allowOnceRule: {}
  })
  const campaignTypes = [
    { id: 1, name: "Rabatt SEK", value: 10 },
    {id:2, name: "Rabatt %",value: 20},
    {id:3,name: "Gratis leverans",value: 30}]

  useEffect(() => {
    const fetchData = async () => {
      const url = campaignId ? '/api/campaign/' + campaignId : '/api/campaigns/empty'
      const response = await getData(
        url
      )
      const result = await response
      if (storeId && result.selectedStores) {
        const storeIndex = result.selectedStores.findIndex(x => x.value === storeId)
        if (storeIndex >= 0) {
          result.selectedStores[storeIndex].selected = true
        }
      }
      setData(result)
    }

    fetchData()
  }, [])

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/campaign/edit'
    }
    if(mode === Mode.Create){
      return '/api/campaign/create'
    }
    if(mode === Mode.Delete){
      return '/api/campaign/delete'
    }

    return undefined
  }

  const handlePostData = async () => {
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response
    }else{
      console.error("url not defined")
    }
  }
  function ShouldParseInt(name){
    if(name === 'active'){
      return true
    }
    return false
  }
  const handleStoreSelectChange = (event) => {
    event.persist()
    if(event.target.name.includes("__")){
      const values = event.target.name.split("__")
      const value = values[1]
      const storeIndex = data.selectedStores.findIndex(x => x.value === value)
      data.selectedStores[storeIndex].selected =  parseBool(event.target.value)
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    if(event.target.name.includes("__")){
      const values = event.target.name.split("__")
      const rule = values[0]
      const variable = values[1]
      data[rule][variable] = event.target.value
    }
    if(ShouldParseInt(event.target.name)){
      data[event.target.name] = parseBool(event.target.value)
    }
    else{
      data[event.target.name] = event.target.value
    }
    setData(data)
  }

  const handleDateChanges = (date, rule, name) => {
    data[rule][name] = date
    setData(data)
  }

  const handleAllowOnceRule = (event) => {
    event.persist()
    let maxCount = parseBool(event.target.value)
    data.allowOnceRule.maxCount = maxCount ? 1 : 0
    setData(data)

  }
  return (
    <div>
      <BackButton editPage={true} backLabel={Placeholder.Campaigns} {...props}>{data.name}</BackButton>
      <ProntoContainer>
        <div className="form c-12">
          <div className="form-row">
            <div className="form-section c-6 pr-10">
              {data &&(data.campaignType || mode === Mode.Create) && campaignTypes && <Select data={campaignTypes} defaultOption="Välj" defaultValue={data.campaignType} name="campaignType" handleEvent={handleInputChange}/>  }
              <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="name" defaultValue={data.name} placeholder={Placeholder.Name}/>
              <input name="description" type="text" className={GetCssInput(mode)} onChange={handleInputChange} defaultValue={data.description} placeholder={Placeholder.Description} />
              {data.codeRule  && <CampaignCodeRule data={data.codeRule} handleInputChange={handleInputChange}  mode={mode}/>}
              <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="campaignValue" defaultValue={data.campaignValue} placeholder="Rabattvärde"/>
              <div className="row">
                { data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange}/>     }
                { !data.id && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange}/>     }
                <span className="label form-label">{Placeholder.Active}</span>
              </div>
            </div>
            <div className="form-section c-6 pl-10">
              {data.dateRule && <CampaignDateRule data={data.dateRule} handleDateChanges={handleDateChanges}  mode={mode}/>}
              {data.useRule && <CampaignUseRule data={data.useRule} handleInputChange={handleInputChange} mode={mode}/>}
              {data.minimumPurchaseAmountRule && <CampaignMinimumPurchaseAmountRule data={data.minimumPurchaseAmountRule} handleInputChange={handleInputChange} mode={mode}/>}
              <div className="row">
                { data.id && <AllowOnceRule checked={data.allowOnceRule.maxCount > 0} name = "userUseRule__maxCount" handleEvent={handleAllowOnceRule} />     }
                <span className="label form-label">{Placeholder.AllowOnce}</span>
              </div>
              <span className="label mt-15">{Placeholder.ChooseStore}</span>
              <hr/>
              {data.storeRule && data.selectedStores && <CampaignStoreRule data={data.storeRule} storeId={storeId} stores={data.selectedStores} handleInputChange={handleStoreSelectChange}  mode={mode}/>}
            </div>
          </div>
          <div className="row">
            <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
            <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>
          </div>
        </div>

      </ProntoContainer>
    </div>
  )

}


export default Campaign

