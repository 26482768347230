import { createBrowserHistory } from 'history'
import { ApplicationPaths } from './ApiAuthorizationConstants'
import { GetBaseRoute } from '../../utilis/Enviroment'
import { postData } from '../Ajax'
import { getServiceData, sendServiceData } from '../HttpClient'

const history = createBrowserHistory({ basename: GetBaseRoute() })

const cuser = "currentUser"

export class AuthorizeService {

  _isAuthenticated = false
  async redirectToLoginPage() {
    if (history.location.pathname !== ApplicationPaths.LoginPath) {
      window.location = ApplicationPaths.LoginPath
    }
  }

  async getUser() {
    const myStorage = window.localStorage
    const user = myStorage.getItem(cuser)
    if (user) {
      return JSON.parse(user)
    }
    return null
  }

  async getAccessToken() {
    const user = await this.getUser()
    if (user && user.token) {
      return user.token
    }
    await (this.redirectToLoginPage())
  }

  async register(user) {
    const response = await postData(
      ApplicationPaths.Register, user, true
    )
    var result = await response

    return result
  }

  async update(user) {
    const response = await postData(
      ApplicationPaths.UpdateUser, user, true
    )
    var result = await response

    return result
  }

  async changePassword(user) {
    const response = await postData(
      '/api/user/changePassword/', user, true
    )
    var result = await response

    return result
  }

  async signIn(user) {
    const response = await postData(
      ApplicationPaths.Login, user
    )
    var result = await response
    if (result && result.status !== 200) {
      return false
    }
    try {
      const resp = await result.json()
      if (resp && resp.token) {

        const jsonUser = JSON.stringify(resp)
        const myStorage = window.localStorage

        myStorage.setItem(cuser, jsonUser)
        return true
      }

      return false

    } catch (error) {
      console.error(error)
      return false
    }

  }

  async getOauthUri() {
    try {
      const response = await getServiceData('ADAuthApi', 'v2', `/authorize?redirect_uri=${window.location.protocol}//${window.location.host}/user/login`, null, false)
      if (response && response.data) {
        const myStorage = window.localStorage
        const oauthPathData = JSON.stringify(response.data)
        myStorage.setItem('oAutoPath', oauthPathData)
      }
      return response
    } catch (error) {
      console.error(error)
    }

  }

  async getOauthAccesTokenAndSignIn(code) {
    try {
      const myStorage = window.localStorage

      const oauthPathData = myStorage.getItem('oAutoPath')
      let oauthData = {}
      if (oauthPathData) {
        oauthData = JSON.parse(oauthPathData)
      }
      const redirectUri = `${window.location.protocol}//${window.location.host}/user/login`
      const tokenData = { authorizationCode: code, redirectUri: redirectUri, clientId: oauthData.clientId }
      const tokenResult = await sendServiceData('ADAuthApi', 'v2', `/token`, tokenData, 'POST', null, false)

      if (tokenResult && tokenResult.data.accessToken) {
        // exchange token and sign in
        const headers = { 'x-client-name': 'icanow-service', 'Authorization': `Bearer ${tokenResult.data.accessToken}` }
        const result = await getServiceData('ADAuthApi', 'v2', `/introspect`, headers, false)
        if (result && result.data && result.data.jwt) {
          const jsonUser = JSON.stringify({ token: result.data.jwt, displayName: result.data.emails })
          myStorage.setItem(cuser, jsonUser)
          return true
        }
        return false

      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async signOut() {
    const myStorage = window.localStorage
    myStorage.removeItem(cuser)
    myStorage.removeItem('oAutoPath')
    return true
  }


  createArguments(state) {
    return { useReplaceToNavigate: true, data: state }
  }

  error(message) {
    return { status: AuthenticationResultStatus.Fail, message }
  }

  success(state) {
    return { status: AuthenticationResultStatus.Success, state }
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect }
  }


  static get instance() { return authService }
}

const authService = new AuthorizeService()

export default authService

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail'
}
