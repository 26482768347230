import React from "react"
import InputField from "./InputField"
import InputLabel from "./InputLabel"
import css from "./SelectList.module.css"
import { emptyGuid } from "../../utilis/Constants"
import { handleValidation } from '../../utilis/ValidationHelpers'
import ValidationErrorMessage from "./ValidationErrorMessage"

function SelectList({ 
  label,
  options,
  value,
  defaultOption = null,
  defaultOptionValue = emptyGuid,
  onChange,
  id,
  name,
  disabled,
  children,
  optionValueField = "id",
  optionDisplayField = "name",
  validationRegister,
  validation,
  errors,
  ...props 
}) {
  const validationData = handleValidation(id, name, label, onChange, validationRegister, validation, errors)

  var classNames = `${css.selectList}`
  if (validationData.hasError) {
    classNames += ' ' + css.error
  }

  function onOptionChange(e) {
    if (!onChange) {
      return
    }

    const selectedOption = options.find(o => o[optionValueField] === e.target.value)

    onChange(e, selectedOption)
  }

  return (
    <InputField className={classNames}>
      <InputLabel label={label} />
      <select
        value={value}
        onChange={(e) => onOptionChange(e)}
        name={name}
        id={id}
        disabled={disabled}
        {...props}
        {...validationData.register}
      >
        {defaultOption !== null && 
          <option key='-1' value={defaultOptionValue}>
            {defaultOption}
          </option>
        }

        {options && options.length > 0
          ? options.map((option) => (
            <option key={option[optionValueField]} value={option[optionValueField]}>
              {option[optionDisplayField]}
            </option>
          ))
          : children
        }
      </select>
      {validationData.hasError && validationData.errorMessage &&
        <ValidationErrorMessage message={validationData.errorMessage} />
      }
    </InputField>
  )
}

export default SelectList