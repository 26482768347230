import React from "react"
import { Editor } from "@tinymce/tinymce-react"

function TextEditor({ height = 300, editorRef, initialValue = "", placeholder }) {
  return (
    <div style={{ height: `${height}px` }}>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(_, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          height,
          menubar: false,
          placeholder: placeholder,
          plugins: ["advlist lists anchor", "paste wordcount"],
          toolbar: "formatselect | bold italic | bullist numlist",
          block_formats:
            "Paragraph=p; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
        }}
      />
    </div>
  )
}

export default TextEditor
