import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { getData } from '../../services/HttpClient'
import Mode from '../../utilis/Mode'
import Placeholder from '../layout/Placeholder'
import '../layout/Layout.css'
import css from "./BackButton.module.css"
import Link from '../UI/Link'
import withRouter from '../../utilis/withRouter'

const BackButton = withRouter(({
  truncateTitle,
  modePrefix,
  disabled,
  backLabel,
  children,
  to,
  simple = false,
  router,
}) => {
  const navigate = useNavigate()
  let referenceId
  let ellipsisClass
  if (truncateTitle) {
    ellipsisClass = "ellipsis_text"
  }

  if (router && router.params) {
    if (router.params.referenceid) {
      referenceId = router.params.referenceid
    }
    else {
      referenceId = router.params.storeid
    }
  }

  const mode = modePrefix
  let modeLabel

  if (mode) {
    if (mode === Mode.Edit) {
      modeLabel = Placeholder.Edit
    }
    else if (mode === Mode.Create) {
      modeLabel = Placeholder.Create
    }
    else if (mode === Mode.Delete) {
      modeLabel = Placeholder.Delete
    }
  }

  let linkAttributes = {
    to: "#"
  }
  if (to) {
    linkAttributes.to = to
  }
  else {
    linkAttributes.onClick = (e) => goBack(e)
  }

  const [label, setLabel] = useState("")

  useEffect(() => {
    if (!disabled && !backLabel && referenceId) {
      let fetchUrl = `/api/stores/${referenceId}`

      const fetchData = async () => {
        const res = await getData(fetchUrl)

        if (res.ok) {
          setLabel(res.data.name)
        }
      }
      fetchData()
    }
    else {
      setLabel(backLabel)
    }
  }, [backLabel, disabled, referenceId])

  const goBack = (e) => {
    e.preventDefault()

    if (!disabled) {
      navigate(-1)
    }
  }

  return (
    <div className={`${css.backButton} ${simple ? css.simple : "drop-shadow"}`}>
      <Link className={css.body} {...linkAttributes}>
        {!disabled &&
          <React.Fragment>
            <img className={css.arrow} src="/arrow-small.png" alt="arrow" />
            <span className={css.label}>{label}</span>
          </React.Fragment>
        }
      </Link>
      {!simple &&
        <div className={css.headline}>
          <h2 className={ellipsisClass}>
            {mode && (modeLabel + " ")}
            {children}
          </h2>
        </div>
      }
    </div>
  )
})

export default BackButton