import React, { useState, useEffect } from "react"
import { getServiceData, sendServiceData } from "../../services/HttpClient"
import Placeholder from '../layout/Placeholder'
import Checkbox from '../Form/Checkbox'
import SelectList from '../Form/SelectList'
import { defaultNotifications } from "../../utilis/Notifications"
import FormActions from "../Form/FormActions"
import ContentContainer from '../layout/ContentContainer'

const TargetGroup = (props) => {
  const history = props.history
  const storeId = props.match.params.storeid
  const minAge = 16
  const maxAge = 30

  const [ages, setAges] = useState([])
  const [data, setData] = useState({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    let ages = []
    for (var i = minAge; i <= maxAge; i++) {
      ages.push({ id: i, value: i, name: i })
    }
    setAges(ages)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const response = await getServiceData("FoodApi", "v1", `/targetgroup/${storeId}`)
        if (response.ok && response.data) {
          setData(response.data)
        }
        else {
          setData({ id: null, storeId: storeId, ageRestriction: null, stammisOnly: false })
        }
      }
    }

    fetchData()
  }, [storeId])

  const handleAgeRestrictionChange = (target, value) => {
    let newData = { ...data }
    newData[target] = value !== "" ? value : null
    setData(newData)
  }

  const handlePostData = async (e) => {
    e.preventDefault()
    setSaving(true)
    const response = await sendServiceData("FoodApi", "v1", "/targetgroup", data, "POST")
    setSaving(false)
    if (response.ok) {
      defaultNotifications.updated(Placeholder.TargetGroup)
      navigateBack()
    } else {
      defaultNotifications.error(Placeholder.TargetGroup)
    }
  }

  function navigateBack() {
    history.push(window.location.pathname + '../unmanned/')
  }

  return (
    <ContentContainer
      title={Placeholder.TargetGroup}
      fetchingData={!data.storeId}
      backTo="../unmanned"
    >
      <form className="form" id="targetGroupForm" onSubmit={handlePostData}>
        <SelectList
          label="Åldersgräns"
          options={ages}
          name="ageRestriction"
          defaultOption=" "
          defaultOptionValue={null}
          value={data.ageRestriction ?? undefined}
          onChange={(e) => handleAgeRestrictionChange(e.target.name, e.target.value)}
        />
        <Checkbox
          name="stammisOnly"
          label={Placeholder.StammisOnly}
          checked={data.stammisOnly}
          onChange={(e) => handleAgeRestrictionChange(e.target.name, e.target.checked)}
        />
        <FormActions
          form="targetGroupForm"
          onCancel={navigateBack}
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default TargetGroup
