import React, { useState, useEffect } from "react"
import { getData, sendData } from "../../services/HttpClient"
import Placeholder from '../layout/Placeholder'
import SelectList from '../Form/SelectList'
import Textbox from '../Form/Textbox'
import { defaultNotifications } from "../../utilis/Notifications"
import FormActions from "../Form/FormActions"
import ContentContainer from '../layout/ContentContainer'

const UnmannedSettings = (props) => {
  const history = props.history
  const storeId = props.match.params.storeid
  const storeTypes = [
    { id: 1, name: Placeholder.UnmannedSettings.StoreType.Store },
    { id: 2, name: Placeholder.UnmannedSettings.StoreType.BranchStore },
    { id: 3, name: Placeholder.UnmannedSettings.StoreType.VendingMachine },
  ]

  const [saving, setSaving] = useState(false)
  const [store, setStore] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const response = await getData('/api/stores/' + storeId)
        if (response.ok && response.data) {
          setStore(response.data)
        }
      }
    }

    fetchData()
  }, [storeId])

  const handleValueChange = (target, value) => {
    let newStore = { ...store }
    newStore.unmannedSettings[target] = value !== "" ? value : null
    setStore(newStore)
  }

  const handlePostData = async (e) => {
    e.preventDefault()
    setSaving(true)
    const response = await sendData('/api/stores/edit', store, "POST")
    setSaving(false)
    if (response.ok) {
      defaultNotifications.updated(Placeholder.UnmannedSettings.Title)
      navigateBack()
    } else {
      defaultNotifications.error(Placeholder.UnmannedSettings.Title)
    }
  }

  function navigateBack() {
    history.push(window.location.pathname + '../unmanned/')
  }

  return (
    <ContentContainer
      title={Placeholder.UnmannedSettings.Title}
      fetchingData={!store.unmannedSettings}
      backTo="../unmanned"
    >
      <form className="form" id="unmannedSettingsForm" onSubmit={handlePostData}>
        <SelectList
          label={Placeholder.UnmannedSettings.StoreType.Title}
          options={storeTypes}
          name="storeType"
          value={store.unmannedSettings?.storeType ?? undefined}
          onChange={(e) => handleValueChange(e.target.name, e.target.value)}
        />
        <Textbox
          label={Placeholder.UnmannedSettings.MainStoreName}
          name="mainStoreName"
          value={store.unmannedSettings?.mainStoreName ?? undefined}
          onChange={(e) => handleValueChange(e.target.name, e.target.value)}
        />
        <FormActions
          form="unmannedSettingsForm"
          onCancel={navigateBack}
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default UnmannedSettings
