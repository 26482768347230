import React, { useState, useEffect } from 'react'
import {  getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import ContentContainer from "../layout/ContentContainer"
import LinkButton from '../../components/UI/LinkButton'
import { PlusIcon } from '../../components/layout/Icons'
import ItemList, { ItemListColumn } from "../ItemList/ItemList"

const VendingEmployees = () => {
  const [vendingEmployees, setVendingEmployees] = useState([])
  const [fetchingData, setFetchingData] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      const response = await getServiceData('AuthApi', 'v1', '/vending')
      if (response.ok) {
        setVendingEmployees(response.data)
      }
      setFetchingData(false)
    }

    fetchData()
  }, [])

  function getChannelsText(channels) {
    return channels?.map(c => c.channelName).join(', ')
  }

  return (
    <ContentContainer
      title={Placeholder.VendingEmployees.Title}
      fetchingData={fetchingData}
      backTo="../"
      actions={() =>
        <LinkButton
          type="save"
          to="create/"
          Icon={PlusIcon}
        >
          {Placeholder.VendingEmployees.Add}
        </LinkButton>
      }
    >
      <ItemList
        data={vendingEmployees}
        idProp="key"
        arrowList
        navigationUrl={vendingEmployee => `${vendingEmployee.key}/`}
        stickyHeader={true}
        renderRow={vendingEmployee =>
          <>
            <ItemListColumn id="key" label={Placeholder.VendingEmployees.Key} columns={2}>{vendingEmployee.key}</ItemListColumn>
            <ItemListColumn id="description" label={Placeholder.Description}>{vendingEmployee.description}</ItemListColumn>
            <ItemListColumn id="channels" label={Placeholder.Channels} columns={4}>{getChannelsText(vendingEmployee.channels)}</ItemListColumn>
            <ItemListColumn id="stores" label={Placeholder.VendingEmployees.Stores} columns={2}>{vendingEmployee.stores?.length}</ItemListColumn>
          </>
        }
      >
      </ItemList>
    </ContentContainer>
  )
}
export default VendingEmployees
