import React from 'react'
import QRCode from 'react-qr-code'
import RadioButton from '../layout/RadioButton'
import './QRGenerator.css'

function QRGenerator({ storeId }) {
  const [qrValue, setQrValue] = React.useState('')
  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState(false)
  const [imgURI, setImgURI] = React.useState('')
  const [isTableFormat, setIsTableFormat] = React.useState(false)
  const stageStore = '1214de17-a004-44f0-19ef-08d6538111e5'
  const backofficeDev = 'cd922853-0b00-44ab-2509-08d6877f6e25'

  const baseUrl = `https://kiosk-mobile.icapronto.se/butik/${
    storeId !== backofficeDev ? storeId : stageStore
  }`
  async function onSubmit() {
    try {
      if ((isTableFormat && value.length > 0) || !isTableFormat) {
        setQrValue(!isTableFormat ? baseUrl : `${baseUrl}/?bord=${value}`)
        !isTableFormat && setValue('')
      } else {
        setQrValue('')
        setError(true)
      }
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  React.useEffect(() => {
    if (error) {
      !isTableFormat && setError(false)
    }
  }, [isTableFormat, error])

  function onInputFocus() {
    setIsTableFormat(true)
    if (error) {
      setError(false)
    }
  }

  React.useEffect(() => {
    function createImg() {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      const svg = document.getElementById('QrCode')
      const data = new XMLSerializer().serializeToString(svg)
      const DOMURL = window.URL || window.webkitURL || window

      const img = new Image()
      const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8'
      })
      const url = DOMURL.createObjectURL(svgBlob)

      img.onload = function () {
        canvas.width = this.naturalWidth
        canvas.height = this.naturalHeight

        ctx.drawImage(img, 0, 0)
        DOMURL.revokeObjectURL(url)

        var imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')

        setImgURI(imgURI)
      }

      img.src = url
    }
    qrValue.length > 0 && createImg()
  }, [qrValue])

  return (
    <div className='qrGeneratorWrapper'>
      <div className='content'>
        <div className='qrContainer'>
          {qrValue && (
            <div>
              <p className='tooltip'>{qrValue}</p>
              <QRCode id={'QrCode'} value={qrValue} size={270} />
            </div>
          )}
        </div>
        <div className={'inputContainer'}>
          <RadioButton
            label={'Restaurang'}
            name={'restaurant'}
            id={'restaurant'}
            onChange={() => setIsTableFormat((prev) => !prev)}
            checked={!isTableFormat}
          />
          <RadioButton
            label={'Bord'}
            name={'table'}
            id={'table'}
            onChange={() => setIsTableFormat((prev) => !prev)}
            checked={isTableFormat}
          />
          <input
            className={error ? 'errorInput' : 'input'}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={onInputFocus}
            type='text'
            placeholder='Ange bordsnummer'
          />
        </div>
        <button className='button' type='button' onClick={onSubmit}>
          Generera QR-kod
        </button>
        {qrValue.length > 0 && (
          <button className='copyButton' type='button'>
            <a
              target='_blank'
              rel='noreferrer'
              href={imgURI}
              download={`QR-kod-Bord${value}`}
            >
              Ladda ner bild
            </a>
          </button>
        )}
        <canvas id='canvas'></canvas>
      </div>
    </div>
  )
}

export default QRGenerator
