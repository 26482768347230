
import React, { useState, useEffect } from 'react'
import {getData ,postMultiPartData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer,ProntoContainerTop} from '../layout/Containers'
import Select from '../layout/Select'
import { ClickButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'

const PriceGroceryImport = (props)=> {
  const storeId = props.storeid ? props.storeid: props.match.params.storeid
  const [showModal, setModal] = useState(true)
  const [file, setFile] = useState( {} )
  const [message, setMessage] = useState()


  const [store, setStore] = useState({})
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [data, setData] = useState({
    percentage : 0,
    importStarted: false, 
    isReady : false,
    message:"Starta import"} )

  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        //Store Data
        const response = await getData(
          '/api/import/store/' + storeId
        )
        const result = await response
        setStore(result)
      }

      fetchData()
    }
  }, [])


  const handleInputChange = (event) => {
    event.persist()
    data.isReady = true
    setData({...data})
    setSelectedChannel(event.target.value)

  }

  const handlePercentageChange = (event) => {
    event.persist()
    data.percentage =  parseInt(event.target.value)
    setData(data)
  }
  const handlePostData = async (event) => {
    event.persist()
    var fileIsvalid =  (file && file.name)
    if(storeId && selectedChannel &&fileIsvalid){
    
      data.message = "Startar import..."
      data.importStarted = true
      data.isReady = false
      setData({...data})
      
      const url = `api/import/store/${storeId}/ChannelTypeId/${selectedChannel}/${data.percentage}/price`

      // const files = e.target.files
      const formData = new FormData()
      formData.append('file', file)
      
      var response = await postMultiPartData(url, formData)
      var fileResponse = await response
      setData({...fileResponse})
      
    }
    else{
      alert("välj channel")
    }
    
  }

  function checkExtension(fileName) {
    return ( /\.(txt)$/i.test(fileName))
  }


  const toggleModal = ()=> {
    setModal(!showModal)
  }
  const updateFile = (e)=>{
    if(!checkExtension(e.target.files[0].name)){
      setMessage("Välj en pris fil")
      return
    }
    setMessage('')
    setFile(e.target.files[0])
  }
  const uploadFile = async () => {

    if(!file || !file.name){
      setMessage("Välj en pris fil")
      return
    }
    setModal(!showModal)
   
  }

  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Uppdatera priser från fil </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section">
            <div className="row">
              { store && store.channels && store.channels.length > 0 &&  <Select data={ store.channels} name="channelId" handleEvent={handleInputChange} defaultOption="Välj" defaultValue={selectedChannel}/>   }
              <span className="label form-label ml-10">Kanal</span>
            </div>
            <div className="row">
              <input className={"input-edit mr-10"} type="number" onChange={handlePercentageChange} name="percentage" defaultValue={data.percentage} placeholder={"Procent påslag (heltal)"}/>
              <span className="label form-label ml-10">Prispåslag i procent (heltal)</span>
            </div>
                  
            <div className="row">
              <ClickButton className="btn--black" title={data.message} disabled={data && !data.isReady} onClick={handlePostData}/>
            </div>
            {data && data.failMessage && <div className="row">
              {data.failMessage}
            </div>}
          </div>
        </div>
      </ProntoContainer>
      <ul className="no-style drop-shadow categories-table">
        <li>
          <div className="list-item list-item__heading">
            <label className="flex-right btn-control btn--red btn-control--absolut btn-control--absolut__top nav-link btn-file-upload" onClick={e => toggleModal(e)}>Läs in csv fil med produkter</label>
          </div>
        </li>
        <li>
          {showModal &&<div className="modal">
            <div className="modal__content">
              <form>
                <div className="form-section c-6 pl-10">
                  <span className="label">Import fil</span>
                  <hr/>
                </div> 
                <div className="form-section c-6 pl-10">
                  <label htmlFor="priceFileUpload" className="btn-control btn--red nav-link btn-file-upload">{(file && file.name?file.name:'välj fil')}</label>
                  <input id="priceFileUpload" name="priceFileUpload" className="file-upload"  type="file" onChange={e => updateFile(e)}/>
                  <span className="label">{message}</span>
                  <br></br>
                </div>  
                <div className="row">
                  <ClickButton className="btn--black mr-10" title="Välj fil" onClick={uploadFile} />
                  <ClickButton className="btn--white mr-10" title={Placeholder.Cancel} onClick={toggleModal} />    
                </div>
              </form>
            </div>
          </div>}
        </li>
      </ul>
              
        
              
    </ProntoContainerTop>
  )

}


export default PriceGroceryImport
