import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import Mode from '../../utilis/Mode'
import { GetCssInput } from '../layout/Input'
import { ClickButton, SaveButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import MediaLibraryUploadInput from '../UI/MediaLibraryUploadInput'



const ExtraIngredient = (props) => {
  const mode = props.mode
  const history = props.history
  const menuItemId = props.match.params.menuItemId
  const extraId = props.match.params.extraId
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (extraId) {
        const response = await getData(
          '/api/menuitem/' + menuItemId + '/extras/' + extraId
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if (mode === Mode.Edit) {
      return '/api/menuitem/' + menuItemId + '/extras/edit'
    }
    if (mode === Mode.Create) {
      return '/api/menuitem/' + menuItemId + '/extras/create'
    }
    if (mode === Mode.Delete) {
      return '/api/menuitem/' + menuItemId + '/extras/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if (url) {
      const response = await postData(
        url, data
      )
      return await response

    } else {
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    data[event.target.name] = event.target.value
    setData(data)
  }

  const handleImageChange = (value) => {
    setData(() => ({ ...data, imageUrl: value }))
  }

  return (
    <div>
      <BackButton backLabel="Extra ingredienser" editPage={true} {...props}>Extra ingrediens</BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section c-6 pr-10">
            <MediaLibraryUploadInput imageUrl={data.imageUrl || ''} onChange={(e) => handleImageChange(e.target.value)} mode={mode} />
            <input className={GetCssInput(mode)} type="number" onChange={handleInputChange} name="price" defaultValue={data.price} placeholder={Placeholder.Price} />
            <input className={GetCssInput(mode)} type="text" onChange={handleInputChange} name="displayName" defaultValue={data.displayName} placeholder={Placeholder.Name} />

            <div className="row">
              <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete : Placeholder.SaveButton} onClick={handlePostData} />
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack} />
            </div>
          </div>
        </div>
      </ProntoContainer>
    </div>
  )

}


export default ExtraIngredient

