/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { getData, sendData, downloadFile } from '../../../services/HttpClient'
import Placeholder from '../../../components/layout/Placeholder'
import ContentContainer from '../../../components/layout/ContentContainer'
import GridRow from '../../../components/Grid/GridRow'
import GridItem from '../../../components/Grid/GridItem'
import SelectList from '../../../components/Form/SelectList'
import Button from '../../../components/UI/Button'
import DatePicker from '../../../components/Form/DatePicker'
import { emptyGuid } from '../../../utilis/Constants'
import { defaultNotifications } from '../../../utilis/Notifications'
import OrderList from '../../../components/orders/OrderList'

const GeneralOrderReport = ({
  type,
  ...props
}) => {
  const storeId = props.match.params.storeid
  const defaultDate = new Date(new Date().setHours(0, 0, 0, 0))
  const [channels, setChannels] = useState([])
  const [data, setData] = useState({
    orders: [],
    continuationToken: ""
  })
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [fetchingData, setFetchingData] = useState(false)
  const [generatingReport, setGeneratingReport] = useState(false)
  const [isSearchPerformed, setIsSearchPerformed] = useState(false)
  const [selectedChannelTypeId, setSelectedChannelTypeId] = useState(emptyGuid)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  let title = ""
  switch (type) {
    case "ondemand":
      title = Placeholder.Reports.AccountingReport.Name
      break
    case "csv":
      title = Placeholder.Reports.CsvReport.Name
      break
    case "deliveryexcel":
      title = Placeholder.Reports.DeliveryExcelReport.Name
      break
    case "salesReport":
      title = Placeholder.Reports.OrderSalesReport.Name
      break
    default:
      throw new Error("Unknown type")
  }

  useEffect(() => {
    setStartDate(getDefaultDate())
    handleEndDateChange(getDefaultDate())

    const fetchData = async () => {
      setIsInitialFetch(true)

      const channelResult = await getData('api/system/channels')
      if (channelResult.ok) {
        setChannels(channelResult.data)
      } else {
        defaultNotifications.error(Placeholder.Channels)
      }

      setIsInitialFetch(false)
    }

    fetchData()
  }, [])

  const handleselectedChannelTypeIdChange = (e) => {
    setSelectedChannelTypeId(e.target.value)
  }

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999)
    setEndDate(date)
  }

  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0)
    setStartDate(date)
  }

  async function fetchInitialOrders() {
    await fetchOrders(true)
  }

  async function fetchMoreOrders() {
    await fetchOrders(false)
  };

  async function fetchOrders(isInitialSearch) {
    setFetchingData(true)

    var searchCriteria = {
      storeId: storeId,
      selectedChannelTypeId: selectedChannelTypeId,
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
    }

    var customHeader = !isInitialSearch && data.continuationToken ? { 'x-ms-continuation': data.continuationToken } : undefined

    const res = await sendData(`api/orders/${type}`, searchCriteria, "POST", customHeader)
    if (res.ok) {
      let newData = {}
      if (!isInitialSearch) {
        newData = {
          orders: [...data.orders, ...res.data.orders],
          continuationToken: res.data.continuationToken
        }
      }
      else {
        newData = res.data
      }

      setData(newData)
    } else {
      defaultNotifications.error()
    }

    setFetchingData(false)
    setIsSearchPerformed(true)
  }

  async function onCreateReportClick() {
    setGeneratingReport(true)

    let reportUrl = ""
    switch (type) {
      case "ondemand":
        reportUrl = `/api/reports/OnDemand/${storeId}/${selectedChannelTypeId}/${startDate.toISOString()}/${endDate.toISOString()}`
        break
      case "csv":
        reportUrl = `/api/reports/export/csv/${storeId}/${selectedChannelTypeId}/${startDate.toISOString()}/${endDate.toISOString()}`
        break
      case "deliveryexcel":
        reportUrl = `/api/reports/export/deliveryexcel/${storeId}/${selectedChannelTypeId}/${startDate.toISOString()}/${endDate.toISOString()}`
        break
      case "salesReport":
        reportUrl = `/api/reports/export/sales/${storeId}/${selectedChannelTypeId}/${startDate.toISOString()}/${endDate.toISOString()}`
        break
      default:
        throw new Error("Unknown type")
    }

    const res = await downloadFile(reportUrl)
    if (!res.ok) {
      defaultNotifications.error()
    }

    setGeneratingReport(false)
  }

  const getDefaultDate = () => {
    return new Date(defaultDate.getTime())
  }

  return (
    <ContentContainer
      title={title}
      backDisabled={!storeId}
      fetchingData={isInitialFetch}
      expandContent={true}
      backText={Placeholder.Reports.Reports}
      backTo={`/stores/${storeId}/reports`}
      actions={() =>
        <>
          <Button
            type="action"
            loading={generatingReport}
            onClick={() => onCreateReportClick()}
          >
            {Placeholder.Reports.CreateReport}
          </Button>
        </>
      }
    >
      <div className="top-container gutter bg--white">
        <GridRow>
          <GridItem cols="2">
            <SelectList
              label="Köpkanal"
              options={channels}
              optionValueField="value"
              name="selectedChannelTypeId"
              onChange={handleselectedChannelTypeIdChange}
              defaultOption="Alla köpkanaler"
              value={selectedChannelTypeId}
            />
          </GridItem>
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, från"
              name="StartDate"
              selectedDate={startDate}
              onChange={(date) => handleStartDateChange(date)}
            />
          </GridItem>
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, till"
              name="EndDate"
              selectedDate={endDate}
              onChange={(date) => handleEndDateChange(date)}
            />
          </GridItem>
        </GridRow>

        <Button onClick={fetchInitialOrders} loading={fetchingData}>Visa</Button>
      </div>

      {data && data.orders && isSearchPerformed &&
        <OrderList
          orders={data.orders}
          hasMore={data.continuationToken}
          onLoadMore={fetchMoreOrders}
          fetchingData={fetchingData}
          clickable={false}
        />
      }
    </ContentContainer>
  )
}

export default GeneralOrderReport
