import React from 'react'
import InputField from './InputField'
import InputLabel from './InputLabel'
import { handleValidation } from '../../utilis/ValidationHelpers'
import css from './Textarea.module.css'
import ValidationErrorMessage from './ValidationErrorMessage'

function Textarea({
  label,
  onChange,
  value,
  id,
  className,
  beforeTextarea,
  afterTextarea,
  validationRegister,
  validation,
  errors,
  onFocus,
  onBlur,
  ...props
}) {
  const validationData = handleValidation(id, props.name, label, onChange, validationRegister, validation, errors)

  var classNames = css.textarea
  if (validationData.hasError) {
    classNames += ' ' + css.error
  }

  if (className) {
    classNames += ' ' + className
  }

  return (
    <InputField onFocus={onFocus} onBlur={onBlur}>
      <InputLabel label={label} htmlFor={id} />
      {beforeTextarea && beforeTextarea()}
      <textarea
        id={id}
        className={classNames}
        onChange={onChange}
        value={value || ''}
        disabled={props.disabled}
        {...props}
        {...validationData.register}
      />
      {afterTextarea && afterTextarea()}
      {validationData.hasError && validationData.errorMessage &&
        <ValidationErrorMessage message={validationData.errorMessage} />
      }
    </InputField>
  )
}

export default Textarea
