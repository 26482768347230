import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer} from '../layout/Containers'
import Mode from '../../utilis/Mode'
import {ClickButton,SaveButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Select from "../layout/Select"
import Moment from "moment"
import DatePicker from "react-datepicker"
import TimePicker from 'react-time-picker'
import Textbox from '../Form/Textbox'
import './SystemMessage.css'
import 'react-time-picker/dist/TimePicker.css'

const SystemMessage = (props)=> {
  const mode = props.mode
  const id = props.match.params.id
  const history = props.history
  const [applications, setApplications] = useState( [] )
  const [data, setData] = useState({ date: new Date(new Date().getTime())})

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const itemResponse = await getData(`api/system/messages/${id}`)
        const itemResult = await itemResponse
        setData(itemResult)
      }
      const response = await getData('/api/system/applications')
      const result = await response
      setApplications(result)
      console.log(result)
    }

    fetchData()
  }, [])

  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/system/messages/edit'
    }
    if(mode === Mode.Create){
      return '/api/system/messages/create'
    }
    if(mode === Mode.Delete){
      return '/api/system/messages/delete'
    }

    return undefined
  }

  const handlePostData = async () => {
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response

    }else{
      console.error("url not defined")
    }
  }

  const handleInputChange = (event) => {
    let newData = { ...data }
    newData[event.target.name] = event.target.value !== "" ? event.target.value : null
    setData(newData)
  }

  function handleTimeChange(time, name) {
    setData({...data, [name]: time})
  }

  function handleDateChanges(date, name) {
    setData({...data, [name]: Moment(date).format('YYYY-MM-DD')})
  }

  return (
    <div>
      <BackButton backLabel="Systemmeddelanden" editPage={true} {...props}>{data.name}</BackButton>
      <ProntoContainer>
        {
          <div className="form">
            <div className="form-section c-6 pr-10">
              {applications && <div className="row">
                <Select data={applications} name="application" handleEvent={handleInputChange}
                  defaultOption="Välj" defaultValue={data.application}/>
                <span className="label form-label ml-10">{Placeholder.DeliveryProviderType}</span>
              </div>}
              <div className="row">
                <DatePicker locale="sv"
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(data.date ? data.date : new Date())}
                  name="date"
                  placeholderText="Välj datum"
                  onChange={(date) => handleDateChanges(date, "date")} />
                <span className="label form-label ml-10">Datum</span>
              </div>
              <div className="system_timepicker_row">
                <span className="label form-label ml-10">Från</span>
                <TimePicker
                  value={data.startTime}
                  name="startTime"
                  disableClock={true}
                  locale="sv"
                  onChange={(time) => handleTimeChange(time, "startTime")} />
                <span className="label form-label ml-10">Till</span>
                <TimePicker className="system__timepicker"
                  value={data.endTime}
                  name="endTime"
                  disableClock={true}
                  locale="sv"
                  onChange={(time) => handleTimeChange(time, "endTime")} />
              </div>
              <Textbox
                label={Placeholder.Title}
                name="title"
                value={data?.title ?? undefined}
                onChange={handleInputChange}
              />
              <Textbox
                label={Placeholder.Message}
                name="message"
                value={data?.message ?? undefined}
                onChange={handleInputChange}
              />
            </div>  
            <div className="row">
              <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>

            </div>
          </div>
        }
          
      </ProntoContainer>
    </div>
  )
  
}


export default SystemMessage

