export const variants = [
  {
    name: "Måltidstyp", 
    values: ['Standard', 'Glutenfri', 'Laktosfri', 'Gluten- och laktosfri']
  },
  {
    name: "Storlek på tårta", 
    values: ['4 bitar', '6 bitar', '8 bitar', '10 bitar']
  },
  {
    name: "Text på tårta", 
    values: ['Nej tack', 'Ja tack']
  },
  {
    name: "Upplagd", 
    values: ['Ja tack', 'Nej tack']
  },
  {
    name: "+ Skapa nytt variantval",
    values: ['Värde 1', 'Värde 2']
  },
]