import React from "react"
import Button from '../UI/Button'
import css from "./HamburgerButton.module.css"

function HamburgerButton({ onClick, }) {
  return (
    <Button
      type="transparent"
      narrow
      className={css.hamburgerButton}
      onClick={onClick}
      title="Öppna meny"
    >
      <span />
      <span />
      <span />
    </Button>
  )
}

export default HamburgerButton