import React from "react"
import InputField from "./InputField"
import InputLabel from "./InputLabel"
import ReactDatePicker from "react-datepicker"
import css from "./DatePicker.module.css"

function DatePicker({ 
  label,
  name,
  onChange,
  selectedDate,
  locale = "sv",
  dateFormat = "yyyy-MM-dd",
  placeholderText,
}) {
  return (
    <InputField>
      <InputLabel label={label} />
      <ReactDatePicker 
        locale={locale}
        dateFormat={dateFormat}
        selected={selectedDate}
        name={name}
        placeholderText={placeholderText || dateFormat.toLowerCase()}
        onChange={onChange}
        className={css.datePickerInput}
      />
    </InputField>
  )
}
  
export default DatePicker