import React from 'react'
import { sendServiceData, getServiceData } from '../../../services/HttpClient'
import TextEditor from '../../layout/TextEditor'
import Placeholder from '../../layout/Placeholder'
import { defaultNotifications } from '../../../utilis/Notifications'
import { getErrorsAsObject } from '../../../utilis/catering/errorHelper'
import Textbox from '../../Form/Textbox'
import '../FormStyles.css'
import FormActions from '../../Form/FormActions'
import ContentContainer from '../../layout/ContentContainer'
import FormSection from '../../Form/FormSection'
import Checkbox from '../../Form/Checkbox'
import { useForm } from 'react-hook-form'
import ImageSelector from '../ImageSelector'

function StartPage({ history, match }) {
  const {
    params: { storeid },
  } = match || {}
  const navigateToCateringUrl = `/stores/${storeid}/catering`

  const imageAltValidationCriteria = {
    minLength: {
      value: 30,
      message:
        'Beskriv kortfattat och sakligt vad bilden innehåller, minst 30 tecken',
    },
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const heroTextRef = React.useRef(null)
  const [prefix, setPrefix] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [heroImage, setHeroImage] = React.useState('')
  const [heroImageAlt, setHeroImageAlt] = React.useState('')
  const [heroText, setHeroText] = React.useState('')
  const defaultHeroText =
    'Beställ allt från goda tårtor till matiga buffér för både helgmyset, festen och företagseventet.'

  const [firstBoxImage, setFirstBoxImage] = React.useState('')
  const [firstBoxHeading, setFirstBoxHeading] = React.useState('')
  const [firstBoxText, setFirstBoxText] = React.useState('')
  const [firstBoxLink, setFirstBoxLink] = React.useState('')
  const [firstBoxLinkText, setFirstBoxLinkText] = React.useState('')
  const [firstBoxActive, setFirstBoxActive] = React.useState(false)
  const [imageAlt, setImageAlt] = React.useState('')

  const [secondBoxImage, setSecondBoxImage] = React.useState('')
  const [secondBoxHeading, setSecondBoxHeading] = React.useState('')
  const [secondBoxText, setSecondBoxText] = React.useState('')
  const [secondBoxLink, setSecondBoxLink] = React.useState('')
  const [secondBoxLinkText, setSecondBoxLinkText] = React.useState('')
  const [secondBoxActive, setSecondBoxActive] = React.useState(false)
  const [imageAlt2, setImageAlt2] = React.useState('')

  const [isInitialFetch, setIsInitialFetch] = React.useState(false)
  const [saving, setSaving] = React.useState(false)

  const [linkError, setLinkError] = React.useState({})

  React.useEffect(() => {
    let componentMounted = true

    async function getStartPage() {
      setIsInitialFetch(true)
      try {
        const res = await getServiceData(
          'FoodApi',
          'v1',
          `/catering/${storeid}/startpage`,
        )
        if (res.ok && componentMounted) {
          setPrefix(res.data.heroPrefix || '')
          setHeading(res.data.heroHeading || '')
          setHeroImage(res.data.heroImage || '')
          setHeroImageAlt(res.data?.heroImageAltText || '')
          setHeroText(res.data.heroText || '')
          setFirstBoxImage(res.data.firstBoxImage || '')
          setImageAlt(res.data?.firstBoxImageAltText || '')
          setFirstBoxHeading(res.data.firstBoxHeading || '')
          setFirstBoxText(res.data.firstBoxText || '')
          setFirstBoxLink(res.data.firstBoxLink || '')
          setFirstBoxLinkText(res.data.firstBoxLinkText || '')
          setFirstBoxActive(res.data.firstBoxActive || false)

          setSecondBoxImage(res.data.secondBoxImage || '')
          setImageAlt2(res.data?.secondBoxImageAltText || '')
          setSecondBoxHeading(res.data.secondBoxHeading || '')
          setSecondBoxText(res.data.secondBoxText || '')
          setSecondBoxLink(res.data.secondBoxLink || '')
          setSecondBoxLinkText(res.data.secondBoxLinkText || '')
          setSecondBoxActive(res.data.secondBoxActive || false)
          setLinkError({})
        }
        reset(res.data)
      } catch (error) {
        console.log(error)
      }
      setIsInitialFetch(false)
    }
    if (storeid) {
      getStartPage()
    }
    return () => {
      componentMounted = false
    }
  }, [storeid])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    try {
      const heroText = heroTextRef.current.getContent() || ''
      let data = {
        heroPrefix: prefix,
        heroHeading: heading,
        heroText: heroText ? heroText : defaultHeroText,
        heroImage,
        firstBoxImage,
        firstBoxHeading,
        firstBoxText,
        firstBoxLink,
        firstBoxLinkText,
        firstBoxActive,
        secondBoxImage,
        secondBoxHeading,
        secondBoxText,
        secondBoxLink,
        secondBoxLinkText,
        secondBoxActive,
        heroImageAltText: heroImageAlt,
        firstBoxImageAltText: imageAlt,
        secondBoxImageAltText: imageAlt2,
      }
      var response = await sendServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeid}/startpage`,
        data,
        'PUT',
      )
      if (response.ok) {
        defaultNotifications.updated('Startsida', 'Startsida')
        history.push(navigateToCateringUrl)
      } else {
        const errorObj = getErrorsAsObject(response.data.errors)
        setLinkError(errorObj)
      }
    } catch (error) {
      console.log(error)
    }
    setSaving(false)
  }

  return (
    <ContentContainer
      title={'Hantera startsidan'}
      fetchingData={isInitialFetch}
      backTo={navigateToCateringUrl}
      backText={Placeholder.Catering.Name}
    >
      <form
        className="form"
        id="startpageform"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormSection title="Sidans översta del - Välkomstyta">
          <Textbox
            label="Butikens namn"
            placeholder="Ange butikens namn"
            id="prefix"
            name="prefix"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
          />
          <Textbox
            label="Rubrik"
            placeholder="Ange rubrik"
            id="heading"
            name="heading"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
          <div className="texteditor">
            <span className="groupLabel">Välkomsttext - ingress</span>
            <TextEditor
              initialValue={heroText || ''}
              placeholder={defaultHeroText}
              editorRef={heroTextRef}
              height={200}
            />
          </div>
          <ImageSelector
            label="Bild - välkomstyta"
            id="heroImage"
            name="heroImage"
            imageUrl={heroImage}
            onChangeImageUrl={(value) => setHeroImage(value)}
            storeId={storeid}
            imageAlt={heroImageAlt}
            onChangeImageAlt={(value) => setHeroImageAlt(value)}
            validationRegister={register}
            validationText={imageAltValidationCriteria}
            errors={errors}
          />
        </FormSection>

        <FormSection title="Första kampanjytan">
          <Textbox
            label="Första kampanjytans rubrik"
            placeholder="Ange rubrik"
            id="firstBoxHeading"
            name="firstBoxHeading"
            value={firstBoxHeading}
            onChange={(e) => setFirstBoxHeading(e.target.value)}
          />
          <Textbox
            label="Första kampanjytans text"
            placeholder="Ange text"
            id="firstBoxText"
            name="firstBoxText"
            value={firstBoxText}
            onChange={(e) => setFirstBoxText(e.target.value)}
          />
          <Textbox
            label="Klistra in länk till den produktsida eller kategorisida som kampanjytan ska länkas till"
            placeholder="Ange länk"
            id="firstBoxLink"
            name="firstBoxLink"
            value={firstBoxLink}
            validation={'firstBoxLink'}
            errors={linkError}
            onChange={(e) => setFirstBoxLink(e.target.value)}
          />
          <Textbox
            label="Text på första kampanjytans länk"
            placeholder="Ange länktext"
            id="firstBoxLinkText"
            name="firstBoxLinkText"
            value={firstBoxLinkText}
            onChange={(e) => setFirstBoxLinkText(e.target.value)}
          />
          <ImageSelector
            label="Första kampanjytans bild"
            id="firstBoxImage"
            name="firstBoxImage"
            imageUrl={firstBoxImage}
            onChangeImageUrl={(value) => setFirstBoxImage(value)}
            storeId={storeid}
            imageAlt={imageAlt}
            onChangeImageAlt={(value) => setImageAlt(value)}
            validationRegister={register}
            validationUrl={{ required: firstBoxActive ? true : false }}
            validationText={imageAltValidationCriteria}
            errors={errors}
          />
        </FormSection>
        <Checkbox
          label={Placeholder.Active}
          id="isActiveFirstBox"
          name="activeFirstBox"
          checked={firstBoxActive}
          onChange={() => setFirstBoxActive((prev) => !prev)}
        />
        <FormSection title="Andra kampanjytan">
          <Textbox
            label="Andra kampanjytans rubrik"
            placeholder="Ange rubrik"
            id="secondBoxHeading"
            name="secondBoxHeading"
            value={secondBoxHeading}
            onChange={(e) => setSecondBoxHeading(e.target.value)}
          />
          <Textbox
            label="Andra kampanjytans text"
            placeholder="Ange text"
            id="secondBoxText"
            name="secondBoxText"
            value={secondBoxText}
            onChange={(e) => setSecondBoxText(e.target.value)}
          />
          <Textbox
            label="Klistra in länk till den produktsida eller kategorisida som kampanjytan ska länkas till"
            placeholder="Ange länk"
            id="secondBoxLink"
            name="secondBoxLink"
            value={secondBoxLink}
            validation={'secondBoxLink'}
            errors={linkError}
            onChange={(e) => setSecondBoxLink(e.target.value)}
          />
          <Textbox
            label="Text på andra kampanjytans länk"
            placeholder="Ange länktext"
            id="secondBoxLinkText"
            name="secondBoxLinkText"
            value={secondBoxLinkText}
            onChange={(e) => setSecondBoxLinkText(e.target.value)}
          />
          <ImageSelector
            label="Andra kampanjytans bild"
            id="secondBoxImage"
            name="secondBoxImage"
            imageUrl={secondBoxImage}
            onChangeImageUrl={(value) => setSecondBoxImage(value)}
            storeId={storeid}
            imageAlt={imageAlt2}
            onChangeImageAlt={(value) => setImageAlt2(value)}
            validationRegister={register}
            validationUrl={{ required: secondBoxActive ? true : false }}
            validationText={imageAltValidationCriteria}
            errors={errors}
          />
        </FormSection>
        <Checkbox
          label={Placeholder.Active}
          id="isActiveSecondBox"
          name="activeSecondBox"
          checked={secondBoxActive}
          onChange={() => setSecondBoxActive((prev) => !prev)}
        />
        <FormActions
          form="startpageform"
          onCancel={() => history.push(navigateToCateringUrl)}
          saving={saving}
        />
      </form>
    </ContentContainer>
  )
}

export default StartPage
