import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from '../layout/Placeholder'


function SystemMessageListItem(props) {
  const item = props.data
  const application = props.application ? props.application.name : "Unknown"
   
  return (
    <div className="list-item">
      <div className="list-item__element">{application}</div>
      <div className="list-item__element">{new Date(item.date).toLocaleDateString('sv-SE')}</div>
      <div className="list-item__element">{item.startTime}</div>
      <div className="list-item__element">{item.endTime}</div>
      <div className="list-item__element">
        <Link className="link-edit" to={`/system/messages/${item.id}/edit/`}>{Placeholder.Edit}</Link>
        <Link className="link-edit" to={`/system/messages/${item.id}/delete/`}>{Placeholder.Delete}</Link>    
      </div>          
    </div>


  )
  
}

export default SystemMessageListItem

