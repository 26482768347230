import React from 'react'
import {ProntoContainerTop} from '../layout/Containers'
import ArrowListItem from '../layout/ArrowListItem'
import BackButton from '../backbutton/BackButton'


const Customizations = (props) => {
  const menuId = props.match.params.menuid
  const menuItemId = props.match.params.menuitemid

  const items = [        
    { url: `/menus/${menuId}/items/${menuItemId}/customizations/1/`, name: 'Lägg till' },
    { url: `/menus/${menuId}/items/${menuItemId}/customizations/2/`, name: 'Ta bort' },
    { url: `/menus/${menuId}/items/${menuItemId}/customizations/3/`, name: 'Anpassa' },
  ]

  return (

    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} {...props}>Anpassningar</BackButton>
   
      <div className="mid-container arrow-list drop-shadow">
        <div className="arrow-list__header arrow-list__header--space-between">
          <div className="arrow-list__header__element label">#Anpassning</div>
        </div>
        <ul className="no-style">
          {items.map((item, index) => (
            <ArrowListItem key={index} url={item.url} name={item.name} />
          ))}
        </ul>
      </div>
          
    </ProntoContainerTop>
  )
}

export default Customizations