import React from "react"
import Button from "./Button"
import css from "./ListSummary.module.css"

function ListSummary({
  itemCount = 0,
  totalItemCount,
  hasMore,
  onLoadMore,
  loading
}) {
  if (itemCount === 0) {
    return null
  }

  return (
    <div className={css.listSummary}>
      {totalItemCount && totalItemCount > 0
        ? <span className={css.itemCount}>
              Visar {itemCount} st av totalt {totalItemCount}
        </span>
        : <span className={css.itemCount}>
              Visar {itemCount} st
        </span>
      }
      {hasMore &&
          <Button loading={loading} onClick={onLoadMore}>
            Hämta fler
          </Button>
      }
    </div>
  )
}

export default ListSummary