import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TopAddButton } from '../layout/Button'
import { getData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainerTop } from '../layout/Containers'
import Checkbox from '../layout/Checkbox'
import BackButton from '../backbutton/BackButton'

const CustomizationMenuItemListItem = (props) => {
  const menuId = props.match.params.menuid
  const menuItemId = props.match.params.menuitemid
  const subMenuId = props.match.params.submenuid
  const customizationId = props.match.params.customizationid
  const customizationMenuItem = props.menuItem

  return (
    <div className="list-item">
      <div className="list-item__element">{customizationMenuItem.name}</div>
      <div className="list-item__element list-item__element--s">
        {customizationMenuItem.price}
      </div>
      <div className="list-item__element list-item__element--s">
        <Checkbox
          checked={customizationMenuItem.active}
          name="active"
          disabled="disabled"
        />
        <span className="label form-label hidden-up">{Placeholder.Active}</span>
      </div>
      {customizationMenuItem && customizationMenuItem.preSelected && (
        <div className="list-item__element list-item__element--s">
          <Checkbox
            checked={customizationMenuItem.preSelected}
            name="preSelected"
            disabled="disabled"
          />
          <span className="label form-label hidden-up">
            {Placeholder.Active}
          </span>
        </div>
      )}
      {customizationMenuItem && !customizationMenuItem.preSelected && (
        <div className="list-item__element list-item__element--s"></div>
      )}
      <div className="list-item__element">
        <Link
          className="link-edit"
          to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationId}/submenu/${subMenuId}/items/${customizationMenuItem.id}/edit/`}
        >
          {Placeholder.Edit}
        </Link>
        <Link
          className="link-edit"
          to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationId}/submenu/${subMenuId}/items/${customizationMenuItem.id}/delete/`}
        >
          {Placeholder.Delete}
        </Link>
      </div>
    </div>
  )
}

const CustomizationMenusItems = (props) => {
  const [data, setData] = useState([])

  const menuId = props.match.params.menuid
  const menuItemId = props.match.params.menuitemid
  const subMenuId = props.match.params.submenuid
  const customizationId = props.match.params.customizationid

  useEffect(() => {
    const fetchData = async () => {
      if (subMenuId && customizationId) {
        const response = await getData(
          `/api/customizations/${customizationId}/submenu/${subMenuId}/`,
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton
        className="flex-right btn-control btn--red"
        to={`/menus/${menuId}/items/${menuItemId}/customizations/${customizationId}/submenu/${subMenuId}/items/create`}
        title={`${Placeholder.Create} anpassning i grupp`}
      />
      <BackButton backLabel={'Anpassning'} {...props}>
        {data?.name}
      </BackButton>
      <ul className="no-style">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">
              {Placeholder.Name}
            </div>
            <div className="list-item__element list-item__element--s label hidden-down">
              {Placeholder.Price}(Kr)
            </div>
            <div className="list-item__element list-item__element--s label hidden-down">
              {Placeholder.Active}
            </div>
            <div className="list-item__element list-item__element--s label hidden-down">
              {customizationId == 3 ? Placeholder.PreSelected : ''}
            </div>
            <div className="list-item__element label hidden-down">
              {Placeholder.Change}
            </div>
          </div>
        </li>
        {data &&
          data.menuItems &&
          data.menuItems.map((item) => (
            <li key={item.id}>
              <CustomizationMenuItemListItem menuItem={item} {...props} />
            </li>
          ))}
      </ul>
    </ProntoContainerTop>
  )
}

export default CustomizationMenusItems
