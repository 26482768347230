import React from 'react'
import { useDayzed } from 'dayzed'
import "./Datepicker.css"
import { AngleIcon } from './Icons'

function DatePicker({
  minDate,
  maxDate,
  firstDayOfWeek = 1,
  onDateSelected,
  selected,
  date,
}) {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    minDate,
    maxDate,
    firstDayOfWeek,
    onDateSelected,
    selected,
    date,
  })

  const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ]
  const monthNamesLong = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ]
  const weekdayNamesShort = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön']

  return (
    <div className="wrapper">
      {calendars.length > 0 && (
        <div>
          {calendars.map((calendar) => (
            <div
              className="datePicker"
              key={`${calendar.month}${calendar.year}`}
            >
              <div className="calendarHeading">
                <button
                  className="prevButton"
                  {...getBackProps({ calendars })}
                  aria-label="Byt månad bakåt"
                >
                  <span className="hidden">Prev</span>
                  <span className="prev">
                    <AngleIcon direction="left" className="prevIcon" />
                  </span>
                </button>
                <span className="monthYear" aria-hidden="true">
                  {monthNamesShort[calendar.month]} {calendar.year}
                </span>
                <span className="hidden">{`${monthNamesLong[calendar.month]} ${calendar.year}`}</span>
                <button
                  className="nextButton"
                  {...getForwardProps({ calendars })}
                  aria-label="Byt månad framåt"
                >
                  <span className="hidden">Next</span>
                  <span className="next">
                    <AngleIcon direction="right" className="nextIcon" />
                  </span>
                </button>
              </div>
              <div className="weekdays" aria-hidden="true">
                {weekdayNamesShort.map((weekday) => (
                  <div
                    className="weekdayLabel"
                    key={`${calendar.month}${calendar.year}${weekday}`}
                  >
                    {weekday}
                  </div>
                ))}
              </div>
              <div className="monthSquares">
                {calendar.weeks.map((week, weekIndex) =>
                  week.map((dateObj, index) => {
                    let key = `${calendar.month}${calendar.year}${weekIndex}${index}`
                    if (!dateObj) {
                      return <div className="emptySquare" key={key} />
                    }
                    let { date, selected, selectable } = dateObj

                    return (
                      <button
                        className={
                          !selectable
                            ? "notSelectableButton"
                            : selected
                              ? "squareButtonSelected"
                              : "squareButton"
                        }
                        key={key}
                        {...getDateProps({ dateObj })}
                      >
                        <span className="dateButton">
                          {date.getDate()}
                        </span>
                      </button>
                    )
                  }),
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default DatePicker