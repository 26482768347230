import React from "react"
import ReactDOM from "react-dom"
import { CloseIcon } from "./Icons"
import styles from "./Modal.module.css"

function Modal({
  active,
  title,
  withClose = true,
  onCancel,
  onConfirm,
  maxWidth = "none",
  minHeight = "none",
  height,
  children,
}) {
  const [container, setContainer] = React.useState(null)
  const [cancelButton, setCancelButton] = React.useState(null)
  const [modal, setModal] = React.useState(null)

  React.useEffect(() => {
    const div = document.createElement("div")
    setModal(div)
  }, [])

  React.useEffect(() => {
    if (typeof document === "object" && modal) {
      document.body.appendChild(modal)
    }
    return function cleanup() {
      if (typeof document === "object" && modal) {
        document.body.removeChild(modal)
      }
    }
  }, [modal])

  React.useEffect(() => {
    if (cancelButton !== null) {
      cancelButton.focus()
    }
  }, [cancelButton])

  const containerRef = React.useCallback((node) => {
    if (node !== null) {
      setContainer(node)
    }
  }, [])

  const cancelRef = React.useCallback((node) => {
    if (node !== null) {
      setCancelButton(node)
    }
  }, [])

  function onBlur(e) {
    if (container === null) {
      return
    }
    if (
      onCancel &&
      typeof onCancel === "function" &&
      !container.contains(e.relatedTarget)
    ) {
      cancelButton.focus()
    }
  }

  function onKeyDown(e) {
    if (e.key === "Escape" && onCancel && typeof onCancel === "function") {
      onCancel()
    } else if (e.target === cancelButton && e.key === "Enter") {
      e.preventDefault()
      if (onConfirm && typeof onConfirm === "function") {
        onConfirm()
      }
    }
  }

  return modal
    ? ReactDOM.createPortal(
      !active ? null : (
        <div 
          className={styles.modal}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={containerRef}
        >
          <div 
            className={styles.modalWrapper} 
            style={{
              maxWidth: maxWidth,
              minHeight: minHeight,
              height: height,
            }} 
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>{title}</h2>
              {onCancel && typeof onCancel === "function" && (
                <button className={withClose ? styles.closeButton : styles.noClose} onClick={(e) => {e.preventDefault(); onCancel()}} ref={cancelRef}><CloseIcon color="#3A3A3A"/></button> )}
            </div>

            <div className={styles.modalBody}>
              {children}
            </div>
          </div>
        </div>
      ),
      modal
    )
    : null
}

export default Modal