import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getData} from '../../services/Ajax'
import {ProntoContainer} from '../layout/Containers'
import Placeholder from '../layout/Placeholder'
import BackButton from '../backbutton/BackButton'

const AppConfigs = ()=> {
  const [data, setData] = useState( [] )

  useEffect(() => {
    const fetchData = async () => {

      const response = await getData(
        '/api/appconfigs'
      )
      const result = await response
      setData(result)

    }

    fetchData()
  }, [])

  return (
    <div>
      <BackButton editPage={true} disabled={true}>{Placeholder.AppVersions}</BackButton>
      <ProntoContainer>
        <ul className="no-style">
          <li>
            <div className="list-item list-item__heading">
              <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
              <div className="list-item__element label hidden-down">Version</div>
              <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
            </div>
          </li>
          {data.map((item, i) => (
            <li key={i}>
              <div className="list-item">
                <div className="list-item__element">{item.appName}</div>
                <div className="list-item__element">{item.version}</div>
                <div className="list-item__element">
                  <Link className="link-action" to={`/appconfig/${item.appName}/edit`}>{Placeholder.Edit}</Link>
                </div>          
              </div>
            </li>
          ))}
        </ul>
      </ProntoContainer>
    </div>
  )
  
}


export default AppConfigs