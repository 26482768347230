import React from 'react'
import NoItemsBox from '../layout/NoItemsBox'
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'
import { DraggableIcon } from '../layout/Icons'
import { shared } from './ItemListShared'
import css from './SortableList.module.css'
import itemListStyle from './ItemList.module.css'
import { ItemListHeaderRow, ItemListRow, ItemListCell } from './ItemList'

function SortableList({
  data,
  renderRow,
  showEmpty = true,
  emptyHeading,
  emptyLinkUrl,
  emptyLinkText,
  showHeader = true,
  shadow = true,
  stickyHeader = false,
  onDragStart,
  onDragEnd,
  droppableId,
  isDropDisabled,
  idProp = 'id',
  sortOrderProp = 'sortOrder',
  ...props
}) {
  const className = shared.buildClassName(itemListStyle, shadow, stickyHeader, false, props.className)

  const [columns, setColumns] = React.useState([])

  React.useEffect(() => {
    if (data && data.length > 0) {
      setColumns(shared.getColumns(renderRow))
    }
  }, [data, renderRow])

  function handleDragEnd(result) {
    const { destination, source, draggableId } = result

    let isSorted = true
    if (!destination || !source.droppableId) {
      // not sorted if dropped outside the droppable area
      isSorted = false
      return
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      // not sorted if the item was dropped in the same place
      isSorted = false
    }

    const newData = [...data]

    if (isSorted) {
      const draggableIndex = newData.findIndex((data) => data[idProp] === draggableId)
      const draggableItem = { ...newData[draggableIndex] }
      newData.splice(source.index, 1)
      newData.splice(destination.index, 0, draggableItem)
    }

    const sortedItems = newData.map((data, index) => ({ ...data, [sortOrderProp]: index + 1 }))

    onDragEnd({
      isSorted,
      sortedItems,
      draggableId,
      source,
      destination
    })
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd}>
      {data && data.length > 0 ? (
        <table className={className} >
          {showHeader &&
            <thead>
              <ItemListHeaderRow
                columns={columns}
              />
            </thead>
          }
          <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
            {(provided, snapshot) => (
              <tbody
                className={snapshot.isDraggingOver ? css.dragOver : css.noDragOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data.map((item, i) => (
                  <Draggable draggableId={item.id} index={i} key={item.id}>
                    {(provided, snapshot) => (
                      <ItemListRow
                        key={item[idProp]}
                        {...provided.draggableProps}
                        innerRef={provided.innerRef}
                        className={snapshot.isDragging ? css.dragging : css.notDragging}
                        renderRow={renderRow}
                        columns={columns}
                        dataItem={item}
                        dataItemIndex={i}
                        extraColumns={() =>
                          <ItemListCell className={`${css.dragHandle}`} {...provided.dragHandleProps}>
                            <DraggableIcon color='#BDBBB9' />
                          </ItemListCell>
                        }
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      ) : (
        showEmpty && <NoItemsBox heading={emptyHeading} linkUrl={emptyLinkUrl} linkText={emptyLinkText} />
      )}
    </DragDropContext>
  )
}

export default SortableList