import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import Checkbox from '../layout/Checkbox'
import {ProntoContainer} from '../layout/Containers'
import Mode from '../../utilis/Mode'
import {ClickButton,SaveButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Select from "../layout/Select"


function ShouldParseInt(name){
  if(name === 'providerType' || name === 'serviceProvider'){
    return true
  }
  return false
}

const Message = (props)=> {
  const mode = props.mode
  const storeId = props.match.params.storeId
  const id = props.match.params.id
  const history = props.history
  const [messageTypes, setMessageTypes] = useState( [] )
  const [data, setData] = useState({ })

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const itemResponse = await getData(`api/stores/${storeId}/messages/${id}`)
        const itemResult = await itemResponse
        setData(itemResult)
      }
      const response = await getData('/api/stores/messageTypes')
      const result = await response
      setMessageTypes(result)
    }

    fetchData()
  }, [])
  const getPostUrl = () => {
    if(mode === Mode.Edit){
      return '/api/stores/' + storeId +'/messages/edit'
    }
    if(mode === Mode.Create){
      return '/api/stores/'+storeId+'/messages/create'
    }
    if(mode === Mode.Delete){
      return '/api/stores/' + storeId +'/messages/delete'
    }

    return undefined
  }
  const handlePostData = async () => {
    const url = getPostUrl()
    if(url){
      const response = await postData(
        url,data
      )
      return await response
    
    }else{
      console.error("url not defined")
    }
  }
  const handleInputChange = (event) => {
    //event.persist();
    data[event.target.name] = ShouldParseInt(event.target.name) ? parseInt(event.target.value, 10) : event.target.value
    setData(data)

  }



  return (
    <div>
      <BackButton backLabel="Meddelanden" editPage={true} {...props}>{data.name}</BackButton>
      <ProntoContainer>
        {
          <div className="form">
            <div className="form-section c-6 pr-10">
              {messageTypes && <div className="row">
                <Select data={messageTypes} name="messageType" handleEvent={handleInputChange}
                  defaultOption="Välj" defaultValue={data.messageType}/>
                <span className="label form-label ml-10">{Placeholder.DeliveryProviderType}</span>
              </div>}
              <input className="input-edit" type="text" onChange={handleInputChange} name="title" defaultValue={data.title} placeholder={Placeholder.Title} />
              <textarea name="text" className={"input-edit" + " input-textarea"} onChange={handleInputChange} defaultValue={data.text} placeholder={Placeholder.Message}></textarea>
              <div className="row">
                {data.active && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
                {!data.active && <Checkbox checked={data.active} name="active" handleEvent={handleInputChange} />}
                <span className="label form-label">Visa i app</span>
              </div>

            </div>  
            <div className="row">
              <SaveButton className="btn--black mr-10" title={Mode.Delete === mode ? Placeholder.Delete:Placeholder.SaveButton} onClick={handlePostData}/>
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={history.goBack}/>

            </div>
          </div>
        }
          
      </ProntoContainer>
    </div>
  )
  
}


export default Message

