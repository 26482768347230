import React from "react"
import css from "./Grid.module.css"
import PropTypes from "prop-types"

GridRow.propTypes = {
  alignment: PropTypes.oneOf(["start", "center", "end", "top", "middle", "bottom"]),
}

function GridRow({children, alignment }) {
  let classes = [css.row]

  if (alignment) {
    classes.push(css[`${alignment}-xs`])
  }

  return (
    <div className={classes.join(" ")}>
      {children}
    </div>
  )
}

export default GridRow