import React from 'react'
import { sendServiceData, getServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import { defaultNotifications } from '../../utilis/Notifications'
import Textbox from '../Form/Textbox'
import Checkbox from '../Form/Checkbox'
import { useForm } from 'react-hook-form'
import FormActions from '../Form/FormActions'
import './FormStyles.css'
import { toSlug } from '../../utilis/UrlUtils'
import ImageSelector from './ImageSelector'

function CreateCategory({ history, match, ...props }) {
  const {
    params: { storeid, groupId },
  } = match || {}

  const imageAltValidationCriteria = {
    minLength: {
      value: 30,
      message:
        'Beskriv kortfattat och sakligt vad bilden innehåller, minst 30 tecken',
    },
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [name, setName] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')
  const [imageAlt, setImageAlt] = React.useState('')
  const [slug, setSlug] = React.useState('')
  const [active, setActive] = React.useState(false)
  const [saving, setSaving] = React.useState(false)

  const createSlugSuggestion = React.useCallback(() => {
    const slugSuggestion = toSlug(name)
    setSlug(slugSuggestion)
  })

  React.useEffect(() => {
    if (name) {
      createSlugSuggestion()
    }
  }, [name])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    const slugRes = await getServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/slug/available/${slug}`,
    )

    if (slugRes.ok) {
      const res = await sendServiceData(
        'FoodApi',
        'v1',
        `/catering/${storeid}/categories`,
        {
          name,
          imageUrl,
          imageAltText: imageAlt,
          slug: slugRes.data.isAvailable ? slug : slugRes.data.suggestion,
          active,
          mainCategoryId: groupId,
        },
        'POST',
      )

      if (res.ok) {
        defaultNotifications.created(Placeholder.Category, name)
        redirectToCategories()
      } else {
        defaultNotifications.error(Placeholder.Category)
      }
    } else {
      defaultNotifications.error(Placeholder.Category)
    }

    setSaving(false)
  }

  function redirectToCategories() {
    history.push(`/stores/${storeid}/catering/categories`)
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Catering.Name}
        editPage={true}
        match={match}
        history={history}
        {...props}
      >
        {Placeholder.Create} {Placeholder.Category}
      </BackButton>
      <ProntoContainer>
        <form
          className="form"
          id="categoryForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Textbox
            label="Kategorinamn"
            placeholder="Ex. Tårtor"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            validationRegister={register}
            validation={{ required: true }}
            errors={errors}
          />
          <ImageSelector
            label="Kategoribild"
            id="image"
            name="image"
            imageUrl={imageUrl}
            onChangeImageUrl={(value) => setImageUrl(value)}
            storeId={storeid}
            imageAlt={imageAlt}
            onChangeImageAlt={(value) => setImageAlt(value)}
            validationRegister={register}
            validationText={imageAltValidationCriteria}
            errors={errors}
          />
          <Checkbox
            label={Placeholder.Active}
            id="active"
            name="active"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
          <FormActions
            form="categoryForm"
            onCancel={redirectToCategories}
            saving={saving}
          />
        </form>
      </ProntoContainer>
    </div>
  )
}

export default CreateCategory
