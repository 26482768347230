import React, { useState, useEffect } from 'react'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {ProntoContainer} from '../layout/Containers'
import {GetCssInput} from '../layout/Input'
import BackButton from '../backbutton/BackButton'
import Moment from "moment"

const DoorEvent = (props)=> {
  const mode = props.mode
  const eventId = props.match.params.eventid
  const storeId = props.match.params.storeid
  console.log(eventId,storeId)
  const [data, setData] = useState( {storeId : storeId,personInformation:{}})

  useEffect(() => {
    const fetchData = async () => {
      if(eventId && storeId){
        const response = await getData(
          '/api/doorsevent/'+eventId+"/store/"+storeId
        )
        const result = await response
        if(response && result){
          setData(result)
          console.log(result)
        }
        else{
          alert("fel när sidan laddades..")
        }
       
      }
    }

    fetchData()
  }, [])
  


  
  return (
    <div>
      <BackButton backLabel="Dörrevent" editPage={true} {...props}>Händelse</BackButton>
      <ProntoContainer>
        <div className="form c-12">
          <div className="form-row">
            <div className="form-section c-6 pr-10">
                    Datum<input className={GetCssInput(mode)} type="text" disabled name="createdAt" value={Moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}/>
                    CurityUserClientId<input className={GetCssInput(mode)} type="text" disabled name="CurityUserClientId" defaultValue={data.personInformation.curityUserClientId}/>
                    CurityUserHouseholdId<input className={GetCssInput(mode)} type="text" disabled name="CurityUserHouseholdId" defaultValue={data.personInformation.curityUserHouseholdId}/>
                    curityId<input className={GetCssInput(mode)} type="text" disabled name="curityId" defaultValue={data.personInformation.curityId}/>
                    CurityUserId<input className={GetCssInput(mode)} type="text" disabled name="CurityUserId" defaultValue={data.personInformation.curityUserId}/>
                    CurityUserSub<input className={GetCssInput(mode)} type="text" disabled name="CurityUserSub" defaultValue={data.personInformation.curityUserSub}/>
                    Acr<input className={GetCssInput(mode)} type="text" disabled name="Acr" defaultValue={data.personInformation.curityAcr}/>
      
            </div>  

            {data && data.openHours &&
                    <div className="form-section c-6 pr-10">
                      <span className="label">Öppetider</span>
                      <hr/>
                      <ul className="no-style drop-shadow">
                        <li>
                          <div className="list-item list-item__heading">
                            <div className="list-item__element label hidden-down">Dag</div>
                            <div className="list-item__element label hidden-down">Öppnar</div>          
                            <div className="list-item__element label hidden-down">Stänger</div>     
                            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>     
                          </div>
                        </li>
                      
                      </ul>
                    </div>  
            }
          </div>   
        </div>
      </ProntoContainer>
    </div>
  )
  
}


export default DoorEvent

