import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getData, sendData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import ContentContainer from '../layout/ContentContainer'
import { emptyGuid } from "../../utilis/Constants"
import GridRow from '../Grid/GridRow'
import GridItem from '../Grid/GridItem'
import SelectList from '../Form/SelectList'
import DatePicker from '../Form/DatePicker'
import Button from '../UI/Button'
import { defaultNotifications } from '../../utilis/Notifications'
import QueryStringBuilder from '../../utilis/QueryStringBuilder'
import OrderList from './OrderList'
import Textbox from '../Form/Textbox'

const Orders = (
  props
) => {
  const navigate = useNavigate()
  const defaultDate = new Date(new Date().setHours(0, 0, 0, 0))
  const storeId = props.match.params.storeid
  const queryStrings = new URLSearchParams(props.history.location.search)

  const [channels, setChannels] = useState([])
  const [stores, setStores] = useState()
  const [data, setData] = useState({
    orders: [],
    continuationToken: ""
  })
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [fetchingData, setFetchingData] = useState(false)
  const [isSearchPerformed, setIsSearchPerformed] = useState(false)
  const [selectedStoreId, setSelectedStoreId] = useState(queryStrings.get("storeId") ? queryStrings.get("storeId") : storeId)
  const [selectedChannelTypeId, setSelectedChannelTypeId] = useState(queryStrings.get("channelId") ? queryStrings.get("channelId") : emptyGuid)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [orderTotalPrice, setOrderTotalPrice] = useState(queryStrings.get("totalPrice") ? queryStrings.get("totalPrice") : null)

  useEffect(() => {
    // Init dates
    setStartDate(queryStrings.get("startDate") ? new Date(queryStrings.get("startDate")) : getDefaultDate())
    if (queryStrings.get("endDate")) {
      setEndDate(new Date(queryStrings.get("endDate")))
    }
    else {
      handleEndDateChange(getDefaultDate())
    }

    const fetchData = async () => {
      setIsInitialFetch(true)

      try {
        let [channelResult, storeResult] = await Promise.all([
          getData('api/system/channels'),
          getData('api/stores/v2')
        ])

        if (channelResult.ok) {
          setChannels(channelResult.data)
        } else {
          defaultNotifications.error(Placeholder.Channels)
        }

        if (storeResult.ok) {
          setStores(storeResult.data)
        } else {
          defaultNotifications.error(Placeholder.Stores)
        }
      } catch (error) {
        defaultNotifications.error()
      }

      setIsInitialFetch(false)
    }

    fetchData()
  }, [])

  useEffect(() => {
    var historyState = getHistoryState()
    if (historyState) {
      setData({
        orders: historyState.data.orders,
        continuationToken: historyState.data.continuationToken
      })
      setIsSearchPerformed(true)
    }
  }, [])

  async function fetchOrders(isInitialSearch, updateBrowserHistory = false) {
    setFetchingData(true)

    var searchCriteria = {
      storeId: selectedStoreId,
      selectedChannelTypeId: selectedChannelTypeId,
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
      totalPrice: orderTotalPrice && parseFloat(orderTotalPrice.replace(',', '.'))
    }

    var customHeader = !isInitialSearch && data.continuationToken ? { 'x-ms-continuation': data.continuationToken } : undefined

    const res = await sendData('api/orders', searchCriteria, "POST", customHeader)
    if (res.ok) {
      let newData = {}
      if (!isInitialSearch) {
        newData = {
          orders: [...data.orders, ...res.data.orders],
          continuationToken: res.data.continuationToken
        }
      }
      else {
        newData = res.data
      }

      setData(newData)
      if (updateBrowserHistory) {
        updateHistory({ data: newData })
      }
    } else {
      defaultNotifications.error()
    }

    setFetchingData(false)
    setIsSearchPerformed(true)
  }

  function updateHistory(state) {
    const queryBuilder = new QueryStringBuilder()

    if (selectedStoreId && selectedStoreId !== emptyGuid) {
      queryBuilder.add("storeId", selectedStoreId)
    }
    if (selectedChannelTypeId && selectedChannelTypeId !== emptyGuid) {
      queryBuilder.add("channelId", selectedChannelTypeId)
    }
    if (startDate) {
      queryBuilder.add("startDate", startDate.toISOString())
    }
    if (endDate) {
      queryBuilder.add("endDate", endDate.toISOString())
    }
    if (orderTotalPrice) {
      queryBuilder.add("totalPrice", orderTotalPrice)
    }
    navigate("?" + queryBuilder.build(), { replace: true, state })
  };

  const getHistoryState = () => {
    const historyState = props.history ? props.history.location.state : null
    if (historyState && historyState.data) {
      return historyState
    }
    return null
  }

  async function onSearchClick() {
    await fetchOrders(true, true)
  }

  const handleLoadMore = async () => {
    await fetchOrders(false, false)
  }

  const handleSelectedChannelTypeIdChange = (e) => {
    e.persist()
    setSelectedChannelTypeId(e.target.value)
  }

  const handleSelectedStoreIdChange = (e) => {
    e.persist()
    setSelectedStoreId(e.target.value)
  }

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999)
    setEndDate(date)
  }

  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 0, 0)
    setStartDate(date)
  }

  const getDefaultDate = () => {
    return new Date(defaultDate.getTime())
  }

  // Regex to validate price format. (e.g. 123,45)
  const numberRegex = /^[0-9]*,?[0-9]*$/

  const handleOrderTotalAmountChange = (e) => {
    e.persist()
    numberRegex.test(e.target.value) && setOrderTotalPrice(e.target.value)
  }

  return (
    <ContentContainer
      title="Ordrar"
      backDisabled={!storeId}
      fetchingData={isInitialFetch}
      expandContent={true}
      backTo={`/stores/${storeId}/view`}
    >
      <div className="top-container gutter bg--white">
        <GridRow>
          <GridItem cols="2">
            <SelectList
              label="Köpkanal"
              options={channels}
              optionValueField="value"
              name="selectedChannelTypeId"
              onChange={handleSelectedChannelTypeIdChange}
              defaultOption="Alla köpkanaler"
              value={selectedChannelTypeId}
            />
          </GridItem>
          <GridItem cols="2">
            <SelectList
              label="Butik"
              options={stores}
              optionValueField="id"
              name="selectedStoreId"
              onChange={handleSelectedStoreIdChange}
              defaultOption="Alla butiker"
              value={selectedStoreId}
              disabled={storeId}
            />
          </GridItem>
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, från"
              name="StartDate"
              selectedDate={startDate ? startDate : getDefaultDate()}
              onChange={(date) => handleStartDateChange(date)}
            />
          </GridItem>
          <GridItem cols="2">
            <DatePicker
              label="Leveransdatum, till"
              name="EndDate"
              selectedDate={endDate ? endDate : getDefaultDate()}
              onChange={(date) => handleEndDateChange(date)}
            />
          </GridItem>
          <GridItem cols="2">
            <Textbox
              label="Ordersumma"
              name="orderTotalAmount"
              type="text"
              placeholder="Sök på ordersumma"
              value={orderTotalPrice}
              onChange={(amount) => handleOrderTotalAmountChange(amount)}
            />
          </GridItem>
        </GridRow>

        <Button onClick={onSearchClick} loading={fetchingData}>{Placeholder.Search}</Button>
      </div>

      {data && data.orders && isSearchPerformed &&
        <OrderList
          orders={data.orders}
          hasMore={data.continuationToken}
          onLoadMore={handleLoadMore}
          fetchingData={fetchingData}
          clickable={true}
        />
      }
    </ContentContainer>
  )
}

export default Orders
