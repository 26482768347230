
import React, { useState, useEffect } from 'react'
import {getData,postData} from '../../services/Ajax'
import {ProntoContainer,ProntoContainerTop} from '../layout/Containers'
import Select from '../layout/Select'
import { ClickButton,DownloadButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import Checkbox, {parseBool} from '../layout/Checkbox'
import authService from '../../services/api-authorization/AuthorizeService'

const StoreOfficeGroceryImport = (props)=> {
  const storeId = props.storeid ? props.storeid: props.match.params.storeid

  const [updatePrice, setUpdatePrice] = useState(true)
  const [updateOffer, setUpdateOffer] = useState(true)
  const [justifyPrice, setJustifyPrice] = useState(false)
  const [store, setStore] = useState({})
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [accesToken, setAccesToken] = useState(null)
  const [data, setData] = useState({
    importStarted: false, 
    isReady : false,
    message:"Hämta ny fil och starta import"} )

  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        //Store Data
        const response = await getData(
          '/api/import/store/' + storeId
        )
        const result = await response
        setStore(result)
        const token = await authService.getAccessToken()
        setAccesToken(token)
      }
      
      fetchData()
    }
  }, [])


  const handleInputChange = (event) => {
    event.persist()

    console.log(data)
    data.isReady = true
    setData({...data})
    setSelectedChannel(event.target.value)
    console.log(data)

  }
  const handlePostData = async (event) => {
    event.persist()
    if(storeId && selectedChannel){
      const url = `/api/import/store/${storeId}/storeoffice/`
      if(url){
        data.message = "Startar import..."
        data.importStarted = true
        setData({...data})
        const d = {
          StoreId : storeId,
          ChannelTypeId : selectedChannel,
          UpdatePrice : updatePrice,
          JustifyPrice : justifyPrice,
          UpdateOffer : updateOffer,
          SourceBms: store.sourceBms,
          SourceCustomerNumber: store.customerNumber,
        }
        const response = await postData(
          url,d,true
        )
        var result = await response
        setData({...result})
      }else{
        console.error("url not defined")
      }
    }else{
      alert("välj channel")
    }
    
  }

  const handleCheckboxInputChange = (event) => {
    var newValue = parseBool(event.target.value)
    if(event.target.name === 'updatPrice'){
      setUpdatePrice(newValue)
    }
    else if(event.target.name === 'justifyPrice'){
      setJustifyPrice(newValue)
    }
    else if(event.target.name === 'updateOffer'){
      setUpdateOffer(newValue)
    }
  }

  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Importera produkter från fil </BackButton>
      <ProntoContainer>
        <div className="form">
          <div className="form-section">
            {
              store && store.importedFile && accesToken && <div className="row">
                <span className="label mb-10 flex">Senast inlästa fil {
                  <DownloadButton id="importgrocerydownload" className="link-edit nav-link" titleClassName="pt-0" title={store.importedFile.originalFileName} token={accesToken} to={`api/import/store/${storeId}/downloadGroceryImport`} />} är från den {store.importedFile.lastModified}</span>
              </div>
            }
              
            <div className="row">
              { store && store.channels && store.channels.length > 0 &&  <Select data={ store.channels} name="channelId" handleEvent={handleInputChange} defaultOption="Välj" defaultValue={selectedChannel}/>   }
              <span className="label form-label ml-10">Kanal</span>
            </div>
            <div className="row">
              <Checkbox checked={updatePrice} name="updatPrice" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Uppdatera pris</span> 
            </div>
            <div className="row">
              <Checkbox checked={justifyPrice} name="justifyPrice" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Avrunda pris</span> 
            </div>
            <div className="row">
              <Checkbox checked={updateOffer} name="updateOffer" handleEvent={handleCheckboxInputChange}/>  
              <span className="label form-label">Aktivera artikel om det är en ny</span> 
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={data.message} disabled={data && !data.isReady} onClick={handlePostData}/>
            </div>
            {data && data.failMessage && <div className="row">
              {data.failMessage}
            </div>}
          </div>
        </div>
      </ProntoContainer>
              
        
              
    </ProntoContainerTop>
  )

}


export default StoreOfficeGroceryImport
