import React from 'react'
import { Link } from 'react-router-dom'
import { sendServiceData, getServiceData } from '../../services/HttpClient'
import { TopAddButton } from '../layout/Button'
import { ProntoContainerTop } from '../layout/Containers'
import BackButton from '../backbutton/BackButton'
import { ItemListColumn } from '../ItemList/ItemList'
import LoadingContainer from '../layout/LoadingContainer'
import Placeholder from '../layout/Placeholder'
import CheckboxV2 from '../layout/CheckboxV2'
import { defaultNotifications } from '../../utilis/Notifications'
// import SortableList from '../ItemList/SortableList'
import { PlusIconLarge } from '../layout/Icons'
import checkMixedImageDimensions from '../../utilis/CheckMixedImageDimensions'
import MessageBox from '../layout/MessageBox'
import CrossSortableList from '../ItemList/CrossSortableList'
import { DragDropContext } from 'react-beautiful-dnd'

function CateringIndex({ match, ...props }) {
  const {
    params: { storeid },
  } = match || {}
  const [groups, setGroups] = React.useState([])
  const [fetching, setFetching] = React.useState(false)
  const prevGroups = React.useRef([])

  React.useEffect(() => {
    let componentMounted = true

    async function getCategories() {
      setFetching(true)
      try {
        const res = await getServiceData(
          'FoodApi',
          'v1',
          `/catering/${storeid}/maincategories`,
        )

        if (res.ok && componentMounted) {
          setGroups(res.data)
        }
      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }
    if (storeid) {
      getCategories()
    }
    return () => {
      componentMounted = false
    }
  }, [storeid])

  React.useEffect(() => {
    let componentMounted = true
    if (!groups.length) return
    prevGroups.current = groups

    const handleMixedImages = async () => {
      let updatedData = await Promise.all(
        groups.map(async (obj) => {
          let mixedImageDimensions = await checkMixedImageDimensions(
            obj.categories.map((category) => category.imageUrl),
          )
          return { ...obj, mixedImageDimensions }
        }),
      )
      let hasMixedDimensions = updatedData.some(
        (obj) => obj.mixedImageDimensions,
      )
      if (
        componentMounted &&
        hasMixedDimensions &&
        !prevGroups.current.every(
          (obj, index) =>
            obj.mixedImageDimensions ===
            updatedData[index].mixedImageDimensions,
        )
      ) {
        setGroups(updatedData)
        prevGroups.current = updatedData
      } else if (
        !hasMixedDimensions &&
        !prevGroups.current.every(
          (obj, index) =>
            obj.mixedImageDimensions ===
            updatedData[index].mixedImageDimensions,
        )
      ) {
        setGroups(updatedData)
        prevGroups.current = updatedData
      }
    }
    handleMixedImages()
    return () => {
      componentMounted = false
    }
  }, [groups])

  async function onChangeActive(checked, categoryId) {
    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/${categoryId}/active/${checked}`,
      null,
      'PATCH',
    )
    if (!res.ok) {
      defaultNotifications.error(Placeholder.Category, res.statusText)
    }

    const category = groups.reduce(function (filtered, group) {
      if (group.categories.filter((c) => c.id === categoryId).length > 0) {
        const category = group.categories.filter((c) => c.id === categoryId)[0]
        if (category) {
          filtered.push(category)
        }
      }
      return filtered
    }, [])[0]

    if (category) {
      category.active = checked
      setGroups([...groups])
    }
  }

  async function onSortCategory(sortedCategories) {
    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/sort`,
      sortedCategories,
      'PATCH',
    )

    if (!res.ok) {
      defaultNotifications.error(
        Placeholder.Category,
        'Något gick fel vid sortering av kategorier',
      )
    }
  }

  function handleDragEnd(result) {
    const { destination, source, draggableId, combine } = result
    let isSorted = true
    if ((!combine && !destination) || !source.droppableId) {
      // not sorted if dropped outside the droppable area
      isSorted = false
      return
    }
    if (
      destination &&
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      // not sorted if the item was dropped in the same place
      isSorted = false
    }

    if (
      (source.droppableId !== destination?.droppableId && !combine) ||
      (combine && combine.droppableId !== source.droppableId)
    ) {
      const id = draggableId
      const newMainCategoryId = destination?.droppableId || combine?.droppableId

      if (id && newMainCategoryId && storeid) {
        onNewMainCategory({
          id,
          newMainCategoryId,
          prevMainCategoryId: source.droppableId,
        })
      }
    } else if (destination) {
      const data = groups.find(
        (group) => group.id === source.droppableId,
      ).categories
      const newData = [...data]

      if (isSorted) {
        const draggableIndex = newData.findIndex(
          (data) => data['id'] === draggableId,
        )
        const draggableItem = { ...newData[draggableIndex] }
        newData.splice(source.index, 1)
        newData.splice(destination.index, 0, draggableItem)
      }

      const sortedItems = newData.map((data, index) => ({
        ...data,
        ['sortOrder']: index + 1,
      }))

      onDragEnd({
        isSorted,
        sortedItems,
        draggableId,
        source,
        destination,
      })
    } else {
      defaultNotifications.error(
        Placeholder.Category,
        'Något gick fel vid sortering av kategorier',
      )
    }
  }

  const onNewMainCategory = async ({
    id,
    newMainCategoryId,
    prevMainCategoryId,
  }) => {
    const res = await sendServiceData(
      'FoodApi',
      'v1',
      `/catering/${storeid}/categories/${id}/maincategory/${newMainCategoryId}`,
      null,
      'PATCH',
    )
    if (res.ok) {
      const oldGroupData = groups.find(
        (group) => group.id === prevMainCategoryId,
      )?.categories

      const newGroupData = groups.find(
        (group) => group.id === newMainCategoryId,
      )?.categories

      const categoryRemovedData = [...oldGroupData].filter(
        (item) => item.id !== id,
      )
      const categoryAddedData = [...newGroupData, res.data]
      setGroups((prev) =>
        prev.map((group) => {
          if (group.id === prevMainCategoryId) {
            return { ...group, categories: categoryRemovedData }
          } else if (group.id === newMainCategoryId) {
            return { ...group, categories: categoryAddedData }
          } else {
            return group
          }
        }),
      )
    } else {
      defaultNotifications.error(Placeholder.Category, res.statusText)
    }
  }

  const onDragEnd = (result) => {
    const { isSorted, sortedItems, source } = result
    if (!isSorted) {
      return
    }

    const sourceIndex = groups.findIndex(
      (group) => group.id === source.droppableId,
    )
    const list = { ...groups[sourceIndex] }
    let sortedCategories = sortedItems.map((item) => ({
      id: item.id,
      sortOrder: item.sortOrder,
    }))
    onSortCategory(sortedCategories)

    const newGroup = {
      ...list,
      categories: sortedItems,
    }

    setGroups(
      groups.map((group) => {
        if (group.id === source.droppableId) {
          return { ...newGroup }
        } else {
          return group
        }
      }),
    )
  }

  return (
    <div>
      <ProntoContainerTop className="scroll-x-mobile">
        <TopAddButton
          className="flex-right btn-control btn--red"
          to={`/stores/${storeid}/catering/groups/create`}
          title={`Lägg till rubrik`}
        />
        <BackButton
          editPage={false}
          disabled={!storeid}
          match={match}
          to={`/stores/${storeid}/catering`}
          {...props}
        >
          Catering – Hantera sortiment
        </BackButton>
        <div>
          <LoadingContainer loading={fetching}>
            <DragDropContext onDragEnd={handleDragEnd}>
              {groups.length > 0 &&
                groups.map((group) => {
                  return (
                    <div key={group.id}>
                      <div className="groupHeader">
                        <div className="groupName">
                          <h3 className="groupHeading">{group.name}</h3>
                          <Link
                            className="category-edit"
                            to={`/stores/${storeid}/catering/groups/${group.id}`}
                          >
                            Ändra
                          </Link>
                        </div>
                        <Link
                          className="category-button"
                          to={`/stores/${storeid}/catering/groups/${group.id}/categories/create`}
                        >
                          <PlusIconLarge
                            color="#CF2E05"
                            width="20"
                            height="20"
                          />
                          Lägg till kategori
                        </Link>
                      </div>
                      <CrossSortableList
                        data={group.categories}
                        droppableId={group.id}
                        shadow={false}
                        showEmpty={false}
                        renderRow={(item) => (
                          <React.Fragment>
                            <ItemListColumn
                              id="name"
                              label={Placeholder.Categories}
                              columns="10"
                            >
                              <Link
                                to={`/stores/${storeid}/catering/categories/${item.id}`}
                                className="list-item__link"
                              >
                                {item.name}
                              </Link>
                              <span> ({item.productCount})</span>
                            </ItemListColumn>
                            <ItemListColumn
                              id="active"
                              label={Placeholder.Active}
                              columns="2"
                            >
                              <CheckboxV2
                                id={`active-${item.id}`}
                                checked={item.active}
                                onChange={(e) =>
                                  onChangeActive(e.target.checked, item.id)
                                }
                              />
                            </ItemListColumn>
                          </React.Fragment>
                        )}
                      />
                      {group.mixedImageDimensions && (
                        <MessageBox
                          type="info"
                          withTitle={true}
                          title={`OBS! Kategoribilder i ${group.name} har olika format`}
                          message="För bästa visuella användarupplevelse rekommenderar vi att använda endast ett bildformat, vi rekommenderar 16:9."
                        />
                      )}
                    </div>
                  )
                })}
            </DragDropContext>
          </LoadingContainer>
        </div>
      </ProntoContainerTop>
    </div>
  )
}
export default CateringIndex
