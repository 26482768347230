import React from 'react'
import {ProntoContainer} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'


const MaintenancePage = (props) => {
  const mode = props.mode


  return (

    <div>
      <BackButton modePrefix={mode} editPage={true} {...props}></BackButton>
      <ProntoContainer>
        <h1>Ursäkta röran!</h1>
        <div>Nu utför vi ett systemunderhåll!</div>
      </ProntoContainer>
    </div>
  )

}


export default MaintenancePage

