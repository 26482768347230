export const OnChangeEntityType = {
  VariantCategory: "variantCategory",
  Variant: "variant"
}

export const OnChangeAction = {
  Create: "create",
  Update: "update",
  Delete: "delete"
}

/**
 * Generates an unique combination of variants from a list of variant categories
 * @param {Array.<Object>} variantCategories 
 * @param {Array.<Object>} currentVariants Current variants. Used for populating generated variants with data.
 * @param {String} variantCategoryAction 
 * @returns An array of generated variants
 */
export function generateVariants(variantCategories, currentVariants, variantCategoryAction) {
  let categoryValues = variantCategories.map(vc => vc.values.map(v => v.name))

  let valueCombinations = []
  if (categoryValues.length > 1) {
    valueCombinations = cartesian(...categoryValues)
  } else {
    categoryValues[0].forEach(value => {
      valueCombinations.push([value])
    })
  }

  let generatedVariants = []
  valueCombinations.forEach(valueCombination => {
    let variant = {
      name: valueCombination.join("/"),
      values: valueCombination.map((v, i) => ({
        categoryName: variantCategories[i].name,
        categoryValueName: v,
      })),
    }

    if (variantCategoryAction === OnChangeAction.Create) {
      let existingItem = currentVariants.find(i => i.name === valueCombination.slice(0, valueCombination.length - 1).join("/"))
      if (existingItem) {
        variant = {
          ...existingItem,
          ...variant,
          id: undefined
        }
      } else {
        variant.active = true
      }
    } else if (variantCategoryAction === OnChangeAction.Update) {
      let existingItem = currentVariants.find(i => i.name === valueCombination.join("/"))
      if (existingItem) {
        variant = {
          ...existingItem,
          ...variant
        }
      } else {
        variant.active = true
      }
    } else if (variantCategoryAction === OnChangeAction.Delete) {
      variant.active = true
    }

    generatedVariants.push(variant)
  })

  return generatedVariants
}

const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))