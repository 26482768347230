import React from "react"
import { useForm } from "react-hook-form"
import { sendServiceData, getData } from "../../services/HttpClient"
import Placeholder from "../layout/Placeholder"
import { defaultNotifications, showNotification } from "../../utilis/Notifications"
import NotificationTypes from "../../utilis/NotificationTypes"
import FormActions from "../Form/FormActions"
import ContentContainer from "../layout/ContentContainer"
import SelectList from "../Form/SelectList"
import "./FormStyles.css"

function CopyStore({ history, match }) {
  const {
    params: { storeid },
  } = match || {}
  const cateringUrl = `/stores/${storeid}/catering`

  const { register, handleSubmit, formState: { errors } } = useForm()

  const [isInitialLoad, setIsInitialLoad] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [stores, setStores] = React.useState([])
  const [fromStore, setFromStore] = React.useState({})

  React.useEffect(() => {
    const fetchStores = async () => {
      if (storeid) {
        setIsInitialLoad(true)
        const res = await getData('/api/stores')
        if (res.ok) {
          setStores(res.data.stores)
        } else {
          defaultNotifications.error()
        }
        setIsInitialLoad(false)
      }
    }

    fetchStores()
  }, [])

  const onSelectChange = (e) => {
    const fromStoreId = e.target.value
    const fromStore = stores.find(x => x.id === fromStoreId)
    setFromStore(fromStore)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()

    let confirmed = window.confirm(`Är du säker på att du vill kopiera innehåll från "${fromStore.name}"?`)
    if (!confirmed) {
      return
    }

    setSaving(true)
    try {
      let data = {
        FromStoreID: fromStore.id,
        ToStoreID: storeid
      }

      const res = await sendServiceData(
        'FoodApi',
        'v1', 
        `/catering/copy`,
        data,
        "POST"
      )
      if (res.ok) {
        showNotification(Placeholder.Catering.CopyStore.Title, `Innehåll från "${fromStore.name}" har kopierats!`, NotificationTypes.SUCCESS)
        redirectToCatering()
      } else {
        defaultNotifications.error(Placeholder.Catering.CopyStore.Title)
      }
    } catch (error) {
      console.log(error)
    }
    setSaving(false)
  }

  function redirectToCatering() {
    history.push(cateringUrl)
  }

  return (
    <ContentContainer
      backText={Placeholder.Catering.Name}
      title={Placeholder.Catering.CopyStore.Title}
      backTo={cateringUrl}
      loading={isInitialLoad}
    >
      <p>{Placeholder.Catering.CopyStore.Info}</p>
      <form className="form" id="copyStoreForm" onSubmit={handleSubmit(onSubmit)}>
        <SelectList
          label={Placeholder.Catering.CopyStore.FromStore}
          defaultOption="(Välj butik)"
          defaultOptionValue=""
          options={stores}
          optionValueField="id"
          optionDisplayField="name"
          name="copyFromStore"
          id="copyFromStore"
          value={fromStore ? fromStore.id : ""}
          onChange={onSelectChange}
          validationRegister={register}
          validation={{ required: true }}
          errors={errors}
        />

        <FormActions 
          form="copyStoreForm"
          onCancel={redirectToCatering}
          saving={saving}
          saveText={Placeholder.Catering.CopyStore.Title}
          
        />
      </form>
    </ContentContainer>
  )
}

export default CopyStore
