import React from 'react'

const LoadingContainer = (props) => {
  const loading = props.loading
  const text = props.text || "Laddar…"
  return (
    <div>
      {loading ? (
        <div className="loading-items">
          <p>{text}</p>
        </div>
      ) : props.children }
    </div>
  )
}

export default LoadingContainer
