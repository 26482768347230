import React from "react"
import { Link } from "react-router-dom"
import Button from "./Button"
import css from './LinkButton.module.css'

function LinkButton({
  to,
  children,
  ...props
}) {
  return (
    <Link
      to={to}
      className={css.linkButton}
    >
      <Button {...props}>
        {children}
      </Button>
    </Link>
  )
}

export default LinkButton