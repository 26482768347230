
import Mode from '../../utilis/Mode'


export function GetCssInput(mode){
  if(mode === Mode.Edit){
    return "input-edit"
  }
  if(mode === Mode.Delete){
    return "input-disabled"
  }

  return "input"
   
}