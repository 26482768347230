import React from 'react'

/**
 * @deprecated Please use SelectList instead. See Components.md for more information.
 * */
const Select = (props) => {
  const name = props.name
  const handleEvent = props.handleEvent
  let defaultValue = props.defaultValue
  const defaultOption = props.defaultOption
  const data = props.data
  const disabled = props.disabled
  const handleChange = (event) => {
    if (handleEvent) {
      handleEvent(event)
    }
  }

  return (
    <select
      className='input input-select'
      defaultValue={defaultValue}
      onChange={handleChange}
      name={name}
      required={true}
      disabled={disabled}
    >
      <option key='-1' value='00000000-0000-0000-0000-000000000000'>
        {defaultOption}
      </option>
      {data.map((item) => (
        <option key={item.id} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  )
}

export default Select
