import authService from './api-authorization/AuthorizeService'
import { createBrowserHistory } from 'history'
import { GetBaseRoute } from '../utilis/Enviroment'
import { getServiceUrl } from './ServiceUrlResolver'
import { defaultNotifications } from '../utilis/Notifications'
const history = createBrowserHistory({ basename: GetBaseRoute() })

function redirectToMaintenancePage() {
  var maintainPath = '/maintenance'
  if (history.location.pathname !== maintainPath) {
    window.location = maintainPath
  }
}
async function getAccessToken() {
  const token = await authService.getAccessToken()
  if (token) {
    return token
  }
  console.log('no token')
  return ''
}

async function buildHeaders(contentType, customHeaders, requireToken = true) {
  if (!requireToken) {
    if (!contentType || contentType === '') return customHeaders

    let headers = {
      'Content-Type': contentType,
    }
    if (customHeaders) {
      headers = { ...headers, ...customHeaders }
    }
    return headers
  }
  const accessToken = await getAccessToken()
  if (!contentType || contentType === '') {
    return {
      Authorization: 'Bearer ' + accessToken,
    }
  }
  let headers = {
    'Content-Type': contentType,
    Authorization: 'Bearer ' + accessToken,
  }
  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }

  return headers
}

async function LogProblem(response) {
  if (response) {
    const contentType = response.headers.get('content-type')

    if (contentType && contentType.includes('application/problem+json')) {
      const clone = response.clone()
      console.error(await clone.json())
    } else {
      console.error(response)
    }
  }
  return
}

export async function getServiceData(
  service,
  version,
  url,
  customHeaders = [],
  requireToken = true,
) {
  const baseUrl = getServiceUrl(service, version)
  return await getData(`${baseUrl}${url}`, customHeaders, requireToken)
}

export async function sendServiceData(
  service,
  version,
  url,
  data = {},
  method = 'POST',
  customHeaders = [],
  requireToken = true,
) {
  const baseUrl = getServiceUrl(service, version)
  return await sendData(
    `${baseUrl}${url}`,
    data,
    method,
    customHeaders,
    requireToken,
  )
}

export async function getData(
  url = '',
  customHeaders = [],
  requireToken = true,
) {
  return await sendRequest(url, null, 'GET', customHeaders, requireToken)
}

export async function sendData(
  url = '',
  data = {},
  method = 'POST',
  customHeaders = [],
  requireToken = true,
) {
  return await sendRequest(url, data, method, customHeaders, requireToken)
}

async function sendRequest(
  url = '',
  data = {},
  method = 'POST',
  customHeaders = [],
  requireToken = true,
) {
  const headers = await buildHeaders(
    'application/json',
    customHeaders,
    requireToken,
  )
  var options = {
    method,
    cache: 'no-cache',
    headers: headers,
    body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(data) : null,
  }

  const response = await fetch(url, options)

  if (!response.ok) {
    await LogProblem(response)

    if (response.status === 401) {
      authService.redirectToLoginPage()
      return { ok: false }
    }
    if (response.status === 403) {
      window.location.href = '/?forbidden'
      return { ok: false }
    }
    if (response.status === 503) {
      redirectToMaintenancePage()
      return { ok: false }
    }
  }

  var responseData = null
  var responseContentType = response.headers.get('content-type')
  if (
    responseContentType &&
    (responseContentType.indexOf('application/json') !== -1 ||
      responseContentType.indexOf('application/problem+json') !== -1)
  ) {
    responseData = await response.json()
    if (
      responseData.title === 'ValidationError' &&
      responseData.errors?.length > 0
    ) {
      responseData.errors.map((error) => {
        defaultNotifications.error('Ett fel inträffade', error.message)
      })
    }
  }

  return {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    data: responseData,
  }
}

export async function downloadFile(url = '', customHeaders = []) {
  const options = {
    method: 'GET',
    cache: 'no-cache',
    headers: await buildHeaders('', customHeaders),
  }

  const response = await fetch(url, options)
  var ok = response.ok

  if (!response.ok) {
    await LogProblem(response)

    if (response.status === 401) {
      authService.redirectToLoginPage()
      return
    } else if (response.status === 503) {
      redirectToMaintenancePage()
      return
    }
  } else {
    try {
      const header = response.headers.get('Content-Disposition')
      const parts = header.split(';')
      const filename = parts[1].split('=')[1].replaceAll('"', '')

      const responseBlob = await response.blob()

      const fileUrl = window.URL.createObjectURL(responseBlob)

      var a = document.createElement('a')
      a.style = 'display: none'
      a.href = fileUrl
      a.download = filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(fileUrl)
      a.remove()
    } catch (error) {
      ok = false
      console.error(error)
    }
  }

  return {
    status: response.status,
    statusText: response.statusText,
    ok: ok,
    data: null,
  }
}
