import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import authService from '../../services/api-authorization/AuthorizeService'
import Mode from '../../utilis/Mode'
import { sendData, getData } from "../../services/HttpClient"
import Placeholder from '../layout/Placeholder'
import Checkbox from '../Form/Checkbox'
import Textbox from '../Form/Textbox'
import Textarea from '../Form/Textarea'
import MediaLibraryUploadInput from "../UI/MediaLibraryUploadInput"
import FormSection from '../Form/FormSection'
import FormActions from '../Form/FormActions'
import { defaultNotifications } from "../../utilis/Notifications"
import GridRow from '../Grid/GridRow'
import GridItem from '../Grid/GridItem'
import ContentContainer from '../layout/ContentContainer'
import ValidationErrorMessage from '../Form/ValidationErrorMessage'
import './Store.css'

const Store = (props) => {
  const navigate = useNavigate()
  const { register, reset, handleSubmit, formState: { errors } } = useForm()

  let mode = props.mode
  const storeId = props.match.params.storeid
  const [data, setData] = useState({})
  const [showComponent, setShowComponent] = useState(false)
  const [storeUser, setStoreUser] = useState(false)
  const [customerNumberError, setCustomerNumberError] = useState(false)
  const [initialCustomerNumber, setInititalCustomerNumber] = useState('')
  const [saving, setSaving] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [isInitialFetch, setIsInitialFetch] = useState(true)

  const backButtonTo = mode === Mode.Edit ? `/stores/${storeId}/view` : '/stores'
  const backButtonText = mode === Mode.Edit ? Placeholder.Store : Placeholder.Stores

  useEffect(() => {
    const fetchData = async () => {
      setIsInitialFetch(true)

      if (storeId) {
        const res = await getData('/api/stores/' + storeId)
        if (res.ok) {
          setData(res.data)
          reset(res.data)
          setShowComponent(true)
          setInititalCustomerNumber(res.data.customerNumber)
        }
      } else {
        const res = await getData('/api/system/channels/viewodels')
        if (res.ok) {
          setData({ channelTypes: res.data })
        }
      }

      setIsInitialFetch(false)
    }

    fetchData()
  }, [storeId])

  useEffect(() => {
    const getUser = async () => {
      const currentUser = await authService.getUser()
      if (currentUser.roles) {
        if (currentUser.roles.length === 1 && currentUser.roles.includes('ProntoStoreUser')) {
          setStoreUser(true)
        }
      }
    }

    getUser()
  }, [])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    let url = '/api/stores/edit'
    if (mode === Mode.Create) {
      url = '/api/stores/create'
    }

    if (mode === Mode.Edit && initialCustomerNumber !== data.customerNumber && initialCustomerNumber !== null) {
      setCustomerNumberError(true)
      setSaving(false)
    } else {
      const res = await sendData(url, data, "POST")
      if (res.ok) {
        defaultNotifications.updated(Placeholder.Store, data.name)
        navigateToStoreView()
      } else {
        defaultNotifications.error(Placeholder.Store)
      }
      setSaving(false)
    }
  }

  async function onRemove(e) {
    e.preventDefault()

    if (!window.confirm(`Vill du verkligen permanent ta bort butiken "${data.name}"?`)) {
      return
    }

    setDeleting(true)

    const res = await sendData('/api/stores/delete', data, "POST")
    if (res.ok) {
      defaultNotifications.deleted(Placeholder.Store, data.name)
      navigateToStoreView(true)
    } else {
      defaultNotifications.error(Placeholder.Store)
    }

    setDeleting(false)
  }
  
  const handleImageChange = (value) => {
    setData(() => ({ ...data, imageUrl: value }))
  }

  const handleKioskImageChange = (event) => {
    setData({
      ...data,
      [event.name]: event.target.value
    })
  }

  const handleKioskBackgroundImageChange = (event) => {
    const imgUrl = event.target.value
    setData(() => ({ ...data, kioskBackgroundImageUrl: imgUrl }))
  }

  const handleInputChange = (event) => {
    event.persist()
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const handleCheckboxChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked
    })
  }

  const handleChannelInputChange = (event) => {
    const values = event.target.name.split('__')
    const key = values[0]
    const name = values[1]

    let newData = {...data}
    const objIndex = newData.channelTypes.findIndex((x) => x.id == key)
    newData.channelTypes[objIndex][name] = event.target.checked

    setData(newData)
  }

  function navigateToStoreView(forceToStoresList = false) {
    if (mode === Mode.Create || forceToStoresList) {
      navigate(`/stores/`)
    } else {
      navigate(`/stores/${storeId}/view/`)
    }
  }

  return (
    <div>
      <ContentContainer
        title={mode === Mode.Edit ? data.name : "Ny butik"}
        fetchingData={isInitialFetch}
        backText={backButtonText}
        backTo={backButtonTo}
      >
        <form className="form" id="storeForm" onSubmit={handleSubmit(onSubmit)}>
          <GridRow>
            <GridItem cols={6}>
              <FormSection title="Adress & namn">
                <Textbox
                  label={Placeholder.Name}
                  placeholder={Placeholder.Name}
                  id="name"
                  name="name"
                  value={data.name}
                  onChange={handleInputChange}
                  validationRegister={register}
                  validation={{ required: true }}
                  errors={errors}
                />
                <Textbox
                  label={Placeholder.StreetAdress}
                  placeholder={Placeholder.StreetAdress}
                  id="streetAddress"
                  name="streetAddress"
                  value={data.streetAddress}
                  onChange={handleInputChange}
                />
                <Textbox
                  label={Placeholder.PostalCode}
                  placeholder={Placeholder.PostalCode}
                  id="postalcode"
                  name="postalcode"
                  value={data.postalcode}
                  onChange={handleInputChange}
                />
                <Textbox
                  label={Placeholder.City}
                  placeholder={Placeholder.City}
                  id="city"
                  name="city"
                  value={data.city}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
            <GridItem cols={6}>
              <FormSection title="Navigation">
                <Textbox
                  label={Placeholder.Latitude}
                  placeholder={Placeholder.Latitude}
                  id="latitude"
                  name="latitude"
                  value={data.latitude}
                  onChange={handleInputChange}
                />
                <Textbox
                  label={Placeholder.Longitude}
                  placeholder={Placeholder.Longitude}
                  id="longitude"
                  name="longitude"
                  value={data.longitude}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem cols={6}>
              <FormSection title="Om butiken">
                {showComponent && (
                  <MediaLibraryUploadInput 
                    storeId={storeId}
                    imageUrl={data.imageUrl || ''}
                    onChange={(e) => handleImageChange(e.target.value)}
                    mode={mode}
                    label='Välj logo'
                    aspectRatio={'1:1'}
                    channel={'other'}
                    className={'media-upload-heading'}
                  />
                )}
                {data.imageUrl && 
                  <div>
                    <img width='100' className='logo-image' src={data.imageUrl} />
                  </div>
                }
                <Textarea 
                  label={Placeholder.Description}
                  placeholder={Placeholder.Description}
                  id="description"
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
            <GridItem cols={6}>
              <FormSection title="Kontaktinformation">
                <Textbox
                  label={Placeholder.ContactName}
                  placeholder={Placeholder.ContactName}
                  id="contactName"
                  name="contactName"
                  value={data.contactName}
                  onChange={handleInputChange}
                />
                <Textbox
                  label={Placeholder.ContactEmail}
                  placeholder={Placeholder.ContactEmail}
                  id="contactEmail"
                  name="contactEmail"
                  value={data.contactEmail}
                  onChange={handleInputChange}
                />
                <Textbox
                  label={Placeholder.ContactPhoneNumber}
                  placeholder={Placeholder.ContactPhoneNumber}
                  id="contactPhoneNumber"
                  name="contactPhoneNumber"
                  value={data.contactPhoneNumber}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem cols={6}>
              <FormSection title="Beskrivning av maten">
                <Textarea
                  placeholder={Placeholder.FoodDescription}
                  id="openHoursTemp"
                  name="openHoursTemp"
                  value={data.openHoursTemp}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
            <GridItem cols={6}>
              <FormSection title="Pronto support">
                <Textbox
                  label={Placeholder.SupportPhone}
                  placeholder={Placeholder.SupportPhone}
                  id="supportPhoneNumber"
                  name="supportPhoneNumber"
                  value={data.supportPhoneNumber}
                  onChange={handleInputChange}
                />
              </FormSection>
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem cols={6}>
              <FormSection title="Metadata">
                <Textbox
                  label="BMS-nummer"
                  placeholder="BMS-nummer"
                  id="storeLocalNumber"
                  name="storeLocalNumber"
                  value={data.storeLocalNumber}
                  onChange={handleInputChange}
                />
                <Textbox
                  label="Kundnummer"
                  placeholder="Kundnummer"
                  id="customerNumber"
                  name="customerNumber"
                  value={data.customerNumber}
                  onChange={handleInputChange}
                  validationRegister={register}
                  validation={{ required: true }}
                  errors={errors}
                />
                {customerNumberError && (
                  <ValidationErrorMessage style={{margin: '-7px 0 15px'}} message={Placeholder.CustomerNumberError} />
                )}
                <Textbox
                  label="Account number"
                  placeholder="Account number"
                  id="accountNumber"
                  name="accountNumber"
                  value={data.accountNumber}
                  onChange={handleInputChange}
                />
                <Textbox
                  label="Organisationsnummer"
                  placeholder="Organisationsnummer"
                  id="businessRegistrationNumber"
                  name="businessRegistrationNumber"
                  value={data.businessRegistrationNumber}
                  onChange={handleInputChange}
                  validationRegister={register}
                  validation={{ required: true }}
                  errors={errors}
                />

                {!storeUser &&
                  <>
                    <Textbox
                      label="Payex Merchant Id (Swish v1)"
                      placeholder="Payex Merchant Id (Swish v1)"
                      id="payexMerchantId"
                      name="payexMerchantId"
                      value={data.payexMerchantId}
                      onChange={handleInputChange}
                    />
                    <Textbox
                      label="Payex Access Token (Swish v1)"
                      placeholder="Payex Access Token (Swish v1)"
                      id="payexAccessToken"
                      name="payexAccessToken"
                      value={data.payexAccessToken}
                      onChange={handleInputChange}
                    />
                  </>
                }
              </FormSection>

              <FormSection title="Status">
                <Checkbox
                  name="active"
                  label={Placeholder.Active}
                  info={Placeholder.ActiveExplanatory}
                  checked={data.active}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  name="paused"
                  label={Placeholder.Paused}
                  checked={data.paused}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  name="reportingActive"
                  label="Aktivera rapportering (AoB Storeoffice)"
                  checked={data.reportingActive}
                  onChange={handleCheckboxChange}
                />
                <Checkbox
                  name="subStoreReportingActive"
                  label="Inkludera i AoB-rapportering på samma BMS"
                  checked={data.subStoreReportingActive}
                  onChange={handleCheckboxChange}
                />
              </FormSection>

              <FormSection title={Placeholder.Channels}>
                {data.channelTypes && data.channelTypes.map((channel) => 
                  <Checkbox
                    key={channel.id}
                    name={ `${channel.id}__checked`}
                    label={channel.displayName}
                    checked={channel.checked}
                    onChange={handleChannelInputChange}
                  />
                )}
              </FormSection>
            </GridItem>
            <GridItem cols={6}>
              <FormSection title="Kiosk">
                <div>
                  {showComponent && (
                    <>
                      <MediaLibraryUploadInput
                        storeId={storeId}
                        imageUrl={data.kioskImageUrl || ''}
                        onChange={(e) => handleKioskImageChange({ ...e, name: 'kioskImageUrl' })}
                        mode={mode}
                        label='Välj kioskens logo'
                        aspectRatio={'1:1'}
                        channel={'icakiosk'}
                        className={'media-upload-heading'}
                      />
                      {data.kioskImageUrl && data.kioskImageUrl.length > 0 && (
                        <MediaLibraryUploadInput
                          storeId={storeId}
                          imageUrl={data.kioskAlternateImageUrl || ''}
                          onChange={(e) => handleKioskImageChange({ ...e, name: 'kioskAlternateImageUrl' })}
                          mode={mode}
                          label='Välj kioskens alternativa logo'
                          aspectRatio={'1:1'}
                          channel={'icakiosk'}
                          className={'media-upload-heading'}
                        />
                      )}
                    </>
                  )}
                  {showComponent && (
                    <MediaLibraryUploadInput
                      storeId={storeId}
                      imageUrl={data.kioskBackgroundImageUrl || ''}
                      onChange={(e) => handleKioskBackgroundImageChange(e)}
                      mode={mode}
                      label='Välj kioskens bakgrundsbild'
                      aspectRatio={'16:9'}
                      channel={'icakiosk'}
                      className={'media-upload-heading'}
                    />
                  )}
                </div>
              </FormSection>
            </GridItem>
          </GridRow>

          <FormActions 
            form="storeForm"
            onCancel={navigateToStoreView}
            showDelete
            onDelete={onRemove}
            saving={saving}
            deleting={deleting}
          />
        </form>
      </ContentContainer>
    </div>
  )
}

export default Store
