/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import ContentContainer from "../layout/ContentContainer"
import { useForm } from 'react-hook-form'
import Textbox from '../Form/Textbox'
import SelectBox from "../Form/SelectBox"
import FormActions from '../Form/FormActions'
import { defaultNotifications } from "../../utilis/Notifications"

const VendingEmployee = (props) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const [channels, setChannels] = useState()
  const [stores, setStores] = useState()
  const [vendingEmployee, setVendingEmployee] = useState()
  const [fetchingData, setFetchingData] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const history = props.history

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      const channelsResponse = await getServiceData('ChannelApi', 'v1', '/channels')
      setChannels(channelsResponse.data)
      const storesResponse = await getServiceData('FoodApi', 'v1', '/store')
      setStores(storesResponse.data)

      switch (props.mode) {
        case Mode.Create:
          setVendingEmployee({ description: null, channels: [], stores: [] })
          break
        case Mode.Edit: {
          const vendingEmployeeKey = props.match.params.vendingEmployeeKey
          const response = await getServiceData('AuthApi', 'v1', `/vending/${vendingEmployeeKey}`)
          if (response.ok) {
            setVendingEmployee(response.data)
            reset(response.data)
          }
          break
        }
      }
      setFetchingData(false)
    }

    fetchData()
  }, [])

  function getTitle() {
    switch (props.mode) {
      case Mode.Create:
        return Placeholder.VendingEmployee.CreateTitle
      case Mode.Edit:
        return Placeholder.VendingEmployee.EditTitle
    }
  }

  function handleInputChange(e) {
    var newVendingEmployee = { ...vendingEmployee }
    newVendingEmployee[e.target.name] = e.target.value
    setVendingEmployee(newVendingEmployee)
  }

  function handleChannelsChange(selectedOptions) {
    const newVendingEmployee = { ...vendingEmployee }
    newVendingEmployee.channels = []
    selectedOptions.forEach(option => {
      newVendingEmployee.channels.push({ channelId: option.id, channelName: option.displayName })
    })
    setVendingEmployee(newVendingEmployee)
  }

  function handleStoresChange(selectedOptions) {
    const newVendingEmployee = { ...vendingEmployee }
    newVendingEmployee.stores = []
    selectedOptions.forEach(option => {
      newVendingEmployee.stores.push({ storeId: option.id, storeName: option.displayName })
    })
    setVendingEmployee(newVendingEmployee)
  }

  function navigateBack() {
    history.push(window.location.pathname + '../')
  }

  async function onDelete(e) {
    e.preventDefault()

    let deleteMsg = `Är du säker på att du vill ta bort "${vendingEmployee.key}`
    if (vendingEmployee.description) {
      deleteMsg += ` - ${vendingEmployee.description}`
    }
    deleteMsg += '"?'
    if (!window.confirm(deleteMsg)) {
      return
    }

    setDeleting(true)

    const res = await sendServiceData('AuthApi', 'v1', `/vending/${vendingEmployee.key}`, null, "DELETE")

    if (res.ok) {
      defaultNotifications.deleted(Placeholder.VendingEmployee.VendingEmployee, vendingEmployee.key)
      navigateBack()
    } else {
      defaultNotifications.error(Placeholder.VendingEmployee.VendingEmployee)
    }

    setDeleting(false)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    let method = ''
    switch (props.mode) {
      case Mode.Create:
        method = 'POST'
        break
      case Mode.Edit:
        method = 'PUT'
        break
    }

    const res = await sendServiceData('AuthApi', 'v1', '/vending', vendingEmployee, method)

    if (res.ok) {
      switch (props.mode) {
        case Mode.Create:
          defaultNotifications.created(Placeholder.VendingEmployee.VendingEmployee, vendingEmployee.key)
          break
        case Mode.Edit:
          defaultNotifications.updated(Placeholder.VendingEmployee.VendingEmployee, vendingEmployee.key)
          break
      }
      navigateBack()
    }
    else {
      defaultNotifications.error(Placeholder.VendingEmployee.VendingEmployee)
    }

    setSaving(false)
  }

  return (
    <ContentContainer
      title={getTitle()}
      fetchingData={fetchingData}
      backTo="../"
    >
      {channels && stores && vendingEmployee &&
        <form className='form' id='employeeForm' onSubmit={handleSubmit(onSubmit)}>
          <Textbox
            label={Placeholder.VendingEmployee.Key}
            id='key'
            name='key'
            value={vendingEmployee.key}
            onChange={handleInputChange}
            disabled={props.mode === Mode.Edit}
            validationRegister={register}
            validation={{
              required: true,
              maxLength: 255,
            }}
            errors={errors}
          />
          <Textbox
            label={Placeholder.Description}
            id='description'
            name='description'
            value={vendingEmployee.description}
            onChange={handleInputChange}
            validationRegister={register}
            validation={{
              maxLength: 255,
            }}
            errors={errors}
          />
          <SelectBox
            label={Placeholder.Channels}
            name="channels"
            options={channels}
            getOptionLabel={channel => channel.displayName}
            getOptionValue={channel => channel.id}
            value={channels.filter(c => vendingEmployee.channels.find(ec => ec.channelId == c.id))}
            isMulti
            onChange={handleChannelsChange}
          />
          <SelectBox
            label={Placeholder.Stores}
            name="stores"
            options={stores}
            getOptionLabel={store => store.name}
            getOptionValue={store => store.id}
            value={stores.filter(s => vendingEmployee.stores.find(es => es.storeId == s.id))}
            isMulti
            onChange={handleStoresChange}
          />
          <FormActions
            form="employeeForm"
            onCancel={navigateBack}
            showDelete={props.mode === Mode.Edit}
            onDelete={onDelete}
            saving={saving}
            deleting={deleting}
          />
        </form>
      }
    </ContentContainer>
  )
}
export default VendingEmployee
