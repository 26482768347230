/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { getServiceData, sendServiceData } from '../../services/HttpClient'
import Placeholder from '../layout/Placeholder'
import Mode from '../../utilis/Mode'
import ContentContainer from "../layout/ContentContainer"
import { useForm } from 'react-hook-form'
import Textbox from '../Form/Textbox'
import SelectBox from "../Form/SelectBox"
import FormActions from '../Form/FormActions'
import { defaultNotifications } from "../../utilis/Notifications"

const Admin = (props) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const [channels, setChannels] = useState()
  const [admin, setAdmin] = useState()
  const [fetchingData, setFetchingData] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const history = props.history

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData(true)
      const channelResponse = await getServiceData('ChannelApi', 'v1', '/channels')
      setChannels(channelResponse.data)

      switch (props.mode) {
        case Mode.Create:
          setAdmin({ description: null, channels: [] })
          break
        case Mode.Edit: {
          const adminKey = props.match.params.adminKey
          const response = await getServiceData('AuthApi', 'v1', `/admin/${adminKey}`)
          if (response.ok) {
            setAdmin(response.data)
            reset(response.data)
          }
          break
        }
      }
      setFetchingData(false)
    }

    fetchData()
  }, [])

  function getTitle() {
    switch (props.mode) {
      case Mode.Create:
        return Placeholder.Admin.CreateTitle
      case Mode.Edit:
        return Placeholder.Admin.EditTitle
    }
  }

  function handleInputChange(e) {
    var newAdmin = { ...admin }
    newAdmin[e.target.name] = e.target.value
    setAdmin(newAdmin)
  }

  function handleChannelsChange(selectedOptions) {
    const newAdmin = { ...admin }
    newAdmin.channels = []
    selectedOptions.forEach(option => {
      newAdmin.channels.push({ channelId: option.id, channelName: option.displayName })
    })
    setAdmin(newAdmin)
  }

  function navigateBack() {
    history.push(window.location.pathname + '../')
  }

  async function onDelete(e) {
    e.preventDefault()

    let deleteMsg = `Är du säker på att du vill ta bort "${admin.key}`
    if (admin.description) {
      deleteMsg += ` - ${admin.description}`
    }
    deleteMsg += '"?'
    if (!window.confirm(deleteMsg)) {
      return
    }

    setDeleting(true)

    const res = await sendServiceData('AuthApi', 'v1', `/admin/${admin.key}`, null, "DELETE")

    if (res.ok) {
      defaultNotifications.deleted(Placeholder.Admin.Admin, admin.key)
      navigateBack()
    } else {
      defaultNotifications.error(Placeholder.Admin.Admin)
    }

    setDeleting(false)
  }

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setSaving(true)

    let method = ''
    switch (props.mode) {
      case Mode.Create:
        method = 'POST'
        break
      case Mode.Edit:
        method = 'PUT'
        break
    }

    const res = await sendServiceData('AuthApi', 'v1', '/admin', admin, method)

    if (res.ok) {
      switch (props.mode) {
        case Mode.Create:
          defaultNotifications.created(Placeholder.Admin.Admin, admin.key)
          break
        case Mode.Edit:
          defaultNotifications.updated(Placeholder.Admin.Admin, admin.key)
          break
      }
      navigateBack()
    }
    else {
      defaultNotifications.error(Placeholder.Admin.Admin)
    }

    setSaving(false)
  }

  return (
    <ContentContainer
      title={getTitle()}
      fetchingData={fetchingData}
      backTo="../"
    >
      {channels && admin &&
        <form className='form' id='adminForm' onSubmit={handleSubmit(onSubmit)}>
          <Textbox
            label={Placeholder.Admin.Key}
            id='key'
            name='key'
            value={admin.key}
            onChange={handleInputChange}
            disabled={props.mode === Mode.Edit}
            validationRegister={register}
            validation={{
              required: true,
              maxLength: 255,
            }}
            errors={errors}
          />
          <Textbox
            label={Placeholder.Description}
            id='description'
            name='description'
            value={admin.description}
            onChange={handleInputChange}
            validationRegister={register}
            validation={{
              maxLength: 255,
            }}
            errors={errors}
          />
          <SelectBox
            label={Placeholder.Channels}
            name="channels"
            options={channels}
            getOptionLabel={channel => channel.displayName}
            getOptionValue={channel => channel.id}
            value={channels.filter(c => admin.channels.find(ac => ac.channelId == c.id))}
            isMulti
            onChange={handleChannelsChange}
          />
          <FormActions
            form="adminForm"
            onCancel={navigateBack}
            showDelete={props.mode === Mode.Edit}
            onDelete={onDelete}
            saving={saving}
            deleting={deleting}
          />
        </form>
      }
    </ContentContainer>
  )
}
export default Admin
