import React, { useEffect } from 'react'
import NavMenu from './NavMenu'
import '../components/layout/Typography.css'
import '../components/layout/Link.css'
import '../components/layout/List.css'
import '../components/layout/Headings.css'
import '../components/layout/Checkbox.css'
import '../components/layout/Input.css'
import '../components/layout/Button.css'
import '../components/layout/Forms.css'
import '../components/layout/Layout.css'
import '../components/layout/Colors.css'
import 'react-notifications-component/dist/theme.css'
import './notifications.css'
import { ReactNotifications } from 'react-notifications-component'
import Header from './layout/Header'
import { useSearchParams } from 'react-router-dom'
import Placeholder from './layout/Placeholder'
import { defaultNotifications } from '../utilis/Notifications'

export const NavMenuContext = React.createContext(null)

export default function Layout(props) {
  const [menuActive, setMenuActive] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('forbidden')) {
      defaultNotifications.error(null, Placeholder.Forbidden)
      searchParams.delete('forbidden')
      setSearchParams(searchParams)
    }
  }, [])

  return (
    <div className="IcaLayoutContainer lIcaLayoutContainer">
      <ReactNotifications />
      <NavMenuContext.Provider value={{ menuActive, setMenuActive }}>
        <div className="outerLayoutContainer">
          <Header />
          <div className="pronto-root lIcaLayoutContainer__inner">
            <NavMenu />
            <div className={"pronto-wrapper layout-relative"}>
              {props.children}
            </div>
          </div>
        </div>
      </NavMenuContext.Provider>
    </div>
  )
};
