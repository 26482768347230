import React from 'react'

import CampaignType from '../../utilis/CampaignType'

const OrderItemPrice = ({subTotal, discountSubTotal, campaignType})=>{

  const discount = discountSubTotal ? discountSubTotal: 0

  if(campaignType == CampaignType.Percentage) 
  {
    return (<div>
      {discount && discount > 0 && <span style={{'color':'red', 'text-decoration':'line-through'}}>
        {<span style={{'color':'black'}}>{subTotal.toFixed(2)}</span>}
      </span>}  <span style={{'color':'red'}}>{(subTotal - discount).toFixed(2)}</span>
    </div>)
  }
  return (<div>
    <span style={{'color':'black'}}>{subTotal.toFixed(2)}</span>
  </div>)
}

export default OrderItemPrice