import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import { ProntoContainer } from '../layout/Containers'
import Mode from '../../utilis/Mode'
import { ClickButton } from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import { defaultNotifications } from "../../utilis/Notifications"
import LoadingContainer from '../layout/LoadingContainer'
import Checkbox from '../Form/Checkbox'
import Textbox from '../Form/Textbox'

const Merchant = (props) => {
  const mode = props.mode
  const history = props.history
  const storeId = props.storeId ? props.storeId : props.match.params.storeid
  const itemId = props.id ? props.id : props.match.params.id || ""


  const [paymentTypes, setPaymentTypes] = useState([])
  const [merchant, setMerchant] = useState({})
  const [store, setStore] = useState({})
  const [channel, setChannel] = React.useState(props.channel ? props.channel : props.match.params.channel)
  const [name, setName] = React.useState("")
  const [token, setToken] = React.useState("")
  const [merchantId, setMerchantId] = React.useState("")
  const [paymentMethods, setPaymentMethods] = React.useState([])
  const [merchants, setMerchants] = useState([])
  const [fetching, setFetching] = React.useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      if (mode === Mode.Create) {
        await fetchStore()
      }
      await fetchPaymentTypes()
      await fetchMerchant()
      await fetchMerchants()
      setFetching(false)
    }

    const fetchStore = async () => {
      const res = await getData(`/api/stores/${storeId}`)
      setName(res.name)
      setStore(res)
    }

    const fetchPaymentTypes = async () => {
      const res = await getData('/api/paymentmethods/types')

      var types = []
      for (const prop in await res.types) {
        types.push({
          id: prop,
          value: res.types[prop]
        })
      }

      setPaymentTypes(types)
    }

    const fetchMerchant = async () => {
      if (!itemId) return
      const res = await getData(`/api/merchants/${channel}/${itemId}`)
      setMerchant(res)
    }

    const fetchMerchants = async () => {
      if (itemId) return
      const res = await getData(`/api/stores/${storeId}/merchants`)
      setMerchants(res)
      if (mode === Mode.Create) {
        var existingMerchants = res.filter(m => m.merchant)
        if (existingMerchants.length > 0) {
          setMerchantId(existingMerchants[0].merchant.payExMerchantId)
          setToken(existingMerchants[0].merchant.payExResourceToken)
        }
      }
    }

    fetchData()
  }, [storeId, itemId])

  React.useEffect(() => {
    if (merchant) {
      setName(merchant.name || "")
      setToken(merchant.payExResourceToken || "")
      setMerchantId(merchant.payExMerchantId || "")
      setPaymentMethods(merchant.paymentMethods || [])
    }
  }, [merchant])

  const handlePostData = async (event) => {
    event.persist()

    const merchantData = {
      ...merchant,
      name: name,
      payExResourceToken: token,
      paymentMethods: paymentMethods,
      payExMerchantId: merchantId
    }

    if (mode === Mode.Create) {
      merchantData.channel = channel
      merchantData.storeId = store.storeLocalNumber
      console.log(merchantData)
      const res = await postData("/api/merchants", merchantData)
      if (res) {
        defaultNotifications.created("Betalningsmetod", name)
        redirectToMerchants()
      } else {
        defaultNotifications.error("Betalningsmetod")
      }
    } else if (mode === Mode.Edit) {
      const res = await postData(`/api/merchants/${itemId}`, merchantData, false, undefined, "PUT")
      if (res) {
        defaultNotifications.updated("Betalningsmetod", name)
        redirectToMerchants()
      } else {
        defaultNotifications.error("Betalningsmetod")
      }
    }
  }

  function paymentMethodChange(e) {
    var newArray = [...paymentMethods]
    const value = e.currentTarget.value
    const checked = e.currentTarget.checked
    const existingIndex = newArray.findIndex(item => {
      return item.toLowerCase() === value.toLowerCase()
    })
    if (checked) {
      if (existingIndex === -1) {
        newArray.push(value)
      }
    } else {
      if (existingIndex >= 0) {
        newArray.splice(existingIndex, 1)
      }
    }
    setPaymentMethods(newArray)
  }

  function paymentTypeIsChecked(paymentType) {
    return paymentMethods.some(item => {
      return item.toLowerCase() === paymentType.value.toLowerCase()
    })
  }

  function getPaymentTypeIsDisabled(paymentType) {
    return channel === "icacatering" && paymentType.id === "10"
  }

  function redirectToMerchants() {
    history.push(`/stores/${storeId}/paymentMethods/payexMerchants/`)
  }

  function pageHeader() {
    if (mode === Mode.Create) {
      return Placeholder.Create + " kanal"
    } else if (merchant.channel && merchant.name) {
      return `${merchant.name} | ${merchant.channel}`
    }
    return ""
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.PayexSettings}
        editPage={true}
        {...props}
      >
        {pageHeader()}
      </BackButton>
      <ProntoContainer>
        <LoadingContainer loading={fetching}>
          <div className="form">
            {mode === Mode.Create &&
              <div>
                <label className="label" htmlFor="channel">
                  Kanal
                </label>
                <select className="input input-select" id="channel" name="channel" required={true} onChange={(e) => setChannel(e.target.value)}>
                  <option key="-1" value="">Välj kanal</option>
                  {merchants.filter(m => !m.merchant).map((item) => {
                    return <option key={item.channel} value={item.channel}>{item.channelName}</option>
                  })}
                </select>
              </div>
            }
            <Textbox
              id="name"
              label={Placeholder.Name}
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={Placeholder.Name}
            />
            <Textbox
              id="merchantId"
              label="PayEx merchant ID"
              name="merchantId"
              value={merchantId}
              onChange={(e) => setMerchantId(e.target.value)}
              placeholder="PayEx merchant ID"
            />
            <Textbox
              id="token"
              label="PayEx token"
              name="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="PayEx token"
            />

            {paymentTypes.map((paymentType, i) => (
              <Checkbox
                key={i}
                label={paymentType.value}
                id={`paymentType_${paymentType.id}`}
                name={`paymentType_${paymentType.id}`}
                checked={paymentTypeIsChecked(paymentType)}
                value={paymentType.value}
                onChange={paymentMethodChange}
                disabled={getPaymentTypeIsDisabled(paymentType)}
                {...getPaymentTypeIsDisabled(paymentType) ? { title: "Går ej att aktivera för Catering" } : {}}
              />
            ))}
            <div className="row">
              <ClickButton className="btn--black mr-10" title={Placeholder.Save} onClick={handlePostData} />
              <ClickButton className="btn--white" title={Placeholder.Cancel} onClick={redirectToMerchants} />
            </div>
          </div>
        </LoadingContainer>
      </ProntoContainer>
    </div>
  )

}

export default Merchant

