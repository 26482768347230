import React, {  useState,useEffect } from 'react'
import { getData } from '../../services/Ajax'
import {ProntoContainerTop} from '../layout/Containers'
import ArrowListItem from '../layout/ArrowListItem'
import BackButton from '../backbutton/BackButton'


const GroceryImport = (props)=> {
  const storeId = props.match.params.storeid
  const [roles, setRoles] = useState({isAdmin: false, isReader: true })
 
  useEffect(() => {
    if (storeId) {
      const fetchData = async () => {
        const readOnlyResponse = await getData('api/navigation/roles')
        const roles = await readOnlyResponse
        setRoles(roles)
      }

      fetchData()
    }
  }, [])


  const ArrowListItems = ( roles) => {
    var items = [
      { url: `/stores/${storeId}/imports/price`, name: 'Uppdatera pris' }
    ]

    if(roles.isAdmin){
      items = items.concat([
        { url: `/stores/${storeId}/imports/online`, name: 'Från online' },
        { url: `/stores/${storeId}/imports/file`, name: 'Från fil' },
        { url: `/stores/${storeId}/imports/storeoffice`, name: 'Från storeoffice' },
        { url: `/stores/${storeId}/imports/feed`, name: 'Manuell updatering av ProductFeed' },
        { url: `/stores/${storeId}/imports/promotions`, name: 'Manuell omräkning av Promotions' },
      ])
    }
    return items
  }

  return (
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Välj importeringsmetod</BackButton>
 
      <div className="mid-container arrow-list drop-shadow">
        <div className="arrow-list__header arrow-list__header--space-between">
          <div className="arrow-list__header__element label">#Metod</div>
        </div>
        <ul className="no-style">
          {ArrowListItems(roles).map((item, index) => (
            <ArrowListItem key={index} url={item.url} name={item.name} />
          ))}
        </ul>
      </div>
        
    </ProntoContainerTop>
       
  )
  
}

export default GroceryImport