import React, { useState, useEffect } from 'react'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import CampaignListItem from './CampaignListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

const Campaigns = (props)=> {
  const [data, setData] = useState([])
  const [store, setStore] = useState({})
  const [campaignItems, setCampaignItems] = useState()
  const storeId = props.match.params.storeid

  useEffect(() => {
    const fetchData = async () => {
      if (storeId) {
        const storeResponse = await getData(
          '/api/stores/' + storeId
        )
        const storeResult = await storeResponse
        setStore(storeResult)
      }

      const url = storeId ? `/api/campaigns/store/${storeId}` :  '/api/campaigns/'
      if(url){
        const response = await getData(
          url
        )
        const result = await response
        setData(result)
        if(storeId){
          setCampaignItems(result)
        }
      }
    }

    fetchData()
  }, [])

  const backLabel = storeId ? store.name : ""
  const disabled = !storeId

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`${props.location.pathname}create/`} title={`${Placeholder.Create} kampanj`} />

      <BackButton backLabel={backLabel} disabled={disabled} {...props}>{Placeholder.Campaigns}</BackButton>

      {
        campaignItems && campaignItems.length > 0 &&
                  <div className="stats-container-wrapper drop-shadow">
                    <div className="stats-container-wrapper__header">
                      <h3>{Placeholder.ActiveCampaign}</h3>
                    </div>
                    <div className="stats-container-wrapper__body">
                      <div className="stats-container">
                        { campaignItems.map((item, index) => (
                          <div key={index} className="stats-container__item">
                            {<h4 className="stats-container__headline">{item.name} </h4> }
                            {<span className="campaign-container__values">{item.numberOfTimeUsed}</span>}

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
      }

      <ul className="no-style drop-shadow">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data?.map(item => (
          <li key={item.id}>
            <CampaignListItem data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}


export default Campaigns