import React, { useState, useEffect } from 'react'
import {getData} from '../../services/Ajax'
import Placeholder from '../layout/Placeholder'
import {TopAddButton} from '../layout/Button'
import RestaurantListItem from './RestaurantListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'

const Restaurants = (props) => {
  const [data, setData] = useState( [] )
  const storeId = props.match.params.storeid

  useEffect(() => {
    const fetchData = async () => {
      if(storeId){
        const response = await getData(
          '/api/restaurants/'+storeId
        )
        const result = await response
        setData(result)
      }

    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/restaurants/create/`} title={`${Placeholder.Create} resturang`} />
      <BackButton {...props}>{Placeholder.Restaurants}</BackButton>
      <ul className="no-style drop-shadow restaurants-table">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">Säljkanaler</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data && data.restaurants && data.restaurants.map(item => (
          <li key={item.id}>
            <RestaurantListItem data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}


export default Restaurants