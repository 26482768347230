
import React, { useState, useEffect } from 'react'
import {postData} from '../../services/Ajax'
import { getData } from "../../services/HttpClient"
import {ProntoContainerTop} from '../layout/Containers'
import { ClickButton} from '../layout/Button'
import BackButton from '../backbutton/BackButton'
import DatePicker from "react-datepicker"
import Moment from "moment"

const Resend = (props)=> {
  var start = new Date(new Date().getTime())
  var end = new Date()
  const storeId = props.storeid ? props.storeid: props.match.params.storeid
  const [isDisabledAoB, setIsDisabledAoB] = useState( false )
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)

  const [bms, setBms] = useState()
  const [search, setSearch] = useState({StartDate: start, EndDate: end})

  useEffect(() => {
    const fetchData = async () => {

      if (storeId) {
        const res = await getData('/api/stores/' + storeId)
        if (res.ok) {
          setBms(res.data.storeLocalNumber)
        }
      }
    }

    fetchData()
  }, [storeId])

  function handleDateChanges(date, name) {
    date = Moment(date).format('YYYY-MM-DD')

    if (name === 'startDate') {
      setStartDate(date)
      search.StartDate = date
    } else {
      setEndDate(date)
      search.EndDate = date
    }
    setSearch(search)
    setIsDisabledAoB(false)
  }

  const handleAoBPostData = async (event) => {
    event.persist()
    const url = '/api/orders/resend/aob'
    if(url){
      setIsDisabledAoB(true)
      const body = { bms, StartDate: search.StartDate, EndDate: search.EndDate }
      const response = await postData(
        url,body,true
      )
      await response

    }else{
      console.error("url not defined")
    }
  }

  return (
            
    <ProntoContainerTop className="scroll-x-mobile">
      <BackButton editPage={false} disabled={!storeId} {...props}>Skicka om ordrar</BackButton>
 
      <div className="top-container gutter bg--white">
        <div className="form">
          <div className="form-section">
      
            <div className="row">
              <DatePicker  locale = "sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(startDate ? startDate : new Date())} name="StartDate"
                placeholderText="startdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChanges(date,"startDate")}/>
              <span className="label form-label ml-10">Startdatum</span>
            </div>
            <div className="row">
              <DatePicker  locale = "sv"
                dateFormat="yyyy-MM-dd"
                selected={new Date(endDate ? endDate : new Date())} name="EndDate"
                placeholderText="slutdatum (yyyy-mm-dd)"
                onChange={(date) => handleDateChanges(date,"endDate")}/>
              <span className="label form-label ml-10">Slutdatum</span>
            </div>
            <div className="row">
              <ClickButton className="btn--black" title={"Skicka om AoB för BMS"} disabled={isDisabledAoB} onClick={handleAoBPostData}/>
            </div>
          </div>
        </div>
      </div>
        
    </ProntoContainerTop>
              
  )

}


export default Resend
