import React, { useState, useEffect } from 'react'
import Placeholder from '../layout/Placeholder'
import {getData} from '../../services/Ajax'
import {TopAddButton} from '../layout/Button'
import ThemeListItem from './ThemeListItem'
import {ProntoContainerTop} from '../layout/Containers'
import BackButton from '../backbutton/BackButton'


const Themes = (props)=> {
  const [data, setData] = useState({})
  const storeId = props.match.params.storeid
  const referenceType = "themes"
  useEffect(() => {
    const fetchData = async () => {
      if(storeId){
        const response = await getData(
          `/api/groups/${storeId}/${referenceType}`
        )
        const result = await response
        setData(result)
      }
    }

    fetchData()
  }, [])

  return (
    <ProntoContainerTop>
      <TopAddButton className="flex-right btn-control btn--red" to={`/stores/${storeId}/themes/create/`} title={`${Placeholder.Create} tema`} />
      <BackButton {...props}>{Placeholder.Themes}</BackButton>
      <ul className="no-style drop-shadow">
        <li>
          <div className="list-item list-item__heading">
            <div className="list-item__element label hidden-down">{Placeholder.Image}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Name}</div>
            <div className="list-item__element list-item__element--s label hidden-down">{Placeholder.Active}</div>
            <div className="list-item__element label hidden-down">{Placeholder.Change}</div>          
          </div>
        </li>
        {data && data.groups && data.groups.map(item => (
          <li key={item.id}>
            <ThemeListItem data={item} {...props}/>
          </li>
        ))}
      </ul>
    </ProntoContainerTop>
  )
  
}

export default Themes