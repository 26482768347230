import React from 'react'
import ItemList, { ItemListColumn } from "../ItemList/ItemList"
import Placeholder from '../layout/Placeholder'
import { formatAmount } from '../../utilis/PricingUtils'
import { generateVariants } from '../../utilis/catering/productVariantUtils'
import CheckboxV2 from '../layout/CheckboxV2'
import SelectList from '../Form/SelectList'
import MessageBox from '../layout/MessageBox'
import Button from '../UI/Button'
import { OnChangeAction } from '../../utilis/catering/productVariantUtils'

function VariantCategoryDeleteForm({
  variantCategories,
  variantCategoryToDelete,
  currentVariants,
  productInfo,
  onSave
}) {
  const [generatedVariants, setGeneratedVariants] = React.useState([])

  const currentVariantsMod = currentVariants.map(cv => ({
    ...cv,
    optionLabel: cv.name
  }))

  React.useEffect(() => {
    let newVariantCategories = [...variantCategories].filter(vc => vc !== variantCategoryToDelete)
    let generated = generateVariants(newVariantCategories, currentVariants, OnChangeAction.Delete)
    setGeneratedVariants(generated)
  }, [variantCategories, variantCategoryToDelete])


  function onSaveClick() {
    onSave([...variantCategories].filter(vc => vc !== variantCategoryToDelete), generatedVariants)
  }

  function onCopyDataChange(e, selectedOption, generatedVariant) {
    var newArray = [...generatedVariants]
    var index = generatedVariants.indexOf(generatedVariant)
    if (~index) {
      if (selectedOption) {
        newArray[index] = {
          ...generatedVariant,
          price: selectedOption.price,
          ean: selectedOption.ean,
          active: selectedOption.active
        }
      } else {
        newArray[index] = {
          ...generatedVariant,
          price: "",
          ean: "",
          active: true
        }
      }
    }

    setGeneratedVariants(newArray)
  }

  return (
    <div>
      <h2>Granska och hantera ny uppsättning av varianter</h2>
      <MessageBox
        type='info'
        withTitle={true}
        title='OBS!'
        message='Radering av ett variantval resulterar i ny uppsättning av varianter och tidigare information kring pris/ean/aktivitet försvinner. Om du vill hämta information från någon av de tidigare varianterna kan du välja det i dropdownlistan.'
      />
      <h2>Nya varianter</h2>
      <ItemList
        data={generatedVariants}
        showEmpty={false}
        shadow={false}
        stickyHeader={true}
        idProp="name"
        renderRow={(item) =>
          <React.Fragment>
            <ItemListColumn id="name" label={Placeholder.Name}>
              {item.name}
            </ItemListColumn>
            <ItemListColumn id="price" label={Placeholder.Price} columns={2} className="nowrap">
              {item.price
                ? formatAmount(item.price / 100.0)
                : formatAmount(productInfo.price / 100.0)
              }
            </ItemListColumn>
            <ItemListColumn id="ean" label={Placeholder.Ean} columns={2}>
              {item.ean 
                ? item.ean
                : productInfo.ean || "---"
              }
            </ItemListColumn>
            <ItemListColumn id="active" label={Placeholder.Active} narrow>
              <CheckboxV2
                name="active"
                id={`isActive-${item.name}`}
                checked={item.active}
                readonly
              />
            </ItemListColumn>
            <ItemListColumn id="copyData" label="Hämta tidigare information" columns={4}>
              <SelectList
                options={currentVariantsMod}
                optionValueField="name"
                optionDisplayField="optionLabel"
                defaultOption="(Hämta inte)"
                onChange={(e, selectedOption) => onCopyDataChange(e, selectedOption, item)}
              />
            </ItemListColumn>
          </React.Fragment>
        }
      />

      <Button type="save" onClick={onSaveClick}>
        {Placeholder.OK}
      </Button>
    </div>
  )
}

export default VariantCategoryDeleteForm
