import slugify from "slugify"

slugify.extend({'%': ' procent'})
slugify.extend({'&': 'och'})

export function toSlug(input) {
  return slugify(input, {
    lower: true,
    remove: /[^A-Za-z0-9\\-\s?]/g
  })
}
