import React from "react"
import "./InputLabel.css"

function InputLabel({ children, label, className, ...props }) {
  const cssClass = className ? `input-label ${className}` : "input-label"
  return (
    <label className={cssClass} {...props}>
      {label}
      {children}
    </label>
  )
}

export default InputLabel