import React from "react"
import { sendServiceData, getServiceData } from "../../services/HttpClient"
import Placeholder from "../layout/Placeholder"
import { ProntoContainer } from "../layout/Containers"
import BackButton from "../backbutton/BackButton"
import { defaultNotifications } from "../../utilis/Notifications"
import Textbox from '../Form/Textbox'
import { useForm } from "react-hook-form"
import FormActions from "../Form/FormActions"
import LoadingContainer from "../layout/LoadingContainer"
import MessageBox from '../layout/MessageBox'
import "./FormStyles.css"

function EditGroup({ history, match, ...props }) {
  const {
    params: { storeid, groupId },
  } = match || {}

  const { register, reset, handleSubmit, formState: { errors } } = useForm()

  const [name, setName] = React.useState("")
  const [categories, setCategories] = React.useState([])
  const [error, setError] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const categoryHasProducts = categories.some((category) => category.productCount > 0)


  React.useEffect(() => {
    let componentMounted = true

    async function getGroup() {
      setFetching(true)
      try {
        const res = await getServiceData('FoodApi', 'v1', `/catering/${storeid}/maincategories/${groupId}`)

        if (componentMounted && res.ok) {
          reset(res.data)
          setName(res.data.name)
          setCategories(res.data.categories)
        }
      } catch (error) {
        console.log(error)
      }
      setFetching(false)
    }
    if (storeid && groupId) {
      getGroup()
    }
    return () => {
      componentMounted = false
    }
  }, [storeid, groupId])

  async function onSubmit(validationData, e) {
    e.preventDefault()
    setError(false)
    setSaving(true)

    const res = await sendServiceData(
      'FoodApi',
      'v1', 
      `/catering/${storeid}/maincategories/${groupId}`,
      {
        name
      },
      "PUT"
    )

    if (res.ok) {
      defaultNotifications.updated("Grupp", name)
      redirectToCategories()
    } else {
      defaultNotifications.error("Grupp")
    }

    setSaving(false)
  }

  async function onRemove(e) {
    e.preventDefault()
    setError(false)

    if (categoryHasProducts) {
      setError(true)
      setDeleting(false)
      return
    }

    if (!window.confirm(`Är du säker på att du vill ta bort "${name}"?`)) {
      return
    }

    setDeleting(true)

    const res = await sendServiceData(
      'FoodApi',
      'v1', 
      `/catering/${storeid}/maincategories/${groupId}`,
      null,
      "DELETE"
    )

    if (res.ok) {
      defaultNotifications.deleted("Grupp", name)
      redirectToCategories()
    } else {
      defaultNotifications.error("Grupp")
    }

    setDeleting(false)
  }

  function redirectToCategories() {
    history.push(`/stores/${storeid}/catering/categories`)
  }

  return (
    <div>
      <BackButton
        backLabel={Placeholder.Catering.Name}
        editPage={true}
        match={match}
        history={history}
        {...props}
      >
        {Placeholder.Edit} rubrik
      </BackButton>
      <ProntoContainer>
        <LoadingContainer loading={fetching}>
          <form className="form" id="groupForm" onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              label="Rubrik för kategorier"
              placeholder="Ex. Vår cateringmeny"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationRegister={register}
              validation={{ required: true }}
              errors={errors}
            />
            <FormActions 
              form="groupForm"
              showDelete
              onCancel={() =>
                history.push(`/stores/${storeid}/catering/categories`)
              }
              onDelete={onRemove}
              saving={saving}
              deleting={deleting}
            />
          </form>
          {error && 
           <MessageBox
             type='info'
             withTitle={true}
             title='OBS! Kategorin kan inte tas bort'
             message='Säkerställ att kategorin inte innehåller några produkter.'
           />
          }
        </LoadingContainer>
      </ProntoContainer>
    </div>
  )
}

export default EditGroup
