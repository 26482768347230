import React from 'react'
import Checkbox from '../layout/Checkbox'
import Placeholder from '../layout/Placeholder'

const GetOffer=(channel,offers) =>{
  if(offers){
    var currentOffer = offers.filter(x => x.purchaseChannelTypeId == channel.id)[0]
    return currentOffer
  }
}

const Price = ({ean,channel, offers,handleEvent}) => {
  let price = 0
  var currentOffer = GetOffer(channel,offers)
  if(currentOffer){
    price = currentOffer.price
  }
  const name =  `${ean}__${channel.id}__price`
    
  return (   <div className={`list-item__element list-item__element-fixed-height ${channel.checked ? "" : "hidden"}`}>
    <input className="input" type="number" onChange={handleEvent} name={ name} defaultValue={price} placeholder={Placeholder.Price}/>
  </div> )
}

const Active = ({ean,channel, offers,handleEvent}) => {
  var currentOffer = offers.filter(x => x.purchaseChannelTypeId == channel.id)[0]
  var offerId =  channel.id
  const active = currentOffer && currentOffer.active
    
  return ( <div className={`llist-item__element list-item__element-fixed-height list-item__element--s ${channel.checked ? "" : "hidden"}`}>
    <Checkbox checked={active} name={ `${ean}__${offerId}__active`} defaultValue={active} handleEvent={handleEvent}/>     
    <span className="label form-label hidden-up">{Placeholder.Active}</span>   
  </div>        )
}


function CatagoryProduct(props) {
  const availableChannels = props.availableChannels
  const item = props.data
  const handleEvent = props.handleEvent
  return (
    <div className="list-item">
      <div className="list-item__element"><img className="list-item__img" src={item.imageUrl} alt=""/></div>
      <div className="list-item__element">
        {item.groceryDisplayName}
      </div>
      <div className="list-item__element">{item.ean}</div>

      <div className="list-item__element list-item__element--column">
        {availableChannels && availableChannels.map((channel,index ) => (
          <div key={"name"+index} className={`list-item__element list-item__element-fixed-height ${channel.checked ? "" : "hidden"}`}>{channel.displayName}</div>
        ))}
      </div>

      {/* <div className="list-item__element list-item__element--column">
             {availableChannels && availableChannels.map((channel,index )=> (
                     <RecommendedPrice  key={"rprice"+index} ean={item.ean} channel={channel} offers={item.offers} defaultValue={item.price} handleEvent={handleEvent}/>
            ))}
            </div> */}

      <div className="list-item__element list-item__element--column">
        {availableChannels && availableChannels.map((channel,index ) => (
          <Price  key={"price"+index} ean={item.ean} channel={channel} offers={item.offers} handleEvent={handleEvent}/>
        ))}
      </div>

      <div className="list-item__element list-item__element--column">
        {availableChannels && availableChannels.map((channel,index ) => (
          <Active key={"active"+index} ean={item.ean} channel={channel} offers={item.offers} handleEvent={handleEvent}/>
        ))}
      </div>
            
         
            
    </div>
  )
  
}

export default CatagoryProduct

